import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlModelsService } from '../ll-models.service';
import { LlCompaniesService } from '../ll-companies.service';
import { LlListingsService } from '../ll-listings.service';
import { LlRolesService } from '../ll-roles.service';
import { LlContactsService } from '../ll-contacts.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { LlUtilityService } from '../ll-utility.service';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxDateTimeInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput";
import { javaDate_to_YYYYMMDD } from '../utility-date';
import { STDDate_to_CCYYMMDD } from "../utility-date";

@Component({
  selector: 'll-admin-generic-dg',
  templateUrl: './ll-admin-generic-dg.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlAdminGenericDgComponent implements OnInit {

  @ViewChild('mainDiv_ll_admin_generic_dg') mainDiv: ElementRef;
  @ViewChild('dataGrid_ll_admin_generic_dg') datagrid: jqxGridComponent;
  @ViewChild('popover_remove_ll-admin-generic-dg') private popover_remove: jqxPopoverComponent;
  @ViewChild('date_monthEnd') date_monthEnd: jqxDateTimeInputComponent;

  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlCompaniesService: LlCompaniesService,
    private LlModelsService: LlModelsService,
    private LlListingsService: LlListingsService,
    private LlRolesService: LlRolesService,
    private LlContactsService: LlContactsService,
    private LlPermissionsService: LlPermissionsService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlUtilityService: LlUtilityService,
  ) { }

  bundle = {

    showDG: false,
    showCompany: false,
    canRemove: false,
    showDownloadBtn:true,
    removeBtnLang: '',
    removePULang: '',
    function:'',
    headerLable: "",
    headerDescription: "",
    activeCODE: '',
    fileLabel:'',
    horzTabs: [],
    columns: [],
    records: [],
    datagrid: {},
    LlInfoRequestsService: this.LlInfoRequestsService,
    LlModelsService: this.LlModelsService,
    LlListingsService: this.LlListingsService,
    LlRolesService: this.LlRolesService,
    LlContactsService: this.LlContactsService,
    LlPermissionsService: this.LlPermissionsService,
    LlScrollpaneService: this.LlScrollpaneService,
    LlUtilityService: this.LlUtilityService,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    gridY: 40,
    gridW: 100,
    gridH: 100,
  }

  ngOnInit() { }

  eventSubscription_reflectChange: any;

  ngOnDestroy() {
    this.eventSubscription_reflectChange.unsubscribe();
  }

  ngAfterViewInit() {

    this.eventSubscription_reflectChange = this.LlModelsService.ll_admin_generic_dg_reflectChange.subscribe(ARR => {


      ///console.log("eventSubscription_reflectChange - ARR[0].TRIGGER: " + ARR[0].TRIGGER)

      for (let i = 0; i < this.bundle.records.length; i++) {

        if (ARR[0].TRIGGER == 'payer' && this.bundle.records[i].PAYERID == ARR[0].PAYERID) {

          this.bundle.records[i].LIMIT_BILLED_AGECAPDAYS = ARR[0].LIMIT_BILLED_AGECAPDAYS
          this.bundle.records[i].LIMIT_BILLEDADVANCE_PCT = ARR[0].LIMIT_BILLEDADVANCE_PCT
          this.bundle.records[i].LIMIT_UNBILLED_AGECAPDAYS = ARR[0].LIMIT_UNBILLED_AGECAPDAYS
          this.bundle.records[i].LIMIT_UNBILLEDADVANCE_PCT = ARR[0].LIMIT_UNBILLEDADVANCE_PCT
          this.bundle.records[i].LIMIT_ENVCALCBUCKET_DAYS = ARR[0].LIMIT_ENVCALCBUCKET_DAYS
          this.bundle.records[i].LIMIT_RESERVES_AMT = ARR[0].LIMIT_RESERVES_AMT

          this.datagrid.updatebounddata();

        } else if (ARR[0].TRIGGER == 'location_cms' && this.bundle.records[i].LOCATIONID == ARR[0].LOCATIONID) {

          this.bundle.records[i].FEDERALPROVIDERNUMBER = ARR[0].FEDERALPROVIDERNUMBER
          this.bundle.records[i].PROVIDERNAME = ARR[0].PROVIDERNAME
          this.bundle.records[i].LEGALBUSINESSNAME = ARR[0].LEGALBUSINESSNAME
          this.bundle.records[i].PROVIDERADDRESS = ARR[0].PROVIDERADDRESS
          this.bundle.records[i].PROVIDERCITY = ARR[0].PROVIDERCITY
          this.bundle.records[i].PROVIDERSTATE = ARR[0].PROVIDERSTATE

          this.datagrid.updatebounddata();

        }


      }



    });


    this.setVars();
  }

  setVars() {

    this.bundle.datagrid = this.datagrid;
    this.bundle.horzTabs.splice(0);

    setTimeout(() => {
      this.bundle.function = this.mainDiv.nativeElement.parentElement.id;

      this.bundle.headerLable = "";
      this.bundle.headerDescription = "";

      if (this.bundle.function == "info_request-pending") {
        this.bundle.headerLable = "Incomplete Information Requests"
        this.bundle.headerDescription = "Active Accounts/Clients Only (Click Row to Open Company View)"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.activeCODE = '1';

        this.bundle.horzTabs.push({ LABEL: "Entry", CODE: "1" });
        this.bundle.horzTabs.push({ LABEL: "Review", CODE: "2" });

      } else if (this.bundle.function == "info_request-pending-display") {
        this.bundle.headerLable = "Incomplete Information Requests"
        this.bundle.headerDescription = "Active Accounts/Clients Only (Click Row to Open Company View)"

      } else if (this.bundle.function == "user_logins_dg" || this.bundle.function == "logins_from_ll_company_switch") {
        this.bundle.headerLable = "User Log In History  (within 1 Year)"
        this.bundle.fileLabel = this.bundle.headerLable;

      } else if (this.bundle.function == "user_emails_dg" || this.bundle.function == "emails_from_ll_company_switch") {
        this.bundle.headerLable = "User Email History (within 1 Year)"
        this.bundle.fileLabel = this.bundle.headerLable;

      } else if (this.bundle.function == "admin_trail_dg" || this.bundle.function == "adminTrail_from_ll_company_switch") {
        this.bundle.headerLable = "Administration Activity Trail (within 1 Year)"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""

      } else if (this.bundle.function == "view_errors_dg" || this.bundle.function == "errors_from_ll_company_switch") {
        this.bundle.headerLable = "System Errors (within 1 Year)"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""

      } else if (this.bundle.function == "user_entry_dg" || this.bundle.function == "entry_from_ll_company_switch") {
        this.bundle.headerLable = "User Entry History (within 1 Year)"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""

      } else if (this.bundle.function == "NG_utility_customerComments_get") {
        this.bundle.headerLable = "Client Comments (within 1 Year)"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""

      } else if (this.bundle.function == "admin_accounting_dg") {
        this.bundle.headerLable = "Monthly Billing - Accounting  Month End: "
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""

        var n = new Date();
        this.LlUtilityService.setMonthEnd(javaDate_to_YYYYMMDD(n))

      } else if (this.bundle.function == "admin_ARModels_dg") {
        this.bundle.headerLable = "Monthly Billing - AR Models Month End: "
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""

        var n = new Date();
        this.LlUtilityService.setMonthEnd(javaDate_to_YYYYMMDD(n))
      
      } else if (this.bundle.function == "model_permissions_dg") {
        this.bundle.headerLable = "Model Access Permissions"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = " - Edit Loan Viewing Permissions"

        if (this.LlPermissionsService.check("assignLoans") == false) {
          this.bundle.headerLable = "Loan Viewing Permissions";
          this.bundle.fileLabel = this.bundle.headerLable;
        }

      } else if (this.bundle.function == "NG_LL_model_AR_admin_client_interface_records_get") {
        this.bundle.headerLable = "File Processing Status"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.activeCODE = 'Incomplete';

        this.bundle.horzTabs.push({ LABEL: "Incomplete", CODE: "Incomplete" });
        this.bundle.horzTabs.push({ LABEL: "Complete", CODE: "Complete" });
        this.bundle.horzTabs.push({ LABEL: "Archive", CODE: "Archive" });
        this.bundle.horzTabs.push({ LABEL: "Disregard", CODE: "Disregard" });
        this.bundle.horzTabs.push({ LABEL: "All", CODE: "All" });

      } else if (this.bundle.function == "edit-roles-dg") {
        this.bundle.headerLable = "User Role Permissions"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = " - Edit Role Permissions"

      } else if (this.bundle.function == "model_locations_dg") {
        this.bundle.headerLable = "Model Locations"
        this.bundle.fileLabel = this.bundle.headerLable;

        if (this.LlPermissionsService.check("editModels")) {
          this.bundle.headerDescription = "Check Include box to include in colateral. Check Find box to match FED-ID"
        }

      } else if (this.bundle.function == "model_payers_dg") {
        this.bundle.headerLable = "Model Payers"
        this.bundle.fileLabel = this.bundle.headerLable;

        if (this.LlPermissionsService.check("editModels")) {
          this.bundle.headerDescription = "Check Eligible box to include in colateral. Check Edit box to change"
        }

      } else if (this.bundle.function == "class_payers_dg") {
        this.bundle.headerLable = "Model Payer Classes-Types"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""
        this.bundle.removeBtnLang = "Remove Payer Class"
        this.bundle.removePULang = "Are you certain you wish to remove the highlighted Payer Class?"

      } else if (this.bundle.function == "model_reports_dg") {
        this.bundle.headerLable = "Model Reports"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""
        this.bundle.gridY = 0;

      } else if (this.bundle.function == "model_location_cms_dg") {
        this.bundle.headerLable = "CMS Provider Survey"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""
        this.bundle.gridY = 0;

      } else if (this.bundle.function == "quest_listing_dg") {
        this.bundle.headerLable = "Listings"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""
        this.bundle.activeCODE = '';

        this.bundle.horzTabs.push({ LABEL: "Active", CODE: "A" });
        this.bundle.horzTabs.push({ LABEL: "Inactive", CODE: "N" });

     ///} else if (this.bundle.function == "quest_listing_columnGroupEdit_dg") {
        ///this.bundle.headerLable = "Column Group Columns"
        //this.bundle.fileLabel = this.bundle.headerLable;
        //this.bundle.headerDescription = "Check to include"

      } else if (this.bundle.function == "edit-contact-roles-dg") {
        this.bundle.headerLable = "Application Roles"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = "Check-Uncheck to Add-Remove"
        this.bundle.gridY = 40;
        this.bundle.showDownloadBtn = false;

      } else if (this.bundle.function == "edit-contact-permissions-dg") {
        this.bundle.headerLable = "Client Model Permissions"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = ""
        this.bundle.gridY = 40;
        this.bundle.showDownloadBtn = false;

        this.bundle.horzTabs.push({ LABEL: "Check All", CODE: "SELECT_All" });
        this.bundle.horzTabs.push({ LABEL: "Uncheck All", CODE: "CLEAR_All" });

      } else if (this.bundle.function == "email-routine-recipients_dg") {
        this.bundle.headerLable = "Email Routine Recipients"
        this.bundle.fileLabel = this.bundle.headerLable;
        this.bundle.headerDescription = "Check user box to receive email"
      
        this.bundle.horzTabs.push({ LABEL: "Check All", CODE: "SELECT_All" });
        this.bundle.horzTabs.push({ LABEL: "Uncheck All", CODE: "CLEAR_All" });

      }

      this.bundle.showDG = false;

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      this.bundle.gridW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth - 20;
      this.bundle.gridH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight - this.bundle.gridY - 40;

      this.bundle.remainingW = this.bundle.parentW;
      this.bundle.remainingH = this.bundle.parentH;

      this.datagrid.width(this.bundle.gridW);
      this.datagrid.height(this.bundle.gridH);


      this.getData(this.bundle);

    });
  }

  getData(bundle) {

    if (bundle.function == "info_request-pending") {
      bundle.LlInfoRequestsService.NG_LL_infoReq_subjectVisits_pending_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "info_request-pending-display") {
      bundle.LlInfoRequestsService.NG_LL_infoReq_subjectVisits_pendingDates_get(done_getPendingDates, bundle, bundle.datagrid);

    } else if (bundle.function == "user_logins_dg") {
      bundle.LlUtilityService.NG_utility_loginRecord_get(paintGrid, bundle, bundle.datagrid, 'all');

    } else if (bundle.function == "logins_from_ll_company_switch") {
      bundle.LlUtilityService.NG_utility_loginRecord_get(paintGrid, bundle, bundle.datagrid, 'company');

    } else if (bundle.function == "user_emails_dg") {
      bundle.LlUtilityService.NG_utility_emailRecords_get(paintGrid, bundle, bundle.datagrid, 'all');

    } else if (bundle.function == "emails_from_ll_company_switch") {
      bundle.LlUtilityService.NG_utility_emailRecords_get(paintGrid, bundle, bundle.datagrid, 'company');

    } else if (bundle.function == "admin_trail_dg") {
      bundle.LlUtilityService.NG_utility_adminTrail_get(paintGrid, bundle, bundle.datagrid, 'all');

    } else if (bundle.function == "adminTrail_from_ll_company_switch") {
      bundle.LlUtilityService.NG_utility_adminTrail_get(paintGrid, bundle, bundle.datagrid, 'company');

    } else if (bundle.function == "view_errors_dg") {
      bundle.LlUtilityService.NG_utility_errorLog_get(paintGrid, bundle, bundle.datagrid, 'all');

    } else if (bundle.function == "errors_from_ll_company_switch") {
      bundle.LlUtilityService.NG_utility_errorLog_get(paintGrid, bundle, bundle.datagrid, 'company');

    } else if (bundle.function == "user_entry_dg") {
      bundle.LlUtilityService.NG_utility_userEntry_get(paintGrid, bundle, bundle.datagrid, 'all');

    } else if (this.bundle.function == "NG_utility_customerComments_get") {
      bundle.LlUtilityService.NG_utility_customerComments_get(paintGrid, bundle, bundle.datagrid, 'all');

    } else if (bundle.function == "entry_from_ll_company_switch") {
      bundle.LlUtilityService.NG_utility_userEntry_get(paintGrid, bundle, bundle.datagrid, 'company');

    } else if (bundle.function == "admin_accounting_dg") {  
      bundle.LlUtilityService.NG_utility_accounting_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "admin_ARModels_dg") {
      bundle.LlUtilityService.NG_utility_accounting_ARModels_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "model_permissions_dg") {
      bundle.LlContactsService.NG_LL_contact_all_model_permissions_picklist_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "NG_LL_model_AR_admin_client_interface_records_get") {
      bundle.LlUtilityService.NG_LL_model_AR_admin_client_interface_records_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "edit-roles-dg") {
      bundle.LlRolesService.NG_LL_system_roles_get(done_getSysRoles, bundle, bundle.datagrid);

    } else if (bundle.function == "model_locations_dg") {
      bundle.LlModelsService.NG_LL_model_AR_locations_picklist_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "model_payers_dg") {
      bundle.LlModelsService.NG_LL_model_AR_payers_picklist_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "class_payers_dg") {
      bundle.LlModelsService.NG_LL_model_AR_payerClasses_picklist_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "model_reports_dg" || bundle.function == "model_location_cms_dg") {
      bundle.LlModelsService.NG_LL_model_AR_reports_grid_elements_get(done_NG_LL_model_AR_reports_grid_elements_get, bundle, bundle.datagrid);
   
    } else if (bundle.function == "quest_listing_dg") {
      bundle.LlListingsService.NG_LL_infoReq_listing_columns_get(done_NG_LL_infoReq_listing_columns_get, bundle, bundle.datagrid);

    //} else if (bundle.function == "quest_listing_columnGroupEdit_dg") {
      //bundle.LlListingsService.NG_LL_infoReq_listing_columns_pickList_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "edit-contact-roles-dg") {      
      bundle.LlContactsService.NG_LL_contact_roles_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "edit-contact-permissions-dg") {
      bundle.LlContactsService.NG_LL_contact_model_permissions_picklist_get(paintGrid, bundle, bundle.datagrid);

    } else if (bundle.function == "email-routine-recipients_dg") {
      bundle.LlUtilityService.NG_utility_email_recipients_picklist_get(paintGrid, bundle, bundle.datagrid);

    }

    function done_getSysRoles(arr, bundle, datagrid) {
      bundle.columns = arr;

      bundle.LlRolesService.NG_LL_system_roles_permisions_get(paintGrid, bundle, datagrid);
    }

     function done_getPendingDates(arr, bundle, datagrid) {
      bundle.columns = arr;

      bundle.LlInfoRequestsService.NG_LL_infoReq_subjectVisits_pendingValues_get(paintGrid, bundle, datagrid);
    }

    function done_NG_LL_infoReq_listing_columns_get(arr, bundle, datagrid) {
      bundle.columns = arr;

      bundle.LlListingsService.NG_LL_infoReq_listing_get(paintGrid, bundle, datagrid);
    }

    function done_NG_LL_model_AR_reports_grid_elements_get(arr, bundle, datagrid) {

      //console.log(arr)

      bundle.columns = arr;

      bundle.LlModelsService.getReportData(paintGrid, bundle, datagrid);
    }

    function paintGrid(records, bundle, datagrid) {

      bundle.records = records;

   ///console.log(bundle.records)

      var fields = [];
      var columns = [];

      let colW01 = 0;
      let colW02 = 0;
      let colW03 = 0;
      let colW04 = 0;
      let colW05 = 0;
      let colW06 = 0;
      let colW07 = 0;
      let colW08 = 0;
      let colW09 = 0;
      let colW10 = 0;
      let colW11 = 0;
      let colW12 = 0;
      let colW13 = 0;

      let remainingW = bundle.gridW - 20;// - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08;

      //columns.push({ text: 'QCSTAGEID', datafield: 'QUESTIONSTAGEID_FORM', type: 'string', width: 0, editable:false, hidden: true });
      //columns.push({ text: '', width: 25, cellsrenderer: photoRenderer });

      if (bundle.function == "info_request-pending") {

        colW01 = 100;
        colW02 = 100;
        colW03 = 150;
        colW04 = 75;
        colW05 = 200;
        colW06 = 0;
        colW07 = 0;
        colW08 = 0;

        remainingW = remainingW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'Stage', datafield: 'QUESTIONSTAGENAME', columntype: 'string', width: colW01, editable:false, });
        columns.push({ text: 'Scheduled', datafield: 'SCHEDULED', columntype: 'string', width: colW02, editable:false,  });
        columns.push({ text: 'Comapny Abrev.', datafield: 'COMPANYABREV', columntype: 'string', width: colW03, editable:false,  });
        columns.push({ text: 'Status', datafield: 'STUDYCOMPANYSTATUS', columntype: 'string', width: colW04, editable:false, });
        columns.push({ text: 'Email', datafield: 'COMPANYEMAILADDRESS', columntype: 'string', width: colW05, editable:false, });
        columns.push({ text: 'Type', datafield: 'COMPANYTYPENAME', columntype: 'string', width: remainingW, editable:false, });

      } else if (bundle.function == "info_request-pending-display") {

        columns.push({ text: 'Model', datafield: 'NAMELABLE', columntype: 'string', width: 150, editable: false, });

        for (let i = 0; i < bundle.columns.length; i++) {

          columns.push({
            text: bundle.columns[i].MONTH,
            datafield: bundle.columns[i].MONTH,
            cellsrenderer: statusCircle,    
            width: 50,
            editable: false,
            
            value:bundle.columns[i].MONTH
          });
        }

      } else if (bundle.function == "user_logins_dg" || bundle.function == "logins_from_ll_company_switch") {

        colW01 = 200;
        colW02 = 75;
        colW03 = 75;
        colW04 = 225;
        colW05 = 200;
        colW06 = 200;
        colW07 = 200;

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'Company', datafield: 'COMPANYNAME', columntype: 'string', width: colW01, editable:false,  });
        columns.push({ text: 'User First', datafield: 'CONTACTFIRST', columntype: 'string', width: colW02, editable:false,  });
        columns.push({ text: 'User Last', datafield: 'CONTACTLAST', columntype: 'string', width: colW03, editable:false,  });
        columns.push({ text: 'User Email', datafield: 'CONTACTEMAIL', columntype: 'string', width: colW04, editable:false,  });
        columns.push({ text: 'Status', datafield: 'SESSIONSTATUS', columntype: 'string', width: colW05, editable:false,  });
        columns.push({ text: 'Attempt Time', datafield: 'ATTEMPTTIMESTAMP', columntype: 'string', width: colW06, editable:false,  });
        columns.push({ text: 'Login Time', datafield: 'LOGINTIMESTAMP', columntype: 'string', width: colW07, editable:false,  });

      } else if (bundle.function == "user_emails_dg" || bundle.function == "emails_from_ll_company_switch") {

        colW01 = 125;
        colW02 = 80;
        colW03 = 200;
        colW04 = 125;
        colW05 = 100;
        colW06 = 100;
        colW07 = 125;
        colW08 = 400;

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'Scheduled', datafield: 'SCHEDULED', columntype: 'string', width: colW01, editable: false, });
        columns.push({ text: 'Status', datafield: 'STATUS',  columntype: 'string', width: colW02, editable: false, });
        columns.push({ text: 'Sent', datafield: 'SENT', columntype: 'string', width: colW03, editable: false, });
        columns.push({ text: 'Company', datafield: 'COMPANYABREV', columntype: 'string', width: colW04, editable: false, });
        columns.push({ text: 'Last Name', datafield: 'CONTACTLAST', columntype: 'string', width: colW05, editable: false, });
        columns.push({ text: 'First Name', datafield: 'CONTACTFIRST', columntype: 'string', width: colW06, editable: false, });
        columns.push({ text: 'Email', datafield: 'CONTACTEMAIL', columntype: 'string', width: colW07, editable: false, });        
        columns.push({ text: 'Subject Line', datafield: 'RECORDSUBJECTLINE', columntype: 'string', width: colW08, editable: false, });

      } else if (bundle.function == "admin_trail_dg" || bundle.function == "adminTrail_from_ll_company_switch") {


        colW01 = 125
        colW02 = 100
        colW03 = 100
        colW04 = 150

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'Role', datafield: 'ROLE', columntype: 'string', width: colW01, editable: false, });
        columns.push({ text: 'First Name', datafield: 'CONTACTFIRST', columntype: 'string', width: colW02, editable: false, });
        columns.push({ text: 'Last Name', datafield: 'CONTACTLAST', columntype: 'string', width: colW03, editable: false, });
        columns.push({ text: 'Date', datafield: 'ACTIONDATE', columntype: 'string', width: colW04, editable: false, });
        columns.push({ text: 'Action', datafield: 'STUDYADMINEVENTACTION', columntype: 'string', width: remainingW, editable: false, });

      } else if (bundle.function == "view_errors_dg" || bundle.function == "errors_from_ll_company_switch") {


        colW01 = 150
        colW02 = 150
        colW03 = 150
        colW04 = 150
        colW05 = 150

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'Company', datafield: 'COMPANYNAME', columntype: 'string', width: colW01, editable: false, });
        columns.push({ text: 'Abrev.', datafield: 'COMPANYABREV', columntype: 'string', width: colW02, editable: false, });
        columns.push({ text: 'First Name', datafield: 'CONTACTFIRST', columntype: 'string', width: colW03, editable: false, });
        columns.push({ text: 'Last Name', datafield: 'CONTACTLAST', columntype: 'string', width: colW04, editable: false, });
        columns.push({ text: 'Date', datafield: 'SYSTEMERRORDATE_NUM', columntype: 'string', width: colW05, editable: false, });
        columns.push({ text: 'Time', datafield: 'SYSTEMERRORTIME_NUM', columntype: 'string', width: remainingW, editable: false, });

      } else if (bundle.function == "user_entry_dg" || bundle.function == "entry_from_ll_company_switch") {


        colW01 = 100
        colW02 = 100
        colW03 = 100
        colW04 = 100
        colW05 = 100
        colW06 = 100
        colW07 = 100
        colW08 = 100
        colW09 = 100
        colW10 = 100
        colW11 = 100
        colW12 = 100

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'Local Date', datafield: 'EVENT_DATE_LOCAL', columntype: 'string', width: colW01, editable: false, });
        columns.push({ text: 'Time', datafield: 'EVENT_TIME_LOCAL', columntype: 'string', width: colW02, editable: false, });

        columns.push({ text: 'Company', datafield: 'COMPANYABREV', columntype: 'string', width: colW03, editable: false, });
        columns.push({ text: 'First Name', datafield: 'CONTACTFIRST', columntype: 'string', width: colW04, editable: false, });
        columns.push({ text: 'Last Name', datafield: 'CONTACTLAST', columntype: 'string', width: colW05, editable: false, });
        columns.push({ text: 'Model', datafield: 'MODELNAME', columntype: 'string', width: colW06, editable: false, });
        columns.push({ text: 'Event', datafield: 'STUDYVISITABREV', columntype: 'string', width: colW07, editable: false, });
        columns.push({ text: 'Scheduled', datafield: 'DATESCHEDULED_ISO', columntype: 'string', width: colW08, editable: false, });
        columns.push({ text: 'Form', datafield: 'FORMABREV', columntype: 'string', width: colW09, editable: false, });
        columns.push({ text: 'Question', datafield: 'QUESTIONNAME', columntype: 'string', width: colW10, editable: false, });
        columns.push({ text: 'Old Value', datafield: 'VALUEOLD', columntype: 'string', width: colW11, editable: false, });
        columns.push({ text: 'New Value', datafield: 'VALUENEW', columntype: 'string', width: colW12, editable: false, });

      } else if (bundle.function == "NG_utility_customerComments_get") {


        colW01 = 100
        colW02 = 100
        colW03 = 100
        colW04 = 100
        colW05 = 100
        colW06 = 100
        colW07 = 100
        colW08 = 100
        colW09 = 100
        colW10 = 100
        colW11 = 100
        colW12 = 100

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'Local Date', datafield: 'ENTRYDATE', columntype: 'string', width: colW01, editable: false, });
        //columns.push({ text: 'Time', datafield: 'EVENT_TIME_LOCAL', columntype: 'string', width: colW02, editable: false, });

        columns.push({ text: 'Company', datafield: 'COMPANYABREV', columntype: 'string', width: colW03, editable: false, });
        columns.push({ text: 'First Name', datafield: 'CONTACTFIRST', columntype: 'string', width: colW04, editable: false, });
        columns.push({ text: 'Last Name', datafield: 'CONTACTLAST', columntype: 'string', width: colW05, editable: false, });
        columns.push({ text: 'Model', datafield: 'MODELNAME', columntype: 'string', width: colW06, editable: false, });
        columns.push({ text: 'Event', datafield: 'STUDYVISITABREV', columntype: 'string', width: colW07, editable: false, });
        columns.push({ text: 'Scheduled', datafield: 'DATESCHEDULED_ISO', columntype: 'string', width: colW08, editable: false, });
       // columns.push({ text: 'Form', datafield: 'FORMABREV', columntype: 'string', width: colW09, editable: false, });
        //columns.push({ text: 'Question', datafield: 'QUESTIONNAME', columntype: 'string', width: colW10, editable: false, });
        //columns.push({ text: 'Old Value', datafield: 'VALUEOLD', columntype: 'string', width: colW11, editable: false, });
        columns.push({ text: 'New Value', datafield: 'LATOFVALUENEW', columntype: 'string', width: colW12, editable: false, });

      } else if (bundle.function == "admin_accounting_dg") {

        colW01 = 50
        colW02 = 150
        colW03 = 60      
        colW04 = 200
        colW05 = 50
        colW06 = 200
        colW07 = 100
        colW08 = 120
        colW09 = 50
        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'No.', datafield: 'COUNT', columntype: 'string', width: colW01 });        
        columns.push({ text: 'Lender', datafield: 'LENDER', columntype: 'string', width: colW02 });
        columns.push({ text: 'SysID', datafield: 'COMPANYID', columntype: 'string', width: colW03 });
        columns.push({ text: 'Name', datafield: 'COMPANYNAME', columntype: 'string', width: colW04 });
        columns.push({ text: 'Model', datafield: 'MODELNAME', columntype: 'string', width: colW05 });
        columns.push({ text: 'Type', datafield: 'COMPANYTYPENAME', columntype: 'string', width: colW06 });     
        columns.push({ text: 'Month End', datafield: 'MONTHEND', columntype: 'string', width: colW07});
        columns.push({ text: 'Date Added', datafield: 'RECORDDATETIMESTAMP', columntype: 'string', width: colW08 });
        columns.push({ text: 'Fee', datafield: 'MONTHLYAMOUNT', columntype: 'string', width: colW09 });
        columns.push({ text: 'Total', datafield: 'TOTAL', columntype: 'string', width: remainingW });

      } else if (bundle.function == "admin_ARModels_dg") {

        colW01 = 150
        colW02 = 150
        colW03 = 100
        colW04 = 100
        colW05 = 75
        colW06 = 100
        colW07 = 75
        colW08 = 100
        colW09 = 100
        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        
        columns.push({ text: 'Lender', datafield: 'LENDER', columntype: 'string', width: colW01 });
        columns.push({ text: 'Name', datafield: 'COMPANYNAME', columntype: 'string', width: colW02 });
        columns.push({ text: 'Model', datafield: 'MODELNAME', columntype: 'string', width: colW03 });
        columns.push({ text: 'Interfaces', datafield: 'INTERFACES', columntype: 'string', width: colW04 });
        columns.push({ text: 'Mnth Fee', datafield: 'MONTHLYAMOUNT', columntype: 'string', width: colW05 });
        columns.push({ text: 'Start Date', datafield: 'STARTDATE', columntype: 'string', width: colW06 });        
        columns.push({ text: 'Mnth#', datafield: 'LOANTERMMONTHS', columntype: 'string', width: colW07 });
        columns.push({ text: 'Through Date', datafield: 'TERMINATIONDATE', columntype: 'string', width: colW08 });
        columns.push({ text: 'Bill Type', datafield: 'BILLTYPE', columntype: 'string', width: colW09 });
        columns.push({ text: 'Bill To', datafield: 'BILLTO', columntype: 'string', width: remainingW });
        
      } else if (bundle.function == "model_permissions_dg") {

        colW01 = 125
        colW02 = 125
        colW03 = 125
        colW04 = 125
        colW05 = 125

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        if (bundle.LlPermissionsService.check("assignLoans")) {
          columns.push({ text: 'Include', datafield: 'SELECTED', columntype: 'checkbox', width: 70, editable: true, });
          remainingW = remainingW - 70
        }

        columns.push({ text: 'Model Provider', datafield: 'COMPANYABREV', columntype: 'string', width: colW01, editable: false, });
        columns.push({ text: 'Model', datafield: 'MODELNAME', columntype: 'string', width: colW02, editable: false, });
        columns.push({ text: 'Contact Company', datafield: 'COMPANYABREV_CONTACT', columntype: 'string', width: colW03, editable: false, });
        columns.push({ text: 'First Name', datafield: 'CONTACTFIRST', columntype: 'string', width: colW04, editable: false, });
        columns.push({ text: 'Last Name', datafield: 'CONTACTLAST', columntype: 'string', width: colW05, editable: false, });              
        columns.push({ text: 'Email', datafield: 'CONTACTEMAIL', columntype: 'string', width: remainingW, editable: false, });

      } else if (bundle.function == "NG_LL_model_AR_admin_client_interface_records_get") {

        colW01 = 70
        colW02 = 70
        colW03 = 250
        colW04 = 70
        colW05 = 70
        colW06 = 70
        colW07 = 70
        colW08 = 70
        colW09 = 70
        colW10 = 70
        colW11 = 70
        colW12 = 70
        colW13 = 500

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'ID', datafield: 'CLIENTINTERFACERECORDID', columntype: 'string', width: colW01, editable: false, });
        columns.push({ text: 'Origin', datafield: 'ORIGIN', columntype: 'string', width: colW02, editable: false, });
        columns.push({ text: 'File Name', datafield: 'CLIENTFILENAME', columntype: 'string', width: colW03, editable: false, });
        columns.push({ text: 'Status', datafield: 'RECORDSTATUS', columntype: 'string', width: colW04, editable: false, });
        columns.push({ text: 'Bytes', datafield: 'SIZEBYTES', columntype: 'string', width: colW05, editable: false, });
        columns.push({ text: 'Row Count', datafield: 'RECORDNUMBER', columntype: 'string', width: colW06, editable: false, });
        columns.push({ text: 'From', datafield: 'RECORDSFROMDATE', columntype: 'string', width: colW07, editable: false, });
        columns.push({ text: 'Through', datafield: 'RECORDSTHROUGHDATE', columntype: 'string', width: colW08, editable: false, });
        columns.push({ text: 'Days-Add', datafield: 'NUMBEROFDAYSTOADD', columntype: 'string', width: colW09, editable: false, });
        columns.push({ text: 'Days-Process', datafield: 'DAYSBACKTOPROCESS', columntype: 'string', width: colW10, editable: false, });
        columns.push({ text: 'Date_in', datafield: 'INBOUNDDATE', columntype: 'string', width: colW11, editable: false, });
        columns.push({ text: 'Time_in', datafield: 'INBOUNDTIME', columntype: 'string', width: colW12, editable: false, });
        columns.push({ text: 'FailMsg', datafield: 'FAILMSG', columntype: 'string', width: colW13, editable: false, });

      } else if (bundle.function == "edit-roles-dg") {

        columns.push({ text: 'Group', datafield: 'PERMISSIONGROUP', columntype: 'string', width: 150, editable: false, });
        columns.push({ text: 'Permission', datafield: 'PERMISIONDESCRIPTION', columntype: 'string', width: 250, editable: false, });

        for (let i = 0; i < bundle.columns.length; i++) {

          columns.push({
            text: bundle.columns[i].LABEL,
            datafield: 'A' + bundle.columns[i].SYSTEMROLEID,
            columntype: 'checkbox',
            width: 70,
            editable: true,
          });
        }

      } else if (bundle.function == "model_locations_dg") {
                
       
        colW01 = 70
        colW02 = 200
        colW03 = 100
        colW04 = 70
        colW05 = 100
        colW06 = 100
        colW07 = 100
        colW08 = 100
        colW09 = 100
        colW10 = 100
        colW11 = 70
        colW12 = 225
        colW13 = 225
        let colW14 = 225
        let colW15 = 50
        let colW16 = 50
        let colW17 = 50

        remainingW = remainingW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13 - colW14 - colW15 - colW16 - colW17;

        ///columns.push({ text: 'LLID', datafield: 'LOCATIONID', columntype: 'string', width: colW01, editable:false, align: 'left' });

        if (bundle.LlPermissionsService.check("editModels")) {          
          columns.push({ text: 'Include', datafield: 'SELECTED', columntype: 'checkbox', width: colW01, editable:true, align: 'center' });
        } else {
          columns.push({ text: 'Included', datafield: 'ACCESS', columntype: 'string', width: colW01, editable:false, align: 'center' });
        }
        
        columns.push({ text: 'Location Name', datafield: 'LOCATIONNAME', columntype: 'string', width: colW02, editable:false, align: 'left' });
        columns.push({ text: 'BillSys', datafield: 'VENDER', columntype: 'string', width: colW03, editable:false,  align: 'left' });
        columns.push({ text: 'SysId', datafield: 'LOCATIONID', columntype: 'string', width: colW04, editable: false, align: 'left' });
        columns.push({ text: 'Include Date', datafield: 'STARTDATE_NUM', columntype: 'string', width: colW09, editable: false, align: 'left' });
        columns.push({ text: 'Exclude Date', datafield: 'ENDDATE_NUM', columntype: 'string', width: colW10, editable: false, align: 'left' });

        if (bundle.LlPermissionsService.check("editModels")) {
          columns.push({ text: 'Edit', datafield: 'FIND', columntype: 'checkbox', width: 70, editable: true, });
        }

        columns.push({ text: 'Report Date', datafield: 'REPORTDATE', columntype: 'string', width: colW05, editable: false, align: 'left' });
        columns.push({ text: 'Netcharges (0-210)', datafield: 'NETCHARGES', columntype: 'string', width: colW06, editable: false, align: 'left' });
        columns.push({ text: 'Payments (0-210)', datafield: 'PAYMENTS', columntype: 'string', width: colW07, editable: false, align: 'left' });
        columns.push({ text: 'AR (0-210)', datafield: 'RECEIVABLES', columntype: 'string', width: colW08, editable: false, align: 'left' });

        columns.push({ text: 'Fed ID', datafield: 'FEDERALPROVIDERNUMBER', columntype: 'string', width: colW11, editable:false,  align: 'left' });
        columns.push({ text: 'Fed Name', datafield: 'PROVIDERNAME', columntype: 'string', width: colW12, editable:false,  align: 'left' });
        columns.push({ text: 'Legal Name', datafield: 'LEGALBUSINESSNAME', columntype: 'string', width: colW13, editable:false,  align: 'left' });
        columns.push({ text: 'Address', datafield: 'PROVIDERADDRESS', columntype: 'string', width: colW14, editable:false,  align: 'left' });
        columns.push({ text: 'City', datafield: 'PROVIDERCITY', columntype: 'string', width: colW15, editable:false,  align: 'left' });
        columns.push({ text: 'State', datafield: 'PROVIDERSTATE', columntype: 'string', width: colW16, editable:false,  align: 'left' });
        columns.push({ text: 'Beds', datafield: 'NUMBEROFCERTIFIEDBEDS', columntype: 'string', width: colW17, editable:false,  align: 'left' });


      } else if (bundle.function == "model_payers_dg") {

       
        colW01 = 70
        colW02 = 250
        colW03 = 80

        remainingW = remainingW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

      
        if (bundle.LlPermissionsService.check("editPayers")) {
          columns.push({ text: 'Eligible', datafield: 'SELECTED', columntype: 'checkbox', width: 70, editable: true, align: 'center' });
        } else {
          columns.push({ text: 'Eligible', datafield: 'ACCESS', columntype: 'string', width: 70, editable: false, align: 'center' });
        }

        columns.push({ text: 'Name', datafield: 'FOLDERNAME', columntype: 'string', width: 200, editable: false, align: 'left', format: "" });
        columns.push({ text: 'Parent', datafield: 'PAYERPARENTNAME', columntype: 'string', width: 150, editable: false, align: 'left', format: "" });


        columns.push({ text: 'Source', datafield: 'VENDERNAME', columntype: 'string', width: 50, editable: false, align: 'left', });
        columns.push({ text: 'sysID', datafield: 'PAYERID', columntype: 'string', width: 50, editable: false, align: 'left', });

        if (bundle.LlPermissionsService.check("editModels")) {
          columns.push({ text: 'Edit', datafield: 'EDIT', columntype: 'checkbox', width: 70, editable: true, });
        }

       
        columns.push({ text: 'Class', datafield: 'FINANCIALCLASSNAME', columntype: 'string', width: 150, editable: false, align: 'left', });
        
        columns.push({ text: 'Billed-AgeCap', datafield: 'LIMIT_BILLED_AGECAPDAYS', columntype: 'string', width: 100, editable: false, align: 'right', });
        columns.push({ text: 'Billed-Adv%', datafield: 'LIMIT_BILLEDADVANCE_PCT', columntype: 'string', width: 100, editable: false, align: 'right', });
        columns.push({ text: 'Unbilled-AgeCap', datafield: 'LIMIT_UNBILLED_AGECAPDAYS', columntype: 'string', width: 100, editable: false, align: 'right', });
        columns.push({ text: 'Unbilled-Adv%', datafield: 'LIMIT_UNBILLEDADVANCE_PCT', columntype: 'string', width: 100, editable: false, align: 'right', });
        columns.push({ text: 'ENV Calc Period', datafield: 'LIMIT_ENVCALCBUCKET_DAYS', columntype: 'string', width: 100, editable: false, align: 'right', });
        columns.push({ text: 'ENV % Override', datafield: 'LIMIT_ENV_OVERRIDE_PCT', columntype: 'string', width: 100, editable: false, align: 'right', });

        columns.push({ text: 'Reserves', datafield: 'LIMIT_RESERVES_AMT', columntype: 'string', width: 100, editable: false, align: 'right', format: "c1" });

      } else if (bundle.function == "class_payers_dg") {

        colW01 = 100
       
        remainingW = remainingW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        if (bundle.records[0].ADDMETHOD != 'file' && bundle.LlPermissionsService.check("editModels")) {
          
          bundle.canRemove = true;
          remainingW = remainingW - 70;

          columns.push({ text: 'Include', datafield: 'SELECTED', columntype: 'checkbox', width: 70, editable: true, align: 'left' });
          columns.push({ text: 'Payer Name', datafield: 'PAYERNAME', columntype: 'string', width: remainingW, editable: false, hidden: false });
          columns.push({ text: 'Source System', datafield: 'VENDERNAME', columntype: 'string', width: colW01 , editable: false, hidden: false });

        } else {
          columns.push({ text: 'Payer Name', datafield: 'PAYERNAME', columntype: 'string', width: remainingW, editable: false, hidden: false });
          columns.push({ text: 'Source System', datafield: 'VENDERNAME', columntype: 'string', width: colW01, editable: false, hidden: false });
        }
        
      } else if (bundle.function == "model_reports_dg" || bundle.function == "quest_listing_dg" || bundle.function == "model_location_cms_dg") {

      
        for (let i = 0; i < bundle.columns.length; i++) {

          let datafield_local = bundle.columns[i].STUDYCOLUMNQUESTIONIDALPHA.toUpperCase();

          ///console.log("datafield_local: " + datafield_local)

          columns.push({
            text: bundle.columns[i].STUDYCOLUMNLABEL,
            datafield: datafield_local,
            columntype: 'string',
            width: bundle.columns[i].STUDYCOLUMNWIDTH, editable: false, hidden: false
          });
        }

      //} else if (bundle.function == "quest_listing_columnGroupEdit_dg") {


        //colW01 = 70
        //colW02 = 250

        //remainingW = remainingW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;
              
        //columns.push({ text: 'Include', datafield: 'SELECTED', columntype: 'checkbox', width: colW01, editable: true, align: 'center' });
        //columns.push({ text: 'Label', datafield: 'STUDYCOLUMNLABEL', columntype: 'string', width: colW02, editable: false});
        //columns.push({ text: 'Data Type', datafield: 'STUDYCOLUMNDATATYPE', columntype: 'string', width: remainingW, editable: false });
 
      } else if (bundle.function == "edit-contact-roles-dg") {

        colW01 = 150
        colW02 = 100
        

        remainingW = remainingW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13 - 70;

        if (bundle.LlPermissionsService.check("editPayers")) {
          columns.push({ text: 'Active', datafield: 'SELECTED', columntype: 'checkbox', width: 70, editable: true, align: 'center' });
        } else {
          columns.push({ text: 'Active', datafield: 'ACCESS', columntype: 'string', width: 70, editable: false, align: 'center' });
        }

        columns.push({ text: 'Role', datafield: 'ROLE', columntype: 'string', width: colW01, editable: false, align: 'left', format: "" });
        columns.push({ text: 'Company Owner', datafield: 'COMPANYABREV', columntype: 'string', width: remainingW, editable: false, align: 'left', format: "" });
        columns.push({ text: 'Default', datafield: 'ROLEAPPLICATION', columntype: 'string', width: colW02, editable: false, align: 'left', format: "" });

      } else if (bundle.function == "edit-contact-permissions-dg") {

        colW01 = 125
        colW02 = 125
     
        remainingW = remainingW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        if (bundle.LlPermissionsService.check("assignLoans")) {
          columns.push({ text: 'Active', datafield: 'SELECTED', columntype: 'checkbox', width: 70, editable: true, align: 'center' });
          remainingW = remainingW - 70
        } else {
          columns.push({ text: 'Active', datafield: 'ACCESS', columntype: 'string', width: 70, editable: false, align: 'center' });
        }

        columns.push({ text: 'Model Provider', datafield: 'COMPANYABREV', columntype: 'string', width: colW01, editable: false, });
        columns.push({ text: 'Model', datafield: 'MODELNAME', columntype: 'string', width: colW02, editable: false, });

      } else if (bundle.function == "email-routine-recipients_dg") {

        colW01 = 70;
        colW02 = 150;
        colW03 = 150;
        colW04 = 150;

        remainingW = bundle.gridW - colW01 - colW02 - colW03 - colW04 - colW05 - colW06 - colW07 - colW08 - colW09 - colW10 - colW11 - colW12 - colW13;

        columns.push({ text: 'Receive', datafield: 'SELECTED', columntype: 'checkbox', width: colW01, editable: true, });
        columns.push({ text: 'Company', datafield: 'COMPANYABREV', columntype: 'string', width: colW02, editable: false, });
        columns.push({ text: 'User First', datafield: 'CONTACTFIRST', columntype: 'string', width: colW03, editable: false, });
        columns.push({ text: 'User Last', datafield: 'CONTACTLAST', columntype: 'string', width: colW04, editable: false, });
        columns.push({ text: 'User Email', datafield: 'CONTACTEMAIL', columntype: 'string', width: remainingW, editable: false, });
      }

      bundle.columns.splice(0);
      bundle.columns = columns

      for (let i = 0; i < columns.length; i++) {        
        fields.push({
          name: columns[i].datafield,
          columntype: columns[i].columntype,
          type:'string',
        });

        
      }
            
      var source = {
        localdata: records,
        datatype: 'array',
        datafields: fields
      };

      var dataAdapter = new jqx.dataAdapter(source);
        datagrid.source(dataAdapter);
        datagrid.columns(columns);
        //datagrid.width(bundle.gridW);
        //datagrid.height(bundle.gridH);

        bundle.showDG = true;


      function statusCircle(row: number, column: any, value: string) {

        //console.log(row)
        //console.log(column)
        //console.log(value)

        ///var imgurl: String = datagrid.getrowdata(row);

        let MONTH = column.toUpperCase();  

        var imgurl: String = bundle.records[row][MONTH]

        //console.log(imgurl)

        var img = '<div height:20px style="background: transparent; text-align: center; padding-top: 1px;"><img height="14" style="width: auto;"  src="' + imgurl + '"></div>';

        return img;
      }    

    }
  }

  clickOnRow(event: any): void {
      

    let rowIndex = event.args.rowindex;
    let dataField = event.args.datafield;
    let newValue = event.args.newvalue;

    //console.log(event)
    //console.log("dataField: " + dataField)

    let ACTION = "REMOVE";

    if (newValue == true) {
      ACTION = "ADD";
    }


    if (this.bundle.function == "edit-roles-dg") {

      //this.bundle.records[event.args.rowindex][event.args.datafield] = newValue;

      let SYSTEMROLEID = Number(event.args.datafield.substr(1, event.args.datafield.length - 1));;
      let PERMISSIONID = this.bundle.records[rowIndex].PERMISSIONID;           

      this.LlRolesService.NG_LL_system_roles_permisions_edit(ACTION, SYSTEMROLEID, PERMISSIONID);

    } else if (this.bundle.function == "model_locations_dg") {

      if (dataField == 'SELECTED') {
        this.LlModelsService.NG_LL_model_AR_locations_picklist_edit(ACTION, this.bundle.records[rowIndex].LOCATIONID);

      } else if (dataField == 'FIND') {
        this.LlModelsService.location_CMS_search_mode(this.bundle.records[rowIndex].LOCATIONID);
      }

    } else if (this.bundle.function == "model_payers_dg") {

      if (dataField == 'SELECTED') {
        this.LlModelsService.NG_LL_model_AR_payers_picklist_edit(ACTION, this.bundle.records[rowIndex].PAYERID);

      } else if (dataField == 'EDIT') {
        this.LlModelsService.payer_edit_mode(this.bundle.records[rowIndex].PAYERID);
      }

    } else if (this.bundle.function == "class_payers_dg") {

      if (dataField == 'SELECTED') {
        this.LlModelsService.NG_LL_model_AR_payerClasses_picklist_edit(ACTION, this.bundle.records[rowIndex].PAYERID);
      }

    } else if (this.bundle.function == "model_permissions_dg") {
      this.LlContactsService.NG_LL_contact_model_permissions_edit_ALL(ACTION, this.bundle.records[rowIndex].LOANMODELID, this.bundle.records[rowIndex].CONTACTID);

    } else if (this.bundle.function == "edit-contact-roles-dg") {
      this.LlContactsService.NG_LL_contact_roles_edit(ACTION, this.bundle.records[rowIndex].SYSTEMROLEID);

    } else if (this.bundle.function == "edit-contact-permissions-dg") {
      this.LlContactsService.NG_LL_contact_model_permissions_edit(this.noAction, this.bundle, "SINGLE", ACTION, this.bundle.records[rowIndex].LOANMODELID);

    } else if (this.bundle.function == "email-routine-recipients_dg") {
      
      ////return function, this.bundle, TYPE, ACTION, CONTACTID
      this.LlUtilityService.NG_utility_email_recipients_picklist_edit(this.noAction, this.bundle, 'SINGLE', ACTION, this.bundle.records[rowIndex].CONTACTID);

    } else if ( this.bundle.function == "info_request-pending-display" || this.bundle.function == "info_request-pending") {

      ///console.log(this.bundle.records[rowIndex].COMPANYID)

      this.LlCompaniesService.setActive(this.bundle.records[rowIndex].COMPANYID);
      this.LlModelsService.setActiveId(this.bundle.records[rowIndex].LOANMODELID);

      this.bundle.showDG = false;
      this.bundle.showCompany = true;
    }
  }

  toSort(event) {
    var target = event.target || event.srcElement || event.currentTarget;
    this.bundle.activeCODE = target.id;

    if (this.bundle.function == "email-routine-recipients_dg") {

      this.bundle.records[1].SELECTED = true

      ////return function, TYPE, ACTION, CONTACTID
      this.LlUtilityService.NG_utility_email_recipients_picklist_edit(this.getData, this.bundle, this.bundle.activeCODE, 'NA', 0);

    } else if (this.bundle.function == "edit-contact-permissions-dg") {

      this.LlContactsService.NG_LL_contact_model_permissions_edit(this.getData, this.bundle, this.bundle.activeCODE, 'NA', 0);

    } else {
      this.getData(this.bundle);
    }
  }

  refreshDisplay() {
    
  }

  noAction() {
    //needed HTML buttons point to this
  }

  add() {

  }

  remove() {

    this.popover_remove.close();
    ///console.log("remove")
  }

  clickHeadBtn(event) {
    ///
  }

  selectMonthEnd() {
    let newDate = this.date_monthEnd.val();
    let CCYYMMDD = STDDate_to_CCYYMMDD(newDate)
    this.LlUtilityService.setMonthEnd(CCYYMMDD)
    this.getData(this.bundle)
  }

  goBack_fromCompany() {
    this.bundle.showDG = true;
    this.bundle.showCompany = false;
  }

  exportOnClick() {

    let label = this.bundle.fileLabel;

    //console.log(label)

    for (let k = 0; k < this.bundle.columns.length; k++) {

      this.bundle.columns[k].STUDYCOLUMNQUESTIONIDALPHA = this.bundle.columns[k].datafield;
      this.bundle.columns[k].STUDYCOLUMNLABEL = this.bundle.columns[k].text
      this.bundle.columns[k].SYSTEMORGANIZATIONFIELDCODE = this.bundle.columns[k].datafield
    }

    var rows = this.datagrid.getdisplayrows();

    ///this.LlUtilityService.saveCSV(this.bundle.records, this.bundle.columns, label)
    this.LlUtilityService.saveCSV(rows, this.bundle.columns, label)

  };

}


import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LlScrollpaneService {

  constructor() { }

  bundle(event, bundle, panel) {

    var target = event.target || event.srcElement || event.currentTarget;

    //console.log("target:" + target)

    bundle.showTab = false;

    if (target != null) {

      bundle.showTab = true;

      var idAttr = target.attributes.id;
      var alphaid = idAttr.nodeValue;

      bundle.selectedId_ondeck = Number(alphaid.substr(2, alphaid.length - 2));

      ///console.log("alphaid:" + alphaid)

      var scrollPaneDiv = target.offsetParent.offsetParent.offsetParent.offsetTop;
      var scrollPane = target.offsetParent.offsetParent.offsetTop;
      var btn = target.offsetTop;
      var scrollPanePosition = panel.getVScrollPosition();

      bundle.tabY = scrollPaneDiv + scrollPane + btn - scrollPanePosition + bundle.topOffset;

      if (bundle.tabY < bundle.spY || bundle.tabY > bundle.spB) {
        bundle.showTab = false;
      } else {
        bundle.showTab = true;
      }
    }

    return bundle
  }

  get2alphaId(event) {



    var target = event.target || event.srcElement || event.currentTarget;

    if (target != null) {

      //console.log(target)


      var idAttr = target.attributes.id;

      //console.log(idAttr)

      var alphaid = idAttr.nodeValue;

      return Number(alphaid.substr(2, alphaid.length - 2));
    }
  }

  standardDimentions(bundle): void {
    
    bundle.objH = bundle.parentH;
    bundle.spY = bundle.vertLineY + 40;////bundle.lblY + 20;
    bundle.spH = bundle.objH - bundle.spY - 40;
    bundle.lblY = bundle.spY - 25;

    bundle.spB = bundle.spY + bundle.spH - 0;
    bundle.tabW = bundle.spW + 10;
    bundle.vertLineX = bundle.spW + 30;
    bundle.vertLineH = bundle.objH;
  }

  paintVertTab(bundle): void {

  ///console.log("bundle.showTab: " + bundle.showTab)

  if (bundle.showTab != null && bundle.showTab == false) {
    this.paintNoTab(bundle);

  } else {

    var ctx = bundle.ctx;
    var c: number = bundle.vertLineW;
    var cl: number = 5;
    var toffl: number = 14
    var x: number = bundle.vertLineX;
    var y: number = bundle.vertLineY;
    var objH: number = bundle.vertLineH;
    var tabY: number = bundle.tabY;
    var tabW: number = bundle.tabW;
    var tabH: number = bundle.tabH;

    ctx.clearRect(0, 0, tabW + 100, objH + 100);
    ctx.lineWidth = bundle.lineWidth;
    ctx.strokeStyle = "#999999";
    ctx.beginPath();
    ctx.moveTo(x, y);
    //ctx.quadraticCurveTo(x - c, 0, x - c, c);
    ctx.quadraticCurveTo(x - c, y, x - c, c + y);
    ctx.lineTo(x - c, tabY - c - ctx.lineWidth);
    ctx.quadraticCurveTo(x - c, tabY - ctx.lineWidth, x - (c * 2), tabY - ctx.lineWidth);
    ctx.lineTo(x - tabW - toffl, tabY - ctx.lineWidth);
    ctx.quadraticCurveTo(x - tabW - toffl - cl, tabY - ctx.lineWidth, x - tabW - toffl - cl, tabY - ctx.lineWidth + cl);
    ctx.lineTo(x - tabW - toffl - cl, tabY + tabH + ctx.lineWidth - 3 - cl);
    ctx.quadraticCurveTo(x - tabW - toffl - cl, tabY + tabH + ctx.lineWidth - 3, x - tabW - toffl, tabY + tabH + ctx.lineWidth - 3);
    ctx.lineTo(x - (c * 2), tabY + tabH + ctx.lineWidth - 3);
    ctx.quadraticCurveTo(x - c, tabY + tabH + ctx.lineWidth - 3, x - c, tabY + tabH + ctx.lineWidth - 3 + c);
    ctx.lineTo(x - c, objH - c);
    ctx.quadraticCurveTo(x - c, objH, x, objH);
    ctx.stroke();
  }

}

  paintNoTab(bundle): void {

  if (bundle.ctx != null) {

    var ctx = bundle.ctx;
    var c: number = bundle.vertLineW;
    var x: number = bundle.vertLineX;
    var y: number = bundle.vertLineY;
    var objH: number = bundle.vertLineH;
    var tabW: number = bundle.tabW;

    ctx.clearRect(0, 0, tabW + 100, objH + 100);
    ctx.lineWidth = bundle.lineWidth;
    ctx.strokeStyle = "#999999";
    ctx.beginPath();
    ctx.moveTo(x, y);
    //ctx.quadraticCurveTo(x - c, 0, x - c, c);
    ctx.quadraticCurveTo(x - c, y, x - c, c + y);
    ctx.lineTo(x - c, objH - c);
    ctx.quadraticCurveTo(x - c, objH, x, objH);
    ctx.stroke();

  }
  }

  standardRemainderValues(bundle, canvasEl): void {
    bundle.remainingX = canvasEl.width - 25;
    bundle.remainingW = bundle.parentW - bundle.remainingX;
    bundle.remainingH = bundle.vertLineH;
  }

  paintHorzTab(callback01: Function, tabs, canvas, ctx, bundle, removeClick: boolean): void {


    ///console.log("bundle.activeHorzTab:"+bundle.activeHorzTab)

    var tp = 2;
    var indent = bundle.hTabIndent;
    var w = bundle.hTabW;
    var h = bundle.hTabH + tp;
    var c = 12;
    var by = tp + h - 1

    //let canvas = bundle.canvasHT;
    //let ctx = canvas.getContext('2d');
   // let tabs: [] = bundle.horzTabs

      
    //bundle.hTabIndx = 0;

    canvas.width = bundle.remainingW;
    canvas.height = h + 5;
    canvas.removeEventListener('click', onClick)
    canvas.addEventListener('click', onClick)

    if (removeClick == true) {
      canvas.addEventListener('click', removeOnClick)
    }
    function removeOnClick(e) {
      canvas.removeEventListener('click', onClick)
    }
    function onClick(e) {

     //console.log("onClick()")

      

      var rect = canvas.getBoundingClientRect();
      var mouseX = e.clientX - rect.left;
      var mouseY = e.clientY - rect.top;

      for (var i = 0; i < tabs.length; i++) {

        if (mouseX > tabs[i].boxL
          && mouseX < tabs[i].boxR
          && mouseY > tabs[i].boxT
          && mouseY < tabs[i].boxB
        ) {

          bundle.hTabIndx = i;
          bundle.activeHorzTab = tabs[i].lbl;
          paintTabs();
          callback01(i, bundle);
          break;

        }
      }
    }


    paintTabs();

    function paintTabs() {

      ///console.log("paintTabs")

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      

        let lineW = canvas.width - 0

        ctx.strokeStyle = "#999999";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(0, h + 1);
        ctx.lineTo(lineW, h + 1);
        ///ctx.quadraticCurveTo(lineW - 20, h + 1, lineW + 20, h + 41);
        ctx.stroke();

      if (tabs.length > 1) {
        for (var i = tabs.length - 1; i >= 0; i--) {

          tabs[i].x = indent + (i * (w - 23));

          tabs[i].boxL = tabs[i].x + 10;
          tabs[i].boxR = tabs[i].x + w - 10;
          tabs[i].boxT = tp;
          tabs[i].boxB = h + tp;

          ////paint all false first so they are under the true tab
          //if (tabs[i].active != true) {
            //drawTab(tabs[i], "#EEEEEE", false);

          //} else {
            //bundle.hTabIndx = i
          //}

          //if (tabs[i].active != true) {
            ///drawTab(tabs[i], "#EEEEEE", false);

          //} else {
            //bundle.hTabIndx = i
          //}

          //console.log(tabs[i].lbl)

          if (tabs[i].lbl == bundle.activeHorzTab) {
            bundle.hTabIndx = i
          } else {
           drawTab(tabs[i], "#EEEEEE", false);
          }

        }


        ////do not include this in the loop so it is painted last and so on top
        drawTab(tabs[bundle.hTabIndx], "#FFFFFF", true);
      }

      function drawTab(tab, fill, active) {

        var x = tab.x;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#999999";
        ctx.beginPath();
        ctx.moveTo(x, h + tp);
        ctx.quadraticCurveTo(x + c, h + tp, x + c, h - c + tp);
        ctx.lineTo(x + c, c + tp);
        ctx.quadraticCurveTo(x + c, tp, x + (c * 2), tp);
        ctx.lineTo(x + (w - (c * 2)), tp);
        ctx.quadraticCurveTo(x + (w - c), tp, x + (w - c), c + tp);
        ctx.lineTo(x + (w - c), h - c + tp);
        ctx.quadraticCurveTo(x + (w - c), h + tp, x + w, h + tp);
        ctx.stroke();
        ctx.fillStyle = fill;
        ctx.fill();
        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(x, h + tp, w, 3)

        if (active == false) {
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(x, by);
          ctx.lineTo(x + w, by);
          ctx.stroke();
        }

        ctx.font = "12px Arial";
        ctx.fillStyle = "#0016BB";

        if (active == true) {
          ctx.fillStyle = "black";
        }

        ctx.textAlign = "center";
        ctx.fillText(tab.lbl, x + (w / 2), (h / 2) + 4);
      }
    }
  }
}

import { Component, AfterViewInit, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { LlRolesService } from '../ll-roles.service';
import { UserService } from '../../services/user.service';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { Router } from '@angular/router';


@Component({
  selector: 'll-main-role-dg',
  templateUrl: './ll-main-role-dg.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlMainRoleDgComponent {

  @ViewChild('canvas_appOutline_ll_main_role_dg') public canvas_appOutline: ElementRef;
  @ViewChild('datagrid_ll_main_role_dg') datagrid: jqxGridComponent;

  constructor(
    private LlRolesService: LlRolesService,
    private UserService: UserService,
    private Router: Router,
  ) { }

  bundle = {
    showObj: false,
    OL_W: 0,
    OL_H: 0,
    OL_X: 15,
    OL_Y: 50,
    spX: 0,
    remainingW: 0,
    logoX: 10,
    logoY: 10,
    logoH: 0,
    parentW: 0,
    parentH: 0,
    gridX: 0,
    gridY: 100,
    gridW: 500,
    gridH: 0,
    records: [],
    LlRolesService: this.LlRolesService,
    Router: this.Router,
    sponsorLogo: "assets/lendingLincLogo_noTag_lrg.png",
  }

  ngOnInit() {
    this.bundle.parentW = window.innerWidth;
    this.bundle.parentH = window.innerHeight;
  }

  ngOnDestroy() { }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {
    this.bundle.parentW = window.innerWidth;
    this.bundle.parentH = window.innerHeight;

    setTimeout(() => {
      
      this.bundle.gridH = this.bundle.parentH - this.bundle.gridY - 80;
      this.bundle.gridX = (this.bundle.parentW - this.bundle.gridW) / 2

      var OL_X = this.bundle.OL_X;
      var by = this.bundle.OL_Y - 1
      this.bundle.logoH = by - (this.bundle.logoY * 2);

      var bw = window.innerWidth - (OL_X * 2);
      var bh = window.innerHeight - 10;

      this.bundle.OL_W = bw;
      this.bundle.OL_H = bh;

      this.bundle.spX = this.bundle.OL_X + 10;
      this.bundle.remainingW = this.bundle.OL_W - 10;

      var canvasOL: HTMLCanvasElement = this.canvas_appOutline.nativeElement;
      canvasOL.width = bw + 23;
      canvasOL.height = bh + 2;

      var ctx1 = canvasOL.getContext('2d');
      ctx1.beginPath()
      ctx1.strokeStyle = "#999999";
      ctx1.lineWidth = 2;
      ctx1.beginPath();
      ctx1.moveTo(OL_X + 20, by);
      ctx1.lineTo(OL_X + bw - 20, by);
      ctx1.quadraticCurveTo(OL_X + bw, by, OL_X + bw, by + 20);
      ctx1.lineTo(OL_X + bw, bh - 20);
      ctx1.quadraticCurveTo(OL_X + bw, bh, OL_X + bw - 20, bh);
      ctx1.lineTo(OL_X + 20, bh);
      ctx1.quadraticCurveTo(OL_X, bh, OL_X, bh - 20);
      ctx1.lineTo(OL_X, by + 20);
      ctx1.quadraticCurveTo(OL_X, by, OL_X + 20, by);
      ctx1.stroke();

      this.getData()
    });
  }

  getData() {

    this.LlRolesService.NG_LL_user_roles_get(done01, this.bundle, this.datagrid);

    function done01(records, bundle, datagrid) {

      bundle.records = records;

      if (bundle.records.length == 1) {

        ///if there is only one user role skip the role-list selection datagrid (see html logic)        
        bundle.LlRolesService.setActiveContactRoleId(bundle.records[0].CONTACTROLEID);

        bundle.LlRolesService.newRole(done02, bundle);

        function done02(bundle) {
          bundle.Router.navigate(["/LlMainCanvasComponent"]);
        }

      } else if (bundle.records.length > 1) {

        bundle.sponsorLogo = "assets/" + bundle.LlRolesService.getActiveLogo();
         

        var fields = [];
        var columns = [];

        var colW01 = 150;
        var colW02 = 0;
        var colW03 = 0;

        var remaining = bundle.gridW - colW01 - colW02 - colW03 - 0;

        columns.push({ text: 'Select User Role', datafield: 'ROLE', columntype: 'string', width: colW01, editable: false, });
        columns.push({ text: 'Sponsor', datafield: 'COMPANYNAME', columntype: 'string', width: remaining, editable: false, });

        //bundle.columns.splice(0);
        //bundle.columns = columns

        for (let i = 0; i < columns.length; i++) {
          fields.push({
            name: columns[i].datafield,
            columntype: columns[i].columntype,
            type: 'string',
          });
        }

        var source = {
          localdata: records,
          datatype: 'array',
          datafields: fields
        };

        var dataAdapter = new jqx.dataAdapter(source);

        datagrid.source(dataAdapter);
        datagrid.columns(columns);
        datagrid.width(bundle.gridW);
        datagrid.height(bundle.gridH);
        
        setTimeout(() => {
          bundle.showObj = true;
        });


      }
    }
  }

  myGridOnRowSelect(event: any): void {

    this.LlRolesService.setActiveContactRoleId(this.bundle.records[event.args.rowindex].CONTACTROLEID);

    this.LlRolesService.newRole(done01, this.bundle);

    function done01(bundle) {
      bundle.Router.navigate(["/LlMainCanvasComponent"]);
    }
  };

  tologOut() {
    this.UserService.logout("userLogOut");
  }

}

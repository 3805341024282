<div #mainDiv>

  <div *ngIf="bundle.showObj"
       [style.width.px]="bundle.parentW"
       style="position: absolute;">


    <div class="wordBtn_11" (click)="tologOut()" style="float: right; padding-left: 10px">
      Log Out
    </div>

    <div *ngIf="bundle.function == 'main-canvas'" >

      <div class="wordBtn_11" *ngIf="bundle.contactRoleCount > 1 && bundle.activeCONTACTROLEID > 0" (click)="toRoleList()" style="float: right; padding-left: 10px">
        Change Role
      </div>

      <div class="txtSmall_11" style="float: right; padding-left: 10px">
        {{bundle.ROLE}}
      </div>

      <div class="txtSmall_11B" style="float: right; padding-left: 10px">
        Role:
      </div>

      <div class="txtSmall_11" style="float: right; padding-left: 10px">
        {{bundle.CONTACTFIRST}} {{bundle.CONTACTLAST}}
      </div>

      <div class="txtSmall_11B" style="float: right;">
        User:
      </div>

    </div>
  </div>
</div>

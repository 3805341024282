import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { removeTextChars } from "../utility";
//import { removeNumberCommas } from "../utility";

@Component({
  selector: 'll-model-payer-edit',
  templateUrl: './ll-model-payer-edit.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelPayerEditComponent {

  @ViewChild('mainDiv_ll_model_payer_edit') mainDiv: ElementRef;
  @ViewChild('loan_alarm_popup') loginMainDiv: ElementRef;
  @ViewChild('text_billedAdvanceRate') text_billedAdvanceRate: jqxTextAreaComponent;
  @ViewChild('text_unbilledAdvanceRate') text_unbilledAdvanceRate: jqxTextAreaComponent;
  @ViewChild('text_reserve') text_reserve: jqxTextAreaComponent;
  @ViewChild('text_LIMIT_ENV_OVERRIDE_PCT') text_LIMIT_ENV_OVERRIDE_PCT: jqxTextAreaComponent; 

  constructor(
    private LlModelsService: LlModelsService,
  ) {
  }

  bundle = {
    action: '',
    showPayerEdit: false,
    editUserMsg:'',
    PAYER: [],
    PAYERID:"",
    PAYERNAME: "",
    VENDERNAME: "",
    PAYERELIGIBLEYN: '',
    text_billedAdvanceRate: {},
    text_unbilledAdvanceRate: {},
    text_reserve: {},
    text_LIMIT_ENV_OVERRIDE_PCT: {},
    ageCapChoices_billed: [],
    ageCapChoices_unbilled: [],
    LIMIT_ENVCALCBUCKET_DAYSChoices: [],
    parentW: 0,
    parentH: 0,
    spX: 10,
    spY: 40,
    spW: 0,
    spH: 0,
    spB: 0,
  };

  ngOnDestroy() {}

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.setVars()
  }

  setVars() {

    setTimeout(() => {
      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;
      this.bundle.spW = this.bundle.parentW - (this.bundle.spX * 2);
      this.bundle.spH = this.bundle.parentH - this.bundle.spY - 40;
      this.bundle.spB = this.bundle.spH;
      this.getData();
    });

  }

  getData() {

    this.bundle.text_billedAdvanceRate = this.text_billedAdvanceRate
    this.bundle.text_unbilledAdvanceRate = this.text_unbilledAdvanceRate
    this.bundle.text_reserve = this.text_reserve
    this.bundle.text_LIMIT_ENV_OVERRIDE_PCT = this.text_LIMIT_ENV_OVERRIDE_PCT

    this.LlModelsService.NG_LL_model_AR_payers_target_get(done01, this.bundle);

    function done01(arr, bundle) {

      bundle.PAYER = arr;

      bundle.PAYERID = bundle.PAYER[0].PAYERID;
      bundle.PAYERNAME = bundle.PAYER[0].PAYERNAME;
      bundle.VENDERNAME = bundle.PAYER[0].VENDERNAME;
      bundle.PAYERELIGIBLEYN = bundle.PAYER[0].ELIGIBLEYESNO;

      bundle.text_billedAdvanceRate.val(bundle.PAYER[0].LIMIT_BILLEDADVANCE_PCT);
      bundle.text_unbilledAdvanceRate.val(bundle.PAYER[0].LIMIT_UNBILLEDADVANCE_PCT);
      bundle.text_reserve.val(bundle.PAYER[0].LIMIT_RESERVES_AMT);
      bundle.text_LIMIT_ENV_OVERRIDE_PCT.val(bundle.PAYER[0].LIMIT_ENV_OVERRIDE_PCT);

      //choices.AGEPERIODTYPEChoices.push({ choice: 'Monthly', code: "Monthly", SELECTED: false })
      //choices.AGEPERIODTYPEChoices.push({ choice: '30DayRoll', code: "30DayRoll", SELECTED: false })

      //choices.STRAIGHTORAVGBBChoices.push({ choice: 'Daily', code: "Daily", SELECTED: false })
      //choices.STRAIGHTORAVGBBChoices.push({ choice: 'Average', code: "Average", SELECTED: false })

      //choices.DAYSTOAVERAGEChoices.push({ choice: '10', code: "10", SELECTED: false })
      //choices.DAYSTOAVERAGEChoices.push({ choice: '15', code: "15", SELECTED: false })
      //choices.DAYSTOAVERAGEChoices.push({ choice: '30', code: "30", SELECTED: false })
      //choices.DAYSTOAVERAGEChoices.push({ choice: '45', code: "45", SELECTED: false })
      //choices.DAYSTOAVERAGEChoices.push({ choice: '60', code: "60", SELECTED: false })

      //choices.PRORATEUNBILLEDYESNOChoices.push({ choice: 'Yes', code: "Yes", SELECTED: false })
      //choices.PRORATEUNBILLEDYESNOChoices.push({ choice: 'No', code: "No", SELECTED: false })

      //bundle.eligibleChoices.push({ choice: 'Yes', code: "Y", SELECTED: false })
      //bundle.eligibleChoices.push({ choice: 'No', code: "N", SELECTED: false })

      //choices.DYNAMICENVYESNOChoices.push({ choice: 'Yes', code: "Yes", SELECTED: false })
      //choices.DYNAMICENVYESNOChoices.push({ choice: 'No', code: "No", SELECTED: false })

      bundle.ageCapChoices_billed.push({ choice: '30', code: "30", SELECTED: false })
      bundle.ageCapChoices_billed.push({ choice: '60', code: "60", SELECTED: false })
      bundle.ageCapChoices_billed.push({ choice: '90', code: "90", SELECTED: false })
      bundle.ageCapChoices_billed.push({ choice: '120', code: "120", SELECTED: false })
      bundle.ageCapChoices_billed.push({ choice: '150', code: "150", SELECTED: false })
      bundle.ageCapChoices_billed.push({ choice: '180', code: "180", SELECTED: false })
      bundle.ageCapChoices_billed.push({ choice: 'All', code: "1000", SELECTED: false })

      bundle.ageCapChoices_unbilled.push({ choice: '0', code: "0", SELECTED: false })
      bundle.ageCapChoices_unbilled.push({ choice: '30', code: "30", SELECTED: false })
      bundle.ageCapChoices_unbilled.push({ choice: '60', code: "60", SELECTED: false })
      bundle.ageCapChoices_unbilled.push({ choice: '90', code: "90", SELECTED: false })
      bundle.ageCapChoices_unbilled.push({ choice: '120', code: "120", SELECTED: false })
      bundle.ageCapChoices_unbilled.push({ choice: '150', code: "150", SELECTED: false })
      bundle.ageCapChoices_unbilled.push({ choice: '180', code: "180", SELECTED: false })
      bundle.ageCapChoices_unbilled.push({ choice: 'All', code: "1000", SELECTED: false })

      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '30', code: "30", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '60', code: "60", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '90', code: "90", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '120', code: "120", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '150', code: "150", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '180', code: "180", SELECTED: false })


      done02(bundle)

      function done02(bundle) {
       
        for (let i = 0; i < bundle.ageCapChoices_billed.length; i++) {

          if (bundle.PAYER[0].LIMIT_BILLED_AGECAPDAYS == bundle.ageCapChoices_billed[i].code) {

            setTimeout(() => {
              bundle.ageCapChoices_billed[i].SELECTED = true;
              done03(bundle)
            });
          }
        }        
      }

      function done03(bundle) {
        for (let j = 0; j < bundle.ageCapChoices_unbilled.length; j++) {

          if (bundle.PAYER[0].LIMIT_UNBILLED_AGECAPDAYS == bundle.ageCapChoices_unbilled[j].code) {

            bundle.ageCapChoices_unbilled[j].SELECTED = true;

            setTimeout(() => {

              ///console.log(bundle.LIMIT_UNBILLED_AGECAPDAYS)

              //bundle.ageCapChoices_unbilled[j].SELECTED = true;
              done04(bundle)
            });
          }

        }
      }

      function done04(bundle) {

        ///console.log(bundle.LIMIT_ENVCALCBUCKET_DAYS)

        for (let i = 0; i < bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.length; i++) {
          if (bundle.PAYER[0].LIMIT_ENVCALCBUCKET_DAYS == bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].code) {
            setTimeout(() => {
              bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].SELECTED = true;
              done05(bundle)
            });
          }
        }
      }

      function done05(bundle) {
        setTimeout(() => {
          bundle.showPayerEdit = true
        });
      }

    }
  }

  selectAgeCap_billed(event) {

    let val = event.target.innerText

    //console.log(val)

    for (var i = 0; i < this.bundle.ageCapChoices_billed.length; i++) {
      this.bundle.ageCapChoices_billed[i].SELECTED = false;


      if (val == this.bundle.ageCapChoices_billed[i].choice || val == this.bundle.ageCapChoices_billed[i].code) {
        this.bundle.ageCapChoices_billed[i].SELECTED = true;

        this.bundle.PAYER[0].LIMIT_BILLED_AGECAPDAYS = this.bundle.ageCapChoices_billed[i].code
      }
    }
  }

  selectAgeCap_unbilled(event) {

    let val = event.target.innerText

    //console.log(val)

    for (var i = 0; i < this.bundle.ageCapChoices_unbilled.length; i++) {
      this.bundle.ageCapChoices_unbilled[i].SELECTED = false;


      if (val == this.bundle.ageCapChoices_unbilled[i].choice || val == this.bundle.ageCapChoices_unbilled[i].code) {
        this.bundle.ageCapChoices_unbilled[i].SELECTED = true;

        this.bundle.PAYER[0].LIMIT_UNBILLED_AGECAPDAYS = this.bundle.ageCapChoices_unbilled[i].code
      }
    }
  }

  selectLIMIT_ENVCALCBUCKET_DAYS(event) {

    let val = event.target.innerText

    //console.log(val)

    for (var i = 0; i < this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.length; i++) {
      this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].SELECTED = false;


      if (val == this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].choice || val == this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].code) {
        this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].SELECTED = true;

        this.bundle.PAYER[0].LIMIT_ENVCALCBUCKET_DAYS = this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].code
      }
    }
  }

  onKeyUp(event) {
    this.text_billedAdvanceRate.val(removeTextChars(this.text_billedAdvanceRate.val()));
    this.text_unbilledAdvanceRate.val(removeTextChars(this.text_unbilledAdvanceRate.val()));
    this.text_reserve.val(removeTextChars(this.text_reserve.val()));
  }

  submitChange() {
    this.bundle.editUserMsg = "";

    this.bundle.PAYER[0].LIMIT_BILLEDADVANCE_PCT = this.text_billedAdvanceRate.val()
    this.bundle.PAYER[0].LIMIT_UNBILLEDADVANCE_PCT = this.text_unbilledAdvanceRate.val()
    this.bundle.PAYER[0].LIMIT_RESERVES_AMT = this.text_reserve.val()
    this.bundle.PAYER[0].LIMIT_ENV_OVERRIDE_PCT = this.text_LIMIT_ENV_OVERRIDE_PCT.val()

    if (this.bundle.PAYER[0].LIMIT_BILLEDADVANCE_PCT != "" && this.bundle.PAYER[0].LIMIT_UNBILLEDADVANCE_PCT != '') {
      this.LlModelsService.NG_LL_model_AR_payers_target_edit(this.returnedMessage,this.bundle.PAYER, this.bundle)
    }
  }

  returnedMessage(msg, bundle) {

    if (msg != '') {
      bundle.editUserMsg = msg
    }
  }


};

import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';
import { LlRolesService } from './ll-roles.service';
import { LlPermissionsService } from './ll-permissions.service';
import { LlCompaniesService } from './ll-companies.service';
import { javaDate_to_YYYYMMDD } from "./utility-date";
import { YYYYMMDD_add_hyphens } from './utility-date';

@Injectable({
  providedIn: 'root'
})

export class LlUtilityService {

  @Output() openMessagePU: EventEmitter<string> = new EventEmitter();
  @Output() refreshTemplates: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private ServerService: ServerService,
    private UserService: UserService,
    private LlRolesService: LlRolesService,
    private LlCompaniesService: LlCompaniesService,
    private LlPermissionsService: LlPermissionsService,
  ) { }

  vars = {
    ac: "",
    APPLICATIONVERSION: "",
    STUDYID_SYS: 0,
    CONTACTID: 0,
    COMPANYID: 0,
    COMPANYID_ACTIVE:0,
    CONTACTROLEID: 0,
    SYSTEMROLEID: 0,
    SUBJECTID_SYS: 0,
    STUDYPROVIDERID: 0,
    SYSTEMPRODUCTID: 0,
    QUESTIONSTAGEID: 0,
    
    ASSIGNLOANS: '',
    VISITCATEGORY: '',
    RECORDSTATUS: '',
    ACTION: '',
    TYPE:'',
    CONTACTID_ACTIVE: 0,
    LOANMODELID: '',
    MONTHENDDATE_NUM: 0,

    SYSTEMEMAILROUTINEID: 0,
    ROUTINENAME: '',
    ROUTINE: [],

    SYSTEMEMAILTEMPLATEID: 0,
    TEMPLATENAME: '',
    TEMPLATE: [],

    STUDYNEWSLETTERID: 0,
    NEWSLETTERNAME: '',
    NEWSLETTER: [],
  }

  bundle_local = {
    ServerService: this.ServerService,
    openMessagePU: this.openMessagePU,
    vars: this.vars,
    records: [],
    emailRoutines: [],
    emailTemplates: [],
    newsletters:[],
    inBundle: [],
  }


  NG_utility_loginRecord_get(callback01: Function, inBundle, obj, ACTION) {


    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_loginRecord_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      if (callback01 == null) {
        return arr

      } else {

        callback01(arr, inBundle, obj);
      }
    }
  }

  NG_utility_emailRecords_get(callback01: Function, inBundle, obj, ACTION) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_emailRecords_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      if (callback01 == null) {
        return arr

      } else {

        callback01(arr, inBundle, obj);
      }
    }
  }

  NG_utility_adminTrail_get(callback01: Function, inBundle, obj, ACTION) {


    var user = this.UserService.getActiveUser();
 
    this.bundle_local.vars.ac = "NG_utility_adminTrail_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();


    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 


      if (callback01 != null) {
        callback01(arr, inBundle, obj)
      } else {
        return arr
      }
    }
  }

  NG_utility_customerComments_get(callback01: Function, inBundle, obj, ACTION) {


    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_customerComments_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;


    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 


      if (callback01 != null) {
        callback01(arr, inBundle, obj)
      } else {
        return arr
      }
    }
  }

  NG_utility_errorLog_get(callback01: Function, inBundle, obj, ACTION) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_errorLog_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 


      if (callback01 != null) {
        callback01(arr, inBundle, obj)
      } else {
        return arr
      }
    }
  }

  NG_utility_userEntry_get(callback01: Function, inBundle, obj, ACTION) {


    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_userEntry_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();


    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 


      if (callback01 != null) {
        callback01(arr, inBundle, obj)
      } else {
        return arr
      }
    }
  }

  NG_LL_model_AR_admin_client_interface_records_get(callback01: Function, inBundle, obj) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_model_AR_admin_client_interface_records_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.RECORDSTATUS = inBundle.activeCODE;
  
    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      callback01(arr, inBundle, obj)

    }
  }

  setMonthEnd(monthEndDate_num) {
    this.bundle_local.vars.MONTHENDDATE_NUM = monthEndDate_num;
  }

  NG_utility_accounting_get(callback01: Function, inBundle?, obj?) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_accounting_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (callback01 != null) {
        callback01(arr, inBundle, obj)
      } else {
        return arr
      }
    }
  }

  NG_utility_accounting_ARModels_get(callback01: Function, inBundle?, obj?) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_accounting_ARModels_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (callback01 != null) {
        callback01(arr, inBundle, obj)
      } else {
        return arr
      }
    }
  }


  NG_utility_email_routines_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_email_routines_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (arr.length > 0) {
        bundle_local.vars.SYSTEMEMAILROUTINEID = arr[0].SYSTEMEMAILROUTINEID
      }

      bundle_local.emailRoutines = arr;

      callback01(arr, inBundle)
     
    }
  }

  getExistingEmailRoutines(callback01: Function, inBundle) {
   
    callback01(this.bundle_local.emailRoutines, inBundle)
  }

  setActiveSYSTEMEMAILROUTINEID(SYSTEMEMAILROUTINEID) {
    this.bundle_local.vars.SYSTEMEMAILROUTINEID = SYSTEMEMAILROUTINEID;
  }

  getActiveSYSTEMEMAILROUTINEID() {
    return this.bundle_local.vars.SYSTEMEMAILROUTINEID;
  }

  NG_utility_email_routineTarget_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_utility_email_routineTarget_get";

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      callback01(arr, inBundle);
     }    
  }

  NG_utility_email_routine_add(callback01: Function, ROUTINENAME, inBundle) {

    this.bundle_local.vars.ac = "NG_utility_email_routine_add";
    
    this.bundle_local.vars.ROUTINENAME = ROUTINENAME;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (arr.length > 0) {

        if (arr[0].SYSTEMEMAILROUTINEID_NEW == 0 ) {
          callback01(arr[0].ERRORMESSAGE, inBundle)

        } else {
          bundle_local.emailRoutines = arr;/////the new routine list is returned so needs to replace the old
          bundle_local.vars.SYSTEMEMAILROUTINEID = arr[0].SYSTEMEMAILROUTINEID_NEW; //set new contact as default for display
          callback01('0', inBundle); 
        }
      }                        
    }
  }

  NG_utility_email_routine_edit(callback01: Function, ROUTINE, inBundle) {

    this.bundle_local.vars.ac = "NG_utility_email_routine_edit";
    this.bundle_local.vars.ROUTINE = ROUTINE; //pass the entire array

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (arr[0].ERRORMESSAGE == '' || arr[0].ERRORMESSAGE == null) {
        callback01(arr[0].ERRORMESSAGE, inBundle);
      }

    }
  }

  NG_utility_email_recipients_picklist_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_utility_email_recipients_picklist_get";

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
          arr[i].ACCESS = 'Yes'
        } else {
          arr[i].SELECTED = false;
          arr[i].ACCESS = ''
        }
      }

      callback01(arr, inBundle, obj)

    }
  }

  NG_utility_email_recipients_picklist_edit(callback01: Function, inBundle, TYPE, ACTION,CONTACTID_ACTIVE) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_email_recipients_picklist_edit";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.CONTACTID_ACTIVE = CONTACTID_ACTIVE;
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.TYPE = TYPE;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (TYPE != 'SINGLE') {
        callback01(inBundle)
      }

    }

  }

  NG_utility_email_templates_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_email_templates_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (arr.length > 0) {
        bundle_local.vars.SYSTEMEMAILTEMPLATEID = arr[0].SYSTEMEMAILTEMPLATEID
      }

      bundle_local.emailTemplates = arr;

      callback01(arr, inBundle)

    }
  }

  getExistingEmailTemplates(callback01?: Function, inBundle?) {

    if (callback01 == null) {
      return this.bundle_local.emailTemplates
    } else {
      callback01(this.bundle_local.emailTemplates, inBundle)
    }
    
  }

  setActiveSYSTEMEMAILTEMPLATEID(SYSTEMEMAILTEMPLATEID) {
    this.bundle_local.vars.SYSTEMEMAILTEMPLATEID = SYSTEMEMAILTEMPLATEID;
  }

  getActiveSYSTEMEMAILTEMPLATEID() {
    return this.bundle_local.vars.SYSTEMEMAILTEMPLATEID;
  }

  NG_utility_email_templateTarget_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_email_templateTarget_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      callback01(arr, inBundle);

    }
  }

  NG_utility_email_template_add(callback01: Function, TEMPLATENAME, inBundle) {

    this.bundle_local.vars.ac = "NG_utility_email_template_add";
    this.bundle_local.vars.TEMPLATENAME = TEMPLATENAME;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)        

      if (arr.length > 0) {

        if (arr[0].SYSTEMEMAILTEMPLATEID_NEW == 0) {
          callback01(arr[0].ERRORMESSAGE, inBundle)

        } else {
          bundle_local.emailTemplates = arr;/////the new template list is returned so needs to replace the old
          bundle_local.vars.SYSTEMEMAILTEMPLATEID = arr[0].SYSTEMEMAILTEMPLATEID_NEW; //set new contact as default for display
          callback01('0', inBundle);
        }
      }                
    }
  }

  NG_utility_email_template_edit(callback01: Function, TEMPLATE, inBundle) {

    this.bundle_local.vars.ac = "NG_utility_email_template_edit";
    this.bundle_local.vars.TEMPLATE = TEMPLATE; //pass the entire array

    console.log(this.bundle_local.vars.TEMPLATE)

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (arr[0].SYSTEMEMAILTEMPLATEID_NEW == 0) {
        callback01(arr[0].ERRORMESSAGE, inBundle);
      }

    }
  }

  NG_utility_newsletters_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_newsLetters_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (arr.length > 0) {
        bundle_local.vars.STUDYNEWSLETTERID = arr[0].STUDYNEWSLETTERID
      }

      bundle_local.newsletters = arr;

      callback01(arr, inBundle)

    }
  }

  getExistingNewsletters(callback01?: Function, inBundle?) {

    if (callback01 == null) {
      return this.bundle_local.emailTemplates
    } else {
      callback01(this.bundle_local.emailTemplates, inBundle)
    }

  }

  setActiveSTUDYNEWSLETTERID(STUDYNEWSLETTERID) {
    this.bundle_local.vars.STUDYNEWSLETTERID = STUDYNEWSLETTERID;

    ///console.log("STUDYNEWSLETTERID: " + STUDYNEWSLETTERID)
  }

  getActiveSTUDYNEWSLETTERID() {
    return this.bundle_local.vars.STUDYNEWSLETTERID;
  }

  NG_utility_newsletterTarget_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_utility_newsletterTarget_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      callback01(arr, inBundle);

    }
  }

  NG_utility_newsletter_add(callback01: Function, TEMPLATENAME, inBundle) {

    this.bundle_local.vars.ac = "NG_utility_newsletter_add";
    this.bundle_local.vars.TEMPLATENAME = TEMPLATENAME;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)        

      if (arr.length > 0) {

        if (arr[0].STUDYNEWSLETTERID_NEW == 0) {
          callback01(arr[0].ERRORMESSAGE, inBundle)

        } else {
          bundle_local.newsletters = arr;/////the new template list is returned so needs to replace the old
          bundle_local.vars.STUDYNEWSLETTERID = arr[0].STUDYNEWSLETTERID_NEW; //set new contact as default for display
          callback01('0', inBundle);
        }
      }
    }
  }

  NG_utility_newsletter_edit(callback01: Function, NEWSLETTER, inBundle) {

    this.bundle_local.vars.ac = "NG_utility_newsletter_edit";
    this.bundle_local.vars.NEWSLETTER = NEWSLETTER; //pass the entire array

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (arr[0].STUDYNEWSLETTERID_NEW == 0) {
        callback01(arr[0].ERRORMESSAGE, inBundle);
      }

    }
  }

  saveCSV(records_arr, fields_arr, name?): any {

    //console.log(records_arr)
    //console.log(fields_arr) 
    ///var contactRole = this.LlRolesService.getActiveContactRole();


    //console.log(loan)
    //console.log(report)
    //console.log("SYSTEMPRODUCTID: " + SYSTEMPRODUCTID)

    var n = new Date()
    let nowDate = javaDate_to_YYYYMMDD(n)
    let fileName = nowDate + "_" + name;
    
    let csv = n + '\n'

    csv += fileName + '\n';
     
    for (let j = 0; j < fields_arr.length; j++) {

      csv += '"' + fields_arr[j].STUDYCOLUMNLABEL + '"';

      if (j < fields_arr.length - 1) {
        csv += ','
      } else {
        csv = csv + '\n'
      }
    }

    

    //if (CSV_ShowCRFFieldCodesYesNo_local == "Yes") { ///////add the first field code
    
      //for (let p = 0; p < fields_arr.length; p++) {
       // csv += '"' + fields_arr[p].SYSTEMORGANIZATIONFIELDCODE + '"';

        //if (p < fields_arr.length - 1) {
          //csv += ','
        //} else {
         // csv = csv + '\n'
        //}
      //}
    //}


    for (let i = 0; i < records_arr.length; i++) {	////loop thorugh all the records	

      ////loop through the columns and get the record value for the column ID - STUDYCOLUMNQUESTIONIDALPHA
      for (let k = 0; k < fields_arr.length; k++) {

        let field = fields_arr[k].STUDYCOLUMNQUESTIONIDALPHA.toUpperCase();

        if (k > 0) {
          csv += ','
        }

        if (records_arr[i][field] != null) {

          var textVal = '"' + records_arr[i][field] + '"';

          if (textVal != null && textVal != "") {        

            //console.log(textVal)
            if (textVal == '"assets/statusCircle_white.png"') {
              textVal = 'Incomplete'
            } else if (textVal == '"assets/statusCircle_yellow.png"') {
              textVal = 'Complete'
            } else if (textVal == '"assets/statusCircle_blue.png"') {
              textVal = 'Verified'
            } else if (textVal == '"assets/statusCircle_green.png"') {
              textVal = 'Reviewed'
            } else if (textVal == '"assets/statusCircle_locked.png"') {
              textVal = 'Locked'
            } else if (textVal == '"assets/statusCircle_signed.png"') {
              textVal = 'Signed'
            } else {
              textVal = String(textVal).replace(/,/g, "");
            }
          }

          csv += textVal;
        }
      }

      csv = csv + '\n'
    }


    var a = document.createElement("a");
    document.body.appendChild(a);
    let blob = new Blob([csv], { type: "text/csv" }),

      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName + ".csv";///'test.csv';//fileName;
    a.click();
    window.URL.revokeObjectURL(url);




  }

  triggerMessagePU(msg) {
    this.openMessagePU.emit(msg);
  }
}

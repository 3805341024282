import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { jqxWindowComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxwindow";
import { ServerService } from '../../services/server.service';


@Component({
  selector: 'loader-popup',
  templateUrl: './loader-popup.component.html',
  styleUrls: ['./loader-popup.component.css']
})

export class LoaderPopupComponent implements AfterViewInit, OnInit , OnDestroy {
  
  @ViewChild('loaderMain') loaderMain: ElementRef;

  constructor( 
    private ServerService: ServerService,
    ) {     
  }
  
  bundle = {  
    
    show:false,
  }
  

  eventSubscription_loaderOpen:any;
  
  ngOnDestroy() {
    this.eventSubscription_loaderOpen.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.eventSubscription_loaderOpen = this.ServerService.loaderOpen.subscribe(isOpen => {  
      
      
      this.bundle.show = isOpen;/////rem'ed for Development

      //console.log("PRODUCTION DELETE REMINDER")
      

    });

  }

  ngOnInit() {} 

};

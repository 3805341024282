import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlCompaniesService } from '../ll-companies.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { LlModelsService } from '../ll-models.service';

@Component({
  selector: 'll-company-switch',
  templateUrl: './ll-company-switch.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlCompanySwitchComponent implements OnInit {


  @ViewChild('mainDiv_ll_company_switch') mainDiv: ElementRef;
  @ViewChild('horzTabs_ll_company_switch') public horzTabs: ElementRef;


  constructor(
    private LlCompaniesService: LlCompaniesService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,
    private LlModelsService: LlModelsService,
  ) { }

  bundle = {
    showHorzTabs: false,
    showRemaining: false,    
    function: '',
    LlCompaniesService: this.LlCompaniesService,
    LlScrollpaneService: this.LlScrollpaneService,
    LlModelsService: this.LlModelsService,
    LlPermissionsService: this.LlPermissionsService,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 20,
    remainingW: 0,
    remainingH: 0,
    showTab: true,
    horzTabsY: 40,
    hTabW: 100,
    hTabH: 25,
    hTabIndent: 100,
    hTabIndx: 0,
    horzTabs: [],
    horzTabs_02: [],
    canvasHT: {},
    ctxHT: {},
    activeHorzTab: '',
    companyName: '',
    companyAbrev: '',
    companyId: 0,
    companyLabelX:0,
  }

  ngOnInit() { }
  ngOnDestroy() { }

  ngAfterViewInit() {

    this.setVars();
  }

  setVars() {
    this.bundle.showHorzTabs = false;
    this.bundle.showRemaining = false;

    this.bundle.function = this.mainDiv.nativeElement.parentElement.id;

    this.bundle.companyId = this.LlCompaniesService.getActiveCompanyId();

    //if (this.bundle.companyId != 0) {
      //this.bundle.horzTabsY = 40;
    //}

    setTimeout(() => {
      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      setTimeout(() => {
        this.bundle.remainingX = 0;
        this.bundle.remainingY = this.bundle.horzTabsY + this.bundle.hTabH + 3
        this.bundle.remainingW = this.bundle.parentW;
        this.bundle.remainingH = this.bundle.parentH - this.bundle.remainingY;

        var canvasHT: HTMLCanvasElement = this.horzTabs.nativeElement;
        this.bundle.canvasHT = canvasHT
        this.bundle.ctxHT = canvasHT.getContext('2d');

        this.getData()
      });
    });
  }

  getData() {
    
    if (this.bundle.companyId != 0) {
      this.LlCompaniesService.NG_LL_company_get(done01, this.bundle);

    } else {
      let dummy:[];

      done01(dummy, this.bundle)
    }

    function done01(record, bundle) {

      //console.log(record)

      if (bundle.companyId != 0) {
        bundle.companyId = record[0].COMPANYID;
        bundle.companyName = record[0].COMPANYNAME;
        bundle.companyAbrev = record[0].COMPANYABREV;
      }

      

      var model = bundle.LlModelsService.getActiveCategory();

      bundle.horzTabs.splice(0);

      if (bundle.function == "from_ll_models_sp" || bundle.function == "from_ll_admin_generic_dg") {

        if (bundle.LlPermissionsService.check("viewModels")
          || bundle.LlPermissionsService.check("EditModels")) {

          bundle.horzTabs.push({ lbl: "Models" });
        }

        if (bundle.LlPermissionsService.check("addEditUser")) { 
          bundle.horzTabs.push({ lbl: "Users" });
        }

        if (bundle.LlPermissionsService.check("addEditCompany")) { 
          bundle.horzTabs.push({ lbl: "Company" });
        }

        if (bundle.LlPermissionsService.check("viewLogInRecords")) {
          bundle.horzTabs.push({ lbl: "Logins" });
        }

        if (bundle.LlPermissionsService.check("viewEmailRecords")) {
          bundle.horzTabs.push({ lbl: "Emails" });
        }
       

        if (bundle.LlPermissionsService.check("adminEventTrail")) {
          bundle.horzTabs.push({ lbl: "Entry" });
        }

      

        bundle.activeHorzTab = "Models";

      } else {

        bundle.hTabIndent = 30;

        if (bundle.LlPermissionsService.check("addEditUser")) {
          bundle.horzTabs.push({ lbl: "Users" });
        }

        bundle.activeHorzTab = "Users";

        if (bundle.companyId != 0) {
          if (bundle.LlPermissionsService.check("viewModels")
            || bundle.LlPermissionsService.check("EditModels")) {

            bundle.horzTabs.push({ lbl: "Models" });
          }
          if (bundle.LlPermissionsService.check("addEditCompany")) {
            bundle.horzTabs.push({ lbl: "Company" });
          }
          if (bundle.LlPermissionsService.check("viewLogInRecords")) {
            bundle.horzTabs.push({ lbl: "Logins" });
          }
          if (bundle.LlPermissionsService.check("viewEmailRecords")) {
            bundle.horzTabs.push({ lbl: "Emails" });
          }

          if (bundle.LlPermissionsService.check("adminEventTrail")) {
            bundle.horzTabs.push({ lbl: "Entry" });
          }

        } else {
          bundle.hTabIndx = 0;///users
        }
      }

      bundle.companyLabelX = bundle.remainingX + bundle.hTabIndent + 20;

      bundle.LlScrollpaneService.paintHorzTab(horzTabSelected, bundle.horzTabs, bundle.canvasHT, bundle.ctxHT, bundle, false);

      function horzTabSelected(indx, bundle) {}

      bundle.showHorzTabs = true;
      bundle.showRemaining = true;
      //}
    }

  }
}



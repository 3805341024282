import { Component, AfterViewInit, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { LlRolesService } from '../ll-roles.service';

@Component({
  selector: 'll-main-canvas',
  templateUrl: './ll-main-canvas.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlMainCanvasComponent implements OnInit {
  @ViewChild('canvas_appOutline') public canvas_appOutline: ElementRef;

  constructor(
    private LlRolesService: LlRolesService,
  ) { }

  bundle = {
    showObj: false,
    OL_W:0,
    OL_H:0,
    OL_X:15,
    OL_Y:50,
    spX: 0,
    remainingW:0,
    logoX: 10,
    logoY:10,
    logoH:0,
    parentW: 0,
    parentH: 0,
    sponsorLogo: "assets/lendingLincLogo_noTag_lrg.png",
  }

  ngOnInit() {
    this.bundle.parentW = window.innerWidth;
    this.bundle.parentH = window.innerHeight;
    
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {

      this.bundle.parentW = window.innerWidth;
      this.bundle.parentH = window.innerHeight;

      setTimeout(() => {
      var OL_X = this.bundle.OL_X;
      var by = this.bundle.OL_Y - 1
      this.bundle.logoH = by - (this.bundle.logoY * 2);

      var bw = window.innerWidth - (OL_X * 2);
      var bh = window.innerHeight - 10;
        
      this.bundle.OL_W = bw;
      this.bundle.OL_H = bh;

      this.bundle.spX = this.bundle.OL_X + 10;
      this.bundle.remainingW = this.bundle.OL_W - 10;

      var canvasOL: HTMLCanvasElement = this.canvas_appOutline.nativeElement;
      canvasOL.width = bw + 23;
      canvasOL.height = bh + 2;

      var ctx1 = canvasOL.getContext('2d');
      ctx1.beginPath()
      ctx1.strokeStyle = "#999999";
      ctx1.lineWidth = 2;
      ctx1.beginPath();
      ctx1.moveTo(OL_X + 20, by);
      ctx1.lineTo(OL_X + bw - 20, by);
      ctx1.quadraticCurveTo(OL_X + bw, by, OL_X + bw, by + 20);
      ctx1.lineTo(OL_X + bw, bh - 20);
      ctx1.quadraticCurveTo(OL_X + bw, bh, OL_X + bw - 20, bh);
      ctx1.lineTo(OL_X + 20, bh);
      ctx1.quadraticCurveTo(OL_X, bh, OL_X, bh - 20);
      ctx1.lineTo(OL_X, by + 20);
      ctx1.quadraticCurveTo(OL_X, by, OL_X + 20, by);
      ctx1.stroke();

      this.getData()
      });
  }

  getData() {

 

    this.bundle.sponsorLogo = "assets/"+this.LlRolesService.getActiveLogo();

    //console.log("this.bundle.sponsorLogo: " + this.bundle.sponsorLogo )
    
    setTimeout(() => {      
      this.bundle.showObj = true;
    });
    
  }
}

<div #mainDiv_ll_info_request_entry>

  <!--HEADER-->
  <div *ngIf="bundle.showHeader">

    <div class="txtBold" [style.width.px]="bundle.objW" style="float: left; padding-top: 10px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
      {{bundle.FORMNAME}}
    </div>
  </div>

  <!--<div>--->
  <!--Stage Arrows-->
  <div [hidden]="!bundle.showQCLable" [style.top.px]="bundle.spY - 20" style="position: absolute;">

    <div class="txtNorm11" style="float: left;">
      Stage Arrows
    </div>

    <jqxTooltip style="float: left;"
                [position]="'left'"
                [name]="'movieTooltip'"
                [autoHideDelay]="30000"
                [content]="'Click UP arrow to graduate question/answer to next control level and DOWN arrow to demote it.'"
                [opacity]="1.0"
                [width]="200">

      <img class="infoBtn" src="assets/infoBtn.png" />

    </jqxTooltip>
  </div>



  <div [style.top.px]="bundle.spY" style="position: absolute;">

    <jqxPanel #formEntryPanel
              style="background-color: transparent;
                  border: none;
                  outline: none;
                  border-top: 1px solid  rgb(160, 158, 158);
                  border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [width]="bundle.objW"
              [height]="bundle.objH">

      <div [hidden]="bundle.viewEditQuestion">

        <div class="questionObj" *ngFor="let item of bundle.questions; index as i" style="padding-top: 4px; ">

          <div *ngIf="item.QUESTIONREQUIRED != 'C'">

            <div *ngIf="item.NGWIDGET == 'number' || item.NGWIDGET == 'ftx'" [style.width.px]="bundle.objW">
              <ll-info-request-widget-general id={{item.STUDYQUESTIONID}}></ll-info-request-widget-general>
            </div>

            <div style="clear:left;" *ngIf="item.NGWIDGET == 'ftx_long'" [style.width.px]="bundle.objW">
              <ll-info-request-widget-long-text id={{item.STUDYQUESTIONID}}></ll-info-request-widget-long-text>
            </div>

            <div style="clear:left;" *ngIf="item.NGWIDGET == 'docs'" [style.width.px]="bundle.objW">
              <ll-info-request-widget-docs id={{item.STUDYQUESTIONID}}></ll-info-request-widget-docs>
            </div>

            <div style="clear:left;" *ngIf="item.NGWIDGET == 'mlp'" [style.width.px]="bundle.objW">
              <ll-info-request-widget-mlp id={{item.STUDYQUESTIONID}}></ll-info-request-widget-mlp>
            </div>

            <div [style.width.px]="bundle.objW - 20" style="clear: left; border-bottom: .5px solid gray; padding-top: 3px "></div>
          </div>
        </div>
      </div>

      <div *ngIf="bundle.viewEditQuestion" style="clear: left; padding-top: 10px; padding-left: 10px">
        <div class="wordBtnGroup">
          <img class="btnIcon" height=23 style="width: auto;" src="assets/icon_backArrow.png" alt="" (click)="backToView()">
          <div class="wordBtn14" style="padding-left:5px; padding-top:3px;" (click)="backToView()">Back</div>
        </div>

        <!-- ll-info-request-question-switch-->
        <div [style.top.px]="20"
             [style.left.px]="10"
             [style.width.px]="bundle.objW - 10"
             [style.height.px]="bundle.objH - 20"
             style="position: absolute; z-index: 100">

          <ll-info-request-question-switch></ll-info-request-question-switch>
        </div>


      </div>


    </jqxPanel>


  </div>

  <div *ngIf="bundle.showScreen"
       [style.top.px]="bundle.spY"
       [style.width.px]="bundle.objW"
       [style.height.px]="bundle.objH"
       class="questionObj"
       style="position: absolute;">

  </div>

  <!--BOTTOM-->
  <div *ngIf="!bundle.viewEditQuestion" id=bottom [ngStyle]="{'top.px':bundle.spB + 10}" style="position: absolute; padding-bottom: 0px;">

    <!--Clear This Form-->
    <div class="btnGroup" id="clearBtn" *ngIf="bundle.canClearForms">

      <div class="wordBtnGroup">
        <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_broom.png" alt="" (click)="noAction()">
        <div class="wordBtn" (click)="noAction()">Clear This Form</div>
      </div>

      <jqxTooltip style="float: left;"
                  [position]="'left'"
                  [name]="'movieTooltip'"
                  [autoHideDelay]="30000"
                  [content]="'Clear/Erase/Delete Form Content'"
                  [opacity]="1.0"
                  [width]="200">

        <img class="infoBtn" src="assets/infoBtn.png" />

      </jqxTooltip>


      <jqxPopover #popover_clear
                  [width]="525"
                  [position]="'top'"
                  [title]="''"
                  [showCloseButton]="true"
                  [selector]="'#clearBtn'">

        <div style="display: table;">
          <img height=50 style="width: auto; float: left; padding-right:20px;" src="assets/icon_hermes.png">
          <div class="txtNorm" width=50>Are you certain that you want to clear/erase/delete the content of this Form?  All of the question values will be erased / wiped clean.</div>
        </div>

        <div class="wordBtnGroup_popover">
          <div class="wordBtn" (click)="clearForm()">Yes, Proceed</div>
        </div>

      </jqxPopover>


    </div>

    <!--Delete This Form-->
    <div class="btnGroup" id="deleteBtn" *ngIf="bundle.deleteFormsStatus == 'button'">

      <div class="wordBtnGroup">
        <img class="btnIcon" width=15 style="height: auto; padding-top:4px" src="assets/icon_minus.png" alt="" (click)="noAction()">
        <div class="wordBtn" (click)="noAction()"> Delete This Form </div>
      </div>


      <jqxTooltip style="float: left;"
                  [position]="'right'"
                  [name]="'movieTooltip'"
                  [autoHideDelay]="30000"
                  [content]="'Delete this Form'"
                  [opacity]="1.0"
                  [width]="200">

        <img class="infoBtn" src="assets/infoBtn.png" />

      </jqxTooltip>


      <jqxPopover #popover_delete
                  [width]="300"
                  [position]="'top'"
                  [title]="'Delete Form'"
                  [showCloseButton]="true"
                  [selector]="'#deleteBtn'">

        <div style="display: table;">
          <img height=50 style="width: auto; float: left; padding-right:20px;" src="assets/icon_hermes.png">
          <div class="txtNorm" width=50>Are you certain that you want to delete Form?</div>
        </div>

        <div class="wordBtnGroup_popover">
          <!--<div class="wordBtn" (click)="deleteForm()">Yes, Proceed</div>-->
        </div>

      </jqxPopover>

    </div>

    <!--QC Steps-->
    <div style="float:left;">

      <!--Verify Form-->
      <div class="btnGroup" id=verifyFormBtn *ngIf="bundle.canVerifyForm">

        <div class="wordBtnGroup">
          <img class="btnIcon" height=15 style="width: auto;" src="assets/statusCircle_blue.png" alt="" (click)="noAction()">
          <div class="wordBtn" (click)="noAction()">Verify Form</div>
        </div>

        <jqxTooltip style="float: left;"
                    [position]="'left'"
                    [name]="'movieTooltipVerify'"
                    [autoHideDelay]="30000"
                    [content]="'Designate ALL ENTERED questions in this FORM as VERIFIED'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

        <jqxPopover #popover_verifyForm
                    [offset]='{ left: -200, top: 0 }'
                    [arrowOffsetValue]="200"
                    [width]="525"
                    [position]="'top'"
                    [title]="''"
                    [showCloseButton]="true"
                    [selector]="'#verifyFormBtn'">

          <div style="display: table;">
            <img height=50 style="width: auto; float: left; padding-right:20px;" src="assets/icon_hermes.png">
            <div class="txtNorm" width=50>Are you certain that you want to designate all ENTERED data in this form as VERIFIED?</div>
          </div>

          <div class="wordBtnGroup_popover">
            <div class="wordBtn" (click)="verifyForm()">Yes, Proceed</div>
          </div>

        </jqxPopover>
      </div>

      <!--Review Form-->
      <div class="btnGroup" id=reviewFormBtn *ngIf="bundle.canReviewForm">

        <div class="wordBtnGroup">
          <img class="btnIcon" height=15 style="width: auto;" src="assets/statusCircle_green.png" alt="" (click)="noAction()">
          <div class="wordBtn" (click)="noAction()" type="button">Review Form</div>
        </div>


        <jqxTooltip style="float: left;"
                    [position]="'left'"
                    [name]="'movieTooltipReview'"
                    [autoHideDelay]="30000"
                    [title]="'Designate ALL VERIFIED questions in this FORM as REVIEWED'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>


        <jqxPopover #popover_reviewForm
                    [offset]='{ left: -200, top: 0 }'
                    [arrowOffsetValue]="200"
                    [width]="525"
                    [position]="'top'"
                    [title]="''"
                    [showCloseButton]="true"
                    [selector]="'#reviewFormBtn'">

          <div style="display: table;">
            <img height=50 style="width: auto; float: left; padding-right:20px;" src="assets/icon_hermes.png">
            <div class="txtNorm" width=50>Are you certain that you want to designate all VERIFIED data in this form as REVIEWED?</div>
          </div>

          <div class="wordBtnGroup_popover">
            <div class="wordBtn" (click)="reviewForm()">Yes, Proceed</div>
          </div>

        </jqxPopover>
      </div>

      <!--Lock Form-->
      <div class="btnGroup" id=lockFormBtn *ngIf="bundle.canLockForm">

        <div class="wordBtnGroup">
          <img class="btnIcon" height=15 style="width: auto;" src="assets/statusCircle_locked.png" alt="" (click)="noAction()">
          <div class="wordBtn" (click)="noAction()" type="button">Lock Form</div>
        </div>

        <jqxTooltip style="float: left;"
                    [position]="'left'"
                    [name]="'movieTooltipLock'"
                    [autoHideDelay]="30000"
                    [title]="'Designate ALL REVIEWED questions in this FORM as LOCKED'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>


        <jqxPopover #popover_lockForm
                    [offset]='{ left: -200, top: 0 }'
                    [arrowOffsetValue]="200"
                    [width]="525"
                    [position]="'top'"
                    [title]="''"
                    [showCloseButton]="true"
                    [selector]="'#lockFormBtn '">

          <div style="display: table;">
            <img height=50 style="width: auto; float: left; padding-right:20px;" src="assets/icon_hermes.png">
            <div class="txtNorm" width=50>Are you certain that you want to designate all REVIEWED data in this form as LOCKED?</div>
          </div>

          <div class="wordBtnGroup_popover">
            <div class="wordBtn" (click)="lockForm()"> Yes, Proceed</div>
          </div>

        </jqxPopover>
      </div>

      <!--Sign Form-->
      <div class="btnGroup" id=signFormBtn *ngIf="bundle.canSignForm">

        <div class="wordBtnGroup">
          <img class="btnIcon" height=15 style="width: auto;" src="assets/statusCircle_signed.png" alt="" (click)="noAction()">
          <div class="wordBtn" (click)="noAction()" type="button">Sign Form</div>
        </div>

        <jqxTooltip style="float: left;"
                    [position]="'left'"
                    [name]="'movieTooltipSign'"
                    [autoHideDelay]="30000"
                    [title]="'Designate ALL LOCKED questions in this FORM as SIGNED'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

        <jqxPopover #popover_signForm
                    [offset]='{ left: -200, top: 0 }'
                    [arrowOffsetValue]="200"
                    [width]="525"
                    [position]="'top'"
                    [title]="''"
                    [showCloseButton]="true"
                    [selector]="'#signFormBtn'">

          <div style="display: table;">
            <img height=50 style="width: auto; float: left; padding-right:20px;" src="assets/icon_hermes.png">
            <div class="txtNorm" width=50>Are you certain that you want to designate all LOCKED data in this form as SIGNED?</div>
          </div>

          <div class="wordBtnGroup_popover">
            <div class="wordBtn" (click)="signForm()">Yes, Proceed</div>
          </div>

        </jqxPopover>
      </div>

    </div>

  </div>


  <!--</div>--->
</div>

import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'

@Component({
  selector: 'll-model-dash',
  templateUrl: './ll-model-dash.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelDashComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_dash') private mainDiv: ElementRef;
  @ViewChild('SP_EXPLINATIONHTML') private SP_EXPLINATIONHTML: jqxPanelComponent;

  constructor(
    private LlModelsService: LlModelsService,
  ) { }

  bundle = {
    showDash: false,
    showGraph: false,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    LlModelsService: this.LlModelsService,
    records: [],
    groups: [],
    columnW: 0,
    rowH: 50,
    spX: 0,
    spY: 30,
    spW: 0,
    spH: 0,
    graphAreaY: 60,
    graphAreaX: 0,
    graphAreaH: 175,
    graphAreaW: 0,
    panelX: 30,
    panelY: 0,
    panelW: 0,
    panelH: 0,
    EXPLANATIONHTML: '',
  }

  ngOnDestroy() {}
  ngOnInit() {}

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {
    this.bundle.showDash = false;
    this.bundle.showGraph = false;

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    this.bundle.spW = this.bundle.parentW - (this.bundle.spX * 2) - 15;
    this.bundle.spH = this.bundle.parentH;

    this.bundle.graphAreaW = this.bundle.parentW;

    this.bundle.panelY = this.bundle.graphAreaY + this.bundle.graphAreaH + 10
    this.bundle.panelW = this.bundle.parentW - (this.bundle.panelX * 2);
    this.bundle.panelH = this.bundle.parentH - this.bundle.panelY - 40;

    setTimeout(() => {
      this.getData();
    });


  }

  getData() {

    //console.log("getData()")

    this.LlModelsService.NG_LL_dashboard_get(done01, this.bundle);

    function done01(arr, bundle) {

      bundle.records = arr;

      var groupArr: any = []

      for (let i = 0; i < arr.length; i++) {
        if (groupArr.indexOf(arr[i].PREFERENCENAME) == -1) {
          groupArr.push(arr[i].PREFERENCENAME);
        }
      }

      ///bundle.LlModelsService.getElementsGroups(done02, bundle.records, bundle);

      done02(groupArr, bundle)
    }

    function done02(groups, bundle) {
      bundle.groups = groups;

      let stage = bundle.spW - 10;

      bundle.columnW = stage / 3;

      if (bundle.groups.length == 2) {
        bundle.columnW = stage / 2;
      } else if (bundle.groups.length == 3) {
        bundle.columnW = stage / 3;
      }

      ///console.log(bundle.columnW)

      if (bundle.columnW < 400) {
        bundle.columnW = 400
      }


      setTimeout(() => {
        bundle.showDash = true;
      });
    }

    
  }

  elementPopUp(event) {

    //console.log(event.target.id)    

    for (let i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].PREFERENCEELEMENT == event.target.id) {

       
        this.LlModelsService.setActiveElement_withElement(this.bundle.records[i].PREFERENCEELEMENT);

        this.LlModelsService.setDaysBack(this.bundle.records[i].PREFERENCEKPIDAYSBACK);

        this.bundle.EXPLANATIONHTML = this.bundle.records[i].EXPLANATIONHTML_BOLD;

        this.bundle.showDash = false;
        this.bundle.showGraph = true;

        break
      }



    }



  }

  backToDash() {
    this.bundle.showDash = true;
    this.bundle.showGraph = false;

  }
}


import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";

@Component({
  selector: 'll-info-request-remove',
  templateUrl: './ll-info-request-remove.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestRemoveComponent implements OnInit {

  @ViewChild('mainDiv_ll_info_request_remove') private mainDiv: ElementRef;
  @ViewChild('dataGrid_ll_info_request_remove') datagrid: jqxGridComponent;
  @ViewChild('mainDiv_ll_info_request_remove_popover') private mainDiv_ll_info_request_remove_popover: jqxPopoverComponent;

  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlPermissionsService: LlPermissionsService,
  ) {

  }

  bundle = {

    showObj: false,
    canEdit: true,
    choices: [],
    recordList: [],
    fields:[],
    LlInfoRequestsService: this.LlInfoRequestsService,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    headerY: 10,
    headerH: 20,
    gridY: 0,
    gridW: 0,
    gridH: 0,
  }

  ngOnDestroy() { }
  ngOnInit() { }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {
    this.bundle.showObj = false


    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {
      //this.bundle.canEdit = this.LlPermissionsService.check("addEditUser")

      //if (this.bundle.canEdit) {
        //this.bundle.headerH = 50;
      //}

      this.bundle.gridY = this.bundle.headerY + this.bundle.headerH;
      this.bundle.gridW = this.bundle.parentW - 20;
      this.bundle.gridH = this.bundle.parentH - this.bundle.gridY - 40;

      this.getData();

    });
  }

  getData() {

    
    this.LlInfoRequestsService.getExistingSubjectVisits(paintGrid, this.bundle, this.datagrid)
   

    function paintGrid(arr, bundle, datagrid) {

      for (let i = 0; i < arr.length; i++) {
          arr[i].SELECTED = false;        
      }

      bundle.recordList = arr;

      var columns = [];
      var fields = [];
      var cols = [];

      let colW01 = 50;
      let colW02 = 0;
      let colW03 = 0;


      ///push strait to columns
      fields.push({ name: 'SELECTED', type: 'bool' });
      columns.push({ text: '', datafield: 'SELECTED', columntype: 'checkbox', width: colW01 });

      let remainingW = bundle.gridW - 30 - colW01 - colW02 - colW03;

      cols.push({ text: 'Request', datafield: 'FOLDERNAME', type: 'string', width: remainingW, hidden: false });

      for (let i = 0; i < cols.length; i++) {

        fields.push({
          name: cols[i].datafield,
          type: 'string'
        });

        columns.push({
          text: cols[i].text,
          datafield: cols[i].datafield,
          width: cols[i].width
        });
      }

      

      var source = {
        localdata: bundle.recordList,
        datatype: 'array',
        datafields: fields
      };

      bundle.fields = fields;

      var dataAdapter = new jqx.dataAdapter(source);

      datagrid.source(dataAdapter);
      datagrid.columns(columns);
      datagrid.width(bundle.gridW);
      datagrid.height(bundle.gridH);

      bundle.showObj = true;

    }

  }

  Cellvaluechanged(event: any): void {

    if (this.bundle.canEdit == true) {

      ///console.log(event.args.newvalue)

      //this.bundle.recordList[event.args.rowindex].CHECKED = event.args.newvalue
      this.bundle.recordList[event.args.rowindex].SELECTED = event.args.newvalue

      //let Action = 'addSingle';

      //if (event.args.newvalue == false) {
        //Action = 'removeSingle';
      //}

      ///this.LlInfoRequestsService.addRemoveRoleCompany(Action, this.bundle, this.bundle.recordList[event.args.rowindex].FOLDERID)


    }
  }

  noAction() {
    //needed HTML buttons point to this
  }

  select(event) {

  }

  enter() {
    this.mainDiv_ll_info_request_remove_popover.close();

    //console.log(this.bundle.recordList)
    this.LlInfoRequestsService.NG_LL_infoReq_subjectVisits_remove(this.bundle.recordList, this.bundle)
  }


  clearAll() {

    for (let i = 0; i < this.bundle.recordList.length; i++) {
      this.bundle.recordList[i].SELECTED = true;
    }

    var source = {
      localdata: this.bundle.recordList,
      datatype: 'array',
      datafields: this.bundle.fields
    };

    var dataAdapter = new jqx.dataAdapter(source);
    this.datagrid.source(dataAdapter);
  }
}


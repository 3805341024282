import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlPermissionsService } from '../ll-permissions.service';

@Component({
  selector: 'll-info-request-entry-question',
  templateUrl: './ll-info-request-entry-question.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestEntryQuestionComponent {

  //@ViewChild('textAreaReference') private myInput: jqxTextAreaComponent;
  //@ViewChild('inputField') private inputField: ElementRef;
  @ViewChild('metricHeader') metricHeader: ElementRef;


  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlPermissionsService: LlPermissionsService,
  ) { }

  bundle = {
    showObj: false,
    showQCCheck: false,
    LlInfoRequestsService: this.LlInfoRequestsService,
    statsButton:false,
    STUDYQUESTIONID: 0,
    QUESTIONINPUTTYPE: '',
    QUESTIONNAME: '',
    QUESTIONINSTRUCTIONS:'',
    COUNT: "",
    CIRCLEIMG_QUESTION: "",
    parentW:0,
    objW: 0,
    col01W: 30, //QC Stages
    col02W: 70, //status circle and row counter
    col02_left: 10,
    col03W: 0, //question language
  }



  ngOnInit() { }

  eventSubscription_renewQuestion: any;

  ngOnDestroy() {
    this.eventSubscription_renewQuestion.unsubscribe();
  }

  ngAfterViewInit() {

    this.eventSubscription_renewQuestion = this.LlInfoRequestsService.renewQuestion.subscribe(STUDYQUESTIONID => {

      if (this.bundle.STUDYQUESTIONID == STUDYQUESTIONID) {
        this.setVars();
      }

    });

    this.setVars();

  }

  setVars() {

    this.bundle.STUDYQUESTIONID = this.metricHeader.nativeElement.parentElement.id;
    let record = this.LlInfoRequestsService.getSelectStudyQuestion(this.bundle.STUDYQUESTIONID);
    let subjectVisit = this.LlInfoRequestsService.getActiveSubjectVisit();

    //console.log(subjectVisit)

    setTimeout(() => {

      this.bundle.objW = this.metricHeader.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.col03W = this.bundle.objW - this.bundle.col01W - this.bundle.col02W - 50; 

      this.bundle.COUNT = record.COUNT;
      this.bundle.CIRCLEIMG_QUESTION = record.CIRCLEIMG_QUESTION;
      this.bundle.QUESTIONINPUTTYPE = record.QUESTIONINPUTTYPE;
      this.bundle.QUESTIONNAME = record.QUESTIONNAME;
      this.bundle.QUESTIONINSTRUCTIONS = record.QUESTIONINSTRUCTIONS;

      //questionStageId	questionStageName
      //1	Not Entered
      //2	Entered
      //3	Verified
      //5	Reviewed
      //6	Locked
      //20	Signed

      //console.log("record.QUESTIONSTAGEID: " + record.QUESTIONSTAGEID)
      //console.log(this.LlPermissionsService.check("qcverify"))
   
      if (subjectVisit != null && subjectVisit.QUERIESENABLEDYESNO == 'Yes') {

        if (this.LlPermissionsService.check("query_add")
          || this.LlPermissionsService.check("query_answer")
          || this.LlPermissionsService.check("query_close")
          || this.LlPermissionsService.check("query_view")) {

          this.bundle.statsButton = true;

         }
       }

      if (this.LlPermissionsService.check("build")
        || this.LlPermissionsService.check("questionView_trail")
        || this.LlPermissionsService.check("questionView_QC")) {

        //this.bundle.statsButton = true;

      }

      if (this.LlPermissionsService.check("qcverify")
        || this.LlPermissionsService.check("qcreview")
        || this.LlPermissionsService.check("qclock")
        || this.LlPermissionsService.check("qcsignature")) {

        this.bundle.col02_left = this.bundle.col01W + 5;
        //this.bundle.col02W = 90;
      }

      if (this.bundle.statsButton) {
        this.bundle.col02_left = this.bundle.col02_left - 5;
        this.bundle.col02W = 83;
      }

      ///questionStageId	questionStageName
      ///1	Not Entered
      ///2	Entered
      ///3	Verified
      ///5	Reviewed
      ///6	Locked
      /// 20	Signed

      if (record.QUESTIONSTAGEID == 2 || record.QUESTIONSTAGEID == 3) {
        if (record.QUESTIONREQUIRED == 'R' && this.LlPermissionsService.check("qcverify")) {
          this.bundle.showQCCheck = true;
        }
      }

      if (record.QUESTIONSTAGEID == 3 || record.QUESTIONSTAGEID == 5) {
        if (record.QUESTIONREQUIRED == 'R' && this.LlPermissionsService.check("qcreview")) {
          this.bundle.showQCCheck = true;
        }
      }

      if (record.QUESTIONSTAGEID == 5 || record.QUESTIONSTAGEID == 6) {
        if (record.QUESTIONREQUIRED == 'R' && record.QUESTIONREQUIRED && this.LlPermissionsService.check("qclock")) {
          this.bundle.showQCCheck = true;
        }
      }

      if (record.QUESTIONSTAGEID == 6 || record.QUESTIONSTAGEID == 20) {
        if (record.QUESTIONREQUIRED == 'R' && this.LlPermissionsService.check("qcsignature")) {
          this.bundle.showQCCheck = true;
        }
      }

    });


  }

  stageChange(ACTION) {

    this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID);
    this.LlInfoRequestsService.NG_LL_infoReq_QC_subjectQuestion_status_edit(ACTION);

    
  }

  editQuestion() {
    this.LlInfoRequestsService.viewQuestionEdit(this.bundle.STUDYQUESTIONID);
  }

}


<div #mainDiv_ll_model_cms_functions_sp>

  <canvas #vertTab_ll_model_cms_functions_sp></canvas>

  <div *ngIf="bundle.showObj">

    <div class="spLabel" [ngStyle]="{'top.px':bundle.lblY, 'width.px':bundle.spW}">
      {{bundle.lable}}
    </div>

    <jqxPanel #scrollpane_ll_model_cms_functions_sp [style.top.px]="bundle.spY" style="background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <tr *ngFor="let item of bundle.records" style="white-space: nowrap;">

        <div class="spRowLabel"
             [id]="bundle.prefix + item.FOLDERID"
             title={{item.FOLDERNAME}}
             [style.width.px]="bundle.spW - 20" (click)="select($event)">
          {{item.FOLDERNAME}}
        </div>

      </tr>

    </jqxPanel>



    <!--BOTTOM-->
    <div id=bottomLine [style.top.px]="bundle.spB">
    </div>

    <!--SEARCH-->
    <div id=search_ll_model_cms_functions_sp *ngIf="bundle.canFilter"
         [style.top.px]="bundle.lblY - 0"
         [style.width.px]="bundle.spW"
         [style.left.px]="bundle.spW  - 10 "
         style="position: absolute;">


      <div class="wordBtnGroup">
        <div *ngIf="bundle.searchText  == '' ">
          <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
        </div>
        <div *ngIf="bundle.searchText  != '' ">
          <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
        </div>
      </div>

      <jqxPopover #popover_search
                  [offset]='{ left: -85, top: 0 }'
                  [arrowOffsetValue]="-0"
                  [width]="220"
                  [position]="'top'"
                  [title]="'Filter Categories'"
                  [selector]="'#search_ll_model_cms_functions_sp'">

        <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">
          <!--<img height = 20 style = "width: auto; "  src="assets/icon_magGlass.png">-->
          <div (keyup)="onKeyUp($event)" style="float: left; ">
            <jqxTextArea #text_search
                         [width]="140"
                         [height]="25"
                         [placeHolder]="'Type Search'">

            </jqxTextArea>
          </div>

          <div style="float: left; padding-left:10px; padding-top:0px; ">
            <div style="clear:left">
              <div class="wordBtn" (click)="search()">Search</div>
            </div>
            <div style="clear:left">
              <div class="wordBtn" (click)="clear()">Clear</div>
            </div>
          </div>
        </div>



      </jqxPopover>
    </div>



    <!--<div *ngIf="bundle.showRemaining && bundle.selectedId == 10"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

    <ll-model-cms-crosswalk></ll-model-cms-crosswalk>
  </div>--->

    <div *ngIf="bundle.showTab && bundle.showRemaining">

      <!--BBC-->
      <div *ngIf="bundle.activeView == 'bbc'"
           [style.top.px]="bundle.remainingY"
           [style.left.px]="bundle.remainingX"
           [style.width.px]="bundle.remainingW"
           [style.height.px]="bundle.remainingH"
           style="position: absolute;">

        <!---<ll-model-report-bbc-one></ll-model-report-bbc-one>--->
      </div>

      <!--DG-->
      <div *ngIf="bundle.activeView == ''"
           [style.top.px]="bundle.remainingY"
           [style.left.px]="bundle.remainingX"
           [style.width.px]="bundle.remainingW"
           [style.height.px]="bundle.remainingH"
           style="position: absolute;">
        <ll-admin-generic-dg id="model_location_cms_dg"></ll-admin-generic-dg>
      </div>

    </div>


  </div>

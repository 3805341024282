import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { jqxPasswordInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpasswordinput';
import { jqxInputComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxinput';
import { UserService } from '../../services/user.service';
import { timer, observable, Subscription } from 'rxjs';
import { fromEvent } from 'rxjs';
import { Observable} from 'rxjs';


@Component({
  selector: 'login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})

export class LoginPopupComponent implements AfterViewInit, OnInit , OnDestroy {
  
  @ViewChild('loginMainDiv') loginMainDiv: ElementRef;
  @ViewChild('userName') userName: jqxInputComponent;
  @ViewChild('password') password: jqxPasswordInputComponent;  
  @ViewChild('PWCode') PWCode: jqxInputComponent;
  @ViewChild('newPassword') newPassword: jqxPasswordInputComponent;  
  @ViewChild('confirmPassword') confirmPassword: jqxPasswordInputComponent;  

  constructor( 
    public UserService: UserService,
    ) {     
  }

  bundle = {  
        
    userName:"",
    userPassword:"",
    logOutReason:"",
    changeCode:"",
    brandTxt:"A Mediant Designs Information Solution",
    PWStrengthMsg:"",
    PWMatchMsg:"",
    newPassword:"",
    UserService:this.UserService,
    timeoutMsg01: "",
    timeoutMsg02: "",
    warningMsg: "",
    showLogIn:true,
    timedOut:false,
    timeWarning:false,
    missingMsg:false,
    failed:false,
    showChange:false,    
    pwCode:false,
    showChngPW:false,
    showMsgPU:false,
    showCountMsg:false,
    showPWMatchMsg:false,
    chngPWHeader:"Change Password",
    countMsg:"",
    timeOutDefault:300, ////(5 mins * 60 seconds = 300 seconds);  value changes on PW change
    timeOut:300, ////(5 mins * 60 seconds = 300 seconds);  value changes on PW change
    loginW:375,
    loginH:300,

  }; 
 
  everySecond:Observable<number> = timer(0, 1000);

  private Subscription: Subscription;
  eventSubscription_loggedOut:any;
  
  ngOnDestroy() {
    this.eventSubscription_loggedOut.unsubscribe();
  }

  ngOnInit() {} 

  ngAfterViewInit(): void {

    this.Subscription =  this.everySecond.subscribe(second => 
      this.hideShowWarning(second)
    );
    
    fromEvent(document, 'mousemove')
    .subscribe(res =>         
        { this.Subscription.unsubscribe();
          this.Subscription =  this.everySecond.subscribe(second => 
            
            this.hideShowWarning(second)
            
          );        
        }        
    );

    this.eventSubscription_loggedOut = this.UserService.loggedOut.subscribe(reason => {  

      setTimeout(() => { 

        if(reason == "timedOut"){
          this.bundle.timedOut = true;
        }

        this.bundle.showLogIn = true;
        this.setUserVal();
      });

    });
  
    this.setUserVal();

    ///this.submit(); /// DELETE FOR PRODUCTION for development in order to bypass login
  ///console.log("PRODUCTION DELETE REMINDER")

  }
  
  setUserVal(){
    setTimeout(() => { 
      this.userName.val(this.bundle.userName);
    });    
  }

  hideShowWarning(second){

    //console.log(second)

    this.bundle.timeWarning = false;
    
    let remainingCount = this.bundle.timeOut - second; 
    
    if(remainingCount < 20 ){

      ///console.log(remainingCount)
      
      this.bundle.timeWarning = true;
        
      setTimeout(() => {
        this.bundle.warningMsg = "Your session will time out in " + remainingCount + " seconds, unless you move your mouse pointer";
      });

  ///console.log("Remaining:"+remainingCount);

      if(remainingCount <= 0 ){

        this.bundle.timeWarning = false;       

        this.Subscription.unsubscribe();
        this.UserService.logout("timedOut");
      }
    }
  }

  onKeyUp_un(event){

    setTimeout(() => { 
      this.bundle.missingMsg = false;
    });
  }

  onKeyUp_pw(event){

//console.log(event)
//console.log(event.keyCode)

    setTimeout(() => { 
      this.bundle.missingMsg = false;
    });

    if(event.keyCode == 13){ //enter
      this.submit()
    }
  }

  hideEverything(){
    setTimeout(() => { 
      ///this.bundle.timedOut = false;
      this.bundle.timeWarning = false;
      this.bundle.missingMsg = false;
      this.bundle.failed= false;      
      this.bundle.showChange = false;
      this.bundle.pwCode = false;
      this.bundle.showChngPW = false;
      this.bundle.showMsgPU = false;
      this.bundle.showCountMsg= false;
    });
  }

  submit(){
    this.bundle.userName = this.userName.val();
    this.bundle.userPassword = this.password.val();
    this.hideEverything();


    if(this.bundle.userName == "" || this.bundle.userPassword == ""){

      setTimeout(() => { 
        this.bundle.missingMsg = true;
      });

    }else{
      this.UserService.getNewUser(done01, this.bundle);
    }

    function done01(user, bundle){   
    
     //console.log(user)
    //console.log(user.PWEXPIREDYESNO)
    //console.log(user.PASSWORDTYPE)

      bundle.showCountMsg= false;

      setTimeout(() => { 

        if(user.ACTION.toLowerCase() == "attemptfailed"){
          bundle.countMsg = "Unsuccessful. Note Password Case Sensitive";
          bundle.showCountMsg= true;

        }else if(user.ACTION.toLowerCase() == "excessattempts"){ 
          bundle.countMsg = "Five attempts within 10 minutes exceeded"
          bundle.showCountMsg= true;
          
        }else if(user.PASSWORDTYPE == 'new'){
          bundle.timeWarning = false;
          bundle.missingMsg = false;
          bundle.failed= false;      
          bundle.showChange = false;
          bundle.pwCode = false;
          bundle.showChngPW = false;
          bundle.showMsgPU = false;
          bundle.showCountMsg= false;
    
          setTimeout(() => { 
            bundle.showLogIn = false;
            bundle.pwCode = true;      
            bundle.UserService.getChangePWCode(bundle)
          });



        }else if(user.PWEXPIREDYESNO.toLowerCase() == "yes"){ 
          bundle.pwCode = false; 
          bundle.showLogIn = false;
          bundle.chngPWHeader = "Password Expired- Please Renew"
          bundle.showChngPW = true; 
          bundle.changeCode = user.CODE;          

        }else{
          bundle.showLogIn = false;
          bundle.timeOut =  bundle.timeOutDefault; 

          if(bundle.timedOut == true){
            bundle.timedOut = false;

          } else {          
             bundle.UserService.newUser();
            
          }
        }
        
      });
    }    
  }
  
  getChangePWCode(){

    if(this.userName.val() != ""){
      this.hideEverything();
      
      setTimeout(() => { 
        this.bundle.showLogIn = false;
        this.bundle.pwCode = true;      
        this.bundle.userName = this.userName.val();
        this.bundle.UserService.getChangePWCode(this.bundle)
      });

    }

  }

  confirmPWCode(){

    if(this.PWCode.val() != ""){
      //this.hideEverything();
      this.bundle.showCountMsg = false;

      this.bundle.changeCode = this.PWCode.val();
      this.bundle.UserService.confirmPWCode(done01, this.bundle)
    }

    function done01(record, bundle){      

      if(record.ACTION.toLowerCase() == "codevalid"){
        bundle.timeOut = 60, ////(60 seconds);
        bundle.pwCode = false; 
        bundle.chngPWHeader = "Change Password"
        bundle.showChngPW = true;        
        

      }else if(record.ACTION.toLowerCase() == "codeexpired"){        
        bundle.countMsg = "Code has expired";
        bundle.showCountMsg= true

      }else if(record.ACTION.toLowerCase() == "codenotfound"){
        bundle.countMsg = "Not Found. Only three attempts allowed";
        bundle.showCountMsg= true

      }else if(record.ACTION.toLowerCase() == "excessattempts"){
        bundle.countMsg = "Attempts exceeded, please restart this process";
        bundle.showCountMsg= true
      }
    }
  }

  onKeyUp_newPW(event){
    this.bundle.showPWMatchMsg = false;

    let val = this.newPassword.val()

    var strNum = String(val).replace(",", "");
				strNum = String(strNum).replace(".", "");
				strNum = String(strNum).replace(" ", "");					
    
    this.newPassword.val(strNum)
    
    if(strNum != ""){
      this.checkPWStruct(strNum);
    }
  }

  checkPWStruct(PW){
			
    var startText = "The new password needs: ";
    var returnTxt = startText;
    
    var chCount:Number = Number(PW.length);
    var numCount:Number = this.countNumberinInString(PW);
    var capsCount:Number = 0;

    var getCaps:String = PW.match(/[A-Z]/g);

    if(getCaps != null){
      var capsCount:Number = getCaps.length;	  
    }else{
      this.bundle.PWStrengthMsg = ""
    }

    var symCount:Number = this.countNonNumberOrLetterCharaters(PW)

    if(Number(chCount) < 8 ){
      returnTxt = returnTxt + "At least eight(8) characters" 
    }
    
    if(numCount == 0  ){
      if(returnTxt == ""){
        returnTxt = "At least one(1) number" 
      }else{
        returnTxt = returnTxt + "; At least one(1) number"
      }
    }
    
    if(capsCount == 0  ){
      if(returnTxt == ""){
        returnTxt = "At least one(1) capital letter" 
      }else{
        returnTxt = returnTxt + "; At least one(1) capital letter"
      }
    }

    if(symCount == 0  ){
      if(returnTxt == ""){
        returnTxt = "At least one(1) non-alphanumeric symbol" 
      }else{
        returnTxt = returnTxt + "; At least one(1) non-alphanumeric symbol"
      }
    }
    
    if(returnTxt == startText){
      returnTxt = "Strong Password";
    }
        
///console.log(returnTxt)

    this.bundle.PWStrengthMsg =  returnTxt;
  }

  countNumberinInString(txt:String):Number{			
    var nums = new Array("0","1","2","3","4","5","6","7","8","9");
    var count = 0;
      
    if(txt != "" && txt != null){																					
      for(var i = 0; i < txt.length; i++){					
        var tl:String = txt.substr(i,1);									
        for(var j = 0; j < nums.length; j++){
                
          var num:String = nums[j];								
          if(tl == num){																	
            count ++					
          }
        }				
      }				
    }
    
    return count;			
  }

  countNonNumberOrLetterCharaters(str:String):Number {
    var count = 0;
    var symbAscii_arr = new Array(59,61,45,47,96,91,92,93,39,41,33,64,35,36,37,94,38,42,40,58,43,60,95,62,63,126,123,124,125)
    
    for (var i = 0; i < str.length; i++) {
      var currentCharCode:Number = str.charCodeAt(i);
      
      for (var j = 0; j < symbAscii_arr.length; j++) {
        if (currentCharCode == symbAscii_arr[j] ) {
          count ++	
        }
      }
    }

    return count;
  }

  onKeyUp_confirmPW(event){

    if(this.newPassword.val() != this.confirmPassword.val()){
      this.bundle.PWMatchMsg = "This confirmation email must match the new email"
    }else{
      this.bundle.PWMatchMsg = ""
    }
    
  }

  changePassword(){

    this.bundle.showPWMatchMsg = false;

    if(this.newPassword.val() != this.confirmPassword.val()){
      this.bundle.PWMatchMsg = "Passwords above do not match"
      this.bundle.showPWMatchMsg = true;

    }

    if(this.bundle.PWStrengthMsg == "Strong Password" && this.newPassword.val() == this.confirmPassword.val() ){

      this.bundle.newPassword = this.newPassword.val();
      this.bundle.UserService.changePW(done01, this.bundle)
    }

    function done01(record, bundle){

  //console.log(record)

      if(record.ACTION.toLowerCase() == "match"){
        bundle.PWMatchMsg = "New passwords cannot match old ones"
        bundle.showPWMatchMsg = true;

      }else{

        bundle.pwCode = false; 
        bundle.showLogIn = false;

        bundle.userPassword = bundle.newPassword;

        bundle.UserService.getNewUser(done01, bundle);

        function done01(user, bundle){  
          
      //console.log(user)
          
          setTimeout(() => { 
            if(user.PWEXPIREDYESNO.toLowerCase() == "yes"){ 
              bundle.pwCode = false; 
              bundle.showLogIn = false;
              bundle.chngPWHeader = "Password Expired- Please Renew"
              bundle.showChngPW = true;   
                
            }else if(user.TOKEN == ""){
              bundle.failed = true;
      
            }else{
              bundle.showChngPW = false; 
              bundle.showLogIn = false;
              bundle.timeOut =  bundle.timeOutDefault;
              bundle.UserService.newUser();
            }
            
          });
        }  
      }  
    }

    
  }
};

<div #mainDiv_email_template>

  
  <div [hidden]="!bundle.showObj && bundle.function == 'html'">

    <!--NEWSLETER NAME - EDIT-->
    <div [hidden]="bundle.disableEdit" style="float:left; padding-top:5px;">
      <div class="txtSmallB" [style.width.px]="150" style="float: left;padding-left:0px">
        Name:
      </div>

      <div style="float:left; padding-left:5px">
        <jqxTextArea #text_NEWSLETTERNAME
                     [width]="400"
                     [height]="20"
                     >

        </jqxTextArea>
      </div>
    </div>

    <!--NEWSLETER NAME - EDIT-->
    <!--<div [hidden]="!bundle.disableEdit" style="float:left; padding-top:5px;">
      <div class="txtBold_16" [style.width.px]="bundle.editorW" style="float: left;padding-left:00px">
        {{bundle.STUDYNEWSLETTERNAME}}
      </div>
    </div>-->

    <!--NEWSLETTER BODY-->
    <div [style.top.px]="bundle.editorY" style="position:absolute; ">

      <div [hidden]="bundle.disableEdit">
        <jqxEditor #editor_NEWSLETTER [theme]="'material'"
                   [height]="bundle.editorH"
                   [width]="bundle.editorW">
        </jqxEditor>
      </div>

      <div [hidden]="!bundle.disableEdit">
        <jqxPanel #SP_newsletter_scrollpane
                  style="background-color: transparent;
                  border: none;
                  outline: none;
                  border-top: 1px solid  rgb(160, 158, 158);
                  border-bottom: 1px solid  rgb(160, 158, 158);"
                  [autoUpdate]="true"
                  [rtl]="false"
                  [width]="bundle.editorW"
                  [height]="bundle.editorH">

          <div [style.width.px]="bundle.editorW - 30" style="padding-top: 10px; padding-left: 20px; overflow-wrap: break-word; ">
            <div [innerHTML]="bundle.STUDYNEWSLETTER_BODY | sanitizeHtml"></div>
          </div>
        </jqxPanel>
      </div>

    </div>

    <div [hidden]="bundle.disableEdit" id=bottomLine [style.top.px]="bundle.spB" style="position:absolute; ">

      <div style="float: left; padding-left: 15px;">
        <div class="wordBtn" (click)="editTemplate()">Save Changes</div>
      </div>

      <div class="DQRAlarm" style="float: left; padding-left: 10px;">
        {{bundle.editMsg}}
      </div>

    </div>

  </div>

  <div *ngIf="bundle.showObj && bundle.function == 'html'">

    <jqxPanel #SP_newsletter_scrollpane
              style="background-color: transparent;
                  border: none;
                  outline: none;
                  border-top: 1px solid  rgb(160, 158, 158);
                  border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="false"
              [width]="bundle.editorW"
              [height]="bundle.objH - 40">

      <div [style.width.px]="bundle.editorW - 30" style="padding-top: 10px; padding-left: 20px; overflow-wrap: break-word; ">
        <!--<div [innerHTML]="bundle.body | sanitizeHtml"></div>--->
        {{bundle.STUDYNEWSLETTER_BODY}}

      </div>
    </jqxPanel>

  </div>
</div>

import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { ServerService} from '../services/server.service';
//import { ProductsService } from './products.service';
///import { RoleListService } from './role-list.service';

@Directive()
@Injectable({
  providedIn: 'root'
})


export class UserService {
 
 @Output() loggedOut: EventEmitter<any> = new EventEmitter();
 @Output() userChangedPreference: EventEmitter<any> = new EventEmitter();
 @Output() changeUser: EventEmitter<any> = new EventEmitter();

 constructor(
   public ServerService: ServerService,
   ///private RoleListService: RoleListService,
  
  ) { }

 vars = {
  ac:"",
  userName:"",
  userPassword:"",
  changeCode:"",
  logOutReason:"",
  newPassword:"",
   CONTACTID: 0,
   APPLICATIONVERSION: "",
   SYSTEMPRODUCTID: 0,
   PREFERENCENAME: "",
   PREFERENCEVALUE: "",
   PREFERENCEORDER: 0,
   ACTION:"",
  }

  bundle_local  = {
    vars:this.vars,
    userName:"", ///intentially repeated    
    loggedOut: this.loggedOut,
    userChangedPreference: this.userChangedPreference,
    CONTACTID:0,
    user:[],
    inBundle: [],
    preferences:[],
  }

  
  getNewUser(callback01:Function, inBundle ){
    
    this.bundle_local.inBundle = inBundle;

    this.bundle_local.vars.ac ="validate" 
    this.bundle_local.vars.userName = inBundle.userName;
    this.bundle_local.vars.userPassword = inBundle.userPassword;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local ){

///console.log(arr)   

      bundle_local.user = arr;
      bundle_local.CONTACTID = arr[0].CONTACTID;     

      ///save the username for to supply if thsi user is timed out
      bundle_local.user.userName = inBundle.userName;         
      
      callback01(bundle_local.user[0], bundle_local.inBundle)
                
    }
  }
 
  getUser(callback01?:Function, bundle?){

    if(callback01 == null){
      return this.bundle_local.user;

    }else if(bundle == null){        
      callback01(this.bundle_local.user); 

    }else{
      callback01(this.bundle_local.user, bundle);
    }    
  }

  getActiveUser(){
    return this.bundle_local.user[0];
  }

  getUserApp(callback01?, bundle?){
    
    var app = this.bundle_local.user[0].APPLICATIONVERSION.substring(0, 2)
    
    ///console.log(app)

    if(callback01 == null){
      return app;

    }else if(bundle == null){        
      callback01(app); 

    }else{

      bundle.app = app;
      
      callback01(bundle);
    }    

  }

  logout(logOutReason){
    
    if(this.bundle_local.user[0] != null){
      this.bundle_local.vars.ac ="logout" 
      this.bundle_local.vars.CONTACTID = this.bundle_local.user[0].CONTACTID;
      this.bundle_local.vars.logOutReason = logOutReason;
      
      this.ServerService.callserver(srvReturn, this.bundle_local, "show" );
    }
    
    function srvReturn(arr, bundle_local ){

      bundle_local.loggedOut.emit(logOutReason);
          
    }
  }

  getChangePWCode(inBundle){
    
    this.bundle_local.vars.ac ="getChangePWCode" 
    this.bundle_local.vars.userName = inBundle.userName;
        
    this.ServerService.callserver(srvReturn, this.bundle_local, "show"  );

    function srvReturn(arr, bundle_local ){}
  }

  confirmPWCode(callback, inBundle){
    
    this.bundle_local.inBundle = inBundle;

    this.bundle_local.vars.ac ="confirmPWCode" 
    this.bundle_local.vars.userName = inBundle.userName;
    this.bundle_local.vars.changeCode = inBundle.changeCode;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show"  );

    function srvReturn(arr, bundle_local ){

      console.log(arr[0])

      bundle_local.CONTACTID = arr[0].ID


////console.log(bundle_local.CONTACTID )


      callback(arr[0], bundle_local.inBundle)
          
    }
  }

  changePW(callback, inBundle){
    
    this.bundle_local.inBundle = inBundle;

    this.bundle_local.vars.ac ="changePW" 
    this.bundle_local.vars.userName = inBundle.userName;
    this.bundle_local.vars.changeCode = inBundle.changeCode;
    this.bundle_local.vars.CONTACTID = this.bundle_local.CONTACTID;
    this.bundle_local.vars.newPassword = inBundle.newPassword;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show"  );

    function srvReturn(arr, bundle_local ){
      callback(arr[0], bundle_local.inBundle)
          
    }
  }


 // getPreference(PREFERENCENAME, SYSTEMPRODUCTID) {

  
    //let records = [];

    //for (let i = 0; i < this.bundle_local.preferences.length; i++) {
      i//f (this.bundle_local.preferences[i].PREFERENCENAME == PREFERENCENAME && this.bundle_local.preferences[i].SYSTEMPRODUCTID == SYSTEMPRODUCTID) {
       

        //records[records.length] = this.bundle_local.preferences[i];
      //}
    //}
      
    //return records;
 // }

  newUser() {
     this.changeUser.emit(true);
  }

}

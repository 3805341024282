<div #mainDiv_ll_info_request_question_switch>

  <div *ngIf="bundle.showRemaining"
       [style.top.px]="10"
       [style.left.px]="0"
       [style.width.px]="bundle.remainingW"
       style="position: absolute"  >

    <div class="txtBold_12" style="float: left; padding-left: 0px; ">
      SysID:
    </div>

    <div class="txtNorm12" style="float: left; padding-left: 10px; ">
      {{bundle.STUDYQUESTIONID}}
    </div>

    <div class="txtBold_12" style="float: left; padding-left: 10px; ">
      Question:
    </div>

    <div class="txtNorm12" style="float: left; padding-left: 10px; ">
      {{bundle.QUESTIONNAME}}
    </div>
  </div>


  <!-- horzTabs_ll_info_request_question_switch  TABS ACROSS THE TOP-->
  <div [hidden]="!bundle.showHorzTabs"
       [style.top.px]="bundle.horzTabsY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;z-index:100">

    <canvas #horzTabs_ll_info_request_question_switch></canvas>

  </div>



  <!-- ll-company-models-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Querys'">
    <div [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; z-index: 100">

      <!--<ll-company-models-sp></ll-company-models-sp>--->
    </div>
  </div>


  <!-- ll-contacts-sp-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Audit Trail'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <!--<ll-contacts-sp id="from_ll_info_request_question_switch"></ll-contacts-sp>--->
  </div>

  <!-- ll-company-info-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Setup'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <!---<ll-company-info></ll-company-info>--->
  </div>

</div>

<canvas #canvas_appOutline_ll_main_role_dg></canvas>


<div *ngIf="bundle.showObj">
  <!--LOGO-->
  <div [style.top.px]="bundle.logoY"
       [style.left.px]="bundle.OL_X + bundle.logoX"
       style="position: absolute;">

    <img [style.height.px]="bundle.logoH" style="width: auto;" [src]='bundle.sponsorLogo'>

  </div>


  <!--USER INFO-->
  <div id=mainTab
       [style.top.px]="bundle.logoY + 10"
       [style.left.px]="200"
       [style.width.px]="bundle.OL_W - 200"
       [style.height.px]="bundle.OL_Y"
       style="position: absolute;">

    <div class="wordBtn_11" (click)="tologOut()" style="float: right; padding-left: 10px">
      Log Out
    </div>

  </div>
</div>

    <div [style.top.px]="bundle.gridY"
         [style.left.px]="bundle.gridX"
         style="position: absolute;">

      <jqxGrid #datagrid_ll_main_role_dg
               [theme]="'material-green'"
               [editable]="true"
               [rowsheight]="20"
               [filterable]="false"
               [sortable]="false"
               [columnsresize]="true"
               [showstatusbar]="false"
               (onRowclick)="myGridOnRowSelect($event)">
      </jqxGrid>

    


  </div>

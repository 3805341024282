import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlUtilityService } from '../ll-utility.service';
import { jqxEditorComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxeditor';
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";

@Component({
  selector: 'll-admin-newsletter-view',
  templateUrl: './ll-admin-newsletter-view.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlAdminNewsletterViewComponent {

  @ViewChild('mainDiv_email_template') private mainDiv: ElementRef;
  @ViewChild('editor_NEWSLETTER') private editor: jqxEditorComponent;
  @ViewChild('text_NEWSLETTERNAME') text_NEWSLETTERNAME: jqxTextAreaComponent;
  //@ViewChild('text_fromEmail') text_fromEmail: jqxTextAreaComponent;
  //@ViewChild('text_templateSubjectLine') text_templateSubjectLine: jqxTextAreaComponent;

  constructor(
    private LlUtilityService: LlUtilityService,
  ) {

  }

  bundle = {
    function: "view",
    showDisplay: true,
    showObj: false,
    canBuild: false,
    disableName: false,
    disableEdit: true,
    parentHeight: 0,
    LlUtilityService: this.LlUtilityService,
    spB: 0,
    objW: 0,
    objH: 0,
    editorX: 10,
    editorY: 30,
    editorW: 0,
    editorH: 0,
    TEMPLATE: [],
    editor: {},
    text_NEWSLETTERNAME: {},
    text_fromEmail: {},
    text_templateSubjectLine: {},
    STUDYNEWSLETTER_BODY: '',
    STUDYNEWSLETTERNAME: '',
    fromEmail: '',
    templateSubjectLine: '',
    templateCategory: '',
    editMsg: '',
  }

  ngOnDestroy() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {

    this.bundle.showObj = false;
    this.bundle.function = this.mainDiv.nativeElement.parentElement.id.toLowerCase();

    this.bundle.objW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.objH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {

      this.bundle.editorW = this.bundle.objW - (this.bundle.editorX * 2)
      this.bundle.editorH = this.bundle.objH - this.bundle.editorY - 40;
      this.bundle.spB = this.bundle.editorY + this.bundle.editorH + 10;

      //this.bundle.showObj = true;

      setTimeout(() => {
        this.getData();
      });

    });
  }

  getData() {

    this.bundle.editor = this.editor;
    this.bundle.text_NEWSLETTERNAME = this.text_NEWSLETTERNAME;
    //this.bundle.text_fromEmail = this.text_fromEmail;
    //this.bundle.text_templateSubjectLine = this.text_templateSubjectLine;

    this.LlUtilityService.NG_utility_newsletterTarget_get(done01, this.bundle);

    function done01(arr, bundle) {

      if (arr.length > 0) {

        bundle.TEMPLATE = arr;

        setTimeout(() => {

          if (bundle.function != 'view') {
            bundle.disableEdit = false;
            //bundle.editorY = 100;
          }

          //bundle.templateCategory = bundle.TEMPLATE[0].STUDYNEWSLETTERNAME;
          bundle.STUDYNEWSLETTERNAME = bundle.TEMPLATE[0].STUDYNEWSLETTERNAME;
          bundle.STUDYNEWSLETTER_BODY = bundle.TEMPLATE[0].STUDYNEWSLETTER_BODY;

          bundle.text_STUDYNEWSLETTERNAME.val(bundle.TEMPLATE[0].STUDYNEWSLETTERNAME);
          //bundle.text_fromEmail.val(bundle.TEMPLATE[0].FROMEMAIL);
          //bundle.text_templateSubjectLine.val(bundle.TEMPLATE[0].TEMPLATESUBJECTLINE);
          bundle.editor.val(bundle.TEMPLATE[0].STUDYNEWSLETTER_BODY);


          bundle.showObj = true;
        });
      }
    }
  }

  editTemplate() {

    //console.log("editTemplate()")

    this.bundle.editMsg = "";

    this.bundle.TEMPLATE[0].STUDYNEWSLETTER_BODY = this.editor.val();
    this.bundle.TEMPLATE[0].STUDYNEWSLETTERNAME = this.text_NEWSLETTERNAME.val();
    //this.bundle.TEMPLATE[0].FROMEMAIL = this.text_fromEmail.val();
    //this.bundle.TEMPLATE[0].TEMPLATESUBJECTLINE = this.text_templateSubjectLine.val();

    let validEmail = this.ValidateEmail(this.bundle.TEMPLATE[0].FROMEMAIL)


    if (this.bundle.TEMPLATE[0].STUDYNEWSLETTERNAME == "") {
      this.bundle.editMsg = "Newsletter Name cannot be blank";
    //} else if (this.bundle.TEMPLATE[0].FROMEMAIL == "") {
      //this.bundle.editMsg = "Email From cannot be blank";
    //} else if (this.bundle.TEMPLATE[0].FROMEMAIL != "" && validEmail == false) {
      //this.bundle.editMsg = "Valid email required";
    //} else if (this.bundle.TEMPLATE[0].TEMPLATESUBJECTLINE == "") {
      //this.bundle.editMsg = "Email Subject Line cannot be blank";
    } else if (this.bundle.TEMPLATE[0].STUDYNEWSLETTER_BODY == "") {
      this.bundle.editMsg = "Newsletter Body cannot be blank";
    } else {

      this.LlUtilityService.NG_utility_email_template_edit(this.returnedMessage, this.bundle.TEMPLATE, this.bundle)
    }

  }

  returnedMessage(msg, bundle) {

    if (msg != '0') {
      bundle.editMsg = msg
    }
  }

  ValidateEmail(inputText) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }
}

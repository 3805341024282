import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { UserService } from '../services/user.service';
import { ServerService } from '../services/server.service';
import { LlRolesService } from './ll-roles.service';

@Directive()
@Injectable({
  providedIn: 'root'
})

export class LlListingsService {

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  @Output() changeColName: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private ServerService: ServerService,
    private LlRolesService: LlRolesService,
    private UserService: UserService,
  ) { }

  vars = {
    ac: "",
    APPLICATIONVERSION: "",
    CONTACTID:0,
    CONTACTROLEID: 0,
    SYSTEMROLEID: 0,
    //STUDYID_SYS: 0,
    STUDYFORMID: 0,
    LOANMODELID: 0,
    STUDYCOLUMNGROUPID: 0,
    STUDYCOLUMNID:0,
    studyColumnGroupName: '',

    ACTION: '',
    STUDYEXPORTRECORDID:0,
  }

  bundle_local = {    
    vars: this.vars,
    studyForms: [],
    listing: [],
    colGroups:[],
    inBundle: []
  }

  NG_LL_infoReq_listing_reports_list_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();
    var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_infoReq_listing_reports_list_get"
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    ///this.bundle_local.vars.STUDYID_SYS = contactRole.STUDYID_SYS;
    //this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();;
    //this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      if (arr.length > 0) {
        bundle_local.vars.STUDYFORMID = arr[0].STUDYFORMID

      }

      ///bundle_local.studyForms = arr;

      callback01(arr, inBundle)

    }

  }

  setActiveStudyFormId(STUDYFORMID) {
    this.bundle_local.vars.STUDYFORMID = STUDYFORMID;
    this.change.emit(true);
  }

  getActiveStudyFormId() {
    return this.bundle_local.vars.STUDYFORMID
  }

  NG_LL_infoReq_listing_reports_models_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_listing_reports_models_get"

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      let topRecord = {
        LOANMODELID: 0,
        FOLDERID: 0,
        FOLDERNAME: "All"
      }

      arr.unshift(topRecord);
            
      bundle_local.vars.LOANMODELID = 0

      callback01(arr, inBundle)

    }

  }

  setActiveLOANMODELID(LOANMODELID) {
    this.bundle_local.vars.LOANMODELID = LOANMODELID;    
  }

  getActiveLOANMODELID() {
    return this.bundle_local.vars.LOANMODELID
  }
  
  NG_LL_infoReq_listing_columns_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_listing_columns_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      callback01(arr, inBundle, obj)
    }

  }

  NG_LL_infoReq_listing_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_infoReq_listing_get";
    this.bundle_local.vars.ACTION = inBundle.activeCODE;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      callback01(arr, inBundle, obj)
    }

  }


}

import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';

@Component({
  selector: 'll-info-request-question-switch',
  templateUrl: './ll-info-request-question-switch.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestQuestionSwitchComponent {

  @ViewChild('mainDiv_ll_info_request_question_switch') mainDiv: ElementRef;
  @ViewChild('horzTabs_ll_info_request_question_switch') public horzTabs: ElementRef;


  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,
  ) { }

  bundle = {
    showHorzTabs: false,
    showRemaining: false,
    function: '',
    LlInfoRequestsService: this.LlInfoRequestsService,
    LlScrollpaneService: this.LlScrollpaneService,
    LlPermissionsService: this.LlPermissionsService,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 20,
    remainingW: 0,
    remainingH: 0,
    showTab: true,
    horzTabsY: 60,
    hTabW: 100,
    hTabH: 25,
    hTabIndent: 100,
    hTabIndx: 0,
    horzTabs: [],
    horzTabs_02: [],
    canvasHT: {},
    ctxHT: {},
    activeHorzTab: '',
    QUESTIONNAME: '',
    STUDYQUESTIONID: 0,
    QUERIESENABLEDYESNO:'',
    companyLabelX: 0,
  }

  ngOnInit() { }
  ngOnDestroy() { }

  ngAfterViewInit() {

    this.setVars();
  }

  setVars() {
    this.bundle.showHorzTabs = false;
    this.bundle.showRemaining = false;

    this.bundle.function = this.mainDiv.nativeElement.parentElement.id;

    setTimeout(() => {
      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      setTimeout(() => {
        this.bundle.remainingX = 0;
        this.bundle.remainingY = this.bundle.horzTabsY + this.bundle.hTabH + 3
        this.bundle.remainingW = this.bundle.parentW;
        this.bundle.remainingH = this.bundle.parentH - this.bundle.remainingY;

        var canvasHT: HTMLCanvasElement = this.horzTabs.nativeElement;
        this.bundle.canvasHT = canvasHT
        this.bundle.ctxHT = canvasHT.getContext('2d');

        this.getData()
      });
    });
  }

  getData() {

    let subjectVisit = this.LlInfoRequestsService.getActiveSubjectVisit();

    this.bundle.QUERIESENABLEDYESNO = subjectVisit.QUERIESENABLEDYESNO

    this.LlInfoRequestsService.getActiveQuestion(done01, this.bundle);
    function done01(record, bundle) {

      console.log(record)
 
      bundle.STUDYQUESTIONID = record.STUDYQUESTIONID;
      bundle.QUESTIONNAME = record.QUESTIONNAME;
      
      bundle.horzTabs.splice(0);      

      if (bundle.QUERIESENABLEDYESNO == 'Yes') {

        if (bundle.LlPermissionsService.check("query_add")
          || bundle.LlPermissionsService.check("query_answer")
          || bundle.LlPermissionsService.check("query_close")
          || bundle.LlPermissionsService.check("query_view")) {

          bundle.horzTabs.push({ lbl: "Querys" });

        }
      }
           

      if (bundle.LlPermissionsService.check("questionView_trail")) {
        bundle.horzTabs.push({ lbl: "Audit Trail" });
      }

      //if (this.LlPermissionsService.check("questionView_QC")) {
        //bundle.horzTabs.push({ lbl: "Users" });
      //}

      if (bundle.LlPermissionsService.check("build")) {
        bundle.horzTabs.push({ lbl: "Setup" });
      }

      if (bundle.horzTabs.length > 0) {
        bundle.activeHorzTab = bundle.horzTabs[0].lbl;
      } 

      bundle.companyLabelX = bundle.remainingX + bundle.hTabIndent + 20;

      bundle.LlScrollpaneService.paintHorzTab(horzTabSelected, bundle.horzTabs, bundle.canvasHT, bundle.ctxHT, bundle, false);

      function horzTabSelected(indx, bundle) { }

      bundle.showHorzTabs = true;
      bundle.showRemaining = true;
      //}
    }

  }
}



import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlRolesService } from '../ll-roles.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-model-cms-functions-sp',
  templateUrl: './ll-model-cms-functions-sp.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlModelCmsFunctionsSpComponent {
  @ViewChild('mainDiv_ll_model_cms_functions_sp') mainDiv: ElementRef;
  @ViewChild('vertTab_ll_model_cms_functions_sp') private vertTab: ElementRef;
  @ViewChild('scrollpane_ll_model_cms_functions_sp') private SP: jqxPanelComponent;
  @ViewChild('popover_search_ll_model_cms_functions_sp') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search_ll_model_cms_functions_sp') text_search: jqxTextAreaComponent;

  constructor(
    private LlModelsService: LlModelsService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlRolesService: LlRolesService,
    private LlPermissionsService: LlPermissionsService,

  ) { }

  bundle = {
    lable: "CMS Functions",
    prefix: "CF",
    activeView: '',
    selectedId: -1,
    selectedId_ondeck: -1,
    showObj: true,//false,
    showRemaining: false,
    scrolling: false,
    canAdd: false,
    canFilter: false,
    searchText: "",
    LlRolesService: this.LlRolesService,
    LlModelsService: this.LlModelsService,
    records: [],
    origionalRecords: [],
    groups: [],
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,
    topOffset: 0,
    spY: 0,
    spW: 125,
    spH: 0,
    spB: 0,
    lineWidth: 1,
    vertLineX: 0,
    vertLineY: -1,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH: 18,
    Tooltip01: 'not Found',

  }

  ngOnInit() { }
  ngOnDestroy() { }

  ngAfterViewInit() {

    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    this.setVars();
  }

  setVars() {

    this.bundle.canAdd = false;

    if (this.LlPermissionsService.check("editModels")) {
      this.bundle.canAdd = true;
    }

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {

      this.LlScrollpaneService.standardDimentions(this.bundle);

      var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
      canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
      canvasEl.height = this.bundle.vertLineH;
      this.bundle.ctx = canvasEl.getContext('2d');

      setTimeout(() => {
        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.getData();
      });
    });
  }

  getData() {

    this.LlModelsService.NG_LL_model_AR_reports_list_get(done01, 'LOCATION', this.bundle);

    function done01(records, bundle) {

      bundle.records = records;
      bundle.origionalRecords = records;

      //this.bundle.records.splice(0);

      //var contactRole = this.LlRolesService.getActiveContactRole();

      //if (this.LlRolesService.getActiveSystemRoleId() == 18
        //|| this.LlPermissionsService.check("cmsCrosswalk")) {
        //this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Match CMS Record", FOLDERID: "10" });
      //}




      //this.bundle.origionalRecords = this.bundle.records;
      //this.bundle.groups.splice(0);

      //for (let i = 0; i < this.bundle.records.length; i++) {

        //if (this.bundle.groups.indexOf(this.bundle.records[i].GROUP) == -1) {
         // this.bundle.groups.push(this.bundle.records[i].GROUP);
        //}
      //}

      let selectedId_initial = bundle.LlModelsService.getActiveSYSTEMOPERATIONREPORTSID();

      setTimeout(() => {

        //console.log("selectedId_initial: " + selectedId_initial)

        bundle.showObj = true;

        setTimeout(() => {

          if (selectedId_initial != null && document.getElementById(bundle.prefix + selectedId_initial.toString()) != null) {
            document.getElementById(bundle.prefix + selectedId_initial.toString()).click();
          }

        });
      });
    }
  }

  select(event) {

    //console.log("select")

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    if (this.bundle.scrolling == true) {

      this.bundle.scrolling = false;

    } else {

      this.bundle.showRemaining = false;

      setTimeout(() => {
        this.bundle.selectedId = this.bundle.selectedId_ondeck;

        this.LlModelsService.setActiveSYSTEMOPERATIONREPORTSID(this.bundle.selectedId);

        this.bundle.showRemaining = true;
      });
    }
  }

  noAction() {
    //needed HTML buttons point to this 
  }

  selectView(targetView) {
    //this.bundle.CONFIG = targetView.toLowerCase();
    //this.UserService.setUserPreference("TraditionalOrTile_config", targetView, 1, "edit");
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }

  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);

        if (this.bundle.records.length > 0) {
          this.bundle.selectedId = this.bundle.records[0].FOLDERID;
        }
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].CATID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].CATID;
        //this.LlModelsService.setActiveCategory(this.bundle.selectedId, 'SP');

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {


    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].FOLDERID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }



}


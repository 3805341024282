import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { formatGraphNumber } from "../utility";
import { removeNumberCommas } from "../utility";
import { javaDate_to_YYYYMMDD } from "../utility-date";

@Component({
  selector: 'll-model-metric-display',
  templateUrl: './ll-model-metric-display.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelMetricDisplayComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_metric_display') private mainDiv: ElementRef;
  @ViewChild('displayArea') public displayArea: jqxPanelComponent;

  constructor(
    private LlModelsService: LlModelsService,
  ) { }

  bundle = {
    showDisplay: false,
    graphArea_show: false,
    displayArea_show: true,
    LlModelsService: this.LlModelsService,
    activeELEMENT:'',
    daysBack: 0,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    graphAreaY: 20,
    graphAreaX: 0,
    graphAreaH: 500,
    graphAreaW: 0,
    graphX: 10,
    graphY: 10,
    graphW: 0,
    graphH: 0,
    displayAreaX: 10,
    displayAreaY: 0,
    displayAreaW: 0,
    displayAreaH: 0,
    metricLabelsW: 150,
    elements: [],
    forms: [],
    records: [],

  }

  ngOnDestroy() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {
    this.bundle.showDisplay = false;

    setTimeout(() => {

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      this.bundle.graphAreaW = this.bundle.parentW - 5;
      this.bundle.displayAreaW = this.bundle.parentW - 20;
      this.bundle.graphW = this.bundle.graphAreaW - this.bundle.graphX;

      this.bundle.daysBack = Math.round(this.bundle.graphAreaW / 6)

      this.LlModelsService.setDaysBack(this.bundle.daysBack);

      this.getData()

    });

  }

  getData() {

    this.bundle.activeELEMENT = this.LlModelsService.getActiveElement();
    this.LlModelsService.NG_LL_model_AR_benchmarkGroupTypes_get(done02, this.bundle);

    function done02(elements, forms, bundle) {
      bundle.elements = elements;

      //console.log(elements);

      bundle.displayAreaH = (elements.length * 28) + 20;
      bundle.displayAreaY = bundle.parentH - bundle.displayAreaH - 35;
      bundle.graphAreaH = bundle.displayAreaY - bundle.graphAreaY - 20;
      bundle.graphH = bundle.graphAreaH - bundle.graphY + 20
      
      bundle.forms = forms;

      //console.log(forms);

      bundle.LlModelsService.NG_LL_model_AR_metric_get(done03, bundle);
    }


    function done03(arr, bundle) {

     console.log(arr);

      setTimeout(() => {

        bundle.records = arr;

        var foundPERIODENDDATE_DATE: any = [];
        let count = 0;
    
        for (let j = 0; j < bundle.elements.length; j++) {

          for (let i = 0; i < bundle.records.length; i++) {

            bundle.records[i].dateHeader = 'N'

            if (bundle.records[i].BENCHMARKGROUPID == bundle.elements[j].BENCHMARKGROUPID) {

              if (Number.isFinite(bundle.records[i][bundle.elements[j].ELEMENT]) == true) {

                let reformatedValue = formatGraphNumber(bundle.records[i][bundle.elements[j].ELEMENT], bundle.elements[j].DISPLAYFORMAT);

                bundle.records[i][bundle.elements[j].ELEMENT] = reformatedValue;

              }

              if (bundle.records[i].COLYN.toLowerCase() == "y") {

                let found = false       

                for (let j = 0; j < foundPERIODENDDATE_DATE.length; j++) {

                  if (bundle.records[i].PERIODENDDATE_DATE == foundPERIODENDDATE_DATE[j].DATE) {
                    found = true
                    bundle.records[i].colX = foundPERIODENDDATE_DATE[j].COLX;
                  }
                }

                if (found == false) {
                  let COLX_local = (count * 100) + bundle.metricLabelsW;

                  bundle.records[i].colX = COLX_local;
                  bundle.records[i].dateHeader = 'Y'
                  count++

                  foundPERIODENDDATE_DATE.push({ DATE: bundle.records[i].PERIODENDDATE_DATE, COLX: COLX_local });
                }                
              }                           
            }
          }
        }

        setTimeout(() => {
          bundle.showDisplay = true;
          bundle.graphArea_show = true;
        });
      });

    }


  }

  downloadCSVReport() {

    console.log("downloadCSVReport()")

    let label = this.LlModelsService.getExportFileName();

    //console.log(activeELEMENT_Record)

    var n = new Date()
    let nowDate = javaDate_to_YYYYMMDD(n)

    let fileName = nowDate + "_" + label

    let csv = fileName + '\n';
    let usedDate = [];

    //console.log(this.bundle.records)

    //add date row
    csv += 'Date,';

    for (let j = 0; j < this.bundle.records.length; j++) {

      let valUsed: Boolean = false;

      for (let r = 0; r < usedDate.length; r++) {
        if (usedDate[r] == this.bundle.records[j].PERIODENDDATE_DATE) {
          valUsed = true;
        }
      }

      if (valUsed == false) {

        usedDate.push(this.bundle.records[j].PERIODENDDATE_DATE);

        csv += this.bundle.records[j].PERIODENDDATE_DATE + ','


      }

    }

    csv = csv + '\n'


    for (let i = 0; i < this.bundle.forms.length; i++) {

      csv += this.bundle.forms[i].FORMNAME + "_" ///+ activeELEMENT_Record.LABEL + '\n';

      ///loop through each element
      for (let j = 0; j < this.bundle.elements.length; j++) {

        csv = csv + '\n'
        csv += this.bundle.elements[j].LABEL + ',';

        //loop through and add the element value to each column
        for (let n = 0; n < this.bundle.records.length; n++) {

          if (this.bundle.records[n].BENCHMARKGROUPID == this.bundle.elements[j].BENCHMARKGROUPID) {

            let val = removeNumberCommas(String(this.bundle.records[n][this.bundle.activeELEMENT]));

            csv += val

            ///continue to add columns if there are remaining records  .. elase add a new line
            if (n < this.bundle.records.length - 1) {
              csv += ','
            } else {
              csv = csv + '\n'
            }
          }
        }
      }
    }

    //console.log(csv)

    var a = document.createElement("a");
    document.body.appendChild(a);
    let blob = new Blob([csv], { type: "text/csv" }),

      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName + ".csv";///'test.csv';//fileName;
    a.click();
    window.URL.revokeObjectURL(url);

  }

}

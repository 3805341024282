<div #mainDiv_ll_model_info>

  <div class="txtSmall" style="position: absolute;" [style.width.px]="bundle.spW" [style.top.px]="10" [style.left.px]="bundle.spX"   >
    Settings for the model and, where specified, defaults for future new Payers.  Edit payer speciifc settings via the "Payers" tab.
  </div>

  <div [hidden]="!bundle.showDisplay" [style.top.px]="bundle.spY" [style.left.px]="bundle.spX" style="position:absolute;">

    <jqxPanel style="white-space: nowrap; background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <!--MODELID-->
      <div style="clear: left; padding-left: 15px; padding-top: 20px;">

        <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
          LendingLinc Model ID:
        </div>

        <div class="txtSmall" style="float: left; padding-top:5px;">
          {{bundle.LOANMODELID}}
        </div>
      </div>

      <!--STATUS-->
      <div style="clear: left; padding-left: 15px; padding-top: 5px;">

        <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
          Active:
        </div>

        <div style="float: left; padding-left:0px;">
          <div *ngFor="let STATUSYESNO of bundle.STATUSChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
            <jqxRadioButton [width]="100"
                            [height]="25"
                            [theme]="'material-green'"
                            [groupName]="'STATUSYESNO'"
                            [checked]="STATUSYESNO.SELECTED"
                            (onChange)='selectSTATUSYESNO($event)'
                            [disabled]="bundle.disabled">

              <span>{{STATUSYESNO.choice}}</span>
            </jqxRadioButton>
          </div>
        </div>
      </div>
                 
      <div [hidden]="!bundle.showARSettings">

        <!--MODEL NAME-->
        <div style="clear: left; padding-left: 15px; padding-top: 5px;">

          <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
            Model Name:
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
            <jqxTextArea #text_MODELNAME
                         [width]="200"
                         [height]="20"
                         [maxLength]="20"
                         [disabled]="bundle.disabled">

            </jqxTextArea>
          </div>
        </div>

        <div [hidden]="!bundle.sysAdmin">

          <!--LEAD LENDER-->
          <div style="clear: left; padding-left: 15px; padding-top: 5px;">

            <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
              Lead Lender:
            </div>

            <div style="float: left; padding-left:0px;">

              <jqxDropDownList #dropDown_leadLender style="float: left;"
                               [theme]="'material-green'"
                               [width]="300"
                               [height]="25"
                               [dropDownHorizontalAlignment]="'left'"
                               [dropDownVerticalAlignment]="'bottom'"
                               [dropDownWidth]="400"
                               [disabled]='false'
                               (onSelect)="selectLeadLender($event)">

              </jqxDropDownList>

            </div>
          </div>

          <!--CLOSE DATE-->
          <div style="clear: left; padding-left: 15px; padding-top: 5px;">

            <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
              Close Date:
            </div>

            <div style="float: left; padding-left:0px;">
              <jqxDateTimeInput #date_approxLoanCloseDate [width]="120" [height]="20" [formatString]="'d'"> </jqxDateTimeInput>
            </div>
          </div>

          <!--LOANTERMMONTHS-->
          <div style="clear: left; padding-left: 15px; padding-top: 5px;">

            <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
              Term Months :
            </div>

            <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">

              <jqxTextArea #text_LOANTERMMONTHS
                           [width]="50"
                           [height]="20"
                           [disabled]="bundle.disabled">


              </jqxTextArea>

            </div>
          </div>

          <!--TERMINATION DATE-->
          <div style="clear: left; padding-left: 15px; padding-top: 5px;">

            <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
              Termination Date:
            </div>

            <div style="float: left; padding-left:0px;">
              <jqxDateTimeInput #date_approxLoanTerminationDate [width]="120" [height]="20" [formatString]="'d'"> </jqxDateTimeInput>
            </div>
          </div>

          <!--SETUP AMOUNT-->
          <div style="clear: left; padding-left: 15px; padding-top: 5px;">

            <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
              Setup Amount:
            </div>

            <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
              <jqxTextArea #text_SETUPAMOUNT
                           [width]="100"
                           [height]="20"
                           [maxLength]="30"
                           [disabled]="bundle.disabled">

              </jqxTextArea>
            </div>
          </div>

          <!--MONTHLY AMOUNT-->
          <div style="clear: left; padding-left: 15px; padding-top: 5px;">

            <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
              Monthly Amount:
            </div>

            <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
              <jqxTextArea #text_MONTHLYAMOUNT
                           [width]="100"
                           [height]="20"
                           [maxLength]="30"
                           [disabled]="bundle.disabled">

              </jqxTextArea>
            </div>
          </div>

        </div>

        <!--LIMIT_COMMITMENT_AMT-->
        <div style="clear:left; padding-top:5px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
            Loan Commitment:
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
            <jqxTextArea #text_LIMIT_COMMITMENT_AMT
                         [width]="200"
                         [height]="20"
                         [disabled]="bundle.disabled">

            </jqxTextArea>
          </div>
        </div>

        <!--DAYSTOAVERAGEChoices-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">
              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>

          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px; ">
            Days to Average:
          </div>

          <div style="float: left; padding-left:0px;">
            <div *ngFor="let DAYSTOAVERAGE of bundle.DAYSTOAVERAGEChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="bundle.radialBtnSpacing"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'DAYSTOAVERAGE'"
                              [checked]="DAYSTOAVERAGE.SELECTED"
                              (onChange)='selectDAYSTOAVERAGE($event)'
                              [disabled]="bundle.disabled">

                <span>{{DAYSTOAVERAGE.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>
        </div>

        <!--ENV SECTION-->
        <div style="clear:left; padding-top:3px;">

          <div class="txtBold" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
            ENV% (Estimated Net Value - Liquidity - Collection Percentage)
          </div>
        </div>

        <!--DYNAMICENVYESNO-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Dynamic ENV%:
          </div>

          <div style="float: left; padding-left:0px;">
            <div *ngFor="let DYNAMICENVYESNO of bundle.DYNAMICENVYESNOChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="bundle.radialBtnSpacing"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'DYNAMICENVYESNO'"
                              [checked]="DYNAMICENVYESNO.SELECTED"
                              (onChange)='selectDYNAMICENVYESNO($event)'
                              [disabled]="bundle.disabled">

                <span>{{DYNAMICENVYESNO.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>
        </div>

        <!--LIMIT_ENV_OVERRIDE_PCT-->
        <div [hidden]="bundle.DYNAMICENVYESNO == 'Yes'" style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Fixed ENV% (New Payer Defalt):
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
            <jqxTextArea #text_LIMIT_ENV_OVERRIDE_PCT
                         [width]="50"
                         [height]="20"
                         [disabled]="bundle.disabled">

            </jqxTextArea>
          </div>
        </div>

        <!--LIMIT_ENVCALCBUCKET_DAYS-->
        <div [hidden]="bundle.DYNAMICENVYESNO == 'No'" style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            ENV% Period (New Payer Defalt):
          </div>

          <div style="float: left; padding-left:0px;">
            <div *ngFor="let LIMIT_ENVCALCBUCKET_DAYS of bundle.LIMIT_ENVCALCBUCKET_DAYSChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="bundle.radialBtnSpacing"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'LIMIT_ENVCALCBUCKET_DAYS'"
                              [checked]="LIMIT_ENVCALCBUCKET_DAYS.SELECTED"
                              (onChange)='selectLIMIT_ENVCALCBUCKET_DAYS($event)'
                              [disabled]="bundle.disabled">

                <span>{{LIMIT_ENVCALCBUCKET_DAYS.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>
        </div>

        <!--UNBILLED SECTION-->
        <div style="clear:left; padding-top:5px;">

          <div class="txtBold" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
            Unbilled Receivables Collateral Constraints
          </div>
        </div>

        <!--PRORATEUNBILLEDYESNO-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Prorated Unbilled:
          </div>

          <div style="float: left; padding-left:0px;">
            <div *ngFor="let PRORATEUNBILLEDYESNO of bundle.PRORATEUNBILLEDYESNOChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="bundle.radialBtnSpacing"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'PRORATEUNBILLEDYESNO'"
                              [checked]="PRORATEUNBILLEDYESNO.SELECTED"
                              (onChange)='selectPRORATEUNBILLEDYESNO($event)'
                              [disabled]="bundle.disabled">

                <span>{{PRORATEUNBILLEDYESNO.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>
        </div>

        <!--DAYSTOBILL-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Days to Bill:
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">

            <jqxTextArea #text_DAYSTOBILL
                         [width]="50"
                         [height]="20"
                         [disabled]="bundle.disabled">


            </jqxTextArea>

          </div>
        </div>

        <!--LIMIT_UNBILLED_AGECAPDAYS-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Unbilled Age Cap (New Payer Defalt):
          </div>

          <div style="float: left; padding-left:0px;">
            <div *ngFor="let LIMIT_UNBILLED_AGECAPDAYS of bundle.LIMIT_UNBILLED_AGECAPDAYSChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="bundle.radialBtnSpacing"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'LIMIT_UNBILLED_AGECAPDAYS'"
                              [checked]="LIMIT_UNBILLED_AGECAPDAYS.SELECTED"
                              (onChange)='selectLIMIT_UNBILLED_AGECAPDAYS($event)'
                              [disabled]="bundle.disabled">

                <span>{{LIMIT_UNBILLED_AGECAPDAYS.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>
        </div>

        <!--LIMIT_UNBILLEDADVANCE_PCT-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Unbilled Advance Rate (New Payer Defalt):
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
            <jqxTextArea #text_LIMIT_UNBILLEDADVANCE_PCT
                         [width]="50"
                         [height]="20"
                         [disabled]="bundle.disabled">

            </jqxTextArea>
          </div>
        </div>

        <!--BILLED SECTION-->
        <div style="clear:left; padding-top:5px;">

          <div class="txtBold" [style.width.px]="175" style="float: left; padding-top: 5px; padding-left: 5px;">
            Billed Receivables Collateral Constraints
          </div>
        </div>

        <!--LIMIT_BILLED_AGECAPDAYS-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Billed Age Cap (New Payer Defalt):
          </div>

          <div style="float: left; padding-left:0px;">
            <div *ngFor="let LIMIT_BILLED_AGECAPDAYS of bundle.LIMIT_BILLED_AGECAPDAYSChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="bundle.radialBtnSpacing"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'LIMIT_BILLED_AGECAPDAYS'"
                              [checked]="LIMIT_BILLED_AGECAPDAYS.SELECTED"
                              (onChange)='selectLIMIT_BILLED_AGECAPDAYS($event)'
                              [disabled]="bundle.disabled">

                <span>{{LIMIT_BILLED_AGECAPDAYS.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>
        </div>

        <!--LIMIT_BILLEDADVANCE_PCT-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Billed Advance Rate: (New Payer Defalt):
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
            <jqxTextArea #text_LIMIT_BILLEDADVANCE_PCT
                         [width]="50"
                         [height]="20"
                         [disabled]="bundle.disabled">

            </jqxTextArea>
          </div>
        </div>

        <!--LIMIT_PAYERCAP_PCT-->
        <div style="clear:left; padding-top:3px;">

          <div style="float: left;">

            <jqxTooltip [position]="'right'"
                        [name]="'movieTooltip'"
                        [autoHideDelay]="30000"
                        [content]="'TEST'"
                        [opacity]="1.0"
                        [width]="400">

              <img class="infoBtn" src="assets/infoBtn.png" />

            </jqxTooltip>

          </div>


          <div class="txtSmall" [style.width.px]="bundle.valueIndent" style="float: left; padding-top: 5px; padding-left: 5px;">
            Payer Cap Percent:
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
            <jqxTextArea #text_LIMIT_PAYERCAP_PCT
                         [width]="50"
                         [height]="20"
                         [disabled]="bundle.disabled">

            </jqxTextArea>
          </div>
        </div>

      </div>

        <div [style.height.px]="100"> </div>


    </jqxPanel>

    <!--BOTTOM-->
    <div id=bottomLine [style.top.px]="bundle.spB">

      <div *ngIf="bundle.canEdit" style="padding-top:10px;">

        <div class="wordBtnGroup">
          <div class="wordBtn" (click)="submitChange()">Save Changes</div>
        </div>

      </div>

    </div>

  </div>

import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlRolesService } from '../ll-roles.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-admin-role-companies-sp',
  templateUrl: './ll-admin-role-companies-sp.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlAdminRoleCompaniesSpComponent {

  @ViewChild('mainDiv_ll_admin_role_companies_sp') mainDiv: ElementRef;
  @ViewChild('vertTab_ll_admin_role_companies_sp') private vertTab: ElementRef;
  @ViewChild('scrollpane_ll_admin_role_companies_sp') private SP: jqxPanelComponent;
  @ViewChild('popover_search') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search') text_search: jqxTextAreaComponent;
  ///@ViewChild('horzTabs_ll_admin_role_companies_sp', { static: false }) public horzTabs: ElementRef;
  @ViewChild('popover_addCo') private popover_addCo: jqxPopoverComponent;
  @ViewChild('text_companyName') text_companyName: jqxTextAreaComponent;


  constructor(
    private LlRolesService: LlRolesService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,

  ) { }

  bundle = {
    lable: "Companies",
    prefix: "CR",
    selectedId: 0,
    selectedId_ondeck: 0,
    showObj: true,//false,
    showRemaining: false,
    scrolling: false,
    canAdd: false,
    canEdit: false,
    canFilter: false,
    searchText: "",
    function:'',
    LlRolesService: this.LlRolesService,
    popover_addCo: {},
    text_search: {},
    newCompanyName: '',
    getData: {},
    records: [],
    origionalRecords: [],
    companyTypes: [],
    SYSTEMCOMPANYTYPEID: 0,
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,//40,//20,
    topOffset: 0,
    spY: 0,
    spW: 150,
    spH: 0,
    spB: 0,
    lineWidth: 1,
    vertLineX: 0,
    vertLineY: -1,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH: 18,
    hTabW: 100,
    hTabH: 25,
    hTabIndx: 0,
    horzTabs: [],
    canvasHT: {},
    ctxHT: {},
  }

  ngOnInit() { }

  ngOnDestroy() { }

  ngAfterViewInit() {

    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    this.setVars();
  }

  setVars() {

    this.bundle.function = this.mainDiv.nativeElement.parentElement.id;
    this.bundle.text_search = this.text_search;

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {
      //this.bundle.canAdd = this.LlPermissionsService.check("addCompany");

      this.LlScrollpaneService.standardDimentions(this.bundle);

      var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
      canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
      canvasEl.height = this.bundle.vertLineH - 0;
      this.bundle.ctx = canvasEl.getContext('2d');

      setTimeout(() => {

        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.bundle.remainingY = this.bundle.remainingY - 8;
        this.bundle.remainingH = this.bundle.remainingH + 7

        this.getData('new', this.bundle);
      });
    });
  }

  getData(action, bundle) {
      
    bundle.LlRolesService.NG_LL_system_roles_companies_get(done01, bundle);
   
    function done01(records, bundle) {


        bundle.records = records;
     

      bundle.origionalRecords = records;

      let selectedId_initial = bundle.LlRolesService.getActiveCOMPANYID();

      setTimeout(() => {

        bundle.showObj = true;

        setTimeout(() => {

          if (selectedId_initial != null && document.getElementById(bundle.prefix + selectedId_initial.toString()) != null) {
            document.getElementById(bundle.prefix + selectedId_initial.toString()).click();
          }

        });
      });
    }
  }

  select(event) {

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    if (this.bundle.scrolling == true) {

      this.bundle.scrolling = false;

    } else {

      this.bundle.showRemaining = false;

      setTimeout(() => {

        this.bundle.selectedId = this.bundle.selectedId_ondeck;

        this.LlRolesService.setActiveCOMPANYID(this.bundle.selectedId);

        this.bundle.showRemaining = true;
      });
    }
  }

  noAction() {
    //needed HTML buttons point to this
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }

  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);

        if (this.bundle.records.length > 0) {
          this.bundle.selectedId = this.bundle.records[0].FOLDERID;

          this.LlRolesService.setActiveCOMPANYID(this.bundle.selectedId);

        }
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].FOLDERID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].FOLDERID;

        this.LlRolesService.setActiveCOMPANYID(this.bundle.selectedId);

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {


    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].FOLDERID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }

  selectType(event: any): void {

    if (event.args.checked == true) {

      let val = event.target.innerText

      //console.log(val)

      for (var i = 0; i < this.bundle.companyTypes.length; i++) {

        if (val == this.bundle.companyTypes[i].COMPANYTYPENAME) {

          this.bundle.SYSTEMCOMPANYTYPEID = this.bundle.companyTypes[i].SYSTEMCOMPANYTYPEID;

          //console.log(this.bundle.SYSTEMCOMPANYTYPEID)

          break;
        }
      }
    }


  };


  add() {

    if (this.text_companyName.val() != "" && this.bundle.SYSTEMCOMPANYTYPEID != 0) {

      this.bundle.newCompanyName = this.text_companyName.val();
      this.bundle.popover_addCo = this.popover_addCo;
      this.bundle.getData = this.getData;

      ///this.LlCompaniesService.NG_LL_company_add(this.returnedMessage, this.bundle, this.text_companyName.val(), this.bundle.SYSTEMCOMPANYTYPEID);
    }
  }

  returnedMessage(msg, bundle) {

    if (msg == '') {
      bundle.popover_addCo.close();
      bundle.getData('newCompany', bundle);

    } else {
      ///do nothing ... need to add error to entry window...but no need now
    }
  }
}


<div #mainDiv_ll_model_metric_graph>

  <div>

    <jqxChart #myChart_line
              [width]="bundle.objW"
              [height]="bundle.objH"
              [showLegend]="true"
              [enableAnimations]="true"
              [colorScheme]="'scheme04'"
              [enableCrosshairs]="true"
              [crosshairsColor]="'#111888'"
              [crosshairsLineWidth]="2"
              [showBorderLine]="false">
    </jqxChart>
  </div>

</div>

import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ServerService } from '../../services/server.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})

export class ErrorComponent implements AfterViewInit, OnInit, OnDestroy {

  @ViewChild('errorMsg') mainDiv: ElementRef;

  constructor( 
    public ServerService: ServerService, 
    private UserService: UserService,
    ) {     
  }

  vars = {
    ac:"", 
    APPLICATIONVERSION:"",
    STUDYID_SYS:0,
    CONTACTROLEID:0,
    SYSTEMROLEID:0,
    SUBJECTID_SYS:0, 
    STUDYPROVIDERID:0, 
    SYSTEMPRODUCTID:0,
    SUBJECTVISITID:0,  
    EVENT_DATE_LOCAL:"",
    EVENT_TIME_LOCAL:"",
    QUESTIONSTAGEID:0,
    STUDYPROTOCOLVERSIONID:0,
    RECORD_COMPANYID:0,
    RECORD_CONTACTID:0,
    SYSTEMCOMPANYTYPEID:0,
    CONTACTID:0,
    STUDYVISITID:0,
    BUILDORENTRY:0,
    COMPANYID:0,
    COMPANYORCONTACT:"",
    STUDYVISITNAME:"",
    STUDYVISITID_TEMPLATE:0,
    ACTION:"",
    FREQUENCY:"",
    EVERY:"",
    DAY:"",
    STARTDATE:"",
    EVENTCOUNT:"",
    systemErrorDescription:"",
  }

  bundle = {  
    error:false,    
    vars:this.vars,
    msg01:"",
    msg02:"",
    closeBtn:false,

  }; 
 
  eventSubscription_serverError:any;
  
  ngOnDestroy() {
    this.eventSubscription_serverError.unsubscribe();
  }

  ngOnInit() {} 

  ngAfterViewInit(): void {

    this.eventSubscription_serverError = this.ServerService.serverError.subscribe(errorMsg => {  

 /// console.log("eventSubscription_serverError")
      ///this.mainDiv.focus()
      //this.mainDiv.open()

      //document.getElementById('errorMsg').focus();
      //document.getElementById('errorMsg').open();

      this.bundle.msg01 = "There has been a server communicaiton error!";
      this.bundle.msg02 = "We apologize for the inconvenience. Details of the error have been sent to the application administrator to eliminate the problem going forward.";
      this.bundle.closeBtn = true;

      this.logError(done01, errorMsg);

      function done01(bundle){
        setTimeout(() => { 
          bundle.error = true;
        });
      }

    });
  }

 logError(callback01, errorMsg){

    var user = this.UserService.getActiveUser();
    this.bundle.vars.ac = "NG_utility_logError" 
    this.bundle.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;  
    this.bundle.vars.CONTACTID = user.CONTACTID;

    this.bundle.vars.systemErrorDescription = errorMsg,

    this.ServerService.callserver(srvReturn, this.bundle, "show");    
  
    function srvReturn(arr, bundle){
      callback01(bundle);
    } 

 }


  close(){
    this.bundle.error = false;
  }

};

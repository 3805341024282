<div #mainDiv_ll_model_payer_edit>

  <div [hidden]="!bundle.showPayerEdit" [style.top.px]="bundle.spY" [style.left.px]="bundle.spX" style="position:absolute;">


    <jqxPanel style="white-space: nowrap; background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <div class="txtBold" style="clear:left; padding-top:10px;">
        Payer Settings
      </div>

      <div style="clear:left; padding-top:20px;">

        <!--payer ID-->
        <div style="clear:left; padding-top:0px;">


          <div class="txtNorm12" [style.width.px]="150" style="float: left;">
            LendingLinc Payer ID:
          </div>

          <div class="txtNorm12" style="float:left; padding-left:5px">
            {{bundle.PAYERID}}
          </div>
        </div>

        <!--payer name-->
        <div style="clear:left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left;">
            Payer:
          </div>

          <div class="txtNorm12" style="float:left; padding-left:5px">
            {{bundle.PAYERNAME}}
          </div>
        </div>

        <!--software vender-->
        <div style="clear:left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left;">
            Software Vender:
          </div>

          <div style="float:left; padding-left:5px">
            {{bundle.VENDERNAME}}
          </div>

        </div>

        <!--payer eligible-->
        <div style="clear:left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left; ">
            Payer Eligible:
          </div>

          <div style="float:left; padding-left:5px">
            {{bundle.PAYERELIGIBLEYN}}
          </div>
        </div>

        <!--Billed Age-Cap-->
        <div [style.width.px]="bundle.spW" style="clear: left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left; ">
            Billed Age-Cap:
          </div>

          <div style="float: left; padding-left:0px;">

            <div *ngFor="let ac_billed of bundle.ageCapChoices_billed" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="50"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'billedgroup'"
                              [checked]="ac_billed.SELECTED"
                              (onChange)='selectAgeCap_billed($event)'>

                <span>{{ac_billed.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>
        </div>

        <!--billed Advance Rate -->
        <div [style.width.px]="bundle.spW" style="clear: left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left; ">
            Billed Advance Pct:
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">

            <jqxTextArea #text_billedAdvanceRate [width]="125" [height]="20"></jqxTextArea>

          </div>
        </div>

        <!--unbilled Age-Cap-->
        <div [style.width.px]="bundle.spW" style="clear: left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left; ">
            Unbilled Age-Cap:
          </div>

          <div style="float: left; padding-left:0px;">

            <div *ngFor="let ac_unbilled of bundle.ageCapChoices_unbilled" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="50"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'unbilledgroup'"
                              [checked]="ac_unbilled.SELECTED"
                              (onChange)='selectAgeCap_unbilled($event)'>

                <span>{{ac_unbilled.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>

        </div>

        <!--unbilled Advance Rate -->
        <div [style.width.px]="bundle.spW" style="clear: left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left; ">
            Unbilled Advance Pct:
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">

            <jqxTextArea #text_unbilledAdvanceRate [width]="125" [height]="20"></jqxTextArea>

          </div>
        </div>

        <!--LIMIT_ENV_OVERRIDE_PCT -->
        <div [style.width.px]="bundle.spW" style="clear: left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left; ">
            Fixed ENV%
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">
            <jqxTextArea #text_LIMIT_ENV_OVERRIDE_PCT [width]="125" [height]="20"></jqxTextArea>
          </div>

          <div class="txtNorm11" style="float: left; padding-left:20px;">
            Note: Dynamic vs Fixed Override ENV% set/determined at the model level
          </div>
        </div>

        <!--LIMIT_ENVCALCBUCKET_DAYS-->
        <div [style.width.px]="bundle.spW" style="clear: left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left; ">
            ENV Calc Period:
          </div>

          <div style="float: left; padding-left:0px;">

            <div *ngFor="let LIMIT_ENVCALCBUCKET_DAYS of bundle.LIMIT_ENVCALCBUCKET_DAYSChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="50"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'LIMIT_ENVCALCBUCKET_DAYS'"
                              [checked]="LIMIT_ENVCALCBUCKET_DAYS.SELECTED"
                              (onChange)='selectLIMIT_ENVCALCBUCKET_DAYS($event)'>

                <span>{{LIMIT_ENVCALCBUCKET_DAYS.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>

        </div>

        <!--reserve -->
        <div [style.width.px]="bundle.spW" style="clear: left; padding-top:5px;">

          <div class="txtNorm12" [style.width.px]="150" style="float: left; ">
            Reserve:
          </div>

          <div (keyup)="onKeyUp($event)" style="float: left; padding-left:0px;">

            <jqxTextArea #text_reserve [width]="125" [height]="20"></jqxTextArea>

          </div>
        </div>

      </div>

    </jqxPanel>

    <!--BOTTOM-->
    <div id=bottomLine [style.top.px]="bundle.spB">

      <div style="padding-top:10px;">

        <div class="wordBtnGroup">
          <div class="wordBtn" (click)="submitChange()">Save Changes</div>
        </div>

      </div>

    </div>
 
</div>

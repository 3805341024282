<div #mainDiv_ll_admin_generic_dg>

  <div [hidden]="!bundle.showDG" style="position:absolute; " [style.top.px]="15">

    <!--<div style="float:left;">
      <b>{{bundle.headerLable}}: &nbsp; &nbsp; </b>
    </div>-->

    <div class="txtBold_14" style="float:left; ">
      {{bundle.headerLable}}:
    </div>

    <div style="float: left; padding-left: 20px">
      <tr *ngFor="let item of bundle.horzTabs; index as i;" style="float:left; white-space:nowrap;">

        <div style="float:left; ">

          <div *ngIf=" i >0 " style="float: left; padding-right: 5px; padding-left: 5px; ">
            |
          </div>

          <div *ngIf='item.CODE == bundle.activeCODE' style="float:left;">
            <div class="txtNorm14" [id]="item.CODE" style="float:left" (click)="toSort($event)">{{item.LABEL}}</div>
          </div>

          <div *ngIf='item.CODE != bundle.activeCODE' style="float:left">
            <div class="wordBtn14" [id]="item.CODE" style="float:left" (click)="toSort($event)">{{item.LABEL}}</div>
          </div>
        </div>

      </tr>

    </div>

    <div class="txtNorm14" style="float:left; padding-left:40px">
      {{bundle.headerDescription}}
    </div>

    <div *ngIf="bundle.function == 'admin_accounting_dg'" style="float: left; padding-left: 20px;">
      <jqxDateTimeInput #date_monthEnd
                        [width]="110"
                        [height]="20"
                        [formatString]="'d'"
                        (onChange)='selectMonthEnd()'>
      </jqxDateTimeInput>
    </div>



  </div>

  <div [hidden]="!bundle.showDG" style="position:absolute; " [style.top.px]="bundle.gridY">


    <jqxGrid #dataGrid_ll_admin_generic_dg
             [editable]="true"
             [rowsheight]="20"
             [filterable]="true"
             [sortable]="true"
             [columnsresize]="true"
             [showstatusbar]="false"
             (onCellvaluechanged)="clickOnRow($event)"
             (onRowclick)="clickOnRow($event)">
    </jqxGrid>




    <div style="padding-top: 10px">

      <div *ngIf="bundle.showDownloadBtn" style="float: left;">
        <div class="wordBtn_11" (click)="exportOnClick()">Download Above List</div>
      </div>

      <div id=remove_ll_admin_generic_dg *ngIf="bundle.canRemove" style="float: left; padding-left: 20px">

        <div class="wordBtnGroup">
          <img class="btnIcon" height=13 style="width: auto;" src="assets/icon_redx.png" alt="" (click)="noAction()">
          <div class="wordBtn" (click)="add()">{{bundle.removeBtnLang}}</div>
        </div>

        <jqxTooltip style="float: left;"
                    [position]="'top'"
                    [name]="'movieTooltip'"
                    [autoHideDelay]="30000"
                    [content]="'Click to remove highlighted record. This removal action will be confirmed'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn_12" src="assets/infoBtn.png" />

        </jqxTooltip>


        <jqxPopover #popover_remove_ll_admin_generic_dg style="font-size: 14px; font-family: Verdana;"
                    [offset]='{ left: 50, top: 0 }'
                    [arrowOffsetValue]="200"
                    [width]="300"
                    [height]="150"
                    [position]="'top'"
                    [title]="'Remove'"
                    [showCloseButton]="true"
                    [selector]="'#remove_ll_admin_generic_dg '">

          <!--text-->
          <div class="txtNorm" [style.width.px]="150" style="float: left; padding-left: 1px; padding-top: 5px;">
            {{bundle.removePULang}}
          </div>

          <!--Save Changes-->
          <div class="wordBtnGroup_popover" style="float:left; padding-left:200px; ">
            <div class="wordBtn14" (click)="remove()">Yes, Remove</div>
          </div>


        </jqxPopover>

      </div>


    </div>

  </div>

  <div *ngIf="bundle.showCompany">

    <div [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; ">
      <ll-company-switch id="from_ll_admin_generic_dg"></ll-company-switch>
    </div>

    <div [style.top.px]="10"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="100"
         [style.height.px]="25"
         style="position: absolute;z-index:100">

      <div class="wordBtnGroup">
        <img class="btnIcon" height=23 style="width: auto;" src="assets/icon_backArrow.png" alt="" (click)="goBack_fromCompany()">
        <div class="wordBtn14" style="padding-left:5px; padding-top:3px;" (click)="goBack_fromCompany()">Go Back</div>
      </div>

    </div>
  </div>
</div>


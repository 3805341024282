import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { LlUtilityService } from '../ll-utility.service';

@Component({
  selector: 'll-message-pu',
  templateUrl: './ll-message-pu.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlMessagePuComponent {

  @ViewChild('errorMsg') mainDiv: ElementRef;

  constructor(
    public LlUtilityService: LlUtilityService,
  ) {
  }

 
  bundle = {
    openMsg: false,
    msg01: "",
  };

  eventSubscription_openMessagePU: any;

  ngOnDestroy() {
    this.eventSubscription_openMessagePU.unsubscribe();
  }

  ngOnInit() { }

  ngAfterViewInit(): void {

    this.eventSubscription_openMessagePU = this.LlUtilityService.openMessagePU.subscribe(msg => {
      this.bundle.msg01 = msg;
      this.bundle.openMsg = true;
    });
  }


  close() {
    this.bundle.openMsg = false;
  }

};


import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy, } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { stringToNumber } from "../utility";
import { javaDate_to_shortDate } from "../utility-date";
import { YYYYMMDD_to_shortDate } from "../utility-date";

@Component({
  selector: 'll-model-report-bbc-one',
  templateUrl: './ll-model-report-bbc-one.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlModelReportBbcOneComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_report_bbc_one') private mainDiv: ElementRef;

  constructor(
    private LlModelsService: LlModelsService,
  ) { }

  bundle = {
    showDisplay: false,
    logo: "",
    imageSource: "",
    imageHeight: 25,
    daysBack: 0,
    REPORTFORMCONTEXTID: 0,
    FOLDERTYPE: "",
    LlModelsService: this.LlModelsService,
    elements: [],
    forms: [],
    locations: [],
    records: [],
    loan: {},
    reportDate_date: "",
    throughDate_date: "",
    BBC_label: "",
    COMPANYNAME: "",
    MODELNAME: "",
    LOANLANGUAGE: "",
    parentW: 0,
    parentH: 0,
    choicesX: 0,
    choicesY: 20,
    choicesW: 150,
    choicesH: 0,
    displayX: 0,
    displayY: 0,
    displayW: 0,
    displayH: 0,
    pageW: 0,
    pageH: 0,
    mainColW_1: 0,
    mainColW_2: 0,
    mainColW_2_1: 50,
    Tooltip01: "",
  }

  ngOnDestroy() {}

  ngOnInit() { }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {

    setTimeout(() => {
      //let activeReport = this.LlModelsService.getActiveReport();
      //var n = new Date();
      //this.bundle.date_now = javaDate_to_shortDate(n);

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;
      this.bundle.choicesX = this.bundle.parentW - this.bundle.choicesW
      this.bundle.choicesX = this.bundle.parentW - this.bundle.choicesW;
      this.bundle.displayW = this.bundle.parentW - (this.bundle.displayX * 2) - 15;
      this.bundle.displayH = this.bundle.parentH - this.bundle.displayY - 40;
      this.bundle.pageW = this.bundle.displayW - 40;

      if (this.bundle.pageW > 800) {
        this.bundle.pageW = 800;
      }

      this.bundle.pageH = this.bundle.displayH;
      this.bundle.mainColW_1 = (this.bundle.pageW * .6) - 30;
      this.bundle.mainColW_2 = this.bundle.pageW - this.bundle.mainColW_1 - 20;

      this.getData();
    });
  }

  getData() {

    this.LlModelsService.clearAll_AR_VIEW_IDs()
    this.LlModelsService.setDaysBack(0);

    this.bundle.LlModelsService.NG_LL_reports_BBC_getData(done01, this.bundle);

    function done01(elements, bundle) {
      bundle.elements = elements;

      //console.log(elements)

      bundle.forms = bundle.LlModelsService.getForms();

      //console.log(bundle.forms)

      bundle.LlModelsService.NG_LL_model_AR_reports_BBC_locations_get(done02, bundle);
    }

    function done02(arr, bundle) {

     //console.log(arr)

      bundle.locations = arr;

      bundle.BBC_label = bundle.elements[0].BBC_LABEL 
      bundle.COMPANYNAME = bundle.elements[0].COMPANYNAME
      bundle.MODELNAME = bundle.elements[0].MODELNAME
      bundle.LOANLANGUAGE = bundle.elements[0].LOANLANGUAGE

      bundle.logo = bundle.elements[0].BBC_LOGO
      bundle.imageSource = "assets/" + bundle.logo
      bundle.imageHeight = bundle.elements[0].BBC_LOGOHEIGHT;

      bundle.throughDate_date = bundle.elements[0].THROUGHDATE_DATE;
      bundle.reportDate_date = bundle.elements[0].REPORTDATE_DATE;

      //bundle.date_calc = YYYYMMDD_to_shortDate(String(bundle.elements[0].PERIODENDDATE_NUM));

      for (let i = 0; i < bundle.elements.length; i++) {        

        if (bundle.elements[i].ELEMENT != '') { 
          bundle.elements[i].ELEMENT = bundle.elements[i].ELEMENT.toUpperCase();
        }

        let element = bundle.elements[i].ELEMENT

        ///console.log("element:"+element)

        bundle.elements[i].input = false;

          if (element == 'LOAN_UNPOSTEDCASH'
            || element == 'LOAN_BEGININGBALANCE_AMT'
            || element == 'LOAN_CASHAPPLIED_AMT'
            || element == 'LOAN_INTEREST_AMT'
            || element == 'LOAN_FEES_AMT'
            || element == 'LOAN_ADVANCEREQUEST_AMT'
            || element == 'LOAN_MANUALADUSTMENT01_AMT'
            || element == 'LOAN_MANUALADUSTMENT02_AMT'
            || element == 'LOAN_MANUALADUSTMENT03_AMT'
            || element == 'LOAN_MANUALADUSTMENT04_AMT'
          ) {

            bundle.elements[i].input = true;

          }
        ////}
      }

      setTimeout(() => {
        bundle.showDisplay = true;
      });
    }
  }

  calculations(event): void {

    //console.log(event)


    var target = event.target || event.srcElement || event.currentTarget;
    var idAttr = target.id;

    //console.log(idAttr)

    let val = (<HTMLInputElement>document.getElementById(idAttr)).value;

    //console.log(val)

    //console.log(document.getElementById(idAttr))

    //this.bundle.records[0][idAttr] = (<HTMLInputElement>document.getElementById(idAttr)).value;

    //this.bundle.records[0][idAttr] = val;

    for (let i = 0; i < this.bundle.elements.length; i++) {
      if (idAttr == this.bundle.elements[i].ELEMENT) {
        this.bundle.elements[i].ELEMENT_VALUE = val;
      }
    }

    this.calculate();
  }

  calculate() {

    //var available = Number(String(this.bundle.records[0].COMBO_AVAILABLE).replace(/,/g, ""))
    //var beginingBal = Number(String(this.bundle.records[0].LOAN_BEGININGBALANCE_AMT).replace(/,/g, ""))
    //var commitment = Number(String(this.bundle.records[0].LIMIT_COMMITMENT_AMT).replace(/,/g, ""))
    //var advance = Number(String(this.bundle.records[0].LOAN_ADVANCEREQUEST_AMT).replace(/,/g, ""))

    //var manual_adj_01 = Number(String(this.bundle.records[0].LOAN_MANUALADUSTMENT01_AMT).replace(/,/g, ""))
    //var manual_adj_02 = Number(String(this.bundle.records[0].LOAN_MANUALADUSTMENT02_AMT).replace(/,/g, ""))
    //var manual_adj_03 = Number(String(this.bundle.records[0].LOAN_MANUALADUSTMENT03_AMT).replace(/,/g, ""))
    //var manual_adj_04 = Number(String(this.bundle.records[0].LOAN_MANUALADUSTMENT04_AMT).replace(/,/g, ""))

    for (let i = 0; i < this.bundle.elements.length; i++) {

      if (this.bundle.elements[i].ELEMENT == 'COMBO_AVAILABLE') {
        var available = Number(String(this.bundle.elements[i].ELEMENT_VALUE).replace(/,/g, ""))
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_BEGININGBALANCE_AMT') {
        var beginingBal = Number(String(this.bundle.elements[i].ELEMENT_VALUE).replace(/,/g, ""))
      }

      if (this.bundle.elements[i].ELEMENT == 'LIMIT_COMMITMENT_AMT') {
        var commitment = Number(String(this.bundle.elements[i].ELEMENT_VALUE).replace(/,/g, ""))
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_ADVANCEREQUEST_AMT') {
        var advance = Number(String(this.bundle.elements[i].ELEMENT_VALUE).replace(/,/g, ""))
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_MANUALADUSTMENT01_AMT') {
        var manual_adj_01 = Number(String(this.bundle.elements[i].ELEMENT_VALUE).replace(/,/g, ""))
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_MANUALADUSTMENT02_AMT') {
        var manual_adj_02 = Number(String(this.bundle.elements[i].ELEMENT_VALUE).replace(/,/g, ""))
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_MANUALADUSTMENT03_AMT') {
        var manual_adj_03 = Number(String(this.bundle.elements[i].ELEMENT_VALUE).replace(/,/g, ""))
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_MANUALADUSTMENT04_AMT') {
        var manual_adj_04 = Number(String(this.bundle.elements[i].ELEMENT_VALUE).replace(/,/g, ""))
      }


    }


    let borrowbase = available + manual_adj_01 + manual_adj_02 + manual_adj_03 + manual_adj_04;
    var loanAvailable = borrowbase;///available - unposted;     

    if (commitment < loanAvailable) {
      loanAvailable = commitment
    }

    let availableToAdvance = loanAvailable - beginingBal;
    let endingBalance = beginingBal + advance
    let excessAvailable = loanAvailable - endingBalance;///commitment - endingBalance;

    for (let i = 0; i < this.bundle.elements.length; i++) {

      if (this.bundle.elements[i].ELEMENT == 'LOAN_BORROWBASE_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(borrowbase, 'c', 0);

        document.getElementById('LOAN_BORROWBASE_AMT').innerText = this.bundle.elements[i].ELEMENT_VALUE
        
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_AVAILABLETOADVANCE_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(availableToAdvance, 'c', 0);

        document.getElementById('LOAN_AVAILABLETOADVANCE_AMT').innerText = this.bundle.elements[i].ELEMENT_VALUE
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_ENDINGBALANCE_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(endingBalance, 'c', 0);

        document.getElementById('LOAN_ENDINGBALANCE_AMT').innerText = this.bundle.elements[i].ELEMENT_VALUE
      }

      if (this.bundle.elements[i].ELEMENT == 'LOAN_EXCESSAVAILABLE_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(excessAvailable, 'c', 0);

        document.getElementById('LOAN_EXCESSAVAILABLE_AMT').innerText = this.bundle.elements[i].ELEMENT_VALUE
      }


    }
  }


  getBBCPDF() {
    
      //this.bundle.records[0].LOAN_UNPOSTEDCASH = stringToNumber(this.bundle.records[0].LOAN_UNPOSTEDCASH, 'c', 0);
      //this.bundle.records[0].LOAN_LOANBALANCEPAST_AMT = stringToNumber(this.bundle.records[0].LOAN_LOANBALANCEPAST_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_CASHAPPLIED_AMT = stringToNumber(this.bundle.records[0].LOAN_CASHAPPLIED_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_FEES_AMT = stringToNumber(this.bundle.records[0].LOAN_FEES_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_INTEREST_AMT = stringToNumber(this.bundle.records[0].LOAN_INTEREST_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_ADVANCEREQUEST_AMT = stringToNumber(this.bundle.records[0].LOAN_ADVANCEREQUEST_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_BEGININGBALANCE_AMT = stringToNumber(this.bundle.records[0].LOAN_BEGININGBALANCE_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_MANUALADUSTMENT01_AMT = stringToNumber(this.bundle.records[0].LOAN_MANUALADUSTMENT01_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_MANUALADUSTMENT02_AMT = stringToNumber(this.bundle.records[0].LOAN_MANUALADUSTMENT02_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_MANUALADUSTMENT03_AMT = stringToNumber(this.bundle.records[0].LOAN_MANUALADUSTMENT03_AMT, 'c', 0);
      //this.bundle.records[0].LOAN_MANUALADUSTMENT04_AMT = stringToNumber(this.bundle.records[0].LOAN_MANUALADUSTMENT04_AMT, 'c', 0);

    for (let i = 0; i < this.bundle.elements.length; i++) {

      if (this.bundle.elements[i].ELEMENT == 'LOAN_UNPOSTEDCASH') {
          this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_LOANBALANCEPAST_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_CASHAPPLIED_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_FEES_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_INTEREST_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_ADVANCEREQUEST_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_BEGININGBALANCE_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_MANUALADUSTMENT01_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_MANUALADUSTMENT02_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_MANUALADUSTMENT03_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
      if (this.bundle.elements[i].ELEMENT == 'LOAN_MANUALADUSTMENT04_AMT') {
        this.bundle.elements[i].ELEMENT_VALUE = stringToNumber(this.bundle.elements[i].ELEMENT_VALUE, 'c', 0);
      }
    }

    this.LlModelsService.NG_LL_model_AR_reports_BBC_PDF_one(this.bundle.elements, this.bundle)
  }

}

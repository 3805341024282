<div #mainDiv_ll_info_request_add>

  <!--<jqxPanel #SP_build_forms_sp [style.top.px]="bundle.spY" style="white-space: nowrap; background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
            [autoUpdate]="true"
            [rtl]="true"
            [width]="bundle.objW"
            [height]="bundle.objH">-->

  <div>
    <div style="float: left; padding-top: 20px; ">
      <img height=30 style="width: auto;" src="assets/icon_schedule.png">
    </div>

    <div class="txtBold_16" style="float: left; padding-left:20px; padding-top:5px;  ">
      Add Information Request
    </div>
  </div>

  <div style="clear: left; padding-top:10px; ">

    <!--<div style="float: left; ">
      <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
    </div>-->

    <div style="float: left; padding-left:1px; padding-top:5px;">

      <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left;">
        Report/Survey:{{bundle.COMPANYTYPENAME}}
      </div>
    </div>
  </div>

  <!--Request Type-->
  <div style="clear:left; padding-top:10px; ">

    <div style="clear: left; padding-top:10px; ">

      <div style="float: left; ">
        <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
      </div>

      <div style="float: left; padding-left:5px; padding-top:5px;">

        <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left;">
          Request Type:
        </div>

        <div style="clear: left; padding-left:10px;">
          <div *ngFor="let visit of bundle.visitChoices" style="clear:left; font-size: 12px; font-family: Arial;  ">
            <jqxRadioButton [width]="200"
                            [height]="25"
                            [theme]="'material-green'"
                            [groupName]="visit"
                            [checked]="visit.SELECTED"
                            (onChange)='selectVisit($event)'>

              <span>{{visit.choice}}</span>
            </jqxRadioButton>
          </div>
        </div>
      </div>
    </div>

    <!--Start Date:-->
    <div style="clear: left; padding-top:10px; ">

      <div style="float: left; ">
        <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
      </div>

      <div style="float: left; padding-left:1px; padding-top:5px;">

        <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left;">
          Start Date:
        </div>

        <div style="float: left; padding-left:10px;">
          <jqxDateTimeInput #date_start
                            [width]="200"
                            [height]="25"
                            [formatString]="'d'">
          </jqxDateTimeInput>
        </div>
      </div>
    </div>

    <!--Frequency:-->
    <div style="clear: left; padding-top:0px; ">

      <div style="float: left; ">
        <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
      </div>

      <div style="float: left; padding-left:1px; padding-top:5px;">

        <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left; ">
          Frequency:
        </div>

        <div style="float: left; padding-left:10px;">
          <div *ngFor="let freq of bundle.frequencyChoices" style="float:left; font-size: 12px; font-family: Arial;  ">
            <jqxRadioButton [width]="80"
                            [height]="25"
                            [theme]="'material-green'"
                            [groupName]="freq"
                            [checked]="freq.SELECTED"
                            (onChange)='selectFrequency($event)'>

              <span>{{freq.choice}}</span>
            </jqxRadioButton>
          </div>
        </div>
      </div>
    </div>

    <!--COUNT-->
    <div *ngIf="bundle.FREQUENCY == 'Monthly' || bundle.FREQUENCY == 'Weekly'" style="clear: left; padding-top:0px; ">

      <div style="float: left; ">
        <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
      </div>

      <div style="float: left; padding-left:1px; padding-top:5px;">

        <div class="txtNorm" [style.width.px]="bundle.col01W" style="float: left; ">
          Count:
        </div>

        <div (keyup)="text_count_onKeyUp($event)" style="float: left; padding-left:10px;">
          <jqxTextArea #text_count
                       [width]="200"
                       [height]="25"
                       [roundedCorners]="true">
          </jqxTextArea>
        </div>
      </div>
    </div>

    <div *ngIf="bundle.FREQUENCY == 'Monthly' || bundle.FREQUENCY == 'Weekly'" style="clear: left; padding-top:10px; ">

      <div style="float: left; ">
        <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
      </div>

      <div style="float: left; padding-left:1px; padding-top:5px;">

        <div style="float: left;">
          <div class="txtNorm" *ngIf="bundle.FREQUENCY == 'Monthly'" [style.width.px]="bundle.col01W" style="float: left;">
            On the
          </div>
          <div class="txtNorm" *ngIf="bundle.FREQUENCY == 'Weekly'" [style.width.px]="bundle.col01W" style="float: left;">
            Each
          </div>

          <div *ngIf="bundle.FREQUENCY == 'Monthly'" style="float: left; padding-left:10px;">
            <div *ngFor="let item of bundle.everyArr" style="clear:left; font-size: 12px; font-family: Arial;  ">
              <jqxRadioButton [width]="100"
                              [height]="25"
                              [theme]="'material-green'"
                              [groupName]="'every'"
                              [checked]="item.SELECTED"
                              (onChange)='selectEvery($event)'>

                <span>{{item.choice}}</span>
              </jqxRadioButton>
            </div>
          </div>
        </div>

        <div *ngIf="bundle.FREQUENCY == 'Monthly' || bundle.FREQUENCY == 'Weekly'" style="float: left; padding-left:10px; ">

          <div *ngFor="let day of bundle.dayArr" style="clear:left; font-size: 12px; font-family: Arial;  ">
            <jqxRadioButton [width]="100"
                            [height]="25"
                            [theme]="'material-green'"
                            [groupName]="day"
                            [checked]="day.SELECTED"
                            (onChange)='selectDay($event)'>
              <span>{{day.choice}}</span>

            </jqxRadioButton>
          </div>

        </div>
      </div>
    </div>

    <div class="wordBtnGroup" style="clear: left; padding-top:20px; padding-left:200px; ">
      <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_plus.png" alt="" (click)="addEvent()">
      <div class="wordBtn" (click)="addEvent()">Enter Event(s) </div>
    </div>

  </div>
  <!--</jqxPanel>-->
</div>

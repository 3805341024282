import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';

@Component({
  selector: 'll-info-request-widget-mlp',
  templateUrl: './ll-info-request-widget-mlp.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestWidgetMLPComponent {
  @ViewChild('inputField') private inputField: ElementRef;
  @ViewChild('metricHeader') metricHeader: ElementRef;


  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
  ) { }

  bundle = {
    showObj: false,
    dataEntry: false,
    keyUp: false,
    STUDYQUESTIONID: 0,
    QUESTIONINPUTTYPE: '',
    QUESTIONNAME: '',
    COUNT: "",
    circleImg: "",
    ///objW: 0,
    objW: 0,
    unitW: 80,
    initialLoad: true,
    orgVal: '',
    newVal: "",
    charLimit: 0,
    NGWIDGET: '',
    questionUnit: "",
    decimalPlaces: 0,
    QUESTIONRESULT_USER: '',
    QUESTIONRESULT_SPONSOR: '',
    VALUE: "",
    FIELD: "",
    Tooltip01: 'not Found',
    objId: 0,
    STUDYVISITFORMID: 0,
    objType: "",
    keyCode: "",
    divId: "",
    questionW: 0,
    valueRow01W: 150,
    padding_left:10,
    choices:[],
  }



  ngOnInit() { }

  eventSubscription_renewQuestion: any;

  ngOnDestroy() {
    this.eventSubscription_renewQuestion.unsubscribe();
  }

  ngAfterViewInit() {

    this.eventSubscription_renewQuestion = this.LlInfoRequestsService.renewQuestion.subscribe(STUDYQUESTIONID => {

      if (this.bundle.STUDYQUESTIONID == STUDYQUESTIONID) {
        ///this.setVars();
      }

    });

    this.setVars();

  }

  setVars() {
    this.bundle.initialLoad = true;
    this.bundle.STUDYQUESTIONID = this.metricHeader.nativeElement.parentElement.id;

    let record = this.LlInfoRequestsService.getSelectStudyQuestion(this.bundle.STUDYQUESTIONID);

    setTimeout(() => {

      this.bundle.objW = this.metricHeader.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.questionW = this.bundle.objW - this.bundle.valueRow01W;

      this.bundle.charLimit = record.QUESTIONCHARACTERLIMIT;
      this.bundle.QUESTIONINPUTTYPE = record.QUESTIONINPUTTYPE;
      this.bundle.NGWIDGET = record.NGWIDGET.toLowerCase();
      this.bundle.questionUnit = record.QUESTIONUNIT;
      this.bundle.dataEntry = record.dataEntry;
      this.bundle.orgVal = String(record.QUESTIONRESULT_USER);

      //if (this.bundle.STUDYQUESTIONID == 69953) {
        //console.log("record.QUESTIONNAME: " + record.QUESTIONNAME)

        //console.log("this.bundle.orgVal: " + this.bundle.orgVal)

      //}

    //if (this.bundle.dataEntry == true) {
    //this.disabled = false;
    //}
    //this.bundle.Tooltip01 = "Type content into this box and EITHER move mouse pointer over-out of the text box or hit the ‘TAB’ or 'ENTER' key to save.";
    //} else {
    //this.bundle.Tooltip01 = "Data entry is disabled for this question.  Either the question has been locked or signed, is read only, deleted (red), or you do not have data entry permission."
    //}

      this.LlInfoRequestsService.getQuestionChoices(done01, this.bundle);
      function done01(choices, bundle) {

        for (let i = 0; i < choices.length; i++) {
          choices[i].SELECTED = false;

          if (choices[i].QUESTIONRESULT_USER == bundle.orgVal) {
            choices[i].SELECTED = true;
          }
        }

       //if (bundle.STUDYQUESTIONID == 69953) {
          //for (let i = 0; i < choices.length; i++) {
           // console.log("choices[i].QUESTIONRESULT_USER: " + choices[i].QUESTIONRESULT_USER + " - " + choices[i].SELECTED)
            
          //}
        //}


        setTimeout(() => {
          
          bundle.showObj = true;

          setTimeout(() => {
            bundle.choices = choices
            bundle.initialLoad = false;
          });
        }, 100);

      }

    });
  }


  onChange_jqxCheckBox(event) {

    let checked = event.args.checked;
    let choice = event.currentTarget.innerText;
       
    //console.log(choice)
    //console.log(checked)
    //console.log("this.bundle.orgVal: " + this.bundle.orgVal)
    //console.log("this.bundle.initialLoad: " + this.bundle.initialLoad)

    //if (this.bundle.initialLoad == true) {
      //this.bundle.initialLoad = false;

    //} else

    //if (this.bundle.initialLoad == false && checked == true && choice != this.bundle.orgVal) {
    if (checked == true && choice != this.bundle.orgVal) {

      //if (this.bundle.STUDYQUESTIONID == 69955) {
        //console.log(choice)
        //console.log(checked)
      //}


      ////if (this.bundle.dataEntry == true && choice != this.bundle.orgVal) {

        this.bundle.QUESTIONRESULT_USER = choice;
        this.bundle.orgVal = choice;

        this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID)
        this.LlInfoRequestsService.NG_LL_infoReq_subjectQuestions_updateValue("STD", this.bundle)

      ///}

    }

    
  }    

}


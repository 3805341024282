

<login-popup></login-popup>
<error></error>
<loader-popup></loader-popup>

<!--<message-popup></message-popup>-->
<!--<loan-alarm-popup></loan-alarm-popup>-->
<!--<loan-location-edit-pu></loan-location-edit-pu>-->
<!--<loan-payeredit-popup></loan-payeredit-popup>-->
<!--<loan-process-record-popup></loan-process-record-popup>-->




<div *ngIf="launch">
  <router-outlet></router-outlet>
</div>

<ng-template #BLANK></ng-template>

<ng-template #COMPONENTS>
  <!-- <router-outlet></router-outlet>

    <subject-popup *ngIf="application == 'TR'"></subject-popup>
    <listing-selectcolumns *ngIf="application == 'TR'"></listing-selectcolumns>
    <build-dqr-popup *ngIf="application == 'TR'"></build-dqr-popup>
    <usv-edit-forms *ngIf="application == 'TR'"></usv-edit-forms>
    <build-select-popup *ngIf="application == 'TR'"></build-select-popup>
    <build-visitforms-popup *ngIf="application == 'TR'"></build-visitforms-popup>

    <company-popup *ngIf="application == 'TR'"></company-popup>

    <coding-popup *ngIf="application == 'TR'"></coding-popup>
    <question-form-popup *ngIf="application == 'TR'"></question-form-popup>
    <linked-edit-forms *ngIf="application == 'TR'"></linked-edit-forms>
    <ticket-popup *ngIf="application == 'TR'"></ticket-popup>
    <ticket-view-popup *ngIf="application == 'TR'"></ticket-view-popup>
    <visit-addschd-popup *ngIf="application == 'TR'"></visit-addschd-popup>
    <payment-addfee-popup *ngIf="application == 'TR'"></payment-addfee-popup>
    <datetime-picker *ngIf="application == 'TR'"></datetime-picker>
    <date-picker *ngIf="application == 'TR'"></date-picker>-->

</ng-template>


import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlRolesService } from '../ll-roles.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-admin-sp',
  templateUrl: './ll-admin-sp.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlAdminSpComponent implements OnInit {

  @ViewChild('mainDiv_ll_admin_sp') mainDiv: ElementRef;
  @ViewChild('vertTab_ll_admin_sp') private vertTab: ElementRef;
  @ViewChild('scrollpane_ll_admin_sp') private SP: jqxPanelComponent;
  @ViewChild('popover_search') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search') text_search: jqxTextAreaComponent;

  constructor(
    private LlScrollpaneService: LlScrollpaneService,
    private LlRolesService: LlRolesService,
    private LlPermissionsService: LlPermissionsService,

  ) { }

  bundle = {
    lable: "Categories",
    prefix: "AD",
    selectedId: -1,
    selectedId_ondeck:-1,
    showObj: true,//false,
    showRemaining: false,
    scrolling: false,
    canAdd: false,
    canFilter: false,
    searchText: "",
    LlRolesService: this.LlRolesService,
    records: [],
    origionalRecords: [],
    groups: [],
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,
    topOffset: 0,
    spY: 0,
    spW: 125,
    spH: 0,
    spB: 0,
    lineWidth: 1,
    vertLineX: 0,
    vertLineY: -1,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH: 18,
    Tooltip01: 'not Found',

  }

  ngOnInit() { }
  ngOnDestroy() { }

  ngAfterViewInit() {

    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    this.setVars();
  }

  setVars() {

    this.bundle.canAdd = false;

    if (this.LlPermissionsService.check("editModels")) {
      this.bundle.canAdd = true;
    }

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {

      this.LlScrollpaneService.standardDimentions(this.bundle);

      var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
      canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
      canvasEl.height = this.bundle.vertLineH;
      this.bundle.ctx = canvasEl.getContext('2d');

      setTimeout(() => {
        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.getData();
      });
    });
  }

  getData() {

    this.bundle.records.splice(0);

    var contactRole = this.LlRolesService.getActiveContactRole();

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("viewPending")) {
      this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Incomplete Requests", FOLDERID: "-90" });
      this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Incomplete Visual", FOLDERID: "-89" });
    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("downloadListings")
      || this.LlPermissionsService.check("buildListings")
      || this.LlPermissionsService.check("viewListings")
      || this.LlPermissionsService.check("subjectBlindListing")
    ) {

      this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Listings", FOLDERID: "-91" });
    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("addEditCompany")
      || this.LlPermissionsService.check("editUserRoles")
      || this.LlPermissionsService.check("addEditUser")) {
      this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Orgs/People", FOLDERID: "-100" });
    }

    

    if (contactRole.SYSTEMROLEID == 18) {
      ///this.bundle.records.push({ GROUP: "Admin",  FOLDERNAME: "Edit Products", FOLDERID: "-110" });
    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("viewLogInRecords")) {
      this.bundle.records.push({ GROUP: "View",  FOLDERNAME: "User Logins", FOLDERID: "-115" });

    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("viewEmailRecords")) {
      this.bundle.records.push({ GROUP: "View", FOLDERNAME: "User Emails", FOLDERID: "-116" });
    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("adminEventTrail")) {
      this.bundle.records.push({ GROUP: "View", FOLDERNAME: "Admin Trail", FOLDERID: "-120" });
      this.bundle.records.push({ GROUP: "View", FOLDERNAME: "Client Entry", FOLDERID: "-121" });
      ///this.bundle.records.push({ GROUP: "View", FOLDERNAME: "Client Comnt", FOLDERID: "-122" });
    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("adminViewErrors")) {
      this.bundle.records.push({ GROUP: "View", FOLDERNAME: "Error Log", FOLDERID: "-125" });
    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("viewEmailTemplates")
      || this.LlPermissionsService.check("editEmailTemplates")) {
      this.bundle.records.push({ GROUP: "Admin",  FOLDERNAME: "Email Templates", FOLDERID: "-129"});
    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("viewEmailRoutines")
      || this.LlPermissionsService.check("editEmailRoutines")) {
      this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Email Routines", FOLDERID: "-130" });
    }

    if (contactRole.SYSTEMROLEID == 18
      || this.LlPermissionsService.check("assignLoans")) {
      this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Model Permissions", FOLDERID: "-131" });
      this.bundle.records.push({ GROUP: "Admin", FOLDERNAME: "Role Users", FOLDERID: "-141" });
    }

    if (contactRole.SYSTEMROLEID == 18) {  ///system administratorand AR Montitoring product
      this.bundle.records.push({ GROUP: "System", FOLDERNAME: "Edit Roles", FOLDERID: "-105" });
      this.bundle.records.push({ GROUP: "System", FOLDERNAME: "Accounting", FOLDERID: "-132" });
      this.bundle.records.push({ GROUP: "System", FOLDERNAME: "AR $Models", FOLDERID: "-133" });
      this.bundle.records.push({ GROUP: "System", FOLDERNAME: "Processing", FOLDERID: "-135" });
      this.bundle.records.push({ GROUP: "System", FOLDERNAME: "Build", FOLDERID: "-140" });
      this.bundle.records.push({ GROUP: "System", FOLDERNAME: "Upload CMS Docs", FOLDERID: "-250" });
    }


    this.bundle.origionalRecords = this.bundle.records;
    this.bundle.groups.splice(0);

    for (let i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.groups.indexOf(this.bundle.records[i].GROUP) == -1) {
        this.bundle.groups.push(this.bundle.records[i].GROUP);
      }
    }

    let selectedId_initial = this.bundle.records[0].FOLDERID;

    setTimeout(() => {

      //console.log("selectedId_initial: " + selectedId_initial)

      this.bundle.showObj = true;

      setTimeout(() => {

        if (selectedId_initial != null && document.getElementById(this.bundle.prefix + selectedId_initial.toString()) != null) {
          document.getElementById(this.bundle.prefix + selectedId_initial.toString()).click();
        }

      });
    });    
  }

  select(event) {

    //console.log("select")

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    if (this.bundle.scrolling == true) {

      this.bundle.scrolling = false;

    } else {

      this.bundle.showRemaining = false;           

      setTimeout(() => {
        this.bundle.selectedId = this.bundle.selectedId_ondeck;

       ///console.log("this.bundle.selectedId: " + this.bundle.selectedId)

        this.bundle.showRemaining = true;
      });
    }
  }

  noAction() {
    //needed HTML buttons point to this 
  }

  selectView(targetView) {
    //this.bundle.CONFIG = targetView.toLowerCase();
    //this.UserService.setUserPreference("TraditionalOrTile_config", targetView, 1, "edit");
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }

  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);

        if (this.bundle.records.length > 0) {
          this.bundle.selectedId = this.bundle.records[0].FOLDERID;
        }
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].CATID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].CATID;
        //this.LlModelsService.setActiveCategory(this.bundle.selectedId, 'SP');

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {


    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].FOLDERID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }



}


import { Component, AfterViewInit, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
//import { UserChangeService } from './services/user-change.service';
import { UserService } from './services/user.service';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnDestroy{

  constructor(
    //private UserChangeService: UserChangeService, 
    private UserService: UserService, 
    private router: Router,
    ) {}

  launch = false;
  application = "";

  eventSubscription_newSubjectPU:any;

  ngOnDestroy() {
    this.eventSubscription_newSubjectPU.unsubscribe();
  }

  ngAfterViewInit(){   
    this.eventSubscription_newSubjectPU = this.UserService.changeUser.subscribe(isOpen => {

      //if(this.launch == false){

        setTimeout(() => {
          //this.launch = true;
          
          var user = this.UserService.getUser(); 

          if(user != null){
            this.launch = true;

            //let application = user[0].APPLICATIONVERSION.substring(0, 2);

            ///console.log("application: " + application)

            //if (application == "TR" || application == "SR") {

              //this.router.navigate(['/trapp']);
        
            //}else if(application == "AR"){ 
              //this.router.navigate(['/arapp']);
            
            //} else if (application == "LL") {
              this.router.navigate(['/LLRoleSwitchComponent']);
            //}

          }

        });
      //}
    });
  }
}

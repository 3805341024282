<div #mainDiv_ll_admin_newsletters_sp>

  <canvas #vertTab_ll_admin_newsletters_sp></canvas>

  <div *ngIf="bundle.showObj">

    <div class="spLabel" [ngStyle]="{'top.px':bundle.lblY, 'width.px':bundle.spW}">
      {{bundle.lable}}
    </div>

    <jqxPanel #scrollpane_ll_admin_newsletters_sp
              [style.top.px]="bundle.spY"
              style="background-color: transparent;
              border: none;
              outline: none;
              border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <tr *ngFor="let item of bundle.records" style="white-space: nowrap;">

        <img *ngIf="item.CONTACTID > 0" class="editGear" [id]="'GR' + item.FOLDERID" src="assets/icon_gear.png" alt="" (click)="edit($event)">

        <div class="spRowLabel"
             [id]="bundle.prefix + item.FOLDERID"
             title={{item.FOLDERNAME}}
             [style.width.px]="bundle.spW - 25" (click)="select($event)">
          {{item.FOLDERNAME}}
        </div>

      </tr>

    </jqxPanel>

    <!--BOTTOM-->
    <div id=bottomLine [style.top.px]="bundle.spB">

      <div id=add_ll_admin_newsletters_sp *ngIf="bundle.canAdd" style="padding-top:10px;">

        <div class="wordBtnGroup">
          <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_plus.png" alt="" (click)="noAction()">
          <div class="wordBtn" (click)="noAction()">Add Newsletter</div>
        </div>

        <jqxTooltip style="float: left;"
                    [position]="'top'"
                    [name]="'movieTooltip'"
                    [autoHideDelay]="30000"
                    [content]="'Click to add'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn_12" src="assets/infoBtn.png" />

        </jqxTooltip>

        <jqxPopover #popover_ll_admin_newsletters_sp style="font-size: 14px; font-family: Verdana;"
                    [offset]='{ left: 50, top: 0 }'
                    [arrowOffsetValue]="-30"
                    [width]="500"
                    [height]="200"
                    [position]="'top'"
                    [title]=""
                    [showCloseButton]="true"
                    [selector]="'#add_ll_admin_newsletters_sp'">

          <div style="position:absolute; top:50px; left:30px;  ">
            <img class="btnIcon" height=25 style="width: auto;" src="assets/icon_plus.png" alt="" (click)="noAction()">
          </div>

          <div style="position:absolute; top:50px; left:70px; ">

            <div class="txtBold" style="width:475px;">
              Add Newsletter
            </div>

            <!--Group Name-->
            <div style="clear: left; padding-top:10px; ">

              <div>
                <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
              </div>

              <div style="float: left; padding-left:10px; width:100px;">Newsletter Name:</div>

              <div style="float: left; padding-left:10px;">
                <jqxTextArea #text_ll_admin_newsletters_sp
                             [width]="225"
                             [height]="25"
                             [placeHolder]="'Type new routine name'">
                </jqxTextArea>
              </div>
            </div>

            <!--Message-->
            <div style="clear: left; padding-top:10px; color:red;">
              {{bundle.newMsg}}
            </div>

            <div class="wordBtnGroup_popover" style="float:left; padding-top:10px; padding-left:250px; ">
              <div class="wordBtn" (click)="add()">Click to Add</div>
            </div>

          </div>

        </jqxPopover>

      </div>


    </div>

    <!--SEARCH-->
    <div id=search_ll_admin_newsletters_sp *ngIf="bundle.canFilter"
         [style.top.px]="bundle.lblY - 0"
         [style.width.px]="bundle.spW"
         [style.left.px]="bundle.spW  - 10 "
         style="position: absolute;">

      <div class="wordBtnGroup">
        <div *ngIf="bundle.searchText  == '' ">
          <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
        </div>
        <div *ngIf="bundle.searchText  != '' ">
          <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
        </div>
      </div>

      <jqxPopover #popover_search
                  [offset]='{ left: -85, top: 0 }'
                  [arrowOffsetValue]="-0"
                  [width]="220"
                  [position]="'top'"
                  [title]="'Filter Categories'"
                  [selector]="'#search_ll_admin_newsletters_sp'">

        <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">

          <div (keyup)="onKeyUp($event)" style="float: left; ">
            <jqxTextArea #text_search
                         [width]="140"
                         [height]="25"
                         [placeHolder]="'Type Search'">

            </jqxTextArea>
          </div>

          <div style="float: left; padding-left:10px; padding-top:0px; ">
            <div style="clear:left">
              <div class="wordBtn" (click)="search()">Search</div>
            </div>
            <div style="clear:left">
              <div class="wordBtn" (click)="clear()">Clear</div>
            </div>
          </div>
        </div>

      </jqxPopover>
    </div>

  </div>

  <!-- horzTabs_ll_admin_email_templates_sp  TABS ACROSS THE TOP-->
  <div [hidden]="!bundle.showObj"
       [style.top.px]="bundle.vertLineY - bundle.hTabH - 3"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

    <canvas #horzTabs_ll_admin_newsletters_sp></canvas>

  </div>

  <!-- editMode-->
  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.activeHorzTab == 'View'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

      <ll-admin-newsletter-view id="view"></ll-admin-newsletter-view>
  </div>

  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.activeHorzTab == 'Edit'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

       <ll-admin-newsletter-view id="edit"></ll-admin-newsletter-view>
  </div>

  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.activeHorzTab == 'HTML'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

      <ll-admin-newsletter-view id="HTML"></ll-admin-newsletter-view>
  </div>




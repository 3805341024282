<div #metricHeader>

  <div *ngIf="bundle.showObj" [style.width.px]="bundle.questionW">
    <ll-info-request-entry-question id={{bundle.STUDYQUESTIONID}}></ll-info-request-entry-question>
  </div>


  <div style="float:left;" >

    <div *ngFor="let choice of bundle.choices">

      <jqxCheckBox [width]="bundle.valueRow01W"
                   [height]="25"
                   [theme]="'material-green'"
                   [checked]="choice.SELECTED"
                   [groupName]="bundle.STUDYQUESTIONID"
                   (onChange)='onChange_jqxCheckBox($event)'
                   [disabled]="!bundle.dataEntry">
        <span class="txtNorm12">{{choice.QUESTIONRESULT_USER}}</span>

      </jqxCheckBox>


      <!--<jqxRadioButton [width]="bundle.valueRow01W"
                  [theme]="'material-green'"
                  [checked]="choice.SELECTED"
                  [groupName]="bundle.STUDYQUESTIONID"
                  (onChange)='onChange_jqxCheckBox($event)'
                  [disabled]="!bundle.dataEntry" >

    <span class="txtNorm12">{{choice.QUESTIONRESULT_USER}}</span>
  </jqxRadioButton>-->


    </div>
  </div>

 <!-- <div *ngIf="!bundle.dataEntry" style="float:left;" >
    <div class="txtNorm12" *ngFor="let choice of bundle.choices; index as i" >

      <jqxCheckBox [width]="300"
                   [height]="25"
                   [theme]="'material-green'"
                   [checked]="choice.SELECTED"
                   [groupName]="bundle.STUDYQUESTIONID"
                   [disabled]="'true'"
                   (onChange)='onChange_jqxCheckBox($event)'>

        <span class="txtNorm12" style="float:left; ">{{choice.QUESTIONRESULT_USER}}</span>



      </jqxCheckBox>
    </div>
  </div>-->
</div>

import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { jqxChartComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxchart";
import { stringToNumber } from "../utility";
import { formatGraphNumber } from "../utility";

@Component({
  selector: 'll-model-graph-one',
  templateUrl: './ll-model-graph-one.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelGraphOneComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_graph_one') private mainDiv: ElementRef;
  @ViewChild('myChart_line') myChart: jqxChartComponent;

  constructor(
    private LlModelsService: LlModelsService,
  ) {

  }

  bundle = {

    title:'',
    showDisplay: false,
    showObj: true,

    parentW: 0,
    parentH: 0,
    statsX:0,
    statsY: 0,
    statsW: 110,
    statsH: 0,
    
    graphX: 0,
    graphY: 0,
    graphW: 0,
    graphH: 0,

    records: [],
    statPackage: {
      COUNT: 0,
      MEAN_formated: 0,
      SLOPE_formated: 0,
      SLOPEPCT: 0,
    },

    GRAPHDATA_INDENT: 100,
    FITTED_LINE_TIP: "",
    SLOPE_TIP: "",
    SLOPEPCT_TIP: "",
    COUNT_TIP: "",
    MEAN_TIP: "",
    objH: 0,
    objW: 0,

  }

  ngOnDestroy() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {

    this.bundle.showDisplay = false;

    setTimeout(() => {

      this.bundle.FITTED_LINE_TIP = "The regression (fitted) line depicts the linear (average) relationship between the graph values over time. Can be used to estimate the future values."
      this.bundle.SLOPE_TIP = "Slope: Statistical increase in value for each new day"
      this.bundle.SLOPEPCT_TIP = "Slope Percent: The Slope divided by the Mean (Average) of the values in the data count.   Determines the relative severity of the slope."
      this.bundle.COUNT_TIP = "Count: The number of individual values included the related formulas."
      this.bundle.MEAN_TIP = "Mean (Average):  Sum of the individual values divided by the count."

      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      this.bundle.statsX = this.bundle.parentW - this.bundle.statsW;

      this.bundle.graphW = this.bundle.statsX - 0
      this.bundle.graphH = this.bundle.parentH;

      this.getData()
    });
  }

  getData() {

    //console.log("getData()")

    this.LlModelsService.NG_LL_model_AR_displayData_graph_one_data_get(done01, this.bundle, this.myChart);

    function done01(arr, bundle, myChart) {

      //console.log(arr)

      bundle.records.splice(0);

      if (arr.length > 0) { 

        bundle.records = arr;
        bundle.statPackage = bundle.records[0];



        let DISPLAYFORMAT = bundle.records[0].DISPLAYFORMAT;
        bundle.statPackage.MEAN_formated = stringToNumber(bundle.statPackage.MEAN, DISPLAYFORMAT, 0);
        bundle.statPackage.SLOPE_formated = stringToNumber(bundle.statPackage.SLOPE, DISPLAYFORMAT, 2);

        for (let i = 0; i < bundle.records.length; i++) {

          bundle.records[i].value01 = bundle.records[i].Y;
          bundle.records[i].value02 = 0;

          if (bundle.records[i].COLYN.toLowerCase() == 'y') {
            bundle.records[i].value01 = 0
            bundle.records[i].value02 = bundle.records[i].Y;
          }
        }

        let title = bundle.records[0].LABEL;

        //let title = '';
        let description = '';

        let padding: any = { left: 5, top: 5, right: 40, bottom: 0 };
        let titlePadding: any = { left: 90, top: 0, right: 0, bottom: 0 };

        let xAxis: any = {
          dataField: 'DATE',
          //textRotationAngle: -25,
          //textRotationAngle: -45,
          //textRotationAngle: -20,
          textRotationAngle: 0,
          gridLines: { visible: false }
        };

        let valueAxis: any = {

          description: '',
          visible: true,

          formatFunction: (value: any) => {
            return formatGraphNumber(value, DISPLAYFORMAT);
          }

        };

        let seriesGroups: any[] =
          [
            {
              type: 'stackedcolumn',
              columnsGapPercent: 0,
              seriesGapPercent: 0,

              series: [
                {
                  dataField: 'value01', showLabels: false, columnsMinWidth: 3, columnsMaxWidth: 3, formatFunction: (value: any) => {
                    return formatGraphNumber(value, DISPLAYFORMAT);
                  }, colorFunction: (value: any, itemIndex: any, serie: any, group): any => {
                    return '#999999'
                  }
                },

                {
                  dataField: 'value02', showLabels: false, columnsMinWidth: 3, columnsMaxWidth: 3, formatFunction: (value: any) => {
                    return formatGraphNumber(value, DISPLAYFORMAT);
                  }, colorFunction: (value: any, itemIndex: any, serie: any, group): any => {
                    return '#55CC55'
                  }
                },
              ]

            },

            ///slope line
            {
              type: 'line', alignEndPointsWithIntervals: false,
              series: [
                {
                  dataField: 'SLOPEY', displayText: 'Direction', opacity: 1.0, lineWidth: 1, color: '#FF0000', formatFunction: (value: any) => {
                    return formatGraphNumber(value, DISPLAYFORMAT);
                  }
                }
              ]
            }


          ];

        setTimeout(() => {
          myChart.title(title);
          myChart.description(description);
          myChart.padding(padding);
          myChart.titlePadding(titlePadding);
          myChart.xAxis(xAxis);
          myChart.valueAxis(valueAxis);
          myChart.seriesGroups(seriesGroups);
          myChart.source(bundle.records);
          myChart.refresh();

          bundle.showDisplay = true;

        });

      }
    }
  }



}


<div #mainDiv_ll_model_displayData>


  <div *ngIf="bundle.navigation_show"
       [style.top.px]="bundle.horzTabsY + 5"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.navArrowColW"
       [style.height.px]="40"
       style="position: absolute;z-index:100">

    <div *ngIf="bundle.diveLevel != 'view'" >
      <div class="txtBold_13" >Dive-Filter</div>

      <div class="wordBtnGroup" >
        <img class="btnIcon" height=23 style="width: auto;" src="assets/icon_backArrow.png" alt="" (click)="backToView()">
        <div class="wordBtn13" style="padding-left: 5px; padding-top: 3px; white-space: nowrap;" (click)="backToView()">Back</div>
      </div>
    </div>

    <div *ngIf="bundle.diveLevel == 'view'" style="width: 10px; padding-left: 15px; padding-top: 0px;">
      <div class="txtBold_13" >Model View</div>
    </div>

  </div>

  <div *ngIf="bundle.navigation_show"
       [style.top.px]="bundle.horzTabsY"
       [style.left.px]="bundle.navArrowColW - 20 "
       [style.width.px]="bundle.navAddressColW"
       [style.height.px]="50"
       style="position: absolute; border: .5px solid #ccc; padding-left: 5px; padding-right: 5px; padding-top: 5px; ">

    <div style="float: left; padding-left: 0px; padding-top: 0px;">

      <!--Location Filter-->
      <div style="clear: left; padding-left: 0px;">

        <div class="txtBold_13" style="float: left; padding-left: 0px; padding-top: 0px; white-space: nowrap;">
          Location Filter:
        </div>

        <div *ngIf="bundle.diveLevel == 'division'" (click)="selectDive('Division')" class="wordBtn_14UL" style="float: left; padding-left: 10px;">
          Division
        </div>

        <div *ngIf="bundle.diveLevel != 'division'" (click)="selectDive('Division')" class="wordBtn14" style="float: left; padding-left: 10px;">
          Division
        </div>

        <div *ngIf="bundle.divisionName == ''" class="txtNorm13" style="float: left; padding-left: 5px;">
          : All
        </div>

        <div *ngIf="bundle.divisionName != ''" style="float: left; padding-top: 0px; padding-left: 0px; white-space: nowrap;">

          <div class="txtNorm13" style="float: left; padding-left: 5px; white-space: nowrap;">
            : {{bundle.divisionName}}
          </div>

          <div class="wordBtnGroup" style="float: left; padding-left: 2px; ">
            <img class="btnIcon" height=8 style="width: auto;" src="assets/icon_removeX.png" alt="" (click)="removeFilter('division')">
          </div>
        </div>

        <div *ngIf="bundle.diveLevel == 'region'" (click)="selectDive('Region')" class="wordBtn_14UL" style="float: left; padding-left: 10px;">
          Region
        </div>

        <div *ngIf="bundle.diveLevel != 'region'" (click)="selectDive('Region')" class="wordBtn14" style="float: left; padding-left: 10px;">
          Region
        </div>

        <div *ngIf="bundle.regionName == ''" class="txtNorm13" style="float: left; padding-left: 5px;">
          : All
        </div>

        <div *ngIf="bundle.regionName != ''" style="float: left; padding-top: 0px; padding-left: 0px; white-space: nowrap;">

          <div class="txtNorm13" style="float: left; padding-left: 5px; white-space: nowrap;">
            : {{bundle.regionName}}
          </div>

          <div class="wordBtnGroup" style="float: left; padding-left: 2px; ">
            <img class="btnIcon" height=8 style="width: auto;" src="assets/icon_removeX.png" alt="" (click)="removeFilter('region')">
          </div>
        </div>

        <div *ngIf="bundle.diveLevel == 'state'" (click)="selectDive('State')" class="wordBtn_14UL" style="float: left; padding-left: 10px;">
          State
        </div>

        <div *ngIf="bundle.diveLevel != 'state'" (click)="selectDive('State')" class="wordBtn14" style="float: left; padding-left: 10px;">
          State
        </div>

        <div *ngIf="bundle.stateName == ''" class="txtNorm13" style="float: left; padding-left: 5px;">
          : All
        </div>

        <div *ngIf="bundle.stateName != ''" style="float: left; padding-top: 0px; padding-left: 0px; white-space: nowrap;">

          <div class="txtNorm13" style="float: left; padding-left: 5px; white-space: nowrap;">
            : {{bundle.stateName}}
          </div>

          <div class="wordBtnGroup" style="float: left; padding-left: 2px; ">
            <img class="btnIcon" height=8 style="width: auto;" src="assets/icon_removeX.png" alt="" (click)="removeFilter('state')">
          </div>
        </div>

        <div *ngIf="bundle.diveLevel == 'location'" (click)="selectDive('Location')" class="wordBtn_14UL" style="float: left; padding-left: 10px;">
          Location
        </div>

        <div *ngIf="bundle.diveLevel != 'location'" (click)="selectDive('Location')" class="wordBtn14" style="float: left; padding-left: 10px;">
          Location
        </div>

        <div *ngIf="bundle.locationName == ''" class="txtNorm13" style="float: left; padding-left: 5px;">
          : All
        </div>

        <div *ngIf="bundle.locationName != ''" style="float: left; padding-top: 0px; padding-left: 0px; white-space: nowrap;">

          <div class="txtNorm13" style="float: left; padding-left: 5px; white-space: nowrap;">
            : {{bundle.locationName}}
          </div>

          <div class="wordBtnGroup" style="float: left; padding-left: 2px; ">
            <img class="btnIcon" height=8 style="width: auto;" src="assets/icon_removeX.png" alt="" (click)="removeFilter('location')">
          </div>
        </div>

      </div>

      <!--Level Filter:-->
      <div [style.width.px]="bundle.navAddressColW" style="clear: left; padding-left: 0px; padding-bottom: 5px; white-space: nowrap;">

        <div class="txtBold_13" style="float: left; padding-left: 0px; padding-top: 0px; white-space: nowrap;">
          Level Filter:
        </div>

        <!--CLASS-->
        <div *ngIf="bundle.diveLevel == 'class'" (click)="selectDive('class')" class="wordBtn_14UL" style="float: left; padding-left: 10px;">
          Class
        </div>

        <div *ngIf="bundle.diveLevel != 'class'" (click)="selectDive('class')" class="wordBtn14" style="float: left; padding-left: 10px;">
          Class
        </div>

        <div *ngIf="bundle.className == ''" class="txtNorm13" style="float: left; padding-left: 5px;">
          : All
        </div>

        <div *ngIf="bundle.className != ''" style="float: left; padding-top: 0px; padding-left: 0px; white-space: nowrap;">

          <div class="txtNorm13" style="float: left; padding-left: 5px; white-space: nowrap;">
            : {{bundle.className}}
          </div>

          <div class="wordBtnGroup" style="float: left; padding-left: 2px; ">
            <img class="btnIcon" height=8 style="width: auto;" src="assets/icon_removeX.png" alt="" (click)="removeFilter('class')">
          </div>
        </div>


        <div *ngIf="bundle.className != '' || bundle.locationName != '' || bundle.stateName != '' || bundle.regionName != '' || bundle.divisionName != ''">

          <div *ngIf="bundle.diveLevel == 'payer'" (click)="selectDive('Payer')" class="wordBtn_14UL" style="float: left; padding-left: 10px;">
            Payer
          </div>

          <div *ngIf="bundle.diveLevel != 'payer'" (click)="selectDive('Payer')" class="wordBtn14" style="float: left; padding-left: 10px;">
            Payer
          </div>

          <div *ngIf="bundle.payerName == ''" class="txtNorm13" style="float: left; padding-left: 5px;">
            : All
          </div>

          <div *ngIf="bundle.payerName != ''" style="float: left; padding-top: 0px; padding-left: 0px;">

            <div class="txtNorm13" style="float: left; padding-left: 5px; white-space: nowrap;">
              : {{bundle.payerName}}
            </div>

            <div class="wordBtnGroup" style="float: left; padding-left: 2px; ">
              <img class="btnIcon" height=8 style="width: auto;" src="assets/icon_removeX.png" alt="" (click)="removeFilter('payer')">
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>




  <!--<div *ngIf="bundle.showDisplay">--->

    <!--GRAPH-->
    <div *ngIf="bundle.graphArea_show"
         [style.top.px]="bundle.graphAreaY"
         [style.left.px]="0"
         [style.width.px]="bundle.parentW"
         [style.height.px]="bundle.graphAreaH"
         style="position: absolute; ">
      <ll-model-graph-one></ll-model-graph-one>
    </div>

  <!--</div>--->

  <div>

    <!--Choices-->
    <div [style.left.px]="bundle.choicesX + 20"
         [style.top.px]="bundle.choicesY"
         style="position: absolute;">

      <!--DayChoice-->
      <!---<div style="float: left;">

        <jqxTooltip [position]="'left'"
                    [name]="'DAY_CHOICE_TIP'"
                    [autoHideDelay]="30000"
                    [content]="'TEST'"
                    [opacity]="1.0"
                    [width]="400">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

      </div>--->

      <div class="txtBold_12" style="float: left; padding-left: 0px;">
        Through Date:
      </div>

      <div style="float: left; padding-top: 5px; padding-left: 0px;">
        <jqxDateTimeInput #date_THROUGHDATE
                          [width]="100"
                          [height]="25"
                          [dropDownHorizontalAlignment]="'right'"
                          [formatString]="'d'"
                          (onChange)="selectThroughDate()">
        </jqxDateTimeInput>
      </div>

      <div style="clear: left; padding-top: 10px;">
        <div class="txtBold_12" style="float: left; padding-left: 0px;">
          Get Days:
        </div>

        <div style="float: left; padding-left: 0px;">

          <jqxTooltip [position]="'left'"
                      [name]="'COUNT_TIP'"
                      [autoHideDelay]="30000"
                      [content]="'Select to view all, or only business days in the months'"
                      [opacity]="1.0"
                      [width]="400">

            <img class="infoBtn" src="assets/infoBtn.png" />

          </jqxTooltip>

        </div>

        <div *ngIf="bundle.showChoices" style="clear: left; padding-left:5px;">

          <div *ngFor="let dayChoice of bundle.dayChoices" style="clear: left; padding-left: 0px; ">

            <div *ngIf="dayChoice.SELECTED" [id]="dayChoice.code" (click)=selectDayChoice($event) class="wordBtn_12UL" style="float: left; padding-left: 5px;">
              {{dayChoice.choice}}
            </div>

            <div *ngIf="!dayChoice.SELECTED" [id]="dayChoice.code" (click)=selectDayChoice($event) class="wordBtn_12" style="float: left; padding-left: 5px;">
              {{dayChoice.choice}}
            </div>

          </div>
        </div>
      </div>
      <!--column choices-->
      <!---<div style="clear: left;">

        <jqxTooltip [position]="'left'"
                    [name]="'COLUMN_CHOICE_TIP'"
                    [autoHideDelay]="30000"
                    [content]="'TEST'"
                    [opacity]="1.0"
                    [width]="400">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>
      </div>--->

      <div class="txtBold_12" style="float: left; padding-left: 0px;">
        Columns:
      </div>

      <div style="float: left; padding-left: 0px;">

        <jqxTooltip [position]="'left'"
                    [name]="'COLUMN_CHOICE_TIP'"
                    [autoHideDelay]="30000"
                    [content]="'Select columns for 30 day jump back, end of month, or same business day in the months'"
                    [opacity]="1.0"
                    [width]="400">

          <img class="infoBtn" src="assets/infoBtn.png" />

        </jqxTooltip>

      </div>

      <div *ngIf="bundle.columnChoices" style="clear: left; padding-left:5px;">

        <div *ngFor="let columnChoice of bundle.columnChoices" style="clear: left; padding-left: 0px; ">

          <div *ngIf="columnChoice.SELECTED" [id]="columnChoice.code" (click)=selectColumnChoice($event) class="wordBtn_12UL" style="float: left; padding-left: 5px;">
            {{columnChoice.choice}}
          </div>

          <div *ngIf="!columnChoice.SELECTED" [id]="columnChoice.code" (click)=selectColumnChoice($event) class="wordBtn_12" style="float: left; padding-left: 5px;">
            {{columnChoice.choice}}
          </div>

        </div>
      </div>
    </div>



    <div *ngFor="let date of bundle.dates;">

      <div [style.left.px]="date.colX + 0"
           [style.top.px]="bundle.displayAreaY - 20"
           [style.width.px]="bundle.dateColumnW + 20"
           style="position: absolute;
         text-align: center;
         font-size: 12px;
         font-weight: bold;
         font-family: Arial;
         color:black; ">

        {{date.FORMAT_US}}

      </div>
    </div>

    <!--DISPLAY AREA-->
    <div [style.left.px]="bundle.displayAreaX"
         [style.top.px]="bundle.displayAreaY"
         style="position: absolute;">

      <jqxPanel #displayArea
                [width]="bundle.displayAreaW"
                [height]="bundle.displayAreaH"
                [autoUpdate]="true"
                style="position: absolute; white-space: nowrap; outline: none;  border-bottom: 2px solid #ccc;">

        <!--METRIC ROW LABLES - LOOP THROUGH FORMS-->
        <div *ngFor="let form of bundle.forms; index as i" style="white-space: nowrap;">

          <div [style.height.px]="0"></div>

          <div class="arLoanForm"
               [style.width.px]="bundle.displayAreaW"
               [style.height.px]="20"
               style="border-bottom: 1px solid white; ">

            {{form.FORMNAME}}

          </div>

          <!--LOOP THROUGH AND FILTER OUT FORM ELEMENTS-->
        <tr *ngFor="let row of bundle.records | visitCategory:{FORMID: form.FORMID}" style="clear:left; white-space:nowrap;">

          <!--METRIC ROWS-->
          <div [id]="row.ELEMENT"
               [style.left.px]="0"
               [style.width.px]="bundle.displayAreaW"
               [style.height.px]="20"
               style=" border-bottom: 1px solid white; ">

            <!--METRIC LABELS-->
            <div style="float: left;">

              <div *ngIf="bundle.diveLevel == 'view'" style="float: left; padding-left:0px ">
                <jqxTooltip [position]="'right'"
                            [name]="'movieTooltip'"
                            [autoHideDelay]="30000"
                            [content]="row.DESCRIPTION"
                            [opacity]="1.0"
                            [width]="400">

                  <img class="infoBtn" src="assets/infoBtn.png" />

                </jqxTooltip>
              </div>

              <!--<div style="float:left; padding-left:4px "><img [id]="element.ELEMENT" src="assets/icon_magGlass.png" alt="" (click)="selectDive($event)" style="height:10px; width:auto;cursor:pointer;"></div>-->
              <!--<div style="float: left; padding-left:4px ">
                <img [id]="row.ELEMENT" src="assets/icon_chart_bar.png" alt="" (click)="selectElement($event)" style="height:12px; width:auto;cursor:pointer;">
              </div>-->

              <div *ngIf="bundle.diveLevel != 'view'" [id]="row.ELEMENT" style="float: left; padding-left: 4px; padding-right: 2px ">

                <div *ngIf="row.ELEMENT >0 " style="float: left;">
                  <jqxCheckBox [width]="22"
                               [height]="20"
                               [groupName]="'FORMID'"
                               [id]="row.ELEMENT"
                               (onChange)='selectDiveElement($event, row.ELEMENT)'>
                  </jqxCheckBox>

                </div>

                <div *ngIf="row.ELEMENT > 0 " [id]="row.ELEMENT" class="wordBtn_12" (click)="selectElement($event)"
                     [style.width.px]="bundle.metricLabelsW - 20" style="float: left; padding-top: 2px; padding-left: 4px; white-space: nowrap; overflow: hidden;
                      text-overflow: ellipsis; cursor: pointer; ">
                  {{row.LABEL}}
                </div>

                <div *ngIf="row.ELEMENT < 0 " style="float: left;">
                  <div [id]="row.ELEMENT" class="txtBold_12"
                       [style.width.px]="bundle.metricLabelsW - 20" style="float: left; padding-top: 2px; padding-left: 4px; white-space: nowrap; overflow: hidden;
                        text-overflow: ellipsis;">
                    {{row.LABEL}}
                  </div>

                </div>
              </div>

              <div *ngIf="bundle.diveLevel == 'view'" [id]="row.ELEMENT" class="wordBtn_12" (click)="selectElement($event)"
                   [style.width.px]="bundle.metricLabelsW - 20" style="float: left; padding-top: 2px; padding-left: 4px; white-space: nowrap; overflow: hidden;
                          text-overflow: ellipsis; cursor: pointer; ">
                {{row.LABEL}}
              </div>

            </div>

            <!--COLUMN VALUES-->
            <div *ngFor="let date of bundle.dates">

              <div [style.left.px]="date.colX"
                   [style.width.px]="bundle.dateColumnW"
                   style="position: absolute;">

                <div *ngIf="row.UNDERLINE == 1"
                     [style.left.px]="-10"
                     [style.top.px]="2"
                     [style.width.px]="bundle.dateColumnW"
                     style="position: absolute;
                             text-align: right;
                             font-size: 12px;
                             font-family: Arial;
                             color:black;
                             text-decoration: overline;">

                  {{row[date.COLUMNNAME]}}
                </div>

                <div *ngIf="row.UNDERLINE != 1"
                     [style.left.px]="-10"
                     [style.top.px]="2"
                     [style.width.px]="bundle.dateColumnW"
                     style="position: absolute;
                             text-align: right;
                             font-size: 12px;
                             font-family: Arial;
                             color:black; ">

                  {{row[date.COLUMNNAME]}}

                </div>

              </div>
            </div>

          </div>
        </tr>


    </div>

    </jqxPanel>




  </div>

  <!--BOTTOM-->
  <div [style.left.px]="bundle.displayAreaX"
       [style.top.px]="bundle.displayAreaY + bundle.displayAreaH + 10"
       style="position: absolute;">

    <div class="wordBtn_11" (click)="downloadCSVReport()">Download This View (CSV)</div>

    <div class="wordBtn_11" style="float: left; padding-left:20px" (click)="downloadAllDatesCSVReport()">Download All Dates (CSV)</div>
  </div>



</div>

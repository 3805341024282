<div id='errorMsg'>

  <div *ngIf="bundle.openMsg; then MESSAGE; else BLANK"></div>

  <ng-template #BLANK></ng-template>

  <ng-template #MESSAGE>

    <div class="bkgrd_warning"></div>

    <div class="holder">
      <div class="subHolder_warning">

        <div class="container_warning" style="width:520px; height:160px;  ">
          <div style="padding:20px; ">

            <img height=50 style="float: left; width: auto; float: left; padding-right:30px;" src="assets/icon_hermes.png">

            <div style="float: left;">
              <div class="txtBold" style="width:350px; clear: left;">
                {{bundle.msg01}}
              </div>

              <!--<div class="txtNorm" style="width:350px; clear: left;">
                {{bundle.msg02}}
              </div>-->

              <div class="wordBtn" style="clear: left; padding-top:15px; padding-left:300px" (click)="close()">
                Close
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  </ng-template>

</div>


<div>
    
    <!--<div *ngIf="show; then LOADER; else BLANK"></div>-->
    
    <!--<ng-template #BLANK ></ng-template>-->

    <!--<ng-template #LOADER>-->
    <div #LOADER [hidden]="!bundle.show" >
      <div class = "bkgrd">
      </div>

      <div class = "holder">
        <div class = "subHolder">
          <div class="loader"></div>

        </div>
        
      </div>
    </div>  
    <!--</ng-template>-->
</div> 
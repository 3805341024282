import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LlMainCanvasComponent } from './app_LL/ll-main-canvas/ll-main-canvas.component';
import { LlMainRoleDgComponent } from './app_LL/ll-main-role-dg/ll-main-role-dg.component';

const routes: Routes = [
  {path: '', component: HomeComponent },
  { path: 'LLRoleSwitchComponent', component: LlMainRoleDgComponent },
  { path: 'LlMainCanvasComponent', component: LlMainCanvasComponent },
      {path: '**',component: NotFoundComponent},
];

@NgModule({imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    enableTracing: false
})],
  exports: [RouterModule]
})

export class AppRoutingModule {}

<div #mainDiv_ll_contact_switch>

  <!--<jqxPanel style="white-space: nowrap; background-color: transparent; border: none;  border-bottom: 2px solid  rgb(160, 158, 158);"
            [autoUpdate]="true"
            [width]="bundle.remainingW"
            [height]="bundle.remainingH">--->
  <!-- horzTabs_ll_company_switch  TABS ACROSS THE TOP-->
  <div [hidden]="!bundle.showHorzTabs"
       [style.top.px]="bundle.horzTabsY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;z-index:100">

    <canvas #horzTabs_ll_contact_switch></canvas>

  </div>



  <!-- ll-contact-info-->
  <div *ngIf="bundle.showRemaining && bundle.hTabIndx == 0"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

      <ll-contact-info></ll-contact-info>
  </div>

  <div *ngIf="bundle.showRemaining && bundle.hTabIndx == 1"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-admin-generic-dg id="edit-contact-roles-dg"></ll-admin-generic-dg>
  </div>

  <div *ngIf="bundle.showRemaining && bundle.hTabIndx == 2"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-admin-generic-dg id="edit-contact-permissions-dg"></ll-admin-generic-dg>
  </div>

  <!-- ll-company-info-->
  <div *ngIf="bundle.showRemaining && bundle.hTabIndx == 3"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <!--<ll-company-models-sp></ll-company-models-sp>--->
  </div>


  <!--</jqxPanel>--->

</div>

import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlUtilityService } from '../ll-utility.service';
///import { LlPermissionsService } from '../ll-permissions.service';
import { jqxDateTimeInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { jqxDropDownListComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownlist";

import { allowOnlyNumbers } from "../utility";
import { STDDate_to_CCYYMMDD } from "../utility-date";

@Component({
  selector: 'll-admin-email-routines-edit',
  templateUrl: './ll-admin-email-routines-edit.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlAdminEmailRoutinesEditComponent {

  @ViewChild('mainDiv_email_routine') private mainDiv: ElementRef;
  @ViewChild('text_routineName') text_routineName: jqxDateTimeInputComponent;
  @ViewChild('date_start') date_start: jqxDateTimeInputComponent;
  @ViewChild('date_end') date_end: jqxDateTimeInputComponent;
  @ViewChild('text_specificDay_01') text_specificDay_01: jqxTextAreaComponent;
  @ViewChild('text_specificDay_02') text_specificDay_02: jqxTextAreaComponent;
  @ViewChild('dropDown_emailTemplate') dropDown_emailTemplate: jqxDropDownListComponent;

  constructor(
    private LlUtilityService: LlUtilityService,
  ) {

  }

  bundle = {
    location: "",
    showDisplay: true,
    showObj: true,
    showEmailTemplate: false,
    canBuild: false,
    parentHeight: 0,
    SYSTEMEMAILROUTINEID: 0,
    LlUtilityService: this.LlUtilityService,
    spB: 0,
    objW: 0,
    objH: 0,
    SPX: 10,
    SPY: 80,
    SPW: 0,
    SPH: 0,
    FREQUENCY: '',
    ONTHEDAY: '',
    ROUTINE: [],
    emailTemplates:[],
    emailTemplateChoices: [],
    templateSelectIndex:0,
    statusChoices: [],
    distributionArr: [],
    frequencyChoices: [],
    onTheDayArr: [],
    EVERY: "",
    dayArr: [],
    weekDayArr: [],
    DAY: "",
    text_routineName: {},
    date_start: {},
    date_end: {},
    text_specificDay_01: {},
    text_specificDay_02: {},
    dropDown_emailTemplate: {},
    editMsg:"",
  }

  ngOnDestroy() {}

  ngOnInit() { }

  ngAfterViewInit() {
    this.setVars(); 
  }

  setVars() {

    //this.bundle.showObj = false;     

    this.bundle.objW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.objH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {

      this.bundle.SPW = this.bundle.objW - (this.bundle.SPX * 2)
      this.bundle.SPH = this.bundle.objH - this.bundle.SPY - 40;
      this.bundle.spB = this.bundle.SPY + this.bundle.SPH + 10;
  
      this.getData();   

    });
  }

  getData() {

    this.bundle.text_routineName = this.text_routineName;
    this.bundle.date_start = this.date_start;
    this.bundle.date_end = this.date_end;
    this.bundle.text_specificDay_01 = this.text_specificDay_01;
    this.bundle.text_specificDay_02 = this.text_specificDay_02;
    this.bundle.dropDown_emailTemplate = this.dropDown_emailTemplate;

    this.LlUtilityService.NG_utility_email_templates_get(done01, this.bundle);

    function done01(arr, bundle) {

      bundle.emailTemplates = arr;

      let topRecord = {
        SYSTEMEMAILTEMPLATEID: 0,
        FOLDERNAME: "NONE SELECTED",
      }

      bundle.emailTemplates.unshift(topRecord);


      bundle.LlUtilityService.NG_utility_email_routineTarget_get(done02, bundle);
      
    }
    

    function done02(arr, bundle) {

      bundle.ROUTINE = arr;

      if (arr.length > 0) {   

        setTimeout(() => {

          ///transfer these variable for HTML use
          bundle.FREQUENCY = bundle.ROUTINE[0].FREQUENCY;
          bundle.ONTHEDAY = bundle.ROUTINE[0].ONTHEDAY;

          let startDate = new Date(bundle.ROUTINE[0].DATESTART);
          let endDate = new Date(bundle.ROUTINE[0].DATEEND);
      
          bundle.date_start.val(startDate);
          bundle.date_end.val(endDate);
          bundle.text_routineName.val(bundle.ROUTINE[0].ROUTINENAME);
          bundle.text_specificDay_01.val(bundle.ROUTINE[0].SPECIFICDAY);
          bundle.text_specificDay_02.val(bundle.ROUTINE[0].SPECIFICDAY);

          for (let i = 0; i < bundle.emailTemplates.length; i++) {
            bundle.emailTemplateChoices.push(bundle.emailTemplates[i].FOLDERNAME);

            if (bundle.emailTemplates[i].SYSTEMEMAILTEMPLATEID == bundle.ROUTINE[0].SYSTEMEMAILTEMPLATEID) {
              bundle.templateSelectIndex = i
            }
          }

          bundle.showEmailTemplate = true;

          setTimeout(() => {

            bundle.statusChoices.splice(0)
            bundle.distributionArr.splice(0)
            bundle.frequencyChoices.splice(0)
            bundle.onTheDayArr.splice(0)
            bundle.dayArr.splice(0)
            bundle.weekDayArr.splice(0)

            bundle.statusChoices.push({ choice: 'Active', SELECTED: false })
            bundle.statusChoices.push({ choice: 'Inactive', SELECTED: false })

            bundle.distributionArr.push({ choice: 'No filter - Include all selected Recipiants', code: 'all', SELECTED: false })
            bundle.distributionArr.push({ choice: 'Only those recipiants with incomplete month-reports', code: 'pending_events', SELECTED: false })

            bundle.frequencyChoices.push({ choice: 'Once', SELECTED: false })
            bundle.frequencyChoices.push({ choice: 'Daily', SELECTED: false })
            bundle.frequencyChoices.push({ choice: 'Weekly', SELECTED: false })
            bundle.frequencyChoices.push({ choice: 'Monthly', SELECTED: false })

            bundle.onTheDayArr.push({ choice: 'First', SELECTED: false })
            bundle.onTheDayArr.push({ choice: 'Last', SELECTED: false })
            bundle.onTheDayArr.push({ choice: 'Specific-Day', SELECTED: false })
            bundle.onTheDayArr.push({ choice: 'Days-Before-Event', SELECTED: false })

            bundle.dayArr.push({ choice: 'Day', SELECTED: false })
            bundle.dayArr.push({ choice: 'Monday', SELECTED: false })
            bundle.dayArr.push({ choice: 'Tuesday', SELECTED: false })
            bundle.dayArr.push({ choice: 'Wednesday', SELECTED: false })
            bundle.dayArr.push({ choice: 'Thursday', SELECTED: false })
            bundle.dayArr.push({ choice: 'Friday', SELECTED: false })
            bundle.dayArr.push({ choice: 'Saturday', SELECTED: false })
            bundle.dayArr.push({ choice: 'Sunday', SELECTED: false })

            bundle.weekDayArr.push({ choice: 'Monday', SELECTED: false })
            bundle.weekDayArr.push({ choice: 'Tuesday', SELECTED: false })
            bundle.weekDayArr.push({ choice: 'Wednesday', SELECTED: false })
            bundle.weekDayArr.push({ choice: 'Thursday', SELECTED: false })
            bundle.weekDayArr.push({ choice: 'Friday', SELECTED: false })
            bundle.weekDayArr.push({ choice: 'Saturday', SELECTED: false })
            bundle.weekDayArr.push({ choice: 'Sunday', SELECTED: false })


            setTimeout(() => {
              

              for (let i = 0; i < bundle.statusChoices.length; i++) {
                if (bundle.ROUTINE[0].ROUTINESTATUS == bundle.statusChoices[i].choice) {
                  bundle.statusChoices[i].SELECTED = true;
                }
              }

              for (let i = 0; i < bundle.distributionArr.length; i++) {

                if (bundle.ROUTINE[0].DISTRIBUTION == bundle.distributionArr[i].code) {
                  bundle.distributionArr[i].SELECTED = true;
                }
              }

              for (let i = 0; i < bundle.frequencyChoices.length; i++) {
                if (bundle.ROUTINE[0].FREQUENCY == bundle.frequencyChoices[i].choice) {
                  bundle.frequencyChoices[i].SELECTED = true;
                }
              }

              for (let i = 0; i < bundle.onTheDayArr.length; i++) {
                if (bundle.ROUTINE[0].ONTHEDAY == bundle.onTheDayArr[i].choice) {
                  bundle.onTheDayArr[i].SELECTED = true;
                }
              }

              for (let i = 0; i < bundle.dayArr.length; i++) {
                if (bundle.ROUTINE[0].DAYOFWEEK == bundle.dayArr[i].choice) {
                  bundle.dayArr[i].SELECTED = true;
                }
              }

              for (let i = 0; i < bundle.weekDayArr.length; i++) {
                if (bundle.ROUTINE[0].DAYOFWEEK == bundle.weekDayArr[i].choice) {
                  bundle.weekDayArr[i].SELECTED = true;
                }
              }


              setTimeout(() => {
                //bundle.showObj = true;  
              });
            });


          });
        },100);
      }
    }


  }

  selectStatus(event) {

    this.bundle.ROUTINE[0].ROUTINESTATUS = event.target.innerText

    for (var i = 0; i < this.bundle.frequencyChoices.length; i++) {
      this.bundle.frequencyChoices[i].SELECTED = false;

      if (this.bundle.FREQUENCY == this.bundle.frequencyChoices[i].choice) {
        this.bundle.frequencyChoices[i].SELECTED = true;
      }
    }
  }

  selectDistribution(event) {

    let choice = event.target.innerText

    for (var i = 0; i < this.bundle.distributionArr.length; i++) {
      this.bundle.distributionArr[i].SELECTED = false;

      if (choice == this.bundle.distributionArr[i].choice) {
        this.bundle.distributionArr[i].SELECTED = true;

        this.bundle.ROUTINE[0].DISTRIBUTION = this.bundle.distributionArr[i].code
      }
    }


  }

  selectFrequency(event) {

    if (this.bundle.ROUTINE[0].FREQUENCY != event.target.innerText) {

      this.bundle.ROUTINE[0].FREQUENCY = event.target.innerText
      this.bundle.FREQUENCY = event.target.innerText

      for (var i = 0; i < this.bundle.frequencyChoices.length; i++) {
        this.bundle.frequencyChoices[i].SELECTED = false;

        if (this.bundle.FREQUENCY == this.bundle.frequencyChoices[i].choice) {
          this.bundle.frequencyChoices[i].SELECTED = true;
        }
      }


      setTimeout(() => {

        if (event.target.innerText == 'Weekly' && this.bundle.ROUTINE[0].DAYOFWEEK == 'Day') {

          this.bundle.ROUTINE[0].DAYOFWEEK = 'Monday';

          for (var i = 0; i < this.bundle.weekDayArr.length; i++) {
            this.bundle.weekDayArr[i].SELECTED = false;

            if (this.bundle.ROUTINE[0].DAYOFWEEK == this.bundle.weekDayArr[i].choice) {
              this.bundle.weekDayArr[i].SELECTED = true;
            }
          }

          for (let i = 0; i < this.bundle.dayArr.length; i++) {
            if (this.bundle.ROUTINE[0].DAYOFWEEK == this.bundle.dayArr[i].choice) {
              this.bundle.dayArr[i].SELECTED = true;
            }
          }
        }
      });

    }
  }

  selectOnTheDay(event) {

    console.log(event.target.innerText)

    if (this.bundle.ROUTINE[0].ONTHEDAY != event.target.innerText) {

      this.bundle.ROUTINE[0].ONTHEDAY = event.target.innerText
      this.bundle.ONTHEDAY = event.target.innerText

      for (var i = 0; i < this.bundle.onTheDayArr.length; i++) {
        this.bundle.onTheDayArr[i].SELECTED = false;

        if (this.bundle.ONTHEDAY == this.bundle.onTheDayArr[i].choice) {
          this.bundle.onTheDayArr[i].SELECTED = true;
        }
      }


      if (this.bundle.ROUTINE[0].ONTHEDAY == 'Specific-Day' || this.bundle.ROUTINE[0].ONTHEDAY == 'Days-Before-Event') {
        setTimeout(() => {
                    
          this.text_specificDay_01.val(String(this.bundle.ROUTINE[0].SPECIFICDAY));
          this.text_specificDay_02.val(String(this.bundle.ROUTINE[0].SPECIFICDAY));
        });

      }
    }

  }

  selectDay(event) {

    if (this.bundle.ROUTINE[0].DAYOFWEEK != event.target.innerText) {

      this.bundle.ROUTINE[0].DAYOFWEEK = event.target.innerText;

      for (var i = 0; i < this.bundle.dayArr.length; i++) {
        this.bundle.dayArr[i].SELECTED = false;

        if (this.bundle.ROUTINE[0].DAYOFWEEK == this.bundle.dayArr[i].choice) {
          this.bundle.dayArr[i].SELECTED = true;
        }
      }

      for (var i = 0; i < this.bundle.weekDayArr.length; i++) {
        this.bundle.weekDayArr[i].SELECTED = false;

        if (this.bundle.ROUTINE[0].DAYOFWEEK == this.bundle.weekDayArr[i].choice) {
          this.bundle.weekDayArr[i].SELECTED = true;
        }
      }
    }
  }

  selectTemplate(event: any): void {
    this.bundle.ROUTINE[0].SYSTEMEMAILTEMPLATEID = this.bundle.emailTemplates[event.args.index].SYSTEMEMAILTEMPLATEID;
  };

  specificDay_onKeyUp_01(event) {

    let newVal = allowOnlyNumbers(this.text_specificDay_01.val())
    this.bundle.ROUTINE[0].SPECIFICDAY = Number(newVal);

    if (newVal == '' || newVal == '0') {
      //this.bundle.ROUTINE[0].SPECIFICDAY = 1;
      //this.text_specificDay.val('1');

    } else if (Number(newVal) > 28) {
      //this.bundle.ROUTINE[0].SPECIFICDAY = 28;
      //this.text_specificDay.val('28');
    } else {
      //this.bundle.ROUTINE[0].SPECIFICDAY = Number(newVal);
    }

  }

  specificDay_onKeyUp_02(event) {

    let newVal = allowOnlyNumbers(this.text_specificDay_02.val())
    this.bundle.ROUTINE[0].SPECIFICDAY = Number(newVal);

  }

  noAction() {
    //needed HTML buttons point to this 
  }

  editRoutine() {
    this.bundle.editMsg = ""

    this.bundle.ROUTINE[0].ROUTINENAME = this.text_routineName.val();
    this.bundle.ROUTINE[0].DATESTART = this.date_start.val();
    this.bundle.ROUTINE[0].DATEEND = this.date_end.val();

    if (this.bundle.ROUTINE[0].ROUTINENAME == "") {
      this.returnedMessage("A name is required", this.bundle);

    } else if (this.bundle.ROUTINE[0].SYSTEMEMAILTEMPLATEID == 0) {
      this.returnedMessage("An email template is required", this.bundle);

    } else if (this.bundle.ROUTINE[0].FREQUENCY == 'Monthly'
      && this.bundle.ROUTINE[0].ONTHEDAY == 'Specific-Day'
      && this.bundle.ROUTINE[0].SPECIFICDAY == '0'
      || Number(this.bundle.ROUTINE[0].SPECIFICDAY) > 28
      || this.bundle.ROUTINE[0].SPECIFICDAY == '') {

      this.returnedMessage("Specific day is required (1-28)", this.bundle);

    } else {
      this.LlUtilityService.NG_utility_email_routine_edit(this.returnedMessage, this.bundle.ROUTINE, this.bundle)
    }

  }

  returnedMessage(msg, bundle) {

    if (msg != '0') {
      bundle.editMsg = msg
    }
  }
}

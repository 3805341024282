<!--<loan-reports-pu></loan-reports-pu>-->
<!--<client-logo></client-logo>-->
<!--<header-user-info></header-user-info>-->
<!--<app-outline></app-outline>-->
<ll-message-pu></ll-message-pu>


  <canvas #canvas_appOutline></canvas>

  <div *ngIf="bundle.showObj">

    <!--LOGO-->
    <div  [style.top.px]="bundle.logoY"
         [style.left.px]="bundle.OL_X + bundle.logoX"
         style="position: absolute;">

      <img [style.height.px]="bundle.logoH" style="width: auto;" [src]='bundle.sponsorLogo'>

    </div>

    <!--USER INFO-->
    <div id=mainTab
         [style.top.px]="bundle.logoY + 10"
         [style.left.px]="200"
         [style.width.px]="bundle.OL_W - 200"
         [style.height.px]="bundle.OL_Y"
         style="position: absolute;">

      <ll-user-info id="main-canvas"></ll-user-info>
    </div>


    <!--MODELS SCROLL PANE-->
    <div [style.top.px]="bundle.OL_Y"
         [style.left.px]="bundle.spX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.OL_H"
         style="position: absolute; ">
      <ll-models-sp></ll-models-sp>
    </div>

  </div>

<div #mainDiv_ll_models_sp>
 
  <canvas #vertTab_ll_models_sp></canvas> 

  <div *ngIf="bundle.showObj">

    <div class="spLabel"
         [style.top.px]="bundle.lblY"
         [style.width.px]="bundle.spW">
      {{bundle.lable}}
    </div>

    <jqxPanel #scrollpane_ll_models_sp [style.top.px]="bundle.spY"
              style="background-color: transparent;
              border: none;  outline: none;
              border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.spW"
              [height]="bundle.spH" >

      <div *ngFor="let group of bundle.groups " style="white-space: nowrap;">

        <div class="spCategory">{{group}}</div>

      <tr *ngFor="let item of bundle.records | visitCategory:{GROUP: group} ">


        <div class="statusCircle_holder" *ngIf="group != 'Models'" [style.width.px]="bundle.spW">

          <div class="spRowLabel_noIcon"
               [id]="bundle.prefix + item.FOLDERID"
               title={{item.FOLDERNAME}}
               [style.width.px]="bundle.spW"
               (click)="select($event)">
            {{item.FOLDERNAME}}
          </div>

        </div>

        <div class="statusCircle_holder" *ngIf="group == 'Models' && bundle.canEdit " [style.width.px]="bundle.spW">

          <img class="editGear" [id]="'GR' + item.FOLDERID" src="assets/icon_gear.png" alt="" (click)="edit($event)">

          <div class="spRowLabel"
               [id]="bundle.prefix + item.FOLDERID"
               title={{item.FOLDERNAME}}
               [style.width.px]="bundle.spW - 25"
               (click)="select($event)">
            {{item.FOLDERNAME}}
          </div>

        </div>

        <div class="statusCircle_holder" *ngIf="group == 'Models' && !bundle.canEdit " [style.width.px]="bundle.spW">

          <div class="spRowLabel"
               [id]="bundle.prefix + item.FOLDERID"
               title={{item.FOLDERNAME}}
               [style.width.px]="bundle.spW"
               (click)="select($event)">
            {{item.FOLDERNAME}}
          </div>

        </div>

      </tr>
      </div>

    </jqxPanel>

  </div>

  <!--BOTTOM-->
<div id=bottomLine [style.top.px]="bundle.spB - 55">

  <!--Active / Inactive-->
  <!--<div style="padding-top:10px;">

      <div *ngIf="bundle.STATUS == 'N'" class="wordBtnGroup">
        <div class="wordBtn" (click)="changeToActive()">Show Active</div>

        <jqxTooltip style="float: left;"
                    [position]="'right'"
                    [name]="'movieTooltip'"
                    [autoHideDelay]="30000"
                    [content]="'Click to Show Active Models'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn_12" src="assets/infoBtn.png" />

        </jqxTooltip>
      </div>



      <div *ngIf="bundle.STATUS == 'A'" class="wordBtnGroup">
        <div class="wordBtn" (click)="changeToInactive()">Show Inactive</div>

        <jqxTooltip style="float: left;"
                    [position]="'right'"
                    [name]="'movieTooltip'"
                    [autoHideDelay]="30000"
                    [content]="'Click to show Inactive Models'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn_12" src="assets/infoBtn.png" />

        </jqxTooltip>
      </div>

    </div>-->
</div>

    <!--SEARCH-->
  <div id=search_ll_models_sp *ngIf="bundle.canFilter && bundle.showObj"
       [style.top.px]="bundle.lblY - 0"
       [style.width.px]="bundle.spW"
       [style.left.px]="bundle.spW  - 10 "
       style="position: absolute;">


    <div class="wordBtnGroup">
      <div *ngIf="bundle.searchText  == '' ">
        <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
      </div>
      <div *ngIf="bundle.searchText  != '' ">
        <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
      </div>
    </div>

    <jqxPopover #popover_search_ll_models_sp
                [offset]='{ left: -85, top: 0 }'
                [arrowOffsetValue]="-0"
                [width]="220"
                [position]="'top'"
                [title]="'Filter Categories'"
                [selector]="'#search_ll_models_sp'">

      <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">

        <div (keyup)="onKeyUp($event)" style="float: left; ">
          <jqxTextArea #text_search_ll_models_sp
                       [width]="140"
                       [height]="25"
                       [placeHolder]="'Type Search'">

          </jqxTextArea>
        </div>

        <div style="float: left; padding-left:10px; padding-top:0px; ">
          <div style="clear:left">
            <div class="wordBtn" (click)="search()">Search</div>
          </div>
          <div style="clear:left">
            <div class="wordBtn" (click)="clear()">Clear</div>
          </div>
        </div>
      </div>
    </jqxPopover>
  </div>

</div>

  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.selectedId > -1 ">
    <div *ngIf="!bundle.INFOREQUEST_ONLY"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; ">
      <ll-model-functions-sp></ll-model-functions-sp>
    </div>

    <div *ngIf="bundle.INFOREQUEST_ONLY"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; ">
      <ll-info-request-visits-sp id="ll-model-functions-sp"></ll-info-request-visits-sp>
    </div>
  </div>



<div *ngIf="bundle.showTab && bundle.editMode && bundle.selectedId > -1 ">
  <div [style.top.px]="bundle.remainingY"
    [style.left.px]="bundle.remainingX"
    [style.width.px]="bundle.remainingW"
    [style.height.px]="bundle.remainingH"
    style="position: absolute; ">
    <ll-company-switch id="from_ll_models_sp"></ll-company-switch>
  </div>

  <div [style.top.px]="10"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="100"
       [style.height.px]="25"
       style="position: absolute;z-index:100">

    <div class="wordBtnGroup">
      <img class="btnIcon" height=23 style="width: auto;" src="assets/icon_backArrow.png" alt="" (click)="goBack()">
      <div class="wordBtn14" style="padding-left:5px; padding-top:3px;" (click)="goBack()">Go Back</div>
    </div>

  </div>
</div>

<div *ngIf="bundle.showTab && bundle.showRemaining && bundle.selectedId == -999 "
     [style.top.px]="bundle.remainingY"
     [style.left.px]="bundle.remainingX"
     [style.width.px]="bundle.remainingW"
     [style.height.px]="bundle.remainingH"
     style="position: absolute; ">
  <ll-admin-sp></ll-admin-sp>
</div>

<div *ngIf="bundle.showTab && bundle.showRemaining && bundle.selectedId == -888 "
     [style.top.px]="bundle.remainingY"
     [style.left.px]="bundle.remainingX"
     [style.width.px]="bundle.remainingW"
     [style.height.px]="bundle.remainingH"
     style="position: absolute; ">
  <ll-admin-newsletters-sp></ll-admin-newsletters-sp>
</div>







import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
///import { ServerVarsService } from './server-vars.service';


@Directive()
@Injectable()

export class ServerService {

  @Output() serverError: EventEmitter<any> = new EventEmitter();
  @Output() loaderOpen: EventEmitter<boolean> = new EventEmitter();
  //@Output() loaderClose: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    
  ) { }

  user = [];


  ////production
 configUrl = 'https://mediantdesigns.com/rest/api/gateway/noCheck'; 

   ////see angular-json:architect–serve-proxyConfig
  ////see proxy-conf-json

 ///configUrl = 'http://localhost:4200/noCheck';
  

  errorMessage:String = "";  

  bundle_local = {
    active_ac:"",
    token:"",
    loaderOpen:this.loaderOpen,
    serverError: this.serverError,
  }

  getConfig() {
    return this.http.get(this.configUrl);
  }

  callserver(callback01: Function, bundle, showHideLoader) {

    ///console.log(this.configUrl);
   console.log('bundle.vars.ac: '+bundle.vars.ac);

    this.bundle_local.active_ac = bundle.vars.ac
    bundle.vars.token = this.bundle_local.token;

    if(showHideLoader == "show"){
      this.loaderOpen.emit(true);
    }

    ///this.bundle_local.loaderVisable = true;

    let body = bundle.vars;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'text/plain');

    this.http.post(this.configUrl, body, {headers: headers}).subscribe({
      next: data => convert(data, this.bundle_local),
      error: error => this.captureError(error, bundle.vars)
      ///error: error => this.captureError(error.Message, bundle.vars)
    });  

    function convert(data, bundle_local){

      if(bundle.vars.ac == "validate"){
        bundle_local.token = data[0].TOKEN;        
      }

      ///because 'Yes' and 'No' values get converted to true and false during the exchance with the Server,
      ///these values are converted at the server befor transmission....so need to be converted bank
      for(let i=0; i< data.length; i++){

        const keys = Object.keys(data[i]);

        for (const key of keys) {

          if(data[i][key] == "Yes^"){
            data[i][key] = "Yes";
          }

          if(data[i][key] == "No^"){
            data[i][key] = "No"
          }
        }
      }

      bundle_local.loaderOpen.emit(false);
      ///bundle_local.loaderVisable = false;

      callback01(data, bundle)
    }

  }

  captureError(srvMsg, vars) {

    //console.log("captureError")
    //console.log(this.configUrl)


    
    if (this.configUrl == 'http://localhost:4200/noCheck') {
      console.log('There was an error!', srvMsg)
    }   

    var parameters: String = "srvMsg " + srvMsg + " PARAMS: ";


    for (const [key, value] of Object.entries(vars)) {

      if (this.configUrl == 'http://localhost:4200/noCheck') {
        console.log(key, value);
      }      

      if (key != "USERNAME" && key != "USERPASSWORD" && key != "TOKEN" && key != "FILEDATA") {
        parameters = parameters + key + ":" + value + " | ";
      }

    }

    

    var errorMsg: String = " ERROR: " + vars.ac + "-" + parameters;

    ///console.log(errorMsg)
 
    if(this.bundle_local.active_ac != "NG_utility_logError"){
      this.loaderOpen.emit(false);
      this.serverError.emit(errorMsg);    
    }
  }
  

}

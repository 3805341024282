import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { LlRolesService } from '../ll-roles.service';
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { jqxDateTimeInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput";
import { jqxDropDownListComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownlist";

import { removeTextChars } from "../utility";
import { removeNumberCommas } from "../utility";

@Component({
  selector: 'll-model-info',
  templateUrl: './ll-model-info.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlModelInfoComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_info') mainDiv: ElementRef;
  @ViewChild('text_MODELNAME') text_MODELNAME: jqxTextAreaComponent;
  @ViewChild('text_LIMIT_COMMITMENT_AMT') text_LIMIT_COMMITMENT_AMT: jqxTextAreaComponent;
  @ViewChild('date_approxLoanCloseDate') date_approxLoanCloseDate: jqxDateTimeInputComponent;
  @ViewChild('date_approxLoanTerminationDate') date_approxLoanTerminationDate: jqxDateTimeInputComponent;
  @ViewChild('text_LOANTERMMONTHS') text_LOANTERMMONTHS: jqxTextAreaComponent;
  @ViewChild('text_SETUPAMOUNT') text_SETUPAMOUNT: jqxTextAreaComponent;
  @ViewChild('text_MONTHLYAMOUNT') text_MONTHLYAMOUNT: jqxTextAreaComponent;
  @ViewChild('text_DAYSTOBILL') text_DAYSTOBILL: jqxTextAreaComponent;
  @ViewChild('text_LIMIT_BILLEDADVANCE_PCT') text_LIMIT_BILLEDADVANCE_PCT: jqxTextAreaComponent;
  @ViewChild('text_LIMIT_UNBILLEDADVANCE_PCT') text_LIMIT_UNBILLEDADVANCE_PCT: jqxTextAreaComponent;
  @ViewChild('text_LIMIT_PAYERCAP_PCT') text_LIMIT_PAYERCAP_PCT: jqxTextAreaComponent;
  @ViewChild('text_LIMIT_ENV_OVERRIDE_PCT') text_LIMIT_ENV_OVERRIDE_PCT: jqxTextAreaComponent;
  @ViewChild('text_reserve') text_reserve: jqxTextAreaComponent;
  @ViewChild('dropDown_leadLender') dropDown_leadLender: jqxDropDownListComponent;

  constructor(
    private LlModelsService: LlModelsService,
    private LlPermissionsService: LlPermissionsService,
    private LlRolesService: LlRolesService,
  ) {
  }

  bundle = {
    action: '',
    showDisplay: false,
    canEdit: false,
    sysAdmin: false,
    disabled: true,
    showARSettings:false,
    text_MODELNAME: {},
    text_LIMIT_COMMITMENT_AMT: {},
    text_DAYSTOBILL: {},
    text_LIMIT_BILLEDADVANCE_PCT: {},
    text_LIMIT_UNBILLEDADVANCE_PCT: {},
    text_LIMIT_PAYERCAP_PCT: {},
    text_LIMIT_ENV_OVERRIDE_PCT: {},
    date_approxLoanCloseDate: {},
    date_approxLoanTerminationDate: {},
    text_LOANTERMMONTHS: {},
    text_SETUPAMOUNT: {},
    text_MONTHLYAMOUNT: {},
    dropDown_leadLender: {},
    MODEL:[],
    LOANMODELID: '',
    DYNAMICENVYESNO:'',
    LEAD_LENDERS: [],
    LEAD_LENDERSChoices: [],
    leadLenderSelectIndex:-1,
    STATUSChoices:[],
    AGEPERIODTYPEChoices: [],
    STRAIGHTORAVGBBChoices: [],
    DAYSTOAVERAGEChoices: [],
    PRORATEUNBILLEDYESNOChoices: [],
    LIMIT_BILLED_AGECAPDAYSChoices: [],
    LIMIT_UNBILLED_AGECAPDAYSChoices: [],
    LIMIT_ENVCALCBUCKET_DAYSChoices: [],
    DYNAMICENVYESNOChoices: [],
    LlModelsService: this.LlModelsService,
    parentW: 0,
    parentH: 0,
    spX: 10,
    spY: 30,
    spW:0,
    spH: 0,
    spB: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    valueIndent: 250,
    radialBtnSpacing:50,

  };


  ngOnDestroy() {}

  ngOnInit() { }

  ngAfterViewInit(): void {

    setTimeout(() => {
      
      this.setVars();

    })


  }

  setVars() {

    this.bundle.canEdit = this.LlPermissionsService.check("editModels")

    this.bundle.disabled = true;

    if (this.bundle.canEdit) {
      this.bundle.disabled = false;
    }

    let SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId()

    if (SYSTEMROLEID == 18 || SYSTEMROLEID == 29) {
      this.bundle.sysAdmin = true;
    }

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;
    this.bundle.spW = this.bundle.parentW - (this.bundle.spX * 2);
    this.bundle.spH = this.bundle.parentH - this.bundle.spY - 40;
    this.bundle.spB = this.bundle.spH;

    this.getData();
  }

  getData() {

    this.bundle.text_MODELNAME = this.text_MODELNAME
    this.bundle.date_approxLoanCloseDate = this.date_approxLoanCloseDate
    this.bundle.date_approxLoanTerminationDate = this.date_approxLoanTerminationDate
    this.bundle.text_LOANTERMMONTHS = this.text_LOANTERMMONTHS;
    this.bundle.text_SETUPAMOUNT = this.text_SETUPAMOUNT;
    this.bundle.text_MONTHLYAMOUNT = this.text_MONTHLYAMOUNT;
    this.bundle.text_LOANTERMMONTHS = this.text_LOANTERMMONTHS
    this.bundle.text_LIMIT_COMMITMENT_AMT = this.text_LIMIT_COMMITMENT_AMT
    this.bundle.text_DAYSTOBILL = this.text_DAYSTOBILL
    this.bundle.text_LIMIT_BILLEDADVANCE_PCT = this.text_LIMIT_BILLEDADVANCE_PCT
    this.bundle.text_LIMIT_UNBILLEDADVANCE_PCT = this.text_LIMIT_UNBILLEDADVANCE_PCT
    this.bundle.text_LIMIT_PAYERCAP_PCT = this.text_LIMIT_PAYERCAP_PCT
    this.bundle.text_LIMIT_ENV_OVERRIDE_PCT = this.text_LIMIT_ENV_OVERRIDE_PCT    
    this.bundle.dropDown_leadLender = this.dropDown_leadLender

    this.LlModelsService.NG_LL_company_model_get(done01, this.bundle);

    function done01(arr, bundle) {

      bundle.MODEL = arr;

      if (bundle.MODEL[0].ARDATAFEEDYESNO.toLowerCase() == 'yes') {
        bundle.showARSettings = true;
      }      

      bundle.LlModelsService.NG_LL_model_AR_leadLenders_get(done02, bundle);
    }

    function done02(arr, bundle) {

      bundle.LEAD_LENDERS = arr;

      for (let i = 0; i < arr.length; i++) {
        bundle.LEAD_LENDERSChoices.push(arr[i].COMPANYNAME);

        if (arr[i].COMPANYID == bundle.MODEL[0].LEADLENDERCOMPANYID) {
          bundle.leadLenderSelectIndex = i;
        }
      }

      done03(bundle)
    }

    function done03(bundle) {
      
      bundle.dropDown_leadLender.source(bundle.LEAD_LENDERSChoices);
      bundle.dropDown_leadLender.selectedIndex(bundle.leadLenderSelectIndex);

      if (bundle.MODEL[0].APPROXLOANCLOSEDATE != '') {
        let closeDate = new Date(bundle.MODEL[0].APPROXLOANCLOSEDATE);
        bundle.date_approxLoanCloseDate.val(closeDate);
      }

      if (bundle.MODEL[0].APPROXLOANTERMINATIONDATE != '') {
        let terminationDate = new Date(bundle.MODEL[0].APPROXLOANTERMINATIONDATE);
        bundle.date_approxLoanTerminationDate.val(terminationDate);
      }


      bundle.LOANMODELID = bundle.MODEL[0].LOANMODELID;
      bundle.DYNAMICENVYESNO = bundle.MODEL[0].DYNAMICENVYESNO;

      bundle.text_LOANTERMMONTHS.val(bundle.MODEL[0].LOANTERMMONTHS);
      bundle.text_MODELNAME.val(bundle.MODEL[0].MODELNAME);
      bundle.text_SETUPAMOUNT.val(bundle.MODEL[0].SETUPAMOUNT);
      bundle.text_MONTHLYAMOUNT.val(bundle.MODEL[0].MONTHLYAMOUNT);

      bundle.text_LIMIT_COMMITMENT_AMT.val(bundle.MODEL[0].LIMIT_COMMITMENT_AMT);
      bundle.text_DAYSTOBILL.val(bundle.MODEL[0].DAYSTOBILL);
      bundle.text_LIMIT_BILLEDADVANCE_PCT.val(bundle.MODEL[0].LIMIT_BILLEDADVANCE_PCT);
      bundle.text_LIMIT_UNBILLEDADVANCE_PCT.val(bundle.MODEL[0].LIMIT_UNBILLEDADVANCE_PCT)
      bundle.text_LIMIT_PAYERCAP_PCT.val(bundle.MODEL[0].LIMIT_PAYERCAP_PCT);
      bundle.text_LIMIT_ENV_OVERRIDE_PCT.val(bundle.MODEL[0].LIMIT_ENV_OVERRIDE_PCT);

      //console.log(bundle.MODEL[0].LIMIT_ENV_OVERRIDE_PCT)


      bundle.DAYSTOAVERAGEChoices.push({ choice: '10', code: "10", SELECTED: false })
      bundle.DAYSTOAVERAGEChoices.push({ choice: '15', code: "15", SELECTED: false })
      bundle.DAYSTOAVERAGEChoices.push({ choice: '30', code: "30", SELECTED: false })
      bundle.DAYSTOAVERAGEChoices.push({ choice: '45', code: "45", SELECTED: false })
      bundle.DAYSTOAVERAGEChoices.push({ choice: '60', code: "60", SELECTED: false })

      bundle.PRORATEUNBILLEDYESNOChoices.push({ choice: 'Yes', code: "Yes", SELECTED: false })
      bundle.PRORATEUNBILLEDYESNOChoices.push({ choice: 'No', code: "No", SELECTED: false })

      bundle.STATUSChoices.push({ choice: 'Active', code: "A", SELECTED: false })
      bundle.STATUSChoices.push({ choice: 'Pending', code: "P", SELECTED: false })
      bundle.STATUSChoices.push({ choice: 'Inactive', code: "N", SELECTED: false })

      bundle.DYNAMICENVYESNOChoices.push({ choice: 'Yes', code: "Yes", SELECTED: false })
      bundle.DYNAMICENVYESNOChoices.push({ choice: 'No', code: "No", SELECTED: false })

      bundle.LIMIT_BILLED_AGECAPDAYSChoices.push({ choice: '30', code: "30", SELECTED: false })
      bundle.LIMIT_BILLED_AGECAPDAYSChoices.push({ choice: '60', code: "60", SELECTED: false })
      bundle.LIMIT_BILLED_AGECAPDAYSChoices.push({ choice: '90', code: "90", SELECTED: false })
      bundle.LIMIT_BILLED_AGECAPDAYSChoices.push({ choice: '120', code: "120", SELECTED: false })
      bundle.LIMIT_BILLED_AGECAPDAYSChoices.push({ choice: '150', code: "150", SELECTED: false })
      bundle.LIMIT_BILLED_AGECAPDAYSChoices.push({ choice: '180', code: "180", SELECTED: false })
      bundle.LIMIT_BILLED_AGECAPDAYSChoices.push({ choice: 'All', code: "1000", SELECTED: false })

      bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.push({ choice: '0', code: "0", SELECTED: false })
      bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.push({ choice: '30', code: "30", SELECTED: false })
      bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.push({ choice: '60', code: "60", SELECTED: false })
      bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.push({ choice: '90', code: "90", SELECTED: false })
      bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.push({ choice: '120', code: "120", SELECTED: false })
      bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.push({ choice: '150', code: "150", SELECTED: false })
      bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.push({ choice: '180', code: "180", SELECTED: false })
      bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.push({ choice: 'All', code: "1000", SELECTED: false })

      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '30', code: "30", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '60', code: "60", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '90', code: "90", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '120', code: "120", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '150', code: "150", SELECTED: false })
      bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.push({ choice: '180', code: "180", SELECTED: false })

  
      done13(bundle);   

      function done13(bundle) {

        

        for (let i = 0; i < bundle.STATUSChoices.length; i++) {
          if (bundle.MODEL[0].STATUS == bundle.STATUSChoices[i].code) {
            setTimeout(() => {
              bundle.STATUSChoices[i].SELECTED = true;
              done14(bundle)
            });
          }
        }
      }

      function done14(bundle) {

        for (let i = 0; i < bundle.DAYSTOAVERAGEChoices.length; i++) {
          if (bundle.MODEL[0].DAYSTOAVERAGE == bundle.DAYSTOAVERAGEChoices[i].code) {
            setTimeout(() => {
              bundle.DAYSTOAVERAGEChoices[i].SELECTED = true;
              done15(bundle)
            });
          }
        }
      }

      function done15(bundle) {
        for (let i = 0; i < bundle.PRORATEUNBILLEDYESNOChoices.length; i++) {
          if (bundle.MODEL[0].PRORATEUNBILLEDYESNO == bundle.PRORATEUNBILLEDYESNOChoices[i].code) {
            setTimeout(() => {
              bundle.PRORATEUNBILLEDYESNOChoices[i].SELECTED = true;
              done16(bundle)
            });
          }
        }
      }

      function done16(bundle) {
        for (let i = 0; i < bundle.PRORATEUNBILLEDYESNOChoices.length; i++) {
          if (bundle.MODEL[0].PRORATEUNBILLEDYESNO == bundle.PRORATEUNBILLEDYESNOChoices[i].code) {
            setTimeout(() => {
              bundle.PRORATEUNBILLEDYESNOChoices[i].SELECTED = true;
              done17(bundle)
            });
          }
        }
      }

      function done17(bundle) {
        for (let i = 0; i < bundle.LIMIT_BILLED_AGECAPDAYSChoices.length; i++) {
          if (bundle.MODEL[0].LIMIT_BILLED_AGECAPDAYS == bundle.LIMIT_BILLED_AGECAPDAYSChoices[i].code) {
            setTimeout(() => {
              bundle.LIMIT_BILLED_AGECAPDAYSChoices[i].SELECTED = true;
              done18(bundle)
            });
          }
        }
      }

      function done18(bundle) {
        for (let i = 0; i < bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.length; i++) {
          if (bundle.MODEL[0].LIMIT_UNBILLED_AGECAPDAYS == bundle.LIMIT_UNBILLED_AGECAPDAYSChoices[i].code) {
            setTimeout(() => {
              bundle.LIMIT_UNBILLED_AGECAPDAYSChoices[i].SELECTED = true;
              done19(bundle)
            });
          }
        }
      }

      function done19(bundle) {
        for (let i = 0; i < bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.length; i++) {
          if (bundle.MODEL[0].LIMIT_ENVCALCBUCKET_DAYS == bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].code) {
            setTimeout(() => {
              bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].SELECTED = true;
              done20(bundle)
            });
          }
        }
      }

      function done20(bundle) {

        for (let i = 0; i < bundle.DYNAMICENVYESNOChoices.length; i++) {
          if (bundle.MODEL[0].DYNAMICENVYESNO == bundle.DYNAMICENVYESNOChoices[i].code) {
            setTimeout(() => {
              bundle.DYNAMICENVYESNOChoices[i].SELECTED = true;
              done21(bundle)
            });
          }
        }
      }

      function done21(bundle) {
        bundle.showDisplay = true;
      }
    }

  }

  selectSTATUSYESNO(event) {

    if (event.args.checked == true) {

      let val = event.target.innerText

      for (var i = 0; i < this.bundle.STATUSChoices.length; i++) {
        this.bundle.STATUSChoices[i].SELECTED = false;

        if (val == this.bundle.STATUSChoices[i].choice || val == this.bundle.STATUSChoices[i].code) {
          this.bundle.STATUSChoices[i].SELECTED = true;

          this.bundle.MODEL[0].STATUS = this.bundle.STATUSChoices[i].code
          
        }
      }
    }
  }

  selectLeadLender(event: any): void {
    this.bundle.MODEL[0].LEADLENDERCOMPANYID = this.bundle.LEAD_LENDERS[event.args.index].COMPANYID;
  };

  selectAGEPERIODTYPE(event) {

    if (event.args.checked == true) {

      let val = event.target.innerText

      for (var i = 0; i < this.bundle.AGEPERIODTYPEChoices.length; i++) {
        this.bundle.AGEPERIODTYPEChoices[i].SELECTED = false;

        if (val == this.bundle.AGEPERIODTYPEChoices[i].choice || val == this.bundle.AGEPERIODTYPEChoices[i].code) {
          this.bundle.AGEPERIODTYPEChoices[i].SELECTED = true;

          this.bundle.MODEL[0].AGEPERIODTYPE = this.bundle.AGEPERIODTYPEChoices[i].code

          //console.log(this.bundle.AGEPERIODTYPE)
        }
      }
    }
  }

  selectSTRAIGHTORAVGBB(event) { }

  selectDAYSTOAVERAGE(event) {

    if (event.args.checked == true) {
      let val = event.target.innerText

      //console.log(val)

      for (var i = 0; i < this.bundle.DAYSTOAVERAGEChoices.length; i++) {
        this.bundle.DAYSTOAVERAGEChoices[i].SELECTED = false;

        if (val == this.bundle.DAYSTOAVERAGEChoices[i].choice || val == this.bundle.DAYSTOAVERAGEChoices[i].code) {
          this.bundle.DAYSTOAVERAGEChoices[i].SELECTED = true;

          this.bundle.MODEL[0].DAYSTOAVERAGE = this.bundle.DAYSTOAVERAGEChoices[i].code

          //console.log(this.bundle.DAYSTOAVERAGE)
        }
      }
    }
  }

  selectPRORATEUNBILLEDYESNO(event) {

    if (event.args.checked == true) {
      let val = event.target.innerText

      //console.log(val)

      for (var i = 0; i < this.bundle.PRORATEUNBILLEDYESNOChoices.length; i++) {
        this.bundle.PRORATEUNBILLEDYESNOChoices[i].SELECTED = false;

        if (val == this.bundle.PRORATEUNBILLEDYESNOChoices[i].choice || val == this.bundle.PRORATEUNBILLEDYESNOChoices[i].code) {
          this.bundle.PRORATEUNBILLEDYESNOChoices[i].SELECTED = true;

          this.bundle.MODEL[0].PRORATEUNBILLEDYESNO = this.bundle.PRORATEUNBILLEDYESNOChoices[i].code

          //console.log(this.bundle.PRORATEUNBILLEDYESNO)
        }
      }
    }
  }

  selectLIMIT_BILLED_AGECAPDAYS(event) {

    if (event.args.checked == true) {
      let val = event.target.innerText

      //console.log(val)

      for (var i = 0; i < this.bundle.LIMIT_BILLED_AGECAPDAYSChoices.length; i++) {
        this.bundle.LIMIT_BILLED_AGECAPDAYSChoices[i].SELECTED = false;


        if (val == this.bundle.LIMIT_BILLED_AGECAPDAYSChoices[i].choice || val == this.bundle.LIMIT_BILLED_AGECAPDAYSChoices[i].code) {
          this.bundle.LIMIT_BILLED_AGECAPDAYSChoices[i].SELECTED = true;

          this.bundle.MODEL[0].LIMIT_BILLED_AGECAPDAYS = this.bundle.LIMIT_BILLED_AGECAPDAYSChoices[i].code

          //console.log(this.bundle.LIMIT_BILLED_AGECAPDAYS)
        }
      }
    }
  }

  selectLIMIT_UNBILLED_AGECAPDAYS(event) {

    if (event.args.checked == true) {
      let val = event.target.innerText

      //console.log(val)

      for (var i = 0; i < this.bundle.LIMIT_UNBILLED_AGECAPDAYSChoices.length; i++) {
        this.bundle.LIMIT_UNBILLED_AGECAPDAYSChoices[i].SELECTED = false;


        if (val == this.bundle.LIMIT_UNBILLED_AGECAPDAYSChoices[i].choice || val == this.bundle.LIMIT_UNBILLED_AGECAPDAYSChoices[i].code) {
          this.bundle.LIMIT_UNBILLED_AGECAPDAYSChoices[i].SELECTED = true;

          this.bundle.MODEL[0].LIMIT_UNBILLED_AGECAPDAYS = this.bundle.LIMIT_UNBILLED_AGECAPDAYSChoices[i].code

          //console.log(this.bundle.LIMIT_UNBILLED_AGECAPDAYS)
        }
      }
    }
  }

  selectDYNAMICENVYESNO(event) {

    if (event.args.checked == true) {

      let val = event.target.innerText

      for (var i = 0; i < this.bundle.DYNAMICENVYESNOChoices.length; i++) {
        this.bundle.DYNAMICENVYESNOChoices[i].SELECTED = false;


        if (val == this.bundle.DYNAMICENVYESNOChoices[i].choice || val == this.bundle.DYNAMICENVYESNOChoices[i].code) {
          this.bundle.DYNAMICENVYESNOChoices[i].SELECTED = true;

          this.bundle.MODEL[0].DYNAMICENVYESNO = this.bundle.DYNAMICENVYESNOChoices[i].code
          this.bundle.DYNAMICENVYESNO = this.bundle.DYNAMICENVYESNOChoices[i].code
          //console.log(this.bundle.DYNAMICENVYESNO)
        }
      }
    }
  }

  selectLIMIT_ENVCALCBUCKET_DAYS(event) {

    if (event.args.checked == true) {
      let val = event.target.innerText

      for (var i = 0; i < this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices.length; i++) {
        this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].SELECTED = false;


        if (val == this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].choice || val == this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].code) {
          this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].SELECTED = true;

          this.bundle.MODEL[0].LIMIT_ENVCALCBUCKET_DAYS = this.bundle.LIMIT_ENVCALCBUCKET_DAYSChoices[i].code

          //console.log(this.bundle.LIMIT_ENVCALCBUCKET_DAYS)
        }
      }
    }
  }


  onKeyUp(event) {
    this.text_LOANTERMMONTHS.val(removeTextChars(this.text_LOANTERMMONTHS.val()));
    this.text_SETUPAMOUNT.val(removeTextChars(this.text_SETUPAMOUNT.val()));
    this.text_MONTHLYAMOUNT.val(removeTextChars(this.text_MONTHLYAMOUNT.val()));
    this.text_LIMIT_ENV_OVERRIDE_PCT.val(removeTextChars(this.text_LIMIT_ENV_OVERRIDE_PCT.val()));
    this.text_DAYSTOBILL.val(removeTextChars(this.text_DAYSTOBILL.val()));
    this.text_LIMIT_UNBILLEDADVANCE_PCT.val(removeTextChars(this.text_LIMIT_UNBILLEDADVANCE_PCT.val()));
    this.text_LIMIT_BILLEDADVANCE_PCT.val(removeTextChars(this.text_LIMIT_BILLEDADVANCE_PCT.val()));
    this.text_LIMIT_PAYERCAP_PCT.val(removeTextChars(this.text_LIMIT_PAYERCAP_PCT.val()));

  }

  submitChange() {
    this.bundle.MODEL[0].MODELNAME = this.text_MODELNAME.val();
    this.bundle.MODEL[0].APPROXLOANCLOSEDATE = this.date_approxLoanCloseDate.val();
    this.bundle.MODEL[0].APPROXLOANTERMINATIONDATE = this.date_approxLoanTerminationDate.val();
    this.bundle.MODEL[0].LOANTERMMONTHS = this.text_LOANTERMMONTHS.val();
    this.bundle.MODEL[0].SETUPAMOUNT = this.text_SETUPAMOUNT.val();
    this.bundle.MODEL[0].MONTHLYAMOUNT = this.text_MONTHLYAMOUNT.val();

    this.bundle.MODEL[0].LIMIT_COMMITMENT_AMT = this.text_LIMIT_COMMITMENT_AMT.val()
    this.bundle.MODEL[0].LIMIT_ENV_OVERRIDE_PCT = this.text_LIMIT_ENV_OVERRIDE_PCT.val()
    this.bundle.MODEL[0].DAYSTOBILL = this.text_DAYSTOBILL.val()
    this.bundle.MODEL[0].LIMIT_UNBILLEDADVANCE_PCT = this.text_LIMIT_UNBILLEDADVANCE_PCT.val()
    this.bundle.MODEL[0].LIMIT_BILLEDADVANCE_PCT = this.text_LIMIT_BILLEDADVANCE_PCT.val()
    this.bundle.MODEL[0].LIMIT_PAYERCAP_PCT = this.text_LIMIT_PAYERCAP_PCT.val()

    this.LlModelsService.NG_LL_company_model_edit(this.bundle.MODEL,this.bundle)
  }

};



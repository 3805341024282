import { Injectable, Output, EventEmitter } from '@angular/core'
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';
import { LlPermissionsService } from './ll-permissions.service';
import { LlStatusCirclesService } from './ll-status-circles.service';
///import { LlModelsService } from './ll-models.service';

@Injectable({
  providedIn: 'root'
})

export class LlRolesService {

  constructor(
    private ServerService: ServerService,
    private UserService: UserService,
    private LlPermissionsService: LlPermissionsService,
    private LlStatusCirclesService: LlStatusCirclesService,
    ///private LlModelsService: LlModelsService,
  ) { }

  vars = {
    ac: "",
    CONTACTID: 0,
    STUDYID_SYS: 0,
    CONTACTROLEID: 0,
    SYSTEMROLEID: 0,
    LOANMODELID:0,
    APPLICATIONVERSION: "",
    SYSTEMROLEID_active: 0,
    PERMISSIONID: 0,
    LEADLENDERCOMPANYID:0,
    ACTION: "",
    COMPANYID_ACTIVE:0,

  }

  bundle_local = {
    activeContactRoleId: 0,
    roleCount:0,
    vars: this.vars,
    LlStatusCirclesService: this.LlStatusCirclesService,
    records: [],
    inBundle: [],
  }

  NG_LL_user_roles_get(callback01: Function, inBundle, myGrid) {

    this.bundle_local.inBundle = inBundle;

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_user_roles_get";
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      bundle_local.records = arr;      

      bundle_local.roleCount = bundle_local.records.length;
     
      callback01(bundle_local.records, bundle_local.inBundle, myGrid)
     

    }
  }

  setActiveContactRoleId(contactRoleId) {

    //console.log("contactRoleId: "+contactRoleId)


    this.bundle_local.vars.CONTACTROLEID = contactRoleId;

    for (let i = 0; i < this.bundle_local.records.length; i++) {

      if (this.bundle_local.records[i].CONTACTROLEID == this.bundle_local.vars.CONTACTROLEID) {
        this.bundle_local.vars.SYSTEMROLEID = this.bundle_local.records[i].SYSTEMROLEID
      }
    }

  }

  getActiveContactRoleId() {
    return this.bundle_local.vars.CONTACTROLEID;
  }

  getActiveSystemRoleId() {
    return this.bundle_local.vars.SYSTEMROLEID
  }
  
  getRoleCount() {
    return this.bundle_local.roleCount;
  }

  getActiveContactRole() {

    //console.log("contactRoleId: " + this.bundle_local.vars.CONTACTROLEID)

    for (let i = 0; i < this.bundle_local.records.length; i++) {

      if (this.bundle_local.records[i].CONTACTROLEID == this.bundle_local.vars.CONTACTROLEID) {
        return this.bundle_local.records[i];
      }
    }
  }

  getActiveLogo() {
    return this.bundle_local.records[0].LOGONAME;
  }

  newRole(callback01: Function, inBundle) {

    var SYSTEMROLEID = this.getActiveSystemRoleId()

    this.LlPermissionsService.NG_LL_user_role_app_permissions_get(done01, SYSTEMROLEID, this.bundle_local);

    function done01(SYSTEMROLEID, bundle_local) {

      //console.log(contactRole)

      bundle_local.LlStatusCirclesService.NG_utility_getActiveQCStages(done99, SYSTEMROLEID, bundle_local);
    }

    function done99(SYSTEMROLEID, bundle_local) {
      callback01(inBundle)

    }

  }

  //NG_LL_system_roles_QCStages_get2(callback01: Function, inBundle) {

    //this.bundle_local.vars.ac = "NG_LL_system_roles_QCStages_get";
    //this.bundle_local.vars.LOANMODELID = this.LlModelsService.getActiveId();

    //this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    //function srvReturn(arr, bundle_local) {
      //callback01(arr, inBundle)
    //}
  //}

  NG_LL_system_roles_companies_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_system_roles_companies_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      console.log(arr)
     
      if (arr.length > 0) {
        bundle_local.vars.COMPANYID_ACTIVE = arr[0].COMPANYID;
      }

      callback01(arr, inBundle, obj)
    }
  }

  setActiveCOMPANYID(COMPANYID) {
    this.bundle_local.vars.COMPANYID_ACTIVE = COMPANYID;
  }

  getActiveCOMPANYID() {
    return this.bundle_local.vars.COMPANYID_ACTIVE;
  }

  NG_LL_system_roles_get(callback01: Function, inBundle, obj) {

   this.bundle_local.vars.ac = "NG_LL_system_roles_get";

   this.ServerService.callserver(srvReturn, this.bundle_local, "show");

   function srvReturn(arr, bundle_local) {

     bundle_local.records = arr;

     callback01(arr, inBundle, obj)
   }
 }

  NG_LL_system_roles_permisions_get(callback01: Function, inBundle, obj) {

    this.bundle_local.vars.ac = "NG_LL_system_roles_permisions_get";

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///bundle_local.records = arr;

      const keys = Object.keys(arr[0]);

      for (const key of keys) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i][key] == 'Yes') {
            arr[i][key] = true;
          } else if (arr[i][key] == 'No') {
            arr[i][key] = false;
          }
        }
      }

      callback01(arr, inBundle, obj)
    }
  }

  NG_LL_system_roles_permisions_edit(ACTION, SYSTEMROLEID, PERMISSIONID) {

    this.bundle_local.vars.ac = "NG_LL_system_roles_permisions_edit";
    this.bundle_local.vars.SYSTEMROLEID_active = SYSTEMROLEID;
    this.bundle_local.vars.PERMISSIONID = PERMISSIONID;
    this.bundle_local.vars.ACTION = ACTION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      //do nothing
    }
  }


}


import { Injectable, Output, EventEmitter } from '@angular/core'
import { UserService } from '../services/user.service';
import { ServerService } from '../services/server.service';
import { LlRolesService } from './ll-roles.service';
import { LlCompaniesService } from './ll-companies.service';
import { LlUtilityService } from './ll-utility.service';


@Injectable({
  providedIn: 'root'
})

export class LlContactsService {

  @Output() refreshContactList: EventEmitter<any> = new EventEmitter();

  constructor(public ServerService: ServerService,
    private LlRolesService: LlRolesService,
    private UserService: UserService,
    private LlCompaniesService: LlCompaniesService,
    private LlUtilityService: LlUtilityService,
  ) { }
     
  vars = {
    ac:"", 
    APPLICATIONVERSION: "",
    CONTACTID:'',
    STUDYID_SYS:0,
    CONTACTROLEID:0,
    SYSTEMROLEID:0,
    LOANMODELID:0,
    COMPANYID:0,
    CONTACTID_ACTIVE:0,
    CONTACTROLEID_ACTIVE:0,
    SYSTEMROLEID_ACTIVE:0,
    COMPANYID_ACTIVE:0,
    ACTION: "",
    TYPE:"",
    contactEmail:"",
    contactFirst:"",
    contactLast:"",
  }

  bundle_local = {
    ServerService:this.ServerService,
    LlRolesService: this.LlRolesService,
    LlUtilityService: this.LlUtilityService,
    refreshContactList: this.refreshContactList,
    vars:this.vars,
    records:[],
  }  
  
  NG_LL_contact_all_get(callback01:Function, inBundle){
  
    var user = this.UserService.getActiveUser();
    ///var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_contact_all_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();   
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");
  
    function srvReturn(arr, bundle_local){

  ///console.log(arr)    
  
      bundle_local.records = arr;  

      if(bundle_local.records.length > 0 ){
        bundle_local.vars.CONTACTID_ACTIVE = bundle_local.records[0].CONTACTID;
      }else{
        bundle_local.vars.CONTACTID_ACTIVE = 0;
      }     
           
      callback01(bundle_local.records, inBundle)
     
    }   
  }

  getExistingContacts(callback01: Function, inBundle) {
    callback01(this.bundle_local.records, inBundle)
  }
    
  NG_LL_contact_roles_get(callback01:Function, inBundle, obj){
  
    var user = this.UserService.getActiveUser();
    var activeContact = this.getActiveContact();

    this.bundle_local.vars.ac = "NG_LL_contact_roles_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;
    this.bundle_local.vars.CONTACTID_ACTIVE = activeContact.CONTACTID;
    this.bundle_local.vars.COMPANYID_ACTIVE = activeContact.COMPANYID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");
  
    function srvReturn(arr, bundle_local){

  //console.log(arr)

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
          arr[i].ACCESS = 'Yes'
        } else {
          arr[i].SELECTED = false;
          arr[i].ACCESS = ''
        }
      }

      callback01(arr, inBundle, obj)
      
    }   
  }

  NG_LL_contact_roles_edit(ACTION, SYSTEMROLEID) {

    this.bundle_local.vars.ac = "NG_LL_contact_roles_edit";
    this.bundle_local.vars.SYSTEMROLEID_ACTIVE = SYSTEMROLEID;
    this.bundle_local.vars.ACTION = ACTION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {
      //do nothing
    }

  }

  NG_LL_contact_model_permissions_picklist_get(callback01: Function, inBundle, obj) {

    var user = this.UserService.getActiveUser();
    var activeContact = this.getActiveContact();

    this.bundle_local.vars.ac = "NG_LL_contact_model_permissions_picklist_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;
    this.bundle_local.vars.CONTACTID_ACTIVE = activeContact.CONTACTID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      console.log(arr)

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
          arr[i].ACCESS = 'Yes'
        } else {
          arr[i].SELECTED = false;
          arr[i].ACCESS = ''
        }
      }

      callback01(arr, inBundle, obj)

    }
  }

  NG_LL_contact_model_permissions_edit(callback01: Function, inBundle, TYPE, ACTION, LOANMODELID_ACTIVE)  {

    var user = this.UserService.getActiveUser();
    var contactRole = this.LlRolesService.getActiveContactRole();
    var activeContact = this.getActiveContact();
    
    this.bundle_local.vars.ac = "NG_LL_contact_model_permissions_edit";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();;
    this.bundle_local.vars.LOANMODELID = LOANMODELID_ACTIVE
    this.bundle_local.vars.CONTACTID_ACTIVE = activeContact.CONTACTID;
    this.bundle_local.vars.ACTION = ACTION;
    this.bundle_local.vars.TYPE = TYPE;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      if (TYPE != 'SINGLE') {
        callback01(inBundle)
      }
    }

  }

  NG_LL_contact_add(callback01: Function, contactEmail, contactFirst, contactLast, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_contact_add";    
    this.bundle_local.vars.COMPANYID_ACTIVE = this.LlCompaniesService.getActiveCompanyId();
    this.bundle_local.vars.contactEmail = contactEmail;
    this.bundle_local.vars.contactFirst = contactFirst;
    this.bundle_local.vars.contactLast = contactLast;   

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      if (arr.length > 0) {

        if (arr[0].CONTACTID_NEW == 0) {
          callback01(arr[0].ERRORMESSAGE, inBundle)

        } else {          
          bundle_local.records = arr;/////the new contact list is returned so needs to replace the old
          bundle_local.vars.CONTACTID_ACTIVE = bundle_local.records[0].CONTACTID_NEW; //set new contact as default for display
          callback01('', inBundle)
        }
      }      
    }
  }

  NG_LL_contact_edit(callback01: Function, contactEmail, contactFirst, contactLast, COMPANYID_ACTIVE, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_contact_edit";
    this.bundle_local.vars.COMPANYID_ACTIVE = COMPANYID_ACTIVE;
    this.bundle_local.vars.contactEmail = contactEmail;
    this.bundle_local.vars.contactFirst = contactFirst;
    this.bundle_local.vars.contactLast = contactLast;

    console.log(COMPANYID_ACTIVE)
    console.log(contactEmail)
    console.log(contactFirst)
    console.log(contactLast)

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");
  
    function srvReturn(arr, bundle_local){

      console.log(arr)

      if (arr.length > 0) {

        if (arr[0].ERRORMESSAGE != '') {
          callback01(arr[0].ERRORMESSAGE, inBundle);

        } else {

          for (let i = 0; i < bundle_local.records.length; i++) {
            if (bundle_local.records[i].CONTACTID == bundle_local.vars.CONTACTID_ACTIVE) {
              bundle_local.records[i].CONTACTEMAIL = contactEmail;
              bundle_local.records[i].CONTACTFIRST = contactFirst;
              bundle_local.records[i].CONTACTLAST = contactLast;
              bundle_local.records[i].COMPANYID = COMPANYID_ACTIVE;
            }
          }

          if (arr[0].CHANGED_COMPANY == "Yes") {
            bundle_local.refreshContactList.emit()
          }
        }
      }      
    }   
  }
    
  NG_LL_contact_account_reset(callback01){
  
    this.bundle_local.vars.ac = "NG_LL_contact_account_reset";

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");
  
    function srvReturn(arr, bundle_local){

      for(let i=0; i< bundle_local.records.length; i++){
        if(bundle_local.records[i].CONTACTID == bundle_local.vars.CONTACTID_ACTIVE){
            bundle_local.records[i].SENTSTUDYINVITEYESNO = 'Yes'
        }
      }
  
      callback01()
     
    }   
  }

  NG_LL_contact_all_model_permissions_picklist_get(callback01: Function, inBundle, obj) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_contact_all_model_permissions_picklist_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    
    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr) 

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].SELECTED.toLowerCase() == 'yes') {
          arr[i].SELECTED = true;
          arr[i].ACCESS = 'Yes'
        } else {
          arr[i].SELECTED = false;
          arr[i].ACCESS = ''
        }
      }

      callback01(arr, inBundle, obj)

    }
  }

  NG_LL_contact_model_permissions_edit_ALL(ACTION, LOANMODELID, CONTACTID) {

    var user = this.UserService.getActiveUser();
    var contactRole = this.LlRolesService.getActiveContactRole();

    this.bundle_local.vars.ac = "NG_LL_contact_model_permissions_edit";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();;
    this.bundle_local.vars.LOANMODELID = LOANMODELID
    this.bundle_local.vars.CONTACTID_ACTIVE = CONTACTID
    this.bundle_local.vars.ACTION = ACTION;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {


    }

  }

  setActiveContactId(CONTACTID) {
    this.bundle_local.vars.CONTACTID_ACTIVE = CONTACTID;
  }

  getActiveContactId() {
    return this.bundle_local.vars.CONTACTID_ACTIVE;
  }

  getActiveContact(callback01?: Function, bundle?) {
    ///console.log("this.bundle_local.vars.CONTACTID_ACTIVE: "+this.bundle_local.vars.CONTACTID_ACTIVE)   

    let record;

    for (var i = 0; i < this.bundle_local.records.length; i++) {
      if (this.bundle_local.records[i].CONTACTID == this.bundle_local.vars.CONTACTID_ACTIVE) {

        record = this.bundle_local.records[i];

      }

    }

    if (callback01 == null) {
      return record
    } else {
      callback01(record, bundle)
    }

  }

}

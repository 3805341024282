<div #mainDiv_email_template>

  <div *ngIf="bundle.showObj && bundle.function != 'html'">

    <!--TEMPLATE NAME-->
    <div style="float:left; padding-top:5px;">
      <div class="txtSmallB" [style.width.px]="150" style="float: left;padding-left:0px">
        Template Name:
      </div>

      <div style="float:left; padding-left:5px">
        <jqxTextArea #text_templateName
                     [width]="400"
                     [height]="20"
                     [disabled]="bundle.disableEdit">

        </jqxTextArea>
      </div>
    </div>

    <!--EMAIL FROM-->
    <div style="clear:left; padding-top:0px;">

      <div style="float:left; padding-top:5px;">
        <div class="txtSmallB" [style.width.px]="150" style="float: left; padding-left:0px">
          Email From:
        </div>

        <div style="float:left; padding-left:5px">
          <jqxTextArea #text_fromEmail
                       [width]="400"
                       [height]="20"
                       [disabled]="bundle.disableEdit">

          </jqxTextArea>
        </div>
      </div>
    </div>

    <!--EMAIL SUBJECT LINE-->
    <div style="clear:left; padding-top:0px;">

      <div style="float:left; padding-top:5px;">
        <div class="txtSmallB" [style.width.px]="150" style="float: left;padding-left:0px">
          Email Subject Line:
        </div>

        <div style="float:left; padding-left:5px">
          <jqxTextArea #text_templateSubjectLine
                       [maxLength]="250"
                       [width]="bundle.editorW - 170"
                       [height]="20"
                       [disabled]="bundle.disableEdit">
          </jqxTextArea>
        </div>
      </div>
    </div>

    <!--EMAIL BODY-->
    <div style="clear:left; padding-top:10px;">

      <div style="float:left; padding-top:5px;">
        <div class="txtSmallB" [style.width.px]="150" style="float: left;">
          Email Body:
        </div>
      </div>
    </div>

    <div [style.top.px]="bundle.editorY" style="position:absolute; ">

      <div [hidden]="bundle.disableEdit">
        <jqxEditor #editor_email_template [theme]="'material'"
                   [height]="bundle.editorH"
                   [width]="bundle.editorW">
        </jqxEditor>
      </div>

      <div [hidden]="!bundle.disableEdit">
        <jqxPanel #SP_newsletter_scrollpane
                  style="background-color: transparent;
                  border: none;
                  outline: none;
                  border-top: 1px solid  rgb(160, 158, 158);
                  border-bottom: 1px solid  rgb(160, 158, 158);"
                  [autoUpdate]="true"
                  [rtl]="false"
                  [width]="bundle.editorW"
                  [height]="bundle.editorH">

          <div [style.width.px]="bundle.editorW - 30" style="padding-top: 10px; padding-left: 20px; overflow-wrap: break-word; ">
            <div [innerHTML]="bundle.body | sanitizeHtml"></div>
          </div>
        </jqxPanel>
      </div>

    </div>

    <div [hidden]="bundle.disableEdit" id=bottomLine [style.top.px]="bundle.spB" style="position:absolute; ">

      <div style="float: left; padding-left: 15px;">
        <div class="wordBtn" (click)="editTemplate()">Save Changes</div>
      </div>

      <div class="DQRAlarm" style="float: left; padding-left: 10px;">
        {{bundle.editMsg}}
      </div>

    </div>


  </div>

  <div *ngIf="bundle.showObj && bundle.function == 'html'">
    
      <jqxPanel #SP_newsletter_scrollpane
                style="background-color: transparent;
                  border: none;
                  outline: none;
                  border-top: 1px solid  rgb(160, 158, 158);
                  border-bottom: 1px solid  rgb(160, 158, 158);"
                [autoUpdate]="true"
                [rtl]="false"
                [width]="bundle.editorW"
                [height]="bundle.objH - 40">

        <div [style.width.px]="bundle.editorW - 30" style="padding-top: 10px; padding-left: 20px; overflow-wrap: break-word; ">
          <!--<div [innerHTML]="bundle.body | sanitizeHtml"></div>--->
          {{bundle.body}}

        </div>
      </jqxPanel>
   
  </div>
</div>

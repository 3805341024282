
export function javaDate_to_ISODate(date, format):string{  
    ///Mon Dec 24 2018 19:00:00 GMT-0500 (Eastern Standard Time)
    
    var day = date.getDate();

    if(Number(day) < 10){
        day = "0"+day; 
    }

    var month = date.getMonth() + 1;

    if(Number(month) < 10){
        month = "0"+month; 
    }

    var year = date.getFullYear();
 
    if(format == "Year"){
        return year+"-00-00";

    }else if (format == "Year-Month"){
        return year+"-"+month+"-00";

    }else{
        return year+"-"+month+"-"+day;
    }
    
   
    
}

export function ISODate_to_DDMMMYYYY(ISODate:String, format ):string{  
    ///2018-12-24T19:00:00Z
    var month_names =["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
    var day = ISODate.substring(8, 10); 
    var month_index = Number( ISODate.substring(5, 7))
    var year = ISODate.substring(0, 4);

    //console.log("day"+day)
    //console.log("month_index"+month_index)  
    //console.log("year"+year)

    

    if(format == "Year"){
        return year;

    }else if (format == "Year-Month"){
        return month_names[month_index - 1]+year;

    }else{
        return day +month_names[month_index - 1]+year;
    }
   
}
export function STDDate_to_CCYYMMDD(STD:String):string{  
    ///  1/1/2021
    
    let res = STD.split("/");

    let year = res[2];
    let month = res[0];
    let day = res[1];

    if(Number(month) <10){
        month = '0'+month
    }

     if(Number(day) <10){
        day = '0'+day
    }


    return year+month+day;
    
   
}
export function DDMMMYYYY_to_ISODate(date:String):string{  
    ///2018-12-24T19:00:00Z
    var month_names =["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
    var day = date.substring(0, 2); 
    var month_txt = date.substring(2, 5).toLowerCase(); 
    var month_num:any
    var year = date.substring(5, 9);

    for(let i=0; i< month_names.length; i++){
     
        if(month_names[i].toLowerCase() == month_txt){
            month_num = i + 1;
        }

    }

    return year +"-"+ month_num +"-"+ day;
   
}
export function HHMM12_to_HHMM24(time:String):string{  
    //1:00:00 AM
    var res = time.split(":");

    var HH = res[0]
    var MM = res[1]
    var SS = res[2].substring(0, 2)
    var AM_PM = res[2].substring(3, 5)

    if(HH == "12" && AM_PM == "AM"){
        HH= "24"
    }else if (Number(HH) < 12 && AM_PM == "PM"){
        HH = String(Number(HH) + 12)
    }

    return HH +":"+ MM +":"+ SS
   
}
export function YYYYMMDD_add_hyphens(YYYYMMDD:String):string{  
    
    if(YYYYMMDD.length > 0){
        var year = YYYYMMDD.substring(0, 4);
        var month = YYYYMMDD.substring(4, 6);
        var day = YYYYMMDD.substring(6, 8);

        return year+"-"+month+"-"+day;
        
    }else{

        return String(YYYYMMDD)
    }
}

export function YYYYMMDD_to_shortDate(YYYYMMDD:String):string{  
    
    var year = YYYYMMDD.substring(0, 4);
    var month = YYYYMMDD.substring(4, 6);
    var day = YYYYMMDD.substring(6, 8);

    return month +"-"+ day +"-"+year
}

export function CCYYMMDD_to_ISODate(YYYYMMDD:String):string{  
    
    var year = YYYYMMDD.substring(0, 4);
    var month = YYYYMMDD.substring(4, 6);
    var day = YYYYMMDD.substring(6, 8);

    return year +"-"+ month +"-"+ day;
}

export function javaDate_to_YYYYMMDD(date):string{  
    ///Mon Dec 24 2018 19:00:00 GMT-0500 (Eastern Standard Time)

 /// console.log(date)


    var day = date.getDate();

    if(Number(day) < 10){
        day = "0"+day; 
    }

    var month = date.getMonth() + 1;

    if(Number(month) < 10){
        month = "0"+month; 
    }

    var year = date.getFullYear();
 
    return year+""+month+""+day;
   
}


export function javaDate_to_shortDate(date):string{  
    ///Mon Dec 24 2018 19:00:00 GMT-0500 (Eastern Standard Time)
    
    var day = date.getDate();

    if(Number(day) < 10){
        day = "0"+day; 
    }

    var month = date.getMonth() + 1;

    if(Number(month) < 10){
        month = "0"+month; 
    }

    var year = date.getFullYear();
 
    return month +"-"+ day +"-"+year
   
}

export function javaDate_to_ISOTime(date):string{  
    ///Mon Dec 24 2018 19:00:00 GMT-0500 (Eastern Standard Time)
  
    var HH = date.getHours();

    if(Number(HH) < 10){
        HH = "0"+HH; 
    }

    var MM = date.getMinutes();

    if(Number(MM) < 10){
        MM = "0"+MM; 
    }

    var SS = date.getSeconds();

    if(Number(SS) < 10){
        SS = "0"+SS; 
    }

    return HH+":"+MM+":"+SS;
   
}

export function getDate_CCYYMMDD():string{  

    var currentDate = new Date();

    return javaDate_to_YYYYMMDD(currentDate)
}

export function getTime_HHMM24():string{  

   var date = new Date();

    var HH = String(date.getHours());

    if(Number(date.getHours()) < 10){
        HH = "0"+date.getHours(); 
    }

    var MM = String(date.getMinutes())

    if(Number(date.getMinutes()) < 10){
        MM = "0"+date.getMinutes(); 
    }

  

    return HH+""+MM
}

export function subtract30DaysFrom_YYYYMMDD(YYYYMMDD:String):string{  
    
    var d = new Date(YYYYMMDD_add_hyphens(YYYYMMDD));

    d.setDate(d.getDate()-30);

    var r = javaDate_to_YYYYMMDD(d);

    return r;
}

export function addOneDayTo_CCYYMMDD(CCYYMMDD: String): any {

  var d = new Date(YYYYMMDD_add_hyphens(CCYYMMDD));

  d.setDate(d.getDate() + 1);

  //var r = javaDate_to_YYYYMMDD(d);

  //return r;

  return d
}

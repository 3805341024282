<div #mainDiv_ll_model_locations_sp>

  <canvas #vertTab_ll_model_locations_sp></canvas>

  <!--scrollpane_ll_model_locations_sp-->
  <div *ngIf="bundle.showObj">

    <div class="spLabel" [ngStyle]="{'top.px':bundle.lblY, 'width.px':bundle.spW}">
      {{bundle.lable}}
    </div>

    <jqxPanel #scrollpane_ll_model_locations_sp
              [style.top.px]="bundle.spY"
              style="background-color: transparent;
              border: none;
              outline: none;
              border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <tr *ngFor="let item of bundle.records" style="white-space: nowrap;">



        <div class="spRowLabel"
             [id]="bundle.prefix + item.FOLDERID"
             title={{item.FOLDERNAME}}
             [style.width.px]="bundle.spW - 20" (click)="select($event)">
          {{item.FOLDERNAME}}
        </div>

      </tr>



    </jqxPanel>


  <!--BOTTOM-->
  <div id=bottomLine [style.top.px]="bundle.spB">

    <div id=add_ll_model_locations_sp *ngIf="bundle.canAdd" style="padding-top:10px;">

      <div class="wordBtnGroup">
        <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_plus.png" alt="" (click)="noAction()">
        <div class="wordBtn" (click)="noAction()">Add</div>
      </div>

      <jqxTooltip style="float: left;"
                  [position]="'right'"
                  [name]="'movieTooltip'"
                  [autoHideDelay]="30000"
                  [content]="'Click to add a user to this the selected company'"
                  [opacity]="1.0"
                  [width]="200">

        <img class="infoBtn_12" src="assets/infoBtn.png" />

      </jqxTooltip>

      <jqxPopover #popover_addContact style="font-size: 14px; font-family: Verdana;"
                  [offset]='{ left: 30, top: 0 }'
                  [arrowOffsetValue]="-30"
                  [width]="600"
                  [height]="300"
                  [position]="'top'"
                  [title]=""
                  [showCloseButton]="true"
                  [selector]="'#add_ll_model_locations_sp'">

        <div style="position:absolute; top:50px; left:10px;  ">
          <img height=50 style="width: auto;" src="assets/icon_addSubject.png">
        </div>

        <div style="position:absolute; top:50px; left:70px; ">

          <div class="txtBold" style="width:475px;">
            Add Location
          </div>

          <div style="clear: left; padding-top:10px; ">

            <div>
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:10px; width:100px;">Email:</div>

            <div style="float: left; padding-left:10px;">
              <jqxTextArea #text_contactEmail
                           [width]="300"
                           [height]="25"
                           [placeHolder]="'Type email address'">
              </jqxTextArea>
            </div>
          </div>

          <div style="clear: left; padding-top:0px; ">

            <div>
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:10px; width:100px; ">First Name:</div>

            <div style="float: left; padding-left:10px;">
              <jqxTextArea #text_contactFirst
                           [width]="300"
                           [height]="25"
                           [placeHolder]="'Type first name here'">
              </jqxTextArea>
            </div>
          </div>

          <div style="clear: left; padding-top:0px; ">

            <div>
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:10px; width:100px;">Last Name:</div>

            <div style="float: left; padding-left:10px;">
              <jqxTextArea #text_contactLast
                           [width]="300"
                           [height]="25"
                           [placeHolder]="'Type last name here'">
              </jqxTextArea>
            </div>
          </div>

          <div class="wordBtnGroup_popover" style="float:left; padding-top:10px; padding-left:250px; ">
            <div class="wordBtn" (click)="add()">Click to Add</div>
          </div>

          <!--<div [hidden]="!bundle.showRqrMsg" style="clear:left;">
            <img height=8 style="float:left; width: auto;" src="assets/icon_asterisk.png">

            <div class="DQRAlarm" style="float:left; padding-left:5px;">
              Required
            </div>

          </div>--->


        </div>



      </jqxPopover>

    </div>


  </div>

  <!--SEARCH-->
  <div id=search_ll_model_locations_sp *ngIf="bundle.canFilter"
       [style.top.px]="bundle.lblY - 0"
       [style.width.px]="bundle.spW"
       [style.left.px]="bundle.spW  - 10 "
       style="position: absolute;">

    <div class="wordBtnGroup">
      <div *ngIf="bundle.searchText  == '' ">
        <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
      </div>
      <div *ngIf="bundle.searchText  != '' ">
        <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
      </div>
    </div>

    <jqxPopover #popover_search
                [offset]='{ left: -85, top: 0 }'
                [arrowOffsetValue]="-0"
                [width]="220"
                [position]="'top'"
                [title]="'Filter Categories'"
                [selector]="'#search_ll_model_locations_sp'">

      <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">

        <div (keyup)="onKeyUp($event)" style="float: left; ">
          <jqxTextArea #text_search
                       [width]="140"
                       [height]="25"
                       [placeHolder]="'Type Search'">

          </jqxTextArea>
        </div>

        <div style="float: left; padding-left:10px; padding-top:0px; ">
          <div style="clear:left">
            <div class="wordBtn" (click)="search()">Search</div>
          </div>
          <div style="clear:left">
            <div class="wordBtn" (click)="clear()">Clear</div>
          </div>
        </div>
      </div>

    </jqxPopover>
  </div>

</div>


<div *ngIf="bundle.showTab && bundle.showRemaining">

  <div [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; ">
    <ll-model-displaydata id='ll_model_locations_sp'></ll-model-displaydata>
  </div>


  <!--ll-model-metrics-sp-->
  <!--<div *ngIf="bundle.activeModelFolderName == 'metrics'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; ">
    <ll-model-metrics-sp></ll-model-metrics-sp>
  </div>--->

  <!--ll-model-displaydata-->
  <!---<div *ngIf="bundle.activeModelFolderName == 'aging' || bundle.activeModelFolderName == 'summary' "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; ">
    <ll-model-displaydata></ll-model-displaydata>
  </div>--->


  <!---<div *ngIf="bundle.activeModelFolderName == 'cms'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; ">
    <ll-model-cms-functions-sp></ll-model-cms-functions-sp>

  </div>--->
  </div>
</div>


import { Injectable, Output, EventEmitter, Directive } from '@angular/core'
import { UserService } from '../services/user.service';
import { ServerService } from '../services/server.service';
import { LlRolesService } from './ll-roles.service';
import { YYYYMMDD_add_hyphens } from './utility-date';

@Directive()
@Injectable({
  providedIn: 'root'
})

export class LlCompaniesService {

  ////@Output() addedCompany: EventEmitter<any> = new EventEmitter();
  @Output() addedModel: EventEmitter<any> = new EventEmitter();

  constructor(public ServerService: ServerService,
    private UserService: UserService,
    private LlRolesService: LlRolesService,
  ) { }

  vars = {
    ac: "",
    APPLICATIONVERSION: "",
    STUDYID_SYS: 0,
    CONTACTROLEID: 0,
    SYSTEMROLEID: 0,
    COMPANYSTATUS:'',
    SYSTEMCOMPANYTYPEID: 0,
    TAXONOMYID_SYS:0,
    CONTACTID: 0,
    COMPANYID:0,
    TYPE: "",
    ACTION: "",
    SITEID: "",
    COMPANYNAME: "",
    COMPANYABREV: "",
    COMPANYEMAILADDRESS: "",
    COMPANYCITY: "",
    COMPANYSTATE: "",
    COMPANYCOUNTRY: "",
    SYSTEMPROVIDERSTAGEID: 0,
    COMPANYID_ACTIVE: 0,
    COMPANYSTATUSACTIVEINACTIVE:'',
    MONTHEND_NUM: '',
    VARIABLEFIELD01: '',
    VARIABLEFIELD02: '',
    VARIABLEFIELD03: '',
    VARIABLEFIELD04: '',
    VARIABLEFIELD05: '',
    VARIABLEFIELD06: '',
    VARIABLEFIELD07: '',
    VARIABLEFIELD08: '',
   
  }

  topRecord = {
    COMPANYID: 0,
    FOLDERID: 0,
    FOLDERNAME: "All",
    COMPANYSTATUSACTIVEINACTIVE: 'Active'
  }

  bundle_local = {
    activeCompanyId: 0,
    activeLoanModelId:0,
    ServerService: this.ServerService,
    LlRolesService: this.LlRolesService,
    activePopupTab: 0,
    vars: this.vars,
    records: [],
    companyTypes: [],
    varHolder: [],
    inBundle: [],
    models:[],
    topRecord: this.topRecord,
    monthEndDate_num: '',
    MODELLENGTH: 0,
  }

  NG_LL_user_companies_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_user_companies_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      //console.log(arr)
      
      bundle_local.records = arr;

      bundle_local.vars.COMPANYID_ACTIVE = 0;

      bundle_local.vars.ac = "NG_LL_company_types_get";

      bundle_local.ServerService.callserver(srvReturn, bundle_local, "show");

      function srvReturn(arr, bundle_local) {

        ///console.log(arr)

        bundle_local.companyTypes = arr;

        callback01(bundle_local.records, inBundle)
      }      
    }
  }

  getExistingCompanies(callback01: Function, inBundle) {
     callback01(this.bundle_local.records, inBundle);    
  }

  setActive(companyId) {
    this.bundle_local.vars.COMPANYID_ACTIVE = companyId;
  }

  getActiveCompanyId() {
    return this.bundle_local.vars.COMPANYID_ACTIVE;
  }

  getActive(callback01?: Function, bundle?) {

    ///console.log("getActive: " + this.bundle_local.vars.COMPANYID_ACTIVE)

    for (var i = 0; i < this.bundle_local.records.length; i++) {


      if (this.bundle_local.records[i].COMPANYID == this.bundle_local.vars.COMPANYID_ACTIVE) {


        if (callback01 == null) {
          return this.bundle_local.records[i];  //if the actual active record is then found use it
        } else {
          callback01(this.bundle_local.records[i], bundle);
        }

      }

    }

  }
      
  NG_LL_company_get(callback01: Function, inBundle) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_company_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.COMPANYID = user.COMPANYID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ///console.log(arr)

      if (arr.length > 0) { 
        bundle_local.MODELLENGTH = arr[0].MODELLENGTH;
      }

      callback01(arr, inBundle)
    }
  }

  getModelLength() {
    return this.bundle_local.MODELLENGTH
  }

  NG_LL_company_types_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_company_types_get";

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      bundle_local.companyTypes = arr;

      callback01(arr, inBundle)
    }
  }

  getExistingCompanyTypes() {
    return this.bundle_local.companyTypes
  }

  NG_LL_company_taxonomyCodes_get(callback01: Function, inBundle) {

    this.bundle_local.vars.ac = "NG_LL_company_taxonomyCodes_get";

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      callback01(arr, inBundle)
    }
  }

  NG_LL_company_edit(callback01: Function, inBundle, company) {

    var user = this.UserService.getActiveUser();
    var contactRole = this.LlRolesService.getActiveContactRole();
    
    this.bundle_local.vars.ac = "NG_LL_company_edit";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.CONTACTID = user.CONTACTID;
    this.bundle_local.vars.CONTACTROLEID = this.LlRolesService.getActiveContactRoleId();
    this.bundle_local.vars.SYSTEMROLEID = this.LlRolesService.getActiveSystemRoleId();
    this.bundle_local.vars.COMPANYID_ACTIVE = company.COMPANYID;

    const keys = Object.keys(company);

    for (const key of keys) {
      this.bundle_local.vars[key.toUpperCase()] = company[key.toUpperCase()];
    }

    ///console.log(this.bundle_local.vars)

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");
    
    function srvReturn(arr, bundle_local) {

      //console.log(arr)

      //if (arr[0].message != "") {
      callback01(inBundle)

      //}
  
    }
  }

  NG_LL_company_add(callback01: Function, inBundle, COMPANYNAME, SYSTEMCOMPANYTYPEID) {

    this.bundle_local.vars.ac = "NG_LL_company_add";

    this.bundle_local.vars.COMPANYNAME = COMPANYNAME;
    this.bundle_local.vars.SYSTEMCOMPANYTYPEID = SYSTEMCOMPANYTYPEID;

    this.bundle_local.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      ////console.log(arr)

      if (arr.length > 0) {
        
        if (arr[0].COMPANYID_NEW == 0) {
          callback01(arr[0].ERRORMESSAGE, inBundle)

        } else {
          bundle_local.records = arr;/////the new company list is returned so needs to replace the old
          bundle_local.vars.COMPANYID_ACTIVE = bundle_local.records[0].COMPANYID_NEW; //set new contact as default for display
          callback01('', inBundle)
        }
      } 


    }
  }
}

  



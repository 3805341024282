<div #mainDiv_ll_admin_sp>

  <canvas #vertTab_ll_admin_sp></canvas>

  <div *ngIf="bundle.showObj">

    <div class="spLabel" [ngStyle]="{'top.px':bundle.lblY, 'width.px':bundle.spW}">
      {{bundle.lable}}
    </div>

    <jqxPanel #scrollpane_ll_admin_sp [style.top.px]="bundle.spY" style="background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <div *ngFor="let group of bundle.groups " style="white-space: nowrap;">

        <div class="spCategory">{{group}}</div>

      <tr *ngFor="let item of bundle.records | visitCategory:{GROUP: group} ">


        <div class="statusCircle_holder" *ngIf="group != 'Models'" [style.width.px]="bundle.spW">

          <div class="spRowLabel_noIcon" [id]="bundle.prefix + item.FOLDERID" title={{item.FOLDERNAME}} [style.width.px]="bundle.spW" (click)="select($event)">
            {{item.FOLDERNAME}}
          </div>

        </div>

        <div class="statusCircle_holder" *ngIf="group == 'Models' " [style.width.px]="bundle.spW">

          <!--<img class="editGear" *ngIf="bundle.canView " [id]="'GR' + item.FOLDERID" src="assets/icon_gear.png" alt="" (click)="edit($event)">-->

          <div class="spRowLabel" [id]="bundle.prefix + item.FOLDERID" [style.width.px]="bundle.spW - 25" (click)="select($event)">
            {{item.FOLDERNAME}}
          </div>

        </div>

      </tr>
  </div>

  </jqxPanel>



  <!--BOTTOM-->
  <div id=bottomLine [style.top.px]="bundle.spB">
  </div>

  <!--SEARCH-->
  <div id=search_ll_admin_sp *ngIf="bundle.canFilter"
       [style.top.px]="bundle.lblY - 0"
       [style.width.px]="bundle.spW"
       [style.left.px]="bundle.spW  - 10 "
       style="position: absolute;">


    <div class="wordBtnGroup">
      <div *ngIf="bundle.searchText  == '' ">
        <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
      </div>
      <div *ngIf="bundle.searchText  != '' ">
        <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
      </div>
    </div>

    <jqxPopover #popover_search
                [offset]='{ left: -85, top: 0 }'
                [arrowOffsetValue]="-0"
                [width]="220"
                [position]="'top'"
                [title]="'Filter Categories'"
                [selector]="'#search_ll_admin_sp'">

      <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">
        <!--<img height = 20 style = "width: auto; "  src="assets/icon_magGlass.png">-->
        <div (keyup)="onKeyUp($event)" style="float: left; ">
          <jqxTextArea #text_search
                       [width]="140"
                       [height]="25"
                       [placeHolder]="'Type Search'">

          </jqxTextArea>
        </div>

        <div style="float: left; padding-left:10px; padding-top:0px; ">
          <div style="clear:left">
            <div class="wordBtn" (click)="search()">Search</div>
          </div>
          <div style="clear:left">
            <div class="wordBtn" (click)="clear()">Clear</div>
          </div>
        </div>
      </div>



    </jqxPopover>
  </div>


  <!-- Incomplete Forms (-90) uses horz-display dimentions-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -90"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

    <ll-admin-generic-dg id="info_request-pending"></ll-admin-generic-dg>
  </div>

  <div *ngIf="bundle.showRemaining && bundle.selectedId == -89"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

    <ll-admin-generic-dg id="info_request-pending-display"></ll-admin-generic-dg>
  </div>

  <!-- ll-info-request-lisiting-sp-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -91"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

    <ll-info-request-lisiting-sp></ll-info-request-lisiting-sp>
  </div>

  <!-- Orgs/People (-100) uses horz-display dimentions-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -100"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

    <ll-companys-sp></ll-companys-sp>
  </div>

  <!-- Edit Roles (-105) uses horz-display dimentions-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -105 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

    <ll-admin-role-companies-sp id="edit_roles"></ll-admin-role-companies-sp>
  </div>

  <!--Role Users Edit-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -141 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">

    <ll-admin-role-companies-sp id="role_users"></ll-admin-role-companies-sp>
  </div>


  <!-- View Logins (-115) view user login records-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -115 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="user_logins_dg"></ll-admin-generic-dg>
  </div>

  <!-- View Logins (-116) view user login records-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -116 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="user_emails_dg"></ll-admin-generic-dg>
  </div>

  <!-- Admin Trail (-120) -->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -120  "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="admin_trail_dg"></ll-admin-generic-dg>
  </div>

  <!-- User Entry Trail (-121) -->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -121  "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="user_entry_dg"></ll-admin-generic-dg>
  </div>

  <!--<div *ngIf="bundle.showRemaining && bundle.selectedId == -122  "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="NG_utility_customerComments_get"></ll-admin-generic-dg>
  </div>-->
  <!-- adminViewErrors (-125) -->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -125  "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="view_errors_dg"></ll-admin-generic-dg>
  </div>

  <!-- Email Templates (-129) -->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -129 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-email-templates-sp></ll-admin-email-templates-sp>
  </div>

  <!-- Email Routines (-130) -->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -130 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-email-routines-sp></ll-admin-email-routines-sp>
  </div>

  <!-- Model Permissions -->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -131 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="model_permissions_dg"></ll-admin-generic-dg>
  </div>

  <!--Accounting-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -132 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="admin_accounting_dg"></ll-admin-generic-dg>
  </div>

  <!--AR Model Accounting-->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -133 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="admin_ARModels_dg"></ll-admin-generic-dg>
  </div>

  <!-- Loan Processing (-135) -->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -135 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <ll-admin-generic-dg id="NG_LL_model_AR_admin_client_interface_records_get"></ll-admin-generic-dg>
  </div>


  <!-- BUILD (-140) -->
  <div *ngIf="bundle.showRemaining && bundle.selectedId == -140 "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;">
    <!--<loan-build-visits-sp></loan-build-visits-sp>-->
  </div>



</div>

import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';

import { LlModelsService } from '../ll-models.service';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { jqxDateTimeInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput";

import { removeTextChars } from "../utility";


@Component({
  selector: 'll-info-request-add',
  templateUrl: './ll-info-request-add.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlInfoRequestAddComponent implements OnInit {

  @ViewChild('mainDiv_ll_info_request_add') mainDiv: ElementRef;
  @ViewChild('date_start') date_start: jqxDateTimeInputComponent;
  @ViewChild('text_count') text_count: jqxTextAreaComponent;

  constructor(
    private LlModelsService: LlModelsService,
    private LlInfoRequestsService: LlInfoRequestsService,

  ) {
  }

  bundle = {
    showObj: false,
    SYSTEMPRODUCTID: 0,
    frequencyChoices: [],
    FREQUENCY: "",
    everyArr: [],
    EVERY: "",
    dayArr: [],
    DAY: "",
    COMPANYTYPENAME: "",
    STARTDATE: "",
    EVENTCOUNT: "1",
    STUDYVISIT: '',
    STUDYVISITID: 0,
    options: [],
    countries: [],
    companyStagesAll: [],
    companyStagesChoices: [],
    visits: [],
    visitChoices: [],

    objH: 0,
    objW: 0,
    headerY: 10,
    headerW: 0,
    headerH: 200,
    bodyH: 0,
    col01W: 0,
    col02W: 0,

  }

  ngOnDestroy() {}
  ngOnInit() { }

  ngAfterViewInit(): void {
    this.setVars();
  }
  
  setVars() {

    ///this.bundle.showObj = true;

    setTimeout(() => {
      this.bundle.objW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.objH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight - 10;

      this.bundle.col01W = 100;
      this.bundle.col02W = this.bundle.objW - this.bundle.col01W - 100;

      this.getData()
    });
  }

  getData() {

    this.LlInfoRequestsService.NG_LL_infoReq_studyVisitChoices_get(done01, this.bundle);

    function done01(arr, bundle) {

      bundle.visits = arr;

      for (var i = 0; i < arr.length; i++) {
        bundle.visitChoices.push({ choice: arr[i].STUDYVISITNAME, SELECTED: false, STUDYVISITID: arr[i].STUDYVISITID })
      }

      setTimeout(() => {
        bundle.frequencyChoices.push({ choice: 'Once', SELECTED: false })
        bundle.frequencyChoices.push({ choice: 'Monthly', SELECTED: false })

        bundle.everyArr.push({ choice: 'First', SELECTED: false })
        bundle.everyArr.push({ choice: 'Last', SELECTED: false })

        bundle.dayArr.push({ choice: 'Day', SELECTED: false })
        bundle.dayArr.push({ choice: 'Monday', SELECTED: false })
        bundle.dayArr.push({ choice: 'Tuesday', SELECTED: false })
        bundle.dayArr.push({ choice: 'Wednesday', SELECTED: false })
        bundle.dayArr.push({ choice: 'Thursday', SELECTED: false })
        bundle.dayArr.push({ choice: 'Friday', SELECTED: false })
        bundle.dayArr.push({ choice: 'Saturday', SELECTED: false })
        bundle.dayArr.push({ choice: 'Sunday', SELECTED: false })

        setTimeout(() => {
          bundle.showObj = true;
        });
      });

    }
  }

  text_count_onKeyUp(event) {
    this.text_count.val(removeTextChars(this.text_count.val()));
  }

  selectVisit(event) {

    ///console.log(event.target.innerText)

    this.bundle.STUDYVISIT = event.target.innerText

    for (var i = 0; i < this.bundle.visitChoices.length; i++) {
      this.bundle.visitChoices[i].SELECTED = false;


      if (this.bundle.STUDYVISIT == this.bundle.visitChoices[i].choice) {
        this.bundle.visitChoices[i].SELECTED = true;
        this.bundle.STUDYVISITID = this.bundle.visitChoices[i].STUDYVISITID

        //console.log("this.bundle.STUDYVISITID: " + this.bundle.STUDYVISITID)
      }
    }
  }

  selectFrequency(event) {

    ///console.log(event.target.innerText)

    this.bundle.FREQUENCY = event.target.innerText

    for (var i = 0; i < this.bundle.frequencyChoices.length; i++) {
      this.bundle.frequencyChoices[i].SELECTED = false;

      if (this.bundle.FREQUENCY == this.bundle.frequencyChoices[i].choice) {
        this.bundle.frequencyChoices[i].SELECTED = true;
      }
    }
  }

  selectEvery(event) {
    ///this.bundle.COMPANYSTATE  = this.bundle.states[event.args.index];

    console.log(event.target.innerText)

    this.bundle.EVERY = event.target.innerText

    for (var i = 0; i < this.bundle.everyArr.length; i++) {
      this.bundle.everyArr[i].SELECTED = false;

      if (this.bundle.EVERY == this.bundle.everyArr[i].choice) {
        this.bundle.everyArr[i].SELECTED = true;
      }
    }
  }

  selectDay(event) {
    ///this.bundle.COMPANYSTATE  = this.bundle.states[event.args.index];

    //console.log(event.target.innerText)

    this.bundle.DAY = event.target.innerText

    for (var i = 0; i < this.bundle.dayArr.length; i++) {
      this.bundle.dayArr[i].SELECTED = false;

      if (this.bundle.DAY == this.bundle.dayArr[i].choice) {
        this.bundle.dayArr[i].SELECTED = true;
      }
    }
  }

  noAction() {
    //needed HTML buttons point to this 
  }


  addEvent() {

    this.bundle.STARTDATE = this.date_start.val();

    //console.log(this.bundle.STARTDATE)

    let go = false;

    if (this.bundle.STARTDATE != "" && this.bundle.STUDYVISITID != 0) {

      if (this.bundle.FREQUENCY.toLowerCase() == "once") {
        go = true;
      }

      if (this.bundle.FREQUENCY.toLowerCase() == "weekly") {

        this.bundle.EVENTCOUNT = this.text_count.val();

        if (this.bundle.EVENTCOUNT != "" && this.bundle.DAY != "") {
          go = true;
        }

      }

      if (this.bundle.FREQUENCY.toLowerCase() == "monthly") {

        this.bundle.EVENTCOUNT = this.text_count.val();

        //console.log(this.bundle.EVENTCOUNT);

        if (this.bundle.EVENTCOUNT != "" && this.bundle.EVERY != "" && this.bundle.DAY != "") {
          go = true;
        }

      }
    }

    if (go == true) {
      this.LlInfoRequestsService.NG_LL_infoReq_subjectVisits_add(this.bundle)
    }

  }


};

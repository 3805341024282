import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { jqxChartComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxchart";

@Component({
  selector: 'll-model-metric-graph',
  templateUrl: './ll-model-metric-graph.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlModelMetricGraphComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_metric_graph') private mainDiv: ElementRef;
  @ViewChild('myChart_line') myChart: jqxChartComponent;

  constructor(
    private LlModelsService: LlModelsService,
  ) {

  }

  bundle = {
    showDisplay: true,
    showObj: true,
    groups: [],
    metricData: [],
    records: [],
    targetElement: "",
    objH: 0,
    objW: 0,
    LlModelsService: this.LlModelsService,

   
  }


  ngOnDestroy() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {

    setTimeout(() => {

      this.bundle.objH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;
      this.bundle.objW = this.mainDiv.nativeElement.parentNode.parentElement.clientWidth;

      this.getData()
    });
  }

  getData() {

    this.LlModelsService.getMetricGroups(done01, this.bundle, this.myChart);

    function done01(groups, bundle, myChart) {

      bundle.groups = groups;

      bundle.LlModelsService.NG_LL_model_AR_metric_graph_data_get(done02, bundle, myChart);
    }

    function done02(metricData, bundle, myChart) {

      bundle.records = metricData;

      bundle.field = bundle.LlModelsService.getActiveElement_record();
      bundle.targetElement = bundle.field.ELEMENT.toUpperCase();

      let DISPLAYFORMAT = bundle.field.DISPLAYFORMAT;

      let title = bundle.field.LABEL
      let description = "";///bundle.field.DESCRIPTION;

      let padding: any = { left: 5, top: 5, right: 40, bottom: 0 };
      let titlePadding: any = { left: 90, top: 0, right: 0, bottom: 0 };

      let xAxis: any =
      {
        dataField: 'DATE', ////variable and name in from server
        textRotationAngle: -35,
        gridLines: { visible: false }
      };


      let valueAxis: any = {
        visible: true,
      };


      var metricSeries = [];

      for (let i = 0; i < bundle.groups.length; i++) {
        metricSeries.push({
          dataField: bundle.groups[i].LABEL.toUpperCase(),
          displayText: bundle.groups[i].LABEL,
          colorFunction: (value: any, itemIndex: any, serie: any, group): any => {
            return '#' + bundle.groups[i].CHARTCOLOR
          }
        });
      }

      let seriesGroups: any =
        [
          {
            type: 'line',
            series: metricSeries
          }
        ];


      setTimeout(() => {
        myChart.title(title);
        myChart.description(description);
        myChart.padding(padding);
        myChart.titlePadding(titlePadding);
        myChart.xAxis(xAxis);
        myChart.valueAxis(valueAxis);
        myChart.seriesGroups(seriesGroups);
        myChart.source(bundle.records);

        myChart.refresh();


      });


    }
  }

  calculate(): void {


    //for (let i = 0; i < this.bundle.records.length; i++) {

     // let value = this.bundle.records[i][this.bundle.targetElement]

      //console.log(value ) 

      //if (value > this.bundle.maxChartNum) {
       // this.bundle.maxChartNum = value
      //}
      //if (value < this.bundle.minChartNum) {
        //this.bundle.minChartNum = value
      //}
    //}

    //let scale = this.bundle.graphAreaH / (this.bundle.maxChartNum + (- this.bundle.minChartNum));

    //for (let i = 0; i < this.bundle.records.length; i++) {
      ///this.bundle.records[i].barH = this.bundle.records[i][this.bundle.targetElement] * scale;
      //this.bundle.records[i].barX = this.bundle.barTotalWidth * i;
      //this.bundle.records[i].barY = 100;///this.bundle.zeroLine; 
    //}

  }
}

import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { LlRolesService } from '../ll-roles.service';

@Component({
  selector: 'll-user-info',
  templateUrl: './ll-user-info.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlUserInfoComponent implements OnInit {

  @ViewChild('mainDiv') mainDiv: ElementRef;

  constructor(
    private router: Router,
    private UserService: UserService,
    private LlRolesService: LlRolesService,
  ) {}

  bundle = {
    showObj:false,
    parentW: 0,
    parentH: 0,
    activeCONTACTROLEID:0,
    CONTACTFIRST: "",
    CONTACTLAST: "",
    COMPANYNAME: "",
    STUDYCODE: "",
    ROLE: "",
    contactRoleCount: 0,
    function:'',
  };

  ngOnInit() {}

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {
    this.bundle.function = this.mainDiv.nativeElement.parentElement.id;

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    this.getData()
  }

  getData() {

    let user = this.UserService.getUser()

    this.bundle.CONTACTFIRST = user[0].CONTACTFIRST;
    this.bundle.CONTACTLAST = user[0].CONTACTLAST;

    this.bundle.contactRoleCount = this.LlRolesService.getRoleCount();

    let role = this.LlRolesService.getActiveContactRole();

    if (role != null) {
      this.bundle.activeCONTACTROLEID = role.CONTACTROLEID;
      this.bundle.ROLE = role.ROLE;
    }

    setTimeout(() => {
      this.bundle.showObj = true;
    });
  }

  toRoleList() {

    //set the activeRoleId to zero to discared currnt role
    this.LlRolesService.setActiveContactRoleId(0);

    //return to the LLRoleSwitchComponent to select a new role
    this.router.navigate(['/LLRoleSwitchComponent']);
  }

  tologOut() {
    this.UserService.logout("userLogOut");
  }
}


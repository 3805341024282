import { Component, ViewChild, AfterViewInit, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { jqxGridComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxgrid';
import { jqxRadioButtonComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxradiobutton";
import { jqxDateTimeInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-model-cms-crosswalk',
  templateUrl: './ll-model-cms-crosswalk.component.html',
  styleUrls: ['../cssMain.css']
})

export class LlModelCmsCrosswalkComponent {

  @ViewChild('mainDiv_ll_model_cms_crosswalk') mainDiv: ElementRef;
  @ViewChild('radio_include') radio_include: jqxRadioButtonComponent;
  @ViewChild('radio_exclude') radio_exclude: jqxRadioButtonComponent;
  @ViewChild('searchResults_DG') datagrid: jqxGridComponent;
  @ViewChild('search_fedId') search_fedId: jqxTextAreaComponent;
  @ViewChild('search_name') search_name: jqxTextAreaComponent;
  @ViewChild('search_legalName') search_legalName: jqxTextAreaComponent;
  @ViewChild('search_address') search_address: jqxTextAreaComponent;
  @ViewChild('search_city') search_city: jqxTextAreaComponent;
  @ViewChild('search_state') search_state: jqxTextAreaComponent;
  @ViewChild('date_STARTDATE') date_STARTDATE: jqxDateTimeInputComponent;
  @ViewChild('date_ENDDATE') date_ENDDATE: jqxDateTimeInputComponent;

  constructor(
    private LlModelsService: LlModelsService,
  ) {
  }

  bundle = {
    action: '',
    showLocationEdit: true,
    showGrid: false,
    datagrid: {},
    date_STARTDATE: {},
    date_ENDDATE: {},
    location: [],
    LOCATION_CMS:[],
    SELECTED: '',
    LOCATIONNAME: "",
    FEDERALPROVIDERNUMBER: "",
    PROVIDER_SYSID: 0,
    PROVIDERNAME: "",
    LEGALBUSINESSNAME: "",
    ADDRESS: "",
    PROVIDERADDRESS: "",
    PROVIDERCITY: "",
    PROVIDERSTATE: "",    
    FEDERALPROVIDERNUMBER_W: 0,
    PROVIDERNAME_W: 0,
    LEGALBUSINESSNAME_W: 0,
    PROVIDERADDRESS_W: 0,
    PROVIDERCITY_W: 0,
    PROVIDERSTATE_W: 0,
    recordList: [],
    columns: [],
    fields: [],
    parentW: 0,
    parentH: 0,
    grid_top: 190,
    gridW: 0,
    gridH: 0,
  };
  
  ngOnDestroy() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.setVars();
  }

  setVars() {

    setTimeout(() => {
      this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

      setTimeout(() => {
        this.bundle.gridW = this.bundle.parentW - 40;
        this.bundle.gridH = this.bundle.parentH - this.bundle.grid_top - 80;
     
        this.bundle.FEDERALPROVIDERNUMBER_W = this.bundle.gridW * .06
        this.bundle.PROVIDERNAME_W = this.bundle.gridW * .245
        this.bundle.LEGALBUSINESSNAME_W = this.bundle.gridW * .245
        this.bundle.PROVIDERADDRESS_W = this.bundle.gridW * .245
        this.bundle.PROVIDERCITY_W = this.bundle.gridW * .125
        this.bundle.PROVIDERSTATE_W = this.bundle.gridW * .0436

        this.getData();
      });
    });
  }

  getData() {

    this.bundle.datagrid = this.datagrid;

    this.bundle.date_STARTDATE = this.date_STARTDATE;
    this.bundle.date_ENDDATE = this.date_ENDDATE;

    this.LlModelsService.NG_LL_model_AR_location_target_get(done01, this.bundle);

    function done01(arr, bundle) {

      bundle.location = arr

      //console.log(arr)

      bundle.PROVIDER_SYSID = bundle.location[0].PROVIDER_SYSID;
      bundle.LOCATIONNAME = "(" + bundle.location[0].LOCATIONID + ") " + bundle.location[0].LOCATIONNAME;
      bundle.PROVIDERNAME = "(" + bundle.location[0].FEDERALPROVIDERNUMBER + ") " + bundle.location[0].PROVIDERNAME;
      bundle.LEGALBUSINESSNAME = bundle.location[0].LEGALBUSINESSNAME;
      bundle.ADDRESS = bundle.location[0].PROVIDERADDRESS + " " + bundle.location[0].PROVIDERCITY + " " + bundle.location[0].PROVIDERSTATE

      if (bundle.location[0].STARTDATE_NUM != 0) {
        let dateNumText = String(bundle.location[0].STARTDATE_NUM);
        var year = Number(dateNumText.substring(0, 4));
        var month = Number(dateNumText.substring(4, 6)) - 1;
        var day = Number(dateNumText.substring(6, 8));
        bundle.date_STARTDATE.setDate(new Date(year, month, day));
      }

      if (bundle.location[0].ENDDATE_NUM != 0) {
        let dateNumText = String(bundle.location[0].ENDDATE_NUM);
        var year = Number(dateNumText.substring(0, 4));
        var month = Number(dateNumText.substring(4, 6)) - 1;
        var day = Number(dateNumText.substring(6, 8));
        bundle.date_ENDDATE.setDate(new Date(year, month, day));
      }



      setTimeout(() => {
        //bundle.showLocationEdit = true;

        //setTimeout(() => {

          paintGrid(bundle)
        //})

      }, 0)
    }

    function paintGrid(bundle) {
         
      bundle.columns.push({ text: 'Fed ID', datafield: 'FEDERALPROVIDERNUMBER', columntype: 'string', width: bundle.FEDERALPROVIDERNUMBER_W, editable: false });
      bundle.columns.push({ text: 'Fed Name', datafield: 'PROVIDERNAME', columntype: 'string', width: bundle.PROVIDERNAME_W, editable: false });
      bundle.columns.push({ text: 'Legal Name', datafield: 'LEGALBUSINESSNAME', columntype: 'string', width: bundle.LEGALBUSINESSNAME_W, editable: false});
      bundle.columns.push({ text: 'Address', datafield: 'PROVIDERADDRESS', columntype: 'string', width: bundle.PROVIDERADDRESS_W, editable: false });
      bundle.columns.push({ text: 'City', datafield: 'PROVIDERCITY', columntype: 'string', width: bundle.PROVIDERCITY_W, editable: false });
      bundle.columns.push({ text: 'State', datafield: 'PROVIDERSTATE', columntype: 'string', width: bundle.PROVIDERSTATE_W, editable: false });
   
      for (let i = 0; i < bundle.columns.length; i++) {
        bundle.fields.push({
          name: bundle.columns[i].datafield,
          columntype: bundle.columns[i].columntype,
          type: 'string',
        });
      }

      var source = {
        localdata: bundle.recordList,
        datatype: 'array',
        datafields: bundle.fields,
      };

      var dataAdapter = new jqx.dataAdapter(source);

      bundle.datagrid.source(dataAdapter);
      bundle.datagrid.columns(bundle.columns);
      bundle.datagrid.width(bundle.gridW);
      bundle.datagrid.height(bundle.gridH);

    }
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
     
      this.search_fedId.val(removeCarriageReturn(this.search_fedId.val()));
      this.search_name.val(removeCarriageReturn(this.search_name.val()));
      this.search_legalName.val(removeCarriageReturn(this.search_legalName.val()));
      this.search_address.val(removeCarriageReturn(this.search_address.val()));
      this.search_city.val(removeCarriageReturn(this.search_city.val()));
      this.search_state.val(removeCarriageReturn(this.search_state.val()));


      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      //this.search();
    }
  }

  clickOnRow(event: any): void {

    ///console.log("clickOnRow")

    this.bundle.PROVIDER_SYSID = this.bundle.recordList[event.args.rowindex].PROVIDER_SYSID;
    this.bundle.PROVIDERNAME = "(" + this.bundle.recordList[event.args.rowindex].FEDERALPROVIDERNUMBER + ") " + this.bundle.recordList[event.args.rowindex].PROVIDERNAME;
    this.bundle.LEGALBUSINESSNAME = this.bundle.recordList[event.args.rowindex].LEGALBUSINESSNAME;
    this.bundle.ADDRESS = this.bundle.recordList[event.args.rowindex].PROVIDERADDRESS + " " + this.bundle.recordList[event.args.rowindex].PROVIDERCITY + " " + this.bundle.recordList[event.args.rowindex].PROVIDERSTATE

    this.bundle.LOCATION_CMS.splice(0);

    this.bundle.LOCATION_CMS.push({
      PROVIDER_SYSID: this.bundle.PROVIDER_SYSID,
      FEDERALPROVIDERNUMBER: this.bundle.recordList[event.args.rowindex].FEDERALPROVIDERNUMBER,
      PROVIDERNAME: this.bundle.recordList[event.args.rowindex].PROVIDERNAME,
      LEGALBUSINESSNAME: this.bundle.recordList[event.args.rowindex].LEGALBUSINESSNAME,
      PROVIDERADDRESS: this.bundle.recordList[event.args.rowindex].PROVIDERADDRESS,
      PROVIDERCITY: this.bundle.recordList[event.args.rowindex].PROVIDERCITY,
      PROVIDERSTATE:this.bundle.recordList[event.args.rowindex].PROVIDERSTATE

    })

    this.LlModelsService.NG_LL_model_AR_location_CMS_edit(this.bundle.LOCATION_CMS, this.bundle);
  };

  search() {

    let searchParams = [];

    searchParams.push({
      FEDERALPROVIDERNUMBER: this.search_fedId.val(),
      PROVIDERNAME:this.search_name.val(),
      LEGALBUSINESSNAME: this.search_legalName.val(),
      PROVIDERADDRESS:this.search_address.val(),
      PROVIDERCITY:this.search_city.val(),
      PROVIDERSTATE:this.search_state.val(),
    });

    this.LlModelsService.NG_LL_model_AR_location_CMS_search(populateDG, searchParams, this.bundle, this.datagrid)

    function populateDG(records, bundle, datagrid) {

      bundle.recordList = records;

      var source = {
        localdata: bundle.recordList,
        datatype: 'array',
        datafields: bundle.fields
      };

      var dataAdapter = new jqx.dataAdapter(source);

      datagrid.source(dataAdapter);
      datagrid.columns(bundle.columns);
    }
  }

  selectStartDate() {

    //this.bundle.showRemaining = false;

    let Date_date = String(this.date_STARTDATE.val())

    var myArray = Date_date.split("/");

    var day = myArray[1];

    if (Number(day) < 10) {
      day = "0" + day;
    }

    var month = myArray[0];

    if (Number(month) < 10) {
      month = "0" + month;
    }

    var year = myArray[2]

    let Date_num = year + "" + month + "" + day;

    //console.log(Date_num)
    if (this.bundle.location[0].STARTDATE_NUM != Date_num) {

      this.bundle.location[0].STARTDATE_NUM = Date_num

      console.log(this.bundle.location[0].STARTDATE_NUM)

      this.LlModelsService.NG_LL_model_AR_location_target_edit(this.bundle.location, this.bundle) 

    }

   
  }

  selectEndDate() {


    let Date_date = String(this.date_ENDDATE.val())

    var myArray = Date_date.split("/");

    var day = myArray[1];

    if (Number(day) < 10) {
      day = "0" + day;
    }

    var month = myArray[0];

    if (Number(month) < 10) {
      month = "0" + month;
    }

    var year = myArray[2]

    let Date_num = year + "" + month + "" + day;

    if (this.bundle.location[0].ENDDATE_NUM != Date_num) {

      this.bundle.location[0].ENDDATE_NUM = Date_num

      console.log(this.bundle.location[0].ENDDATE_NUM)

      this.LlModelsService.NG_LL_model_AR_location_target_edit(this.bundle.location, this.bundle)
    }
  }




}


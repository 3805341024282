<div #metricHeader>

  <div *ngIf="bundle.QUESTIONINPUTTYPE != 'HEADER' && bundle.showQCCheck" style="float: left; padding-right: 10px; ">

    <!--QC Stage -->
    <div [style.width.px]="bundle.col01W" style="float: left;">
      <div class="wordBtnGroup" style="float: left; padding-left: 3px; padding-top: 3px;">
        <img height=14 style="float: left; width: auto; cursor: pointer;" src="assets/icon_arrowUp.png" (click)="stageChange('UP')">
      </div>

      <div class="wordBtnGroup" style="float: left; padding-top: 5px;" (click)="stageChange('DOWN')">
        <img height=14 style="float: left; width: auto; cursor: pointer;" src="assets/icon_arrowDown.png">
      </div>
    </div>

    <!--STATS GEAR -->
    <div *ngIf="bundle.statsButton" class="wordBtnGroup" style="float: left; padding-top: 5px;" (click)="editQuestion()">
      <img height=14 style="float: left; width: auto; cursor: pointer;" src="assets/icon_gear.png">
    </div>

    <!--STATUS CIRCLE -->
    <div class="wordBtnGroup" style="float: left; padding-left: 5px; padding-top: 5px;">
      <img height=14 style="float: left; width: auto; cursor: pointer;" [src]="bundle.CIRCLEIMG_QUESTION">
    </div>

    <!--QUESTION COUNT -->
    <div class="txtNorm12" style="float: left; width: 10px; padding-left: 10px; padding-top: 6px; text-align: right;">
      {{bundle.COUNT}}:
    </div>

  </div>

  <div *ngIf="bundle.QUESTIONINPUTTYPE != 'HEADER' && !bundle.showQCCheck" [style.padding-left.px]="bundle.col02_left" [style.width.px]="bundle.col02W + 0" style="float: left;">

    <!--STATS GEAR -->
    <div *ngIf="bundle.statsButton" class="wordBtnGroup" style="float: left; padding-top: 5px; padding-right: 5px;" (click)="editQuestion()">
      <img height=14 style="float: left; width: auto; cursor: pointer;" src="assets/icon_gear.png">
    </div>

    <!--STATUS CIRCLE -->
    <div class="wordBtnGroup" style="float: left; padding-left: 0px; padding-top: 5px;">
      <img height=14 style="float: left; width: auto; cursor: pointer;" [src]="bundle.CIRCLEIMG_QUESTION">
    </div>

    <!--QUESTION COUNT -->
    <div class="txtNorm12" style="float: left; width: 10px; padding-left: 10px; padding-top: 6px; text-align: right;">
      {{bundle.COUNT}}:
    </div>

  </div>

  <!--QUESTION - QUESTIONNAME-->
  <div [style.width.px]="bundle.col03W" style="float: left; padding-left: 10px; padding-top: 6px;">
    <div class="txtNorm12" *ngIf="bundle.QUESTIONINPUTTYPE != 'HEADER'" style="float: left;">
      {{bundle.QUESTIONNAME}}
    </div>

    <div class="txtBold" *ngIf="bundle.QUESTIONINPUTTYPE == 'HEADER'" [style.padding-left.px]="bundle.col02W" style="float: left;">
      {{bundle.QUESTIONNAME}}
    </div>


    <div *ngIf="bundle.QUESTIONINSTRUCTIONS" style="float: left; padding-left: 5px; ">
      <jqxTooltip [position]="'mouse'"
                  [name]="'movieTooltip'"
                  [autoHideDelay]="30000"
                  [content]="bundle.QUESTIONINSTRUCTIONS"
                  [width]="500"
                  [opacity]="1.0"
                  [showArrow]="false">

        <img height=10 style="float: left; width: auto; top:0px;" src="assets/infoBtn.png" />

      </jqxTooltip>
    </div>

  </div>
</div>

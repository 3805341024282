import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { LlInfoRequestsService } from '../ll-info-requests.service';
import { LlPermissionsService } from '../ll-permissions.service';

@Component({
  selector: 'll-info-request-widget-general',
  templateUrl: './ll-info-request-widget-general.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestWidgetGeneralComponent {
  @ViewChild('inputField') private inputField: ElementRef;
  @ViewChild('metricHeader') metricHeader: ElementRef;


  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
    private LlPermissionsService: LlPermissionsService,
  ) { }

  bundle = {
    showObj: false,
    dataEntry: false,
    keyUp: false,
    STUDYQUESTIONID: 0,
    QUESTIONINPUTTYPE: '',
    QUESTIONNAME: '',
    COUNT: "",
    circleImg: "",
    ///objW: 0,
    objW: 0,
    unitW: 80,
    initialLoad: false,
    orgVal: '',
    newVal: "",
    charLimit: 0,
    NGWIDGET: '',
    questionUnit: "",
    decimalPlaces: 0,
    QUESTIONRESULT_USER: '',
    QUESTIONRESULT_SPONSOR: '',
    VALUE: "",
    FIELD: "",
    Tooltip01: 'not Found',
    objId: 0,
    STUDYVISITFORMID: 0,
    objType: "",
    keyCode: "",
    divId: "",
    questionW: 0,
    valueRow01W: 150,
  }



  ngOnInit() { }

  eventSubscription_renewQuestion: any;

  ngOnDestroy() {
    this.eventSubscription_renewQuestion.unsubscribe();
  }

  ngAfterViewInit() {

    this.eventSubscription_renewQuestion = this.LlInfoRequestsService.renewQuestion.subscribe(STUDYQUESTIONID => {

      if (this.bundle.STUDYQUESTIONID == STUDYQUESTIONID) {
        this.setVars();
      }

    });

    this.setVars();

  }

  setVars() {

    this.bundle.STUDYQUESTIONID = this.metricHeader.nativeElement.parentElement.id;
    let record = this.LlInfoRequestsService.getSelectStudyQuestion(this.bundle.STUDYQUESTIONID);

    //console.log(record)

    setTimeout(() => {
      this.bundle.objW = this.metricHeader.nativeElement.parentElement.parentElement.clientWidth;
      this.bundle.questionW = this.bundle.objW - this.bundle.valueRow01W;

      this.bundle.charLimit = record.QUESTIONCHARACTERLIMIT;
      this.bundle.QUESTIONINPUTTYPE = record.QUESTIONINPUTTYPE;
      this.bundle.NGWIDGET = record.NGWIDGET.toLowerCase();
      this.bundle.questionUnit = record.QUESTIONUNIT;
      this.bundle.dataEntry = record.dataEntry;
      this.bundle.orgVal = String(record.QUESTIONRESULT_USER);

      if (this.bundle.dataEntry == true) {
        this.bundle.Tooltip01 = "Type content into this box and EITHER move mouse pointer over-out of the text box or hit the ‘TAB’ or 'ENTER' key to save.";
      } else {
        this.bundle.Tooltip01 = "Data entry is disabled for this question.  Either the question has been locked or signed, is read only, deleted (red), or you do not have data entry permission."
      }    

      this.bundle.showObj = true;
    });


  }


  onKeyDown(event) {

    this.bundle.keyUp = true;

    if (event.keyCode == 13) { ///Enter
      this.checkEnter();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.checkEnter();
    }

  }

  mouseLeave(event) {  
    this.checkEnter();    
  }

  checkEnter() {

    this.bundle.newVal = this.inputField.nativeElement.value;

    if (this.bundle.keyUp == true && this.bundle.dataEntry == true && String(this.bundle.newVal) != String(this.bundle.orgVal)) {

      this.bundle.keyUp = false;

      this.bundle.QUESTIONRESULT_USER = this.bundle.newVal;

      this.LlInfoRequestsService.setActiveStudyQuestionId(this.bundle.STUDYQUESTIONID)
      this.LlInfoRequestsService.NG_LL_infoReq_subjectQuestions_updateValue("STD", this.bundle)
    

    } 
  }
}


<div #mainDiv_ll_model_cms_crosswalk>

  <!--<div *ngIf="bundle.showLocationEdit">-->
  <div [hidden]="!bundle.showLocationEdit">

    <div [style.top.px]="30" style="position:absolute;">


      <!--START/END DATES-->
      <div style="clear:left; padding-top:5px;">

        <div class="txtSmallB" style="float: left;">
          Start Date:
        </div>

        <div class="txtSmall" style="float:left; padding-left:10px">
          <jqxDateTimeInput #date_STARTDATE
                            [width]="110"
                            [height]="17"
                            [formatString]="'d'"
                            (onChange)="selectStartDate()">
          </jqxDateTimeInput>
        </div>

        <div class="txtSmallB"style="float: left; padding-left: 20px">
          End Date:
        </div>

        <div class="txtSmall" style="float:left; padding-left:10px">
          <jqxDateTimeInput #date_ENDDATE
                            [width]="110"
                            [height]="17"
                            [formatString]="'d'"
                            (onChange)="selectEndDate()">
          </jqxDateTimeInput>
        </div>
      </div>

      <!--LOCATIONNAME-->
      <div style="clear:left; padding-top:5px;">

        <div class="txtSmallB" [style.width.px]="150" style="float: left;">
          Billing Sys. Name:
        </div>

        <div class="txtSmallB" style="float:left; padding-left:5px">
          {{bundle.LOCATIONNAME}}
        </div>
      </div>

      <!--PROVIDERNAME-->
      <div style="clear:left; padding-top:5px;">

        <div class="txtSmallB" [style.width.px]="150" style="float: left;">
          Federal Name:
        </div>

        <div class="txtSmallB" style="float:left; padding-left:5px">
          {{bundle.PROVIDERNAME}}
        </div>
      </div>

      <!--LEGALBUSINESSNAME-->
      <div style="clear:left; padding-top:5px;">
        <div class="txtSmallB" [style.width.px]="150" style="float: left;">
          Fed. Legal Name:
        </div>

        <div class="txtSmallB" style="float:left; padding-left:5px">
          {{bundle.LEGALBUSINESSNAME}}
        </div>
      </div>

      <!--ADDRESS-->
      <div style="clear:left; padding-top:5px;">
        <div class="txtSmallB" [style.width.px]="150" style="float: left;">
          Address:
        </div>

        <div class="txtSmallB" style="float:left; padding-left:5px">
          {{bundle.ADDRESS}}
        </div>
      </div>

      <!--INSTRUCTIONS AND BUTTON-->
      <div style="clear: left; padding-top: 5px;">
        <div style="clear: left; padding-top: 0px; border-top: 2px solid gainsboro;" [style.width.px]="bundle.parentW - 50"></div>

        <div style="padding-top: 10px;">

          <div class="txtBold_14" style="float: left; padding-top: 0px;">
            Enter criteria in boxes.
          </div>

          <div style="float: left; padding-left: 10px;">
            <div class="wordBtn14" (click)="search()">Click Here to Search</div>
          </div>

          <div class="txtBold_14" style="float: left; padding-left: 10px;">
            Click result row to select.
          </div>

        </div>
      </div>


      <div [style.width.px]="bundle.parentW" style="float: left; padding-top:5px;">


        <!--search_Federal ID-->
        <div style="float: left;">

          <div class="txtSmallB" [style.width.px]="bundle.FEDERALPROVIDERNUMBER_W">
            Fed ID
          </div>

          <div (keyup)="onKeyUp($event)" style="clear: left; padding-top:2px;">
            <jqxTextArea #search_fedId [width]="bundle.FEDERALPROVIDERNUMBER_W" [height]="20"></jqxTextArea>
          </div>
        </div>

        <!--search_name-->
        <div style="float: left;">
          <div class="txtSmallB" [style.width.px]="bundle.PROVIDERNAME_W - 2">
            Provider Name
          </div>

          <div (keyup)="onKeyUp($event)" style="clear: left; padding-top:2px;">
            <jqxTextArea #search_name [width]="bundle.PROVIDERNAME_W - 2" [height]="20"></jqxTextArea>
          </div>
        </div>

        <!--legal_name-->
        <div style="float: left;">
          <div class="txtSmallB" [style.width.px]="bundle.LEGALBUSINESSNAME_W - 2">
            Legal Name
          </div>

          <div (keyup)="onKeyUp($event)" style="clear: left; padding-top:2px;">
            <jqxTextArea #search_legalName [width]="bundle.LEGALBUSINESSNAME_W - 2" [height]="20"></jqxTextArea>
          </div>
        </div>

        <!--search_address-->
        <div style="float: left;">
          <div class="txtSmallB" [style.width.px]="bundle.PROVIDERADDRESS_W - 1">
            Address
          </div>

          <div (keyup)="onKeyUp($event)" style="clear: left; padding-top:2px;">
            <jqxTextArea #search_address [width]="bundle.PROVIDERADDRESS_W - 1" [height]="20"></jqxTextArea>
          </div>
        </div>

        <!--search_city-->
        <div style="float: left;">
          <div class="txtSmallB" [style.width.px]="bundle.PROVIDERCITY_W - 2">
            City
          </div>

          <div (keyup)="onKeyUp($event)" style="clear: left; padding-top:2px;">
            <jqxTextArea #search_city [width]="bundle.PROVIDERCITY_W - 2" [height]="20"></jqxTextArea>
          </div>
        </div>

        <!--search_state -->
        <div style="float: left;">
          <div class="txtSmallB" [style.width.px]="bundle.PROVIDERSTATE_W - 1">
            State
          </div>

          <div (keyup)="onKeyUp($event)" style="clear: left; padding-top:2px;">
            <jqxTextArea #search_state [width]="bundle.PROVIDERSTATE_W - 1" [height]="20"></jqxTextArea>
          </div>
        </div>

        <!--Search Results-->
        <div style="position:absolute;" [style.top.px]="bundle.grid_top">

          <jqxGrid #searchResults_DG
                   [editable]="true"
                   [rowsheight]="20"
                   [filterable]="true"
                   [sortable]="true"
                   [columnsresize]="true"
                   [showstatusbar]="false"
                   (onRowclick)="clickOnRow($event)">

          </jqxGrid>

        </div>


      </div>
    </div>
  </div>

import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { jqxDropDownListComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdropdownlist";
import { jqxChartComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxchart";
import { LlInfoRequestsService } from '../ll-info-requests.service';

@Component({
  selector: 'll-info-request-graph01',
  //standalone: true,
  //imports: [],
  templateUrl: './ll-info-request-graph01.component.html',
  styleUrls: ['../cssMain.css']
})
export class LlInfoRequestGraph01Component {


  @ViewChild('mainDiv_quest_graph') private mainDiv: ElementRef;
  @ViewChild('myChart_line') myChart: jqxChartComponent;
  @ViewChild('dropDown_leftElement') dropDown_leftElement: jqxDropDownListComponent;
  @ViewChild('dropDown_rightElement') dropDown_rightElement: jqxDropDownListComponent;

  ///@ViewChild('graphObj', {static: false}) private graphObjRef: ElementRef;

  constructor(
    private LlInfoRequestsService: LlInfoRequestsService,
  ) {

  }

  bundle = {
    showObj: false,
    LlInfoRequestsService: this.LlInfoRequestsService,
    records: [],
    elements: [],
    elementChoices: [],
    leftElementId: 0,
    rightElementId: 1,
    ctx: {},
    field: {},
    TARGETELEMENT_LEFT: "",
    TARGETELEMENT_RIGHT: "",
    objH: 0,
    objW: 0,
    graphAreaX: 10,
    graphAreaY: 30,
    graphAreaH: 0,
    graphAreaW: 0,
    graphActW: 0,
    curlyBracketH: 10,
    statAreaW: 100,
    parentHeight: 0,
    barW: 5,
    barSpace: 5,
    barTotalWidth: 0,
    zeroLine: 0,
    maxChartNum: -100,
    minChartNum: 9999999999999,
  }


  ngOnDestroy() { }

  ngOnInit() {
    this.bundle.parentHeight = window.innerHeight - 20 - 68;
  }

  ngAfterViewInit() {
    this.setVars();
  }

  setVars() {

    ///console.log("setVars()")

    this.bundle.showObj = false;

    setTimeout(() => {

      this.bundle.objH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;
      this.bundle.objW = this.mainDiv.nativeElement.parentNode.parentElement.clientWidth;

      this.getData()
    });
  }

  getData() {

    this.LlInfoRequestsService.NG_LL_infoReq_graph01_elements_get(done01, this.bundle, this.myChart);

    function done01(elements, bundle, myChart) {
      bundle.elements = elements;
      bundle.LlInfoRequestsService.setActiveElements_graph01(bundle.leftElementId, bundle.rightElementId);

      for (let i = 0; i < elements.length; i++) {
        bundle.elementChoices.push(elements[i].QUESTIONNAME);
      }

      bundle.showObj = true;

      bundle.LlInfoRequestsService.NG_LL_infoReq_graph01_data_get(done02, bundle, myChart);
    }

    function done02(arr, bundle, myChart) {

      console.log(arr)

      bundle.records = arr;

      let title = ""
      let description = '';

      let padding: any = { left: 5, top: 5, right: 40, bottom: 0 };
      let titlePadding: any = { left: 90, top: 0, right: 0, bottom: 0 };

      let xAxis: any =
      {
        dataField: 'DATE', ////variable and name in from server
        textRotationAngle: -25,
        gridLines: { visible: false }
      };


      let valueAxis: any = {
        visible: true,
      };

      let seriesGroups: any =
        [
          {
            type: 'line',
            series: [
              { dataField: 'VALUE_LEFT', displayText: bundle.elements[bundle.leftElementId].QUESTIONNAME, opacity: 0.7 },
              { dataField: 'VALUE_RIGHT', displayText: bundle.elements[bundle.rightElementId].QUESTIONNAME, opacity: 1.0, lineWidth: 4, dashStyle: '4,4' }
            ]
          }
        ];

      //let seriesGroups: any[] =
      //[
      //{
      // alignEndPointsWithIntervals: false,
      // //type: 'splinearea',
      // //type: 'spline',
      //type: 'line',
      //valueAxis:
      //{
      // visible: true,
      //unitInterval: 20,
      //title: { text: bundle.elements[bundle.leftElementId].QUESTIONNAME },
      //labels: {
      //horizontalAlignment: 'right',
      //formatSettings: { decimalPlaces: 0 }
      //}
      //},
      //series: [
      //{ dataField: 'VALUE_LEFT', displayText: bundle.elements[bundle.leftElementId].QUESTIONNAME, opacity: 0.7 },
      //]
      //},
      //{
      //type: 'spline',
      // type: 'line',
      //alignEndPointsWithIntervals: false,
      //valueAxis:
      //{
      //title: { text: bundle.elements[bundle.rightElementId].QUESTIONNAME },
      //position: 'right',
      //unitInterval: 0.01,
      //maxValue: 0.2,
      //labels: { formatSettings: { decimalPlaces: 0 } },
      //tickMarks: {
      //visible: true,
      //interval: 0.005,
      //},
      //gridLines: {
      //visible: false,
      //interval: 0.01
      //}
      //}//,
      //series: [
      //{ dataField: 'VALUE_RIGHT', displayText: bundle.elements[bundle.rightElementId].QUESTIONNAME, opacity: 1.0, lineWidth: 4, dashStyle: '4,4' }
      //]
      //}
      //];

      setTimeout(() => {
        myChart.title(title);
        myChart.description(description);
        myChart.padding(padding);
        myChart.titlePadding(titlePadding);
        myChart.xAxis(xAxis);
        myChart.valueAxis(valueAxis);
        myChart.seriesGroups(seriesGroups);
        myChart.source(bundle.records);

        myChart.refresh();


      });
    }
  }

  selectLeftIndex(event: any) {

    this.bundle.leftElementId = event.args.index;
    this.getData();
  }

  selectRightIndex(event: any) {

    this.bundle.rightElementId = event.args.index;
    this.getData();
  }

}

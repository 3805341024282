<div #mainDiv_ll_model_dash>

  <div *ngIf="bundle.showDash">

    <div *ngFor="let group of bundle.groups; let g = index" style="white-space: nowrap;">

      <div [style.top.px]="bundle.spY - 10"
           [style.left.px]="(g * (bundle.columnW + 10)) + bundle.spX"
           [style.width.px]="bundle.columnW"
           style="position: absolute;">

        <div class="txtBold_14">{{group}}</div>
      </div>

    </div>

    <jqxPanel [style.top.px]="bundle.spY"
              [style.left.px]="bundle.spX"
              style="position: absolute; background-color: transparent; border: none;  outline: none; border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <div *ngFor="let group of bundle.groups; let g = index" style="white-space: nowrap; padding-left: 20px">

        <tr *ngFor="let item of bundle.records | visitCategory:{COLUMNNUMBER:g }; let i = index">

          <div [style.left.px]="g * (bundle.columnW + 10)"
               [style.top.px]="20 +(i * (bundle.rowH + 2))"
               [style.height.px]="bundle.rowH"
               [style.width.px]="bundle.columnW"
               [style.background-color]="item.BACKGROUND_COLOR"
               style="position: absolute;
                              border-style: solid;
                              border-width: 1px;
                              border-color:gray;
                              border-radius: 10px;
                              opacity: 1;">
          </div>

          <div [style.left.px]="3 + (g * (bundle.columnW + 10))"
               [style.top.px]="23 +(i * (bundle.rowH + 2))"
               [style.height.px]="bundle.rowH - 6"
               [style.width.px]="bundle.columnW - 6"
               style="position: absolute;
                              background-color:white;
                              border-style: solid;
                              border-width: 1px;
                              border-color:gray;
                              border-radius: 8px;
                              opacity: 1;">
          </div>


          <div [style.left.px]="3 + (g * (bundle.columnW + 10))"
               [style.top.px]="23 +(i * (bundle.rowH + 2))"
               [style.height.px]="bundle.rowH"
               [style.width.px]="bundle.columnW"
               style="position: absolute;">

            <div style="padding-left:0px; padding-top:5px;">

              <div style="float: left; padding-left: 0px">

                <jqxTooltip [position]="'right'"
                            [name]="'movieTooltip'"
                            [autoHideDelay]="30000"
                            [content]="item.DESCRIPTION"
                            [opacity]="1.0"
                            [width]="400">

                  <img class="infoBtn" src="assets/infoBtn.png" />

                </jqxTooltip>

              </div>


              <div class="txtBold_12" style="float: left; padding-left: 5px">
                {{item.LABEL}}
              </div>

              <div class="txtSmall_10" style="clear: left; padding-left: 20px;">
                {{item.METRICNOTE}}
              </div>
            </div>

          </div>

          <div [style.left.px]="bundle.columnW - 25 + (g * (bundle.columnW + 10))"
               [style.top.px]="30 +(i * (bundle.rowH + 2))"
               style="position: absolute;">

            <img *ngIf="item.PREFERENCENAME == 'Model Receivables & Collateral'" [id]="item.PREFERENCEVALUE" class="icon" src="assets/icon_magGlass.png" (click)="elementPopUp($event)" style="height:15px; width:auto;">

          </div>


        </tr>

      </div>

    </jqxPanel>



</div>

<!--SHOW GRAPH--->
<div *ngIf="bundle.showGraph">

  <div class="wordBtnGroup" style="float: left; padding-top: 10px; padding-left: 10px; ">
    <img height=20 style="float: left; width: auto; cursor: pointer; " src="assets/icon_backArrow.png" (click)="backToDash()">
    <div (click)="backToDash()"
         style="float:left;
         padding-left:10px;
         padding-top: 3px;
         font-size: 12px;
         font-family: Arial;
         color:darkblue; cursor: pointer; ">Back to Dashboard</div>
  </div>


  <!---GRAPH-->
  <div [style.top.px]="bundle.graphAreaY"
       [style.left.px]="bundle.graphAreaX"
       [style.width.px]="bundle.graphAreaW"
       [style.height.px]="bundle.graphAreaH"
         style="position: absolute;">
      <ll-model-graph-one></ll-model-graph-one>
  </div>

 

  <!--HTML SUMMARY-->
  <div [style.top.px]="bundle.panelY" [style.left.px]="bundle.panelX" style="position:absolute;">
    <jqxPanel #SP_EXPLINATIONHTML
              style="background-color: transparent;
                border: none;
                outline: none;
                border-top: 1px solid  rgb(160, 158, 158);
                border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.panelW"
              [height]="bundle.panelH">

      <div [style.width.px]="bundle.panelW - 30" style="padding-top: 10px; padding-left: 20px; overflow-wrap: break-word; ">
        <div [innerHTML]="bundle.EXPLANATIONHTML | sanitizeHtml"></div>
      </div>

    </jqxPanel>

  </div>

</div>

</div>


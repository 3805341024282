<div #mainDiv_ll_info_request_remove>

  <!--Active - Inactive-->
  <div *ngIf="bundle.canEdit && bundle.showObj" style="padding-left:0px; padding-top:0px;  ">

    <div [style.top.px]="bundle.headerY"
         [style.left.px]="0"
         [style.width.px]="bundle.parentW"
         [style.height.px]="bundle.headerH"
         style="position: absolute;">

      <div *ngFor="let item of bundle.choices" style="float:left; font-size: 12px; font-family: Arial;  ">
        <jqxCheckBox [width]="100"
                     [height]="25"
                     [theme]="'material-green'"
                     [checked]="item.SELECTED"
                     (onChange)='select($event)'>
          <span>{{item.CHOICE}}</span>
        </jqxCheckBox>
      </div>

    </div>
  </div>

  <!--Remove All-->
  <div [hidden]="!bundle.showObj"
       [style.top.px]="10"
       style="position:absolute; ">


    <div *ngIf="bundle.canEdit">

      <jqxTooltip style="float: left; padding-left: 0px;"
                  [position]="'right'"
                  [name]="'movieTooltip'"
                  [autoHideDelay]="30000"
                  [content]="'Only information requests that have had no data entry can be removed.  To remove an information request, check the box corresponding to the request to be removed.  To select all requests, click on the nearby blue “Remove All” word button.  To complete any removals, click on the “Remove Unchecked Requests” blue word button at the bottom of this list.  After clicking this word button, your decision to remove will be confirmed one last time.'"
                  [opacity]="1.0"
                  [width]="200">

        <img class="infoBtn_12" src="assets/infoBtn.png" />

      </jqxTooltip>

      
      <div style="float: left; padding-left:10px; padding-top:0px;">
        <div class="wordBtn" (click)="clearAll()">Select All</div>
      </div>

    </div>

  </div>

  <!--dataGrid_ll_info_request_remove-->
  <div [hidden]="!bundle.showObj"
       [style.top.px]="bundle.gridY"
       style="position:absolute; ">


    <jqxGrid #dataGrid_ll_info_request_remove
             [theme]="'material-green'"
             [editable]="true"
             [rowsheight]="20"
             [filterable]="true"
             [sortable]="true"
             [columnsresize]="true"
             [showstatusbar]="false"
             (onCellvaluechanged)="Cellvaluechanged($event)">

    </jqxGrid>




    <div style="padding-top: 10px">

      <div id=add_ll_info_request_remove *ngIf="bundle.canEdit" >

        <div class="wordBtnGroup">
          <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_plus.png" alt="" (click)="noAction()">
          <div class="wordBtn" (click)="noAction()">Remove all CHECKED Information Requests</div>
        </div>

        <jqxTooltip style="float: left;"
                    [position]="'top'"
                    [name]="'movieTooltip'"
                    [autoHideDelay]="30000"
                    [content]="'Click to remove the CHECKED information requests in the above list'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn_12" src="assets/infoBtn.png" />

        </jqxTooltip>


        <jqxPopover #mainDiv_ll_info_request_remove_popover style="font-size: 14px; font-family: Verdana;"
                    [offset]='{ left: -200, top: 0 }'
                    [arrowOffsetValue]="0"
                    [width]="400"
                    [height]="180"
                    [position]="'top'"
                    [title]="'Remove Information Requests'"
                    [showCloseButton]="true"
                    [selector]="'#add_ll_info_request_remove'">        

            
            <div style="float: left; padding-left:1px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="360" style="float: left;">
                Are you certian you want to remove all of the CHECKED information requests in the above list?

                NOTE: This information request, its forms, answers and data will be removed.
              </div>

              
            </div>
         

            <!--Save Changes-->
            <div style="float:left; padding-left:200px; ">
              <div class="wordBtnGroup_popover">
                <div class="wordBtn14" (click)="enter()">Yes, remove</div>
              </div>
            </div>

        </jqxPopover>

      </div>


    </div>

  </div>

</div>

<div #mainDiv_ll_admin_role_companies_sp>

  <canvas #vertTab_ll_admin_role_companies_sp></canvas>

  <!--scrollpane_ll_admin_role_companies_sp-->
  <div *ngIf="bundle.showObj">

    <div class="spLabel" [ngStyle]="{'top.px':bundle.lblY, 'width.px':bundle.spW}">
      {{bundle.lable}}
    </div>

    <jqxPanel #scrollpane_ll_admin_role_companies_sp
              [style.top.px]="bundle.spY"
              style="background-color: transparent;
              border: none;
              outline: none;
              border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <tr *ngFor="let item of bundle.records" style="white-space: nowrap;">

        <div class="spRowLabel"
             [id]="bundle.prefix + item.FOLDERID"
             title={{item.FOLDERNAME}}
             [style.width.px]="bundle.spW - 30" style="float: left; " (click)="select($event)">
          {{item.FOLDERNAME}}
        </div>
      </tr>

    </jqxPanel>

    <!--BOTTOM-->
    <div id=bottomLine [style.top.px]="bundle.spB">

      <div id=add_ll_admin_role_companies_sp *ngIf="bundle.canAdd" style="padding-top:10px;">

        <div class="wordBtnGroup">
          <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_plus.png" alt="" (click)="noAction()">
          <div class="wordBtn" (click)="noAction()">Add Company</div>
        </div>

        <jqxTooltip style="float: left;"
                    [position]="'top'"
                    [name]="'movieTooltip'"
                    [autoHideDelay]="30000"
                    [content]="'Click to add'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn_12" src="assets/infoBtn.png" />

        </jqxTooltip>


        <jqxPopover #popover_addCo style="font-size: 14px; font-family: Verdana;"
                    [offset]='{ left: 50, top: 0 }'
                    [arrowOffsetValue]="200"
                    [width]="400"
                    [height]="200"
                    [position]="'top'"
                    [title]="'Add Organization'"
                    [showCloseButton]="true"
                    [selector]="'#add_ll_admin_role_companies_sp'">

          <!--text_companyName-->
          <div style="clear: left; padding-top:0px; ">

            <div style="float: left; ">
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:1px; padding-top:5px;">

              <div class="txtNorm" [style.width.px]="65" style="float: left;">
                Name:
              </div>

              <div style="float: left; padding-left:10px;">
                <jqxTextArea #text_companyName
                             [width]="200"
                             [height]="25"
                             [placeHolder]="'Type organization name here'">
                </jqxTextArea>
              </div>
            </div>
          </div>

          <!--COMPANY TYPE-->
          <div style="clear: left; padding-top:20px; ">

            <div style="float: left; ">
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div class="txtNorm" [style.width.px]="65" style="float: left; padding-left: 1px; padding-top: 5px;">
              Type:
            </div>

            <div style="float: left; padding-left:15px; padding-top:0px;">

              <!--<div style="clear: left; padding-left:10px;">--->
              <div *ngFor="let type of bundle.companyTypes" style="clear: left; font-size: 12px; font-family: Arial; ">
                <jqxRadioButton [width]="200"
                                [height]="25"
                                [theme]="'material-green'"
                                [groupName]="'type'"
                                (onChange)='selectType($event)'>

                  <span>{{type.COMPANYTYPENAME}}</span>
                </jqxRadioButton>
              </div>
              <!--</div>-->
            </div>
          </div>

          <!--Save Changes-->
          <div style="float:left; padding-left:200px; ">
            <div class="wordBtnGroup_popover">
              <div class="wordBtn14" (click)=" add()">Click to Add Company</div>
            </div>
          </div>

        </jqxPopover>

      </div>

    </div>


    <!--SEARCH-->
    <div id=search_ll_admin_role_companies_sp *ngIf="bundle.canFilter"
         [style.top.px]="bundle.lblY - 0"
         [style.width.px]="bundle.spW"
         [style.left.px]="bundle.spW  - 10 "
         style="position: absolute;">


      <div class="wordBtnGroup">
        <div *ngIf="bundle.searchText  == '' ">
          <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
        </div>
        <div *ngIf="bundle.searchText  != '' ">
          <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
        </div>
      </div>

      <jqxPopover #popover_search
                  [offset]='{ left: -85, top: 0 }'
                  [arrowOffsetValue]="-0"
                  [width]="220"
                  [position]="'top'"
                  [title]="'Filter Categories'"
                  [selector]="'#search_ll_admin_role_companies_sp'">

        <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">

          <div (keyup)="onKeyUp($event)" style="float: left; ">
            <jqxTextArea #text_search
                         [width]="140"
                         [height]="25"
                         [placeHolder]="'Type Search'">

            </jqxTextArea>
          </div>

          <div style="float: left; padding-left:10px; padding-top:0px; ">
            <div style="clear:left">
              <div class="wordBtn" (click)="search()">Search</div>
            </div>
            <div style="clear:left">
              <div class="wordBtn" (click)="clear()">Clear</div>
            </div>
          </div>
        </div>



      </jqxPopover>
    </div>

  </div>

  
  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.function == 'edit_roles' "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-admin-generic-dg id="edit-roles-dg"></ll-admin-generic-dg>
  </div>

  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.function == 'role_users' "
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-admin-generic-dg id="role-users-dg"></ll-admin-generic-dg>
  </div>

</div>

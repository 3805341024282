import { Injectable } from '@angular/core';
import { ServerService } from '../services/server.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})

export class LlPermissionsService {

  constructor(
    public ServerService: ServerService,
    public UserService: UserService,
  ) { }

  vars = {
    ac: "",
    APPLICATIONVERSION: "",
    SYSTEMROLEID: 0,
    STUDYID_SYS: 0,
  }

  bundle_local = {
    ServerService: this.ServerService,
    vars: this.vars,
    records: [],
    inBundle: []
  }

  NG_LL_user_role_app_permissions_get(callback01: Function, SYSTEMROLEID, inBundle) {

    var user = this.UserService.getActiveUser();

    this.bundle_local.vars.ac = "NG_LL_user_role_app_permissions_get";
    this.bundle_local.vars.APPLICATIONVERSION = user.APPLICATIONVERSION;
    this.bundle_local.vars.SYSTEMROLEID = SYSTEMROLEID;

    this.ServerService.callserver(srvReturn, this.bundle_local, "show");

    function srvReturn(arr, bundle_local) {

      bundle_local.records = arr;

      //console.log(bundle_local.records)

      callback01(SYSTEMROLEID, inBundle)
    }


  }

  check(permission: String) {

    var found = false;

    for (let i = 0; i < this.bundle_local.records.length; i++) {
      if (this.bundle_local.records[i].PERMISSION == permission) {
        found = true;
      }
    }

    return found;

  }



}

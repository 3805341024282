<div #mainDiv_ll_company_models_sp>

  <canvas #vertTab_ll_company_models_sp></canvas>

  <!--scrollpane_ll_company_models_sp-->
  <div *ngIf="bundle.showObj">

    <div class="spLabel" [ngStyle]="{'top.px':bundle.lblY, 'width.px':bundle.spW}">
      {{bundle.lable}}
    </div>

    <jqxPanel #scrollpane_ll_company_models_sp
              [style.top.px]="bundle.spY"
              style="background-color: transparent;
              border: none;
              outline: none;
              border-bottom: 1px solid  rgb(160, 158, 158);"
              [autoUpdate]="true"
              [rtl]="true"
              [width]="bundle.spW"
              [height]="bundle.spH">

      <tr *ngFor="let item of bundle.records" style="white-space: nowrap;">

        <div class="spRowLabel"
             [id]="bundle.prefix + item.FOLDERID"
             title={{item.FOLDERNAME}}
             [style.width.px]="bundle.spW - 30" style="float: left; " (click)="select($event)">
          {{item.FOLDERNAME}}
        </div>
      </tr>

    </jqxPanel>

    <!--BOTTOM-->
    <div id=bottomLine [style.top.px]="bundle.spB">

      <div id=add_ll_company_models_sp *ngIf="bundle.canAdd" style="padding-top:10px;">

        <div class="wordBtnGroup">
          <img class="btnIcon" height=15 style="width: auto;" src="assets/icon_plus.png" alt="" (click)="add()">
          <div class="wordBtn" (click)="add()">Add Model</div>
        </div>

        <jqxTooltip style="float: left;"
                    [position]="'top'"
                    [name]="'movieTooltip'"
                    [autoHideDelay]="30000"
                    [content]="'Click to add Model'"
                    [opacity]="1.0"
                    [width]="200">

          <img class="infoBtn_12" src="assets/infoBtn.png" />

        </jqxTooltip>


        <jqxPopover #popover_ll_company_models_sp style="font-size: 14px; font-family: Verdana;"
                    [offset]='{ left: 50, top: 0 }'
                    [arrowOffsetValue]="200"
                    [width]="350"
                    [height]="150"
                    [position]="'top'"
                    [title]="'Add Model'"
                    [showCloseButton]="true"
                    [selector]="'#add_ll_company_models_sp'">

          <!--text_Name-->
          <div style="clear: left; padding-top:0px; ">

            <div style="float: left; ">
              <img height=8 style="width: auto;" src="assets/icon_asterisk.png">
            </div>

            <div style="float: left; padding-left:1px; padding-top:5px;">

              <div class="txtNorm" style="float: left;">
                Name:
              </div>

              <div style="float: left; padding-left:10px;">
                <jqxTextArea #text_name_ll_company_models_sp
                             [width]="200"
                             [height]="25"
                             [maxLength]="15"
                             [placeHolder]="'Type name here'">
                </jqxTextArea>
              </div>
            </div>
          </div>

          <!--Save Changes-->
          <div style="float:left; padding-left:200px; ">
            <div class="wordBtnGroup_popover">
              <div class="wordBtn14" (click)=" add()">Add Record</div>
            </div>
          </div>

        </jqxPopover>

      </div>

    </div>


    <!--SEARCH-->
    <div id=search_ll_company_models_sp *ngIf="bundle.canFilter"
         [style.top.px]="bundle.lblY - 0"
         [style.width.px]="bundle.spW"
         [style.left.px]="bundle.spW  - 10 "
         style="position: absolute;">


      <div class="wordBtnGroup">
        <div *ngIf="bundle.searchText  == '' ">
          <img class="btnIcon" height=12 style="width: auto;" src="assets/icon_magGlass.png" alt="" (click)="noAction()">
        </div>
        <div *ngIf="bundle.searchText  != '' ">
          <img class="btnIcon" height=14 style="width: auto;" src="assets/icon_filter_red.png" alt="" (click)="noAction()">
        </div>
      </div>

      <jqxPopover #popover_search
                  [offset]='{ left: -85, top: 0 }'
                  [arrowOffsetValue]="-0"
                  [width]="220"
                  [position]="'top'"
                  [title]="'Filter Categories'"
                  [selector]="'#search_ll_company_models_sp'">

        <div style="float: left; padding-left:0px; padding-bottom:10px;" [style.width.px]="200">

          <div (keyup)="onKeyUp($event)" style="float: left; ">
            <jqxTextArea #text_search
                         [width]="140"
                         [height]="25"
                         [placeHolder]="'Type Search'">

            </jqxTextArea>
          </div>

          <div style="float: left; padding-left:10px; padding-top:0px; ">
            <div style="clear:left">
              <div class="wordBtn" (click)="search()">Search</div>
            </div>
            <div style="clear:left">
              <div class="wordBtn" (click)="clear()">Clear</div>
            </div>
          </div>
        </div>



      </jqxPopover>
    </div>

  </div>

  <!-- horzTabs_ll_companys_sp  TABS ACROSS THE TOP-->
  <div [hidden]="!bundle.showObj"
       [style.top.px]="bundle.vertLineY - bundle.hTabH - 3"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;z-index:100">

    <canvas #horzTabs_ll_company_models_sp></canvas>

  </div>

  <!-- ll-info-request-visits-sp-->
  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.activeHorzTab == 'Info. Reqsts'"
       [style.top.px]="bundle.remainingY - 10"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH + 10"
       style="position: absolute; z-index: 100">

    <ll-info-request-visits-sp id="ll_companys_models_sp"></ll-info-request-visits-sp>
  </div>

  <!-- ll-model-info-->
  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.activeHorzTab == 'Parameters'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

      <ll-model-info></ll-model-info>
  </div>


  <!-- model_locations_dg-->
  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.activeHorzTab == 'Locations'">

    <div [hidden]="bundle.mode == 'findCMS'"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; z-index: 100">
      <ll-admin-generic-dg id="model_locations_dg"></ll-admin-generic-dg>
    </div>

    <div *ngIf="bundle.mode == 'findCMS'"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; z-index: 100">

      <div class="wordBtnGroup">
        <img class="btnIcon" height=23 style="width: auto;" src="assets/icon_backArrow.png" alt="" (click)="clearMode() ">
        <div class="wordBtn14" style="padding-left:5px; padding-top:3px;" (click)="clearMode() ">Go Back</div>
      </div>

      <ll-model-cms-crosswalk></ll-model-cms-crosswalk>
    </div>

  </div>

  <!-- model_payers_dg-->
  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.activeHorzTab == 'Payers'">

    <div [hidden]="bundle.mode == 'editPayer'"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; z-index: 100">

      <ll-admin-generic-dg id="model_payers_dg"></ll-admin-generic-dg>
    </div>

    <div *ngIf="bundle.mode == 'editPayer'"
         [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; z-index: 100">

      <div class="wordBtnGroup">
        <img class="btnIcon" height=23 style="width: auto;" src="assets/icon_backArrow.png" alt="" (click)="clearMode() ">
        <div class="wordBtn14" style="padding-left:5px; padding-top:3px;" (click)="clearMode() ">Go Back</div>
      </div>

      <ll-model-payer-edit></ll-model-payer-edit>
    </div>

  </div>

  <!--ll-model-classes-sp-->
  <div *ngIf="bundle.showTab && bundle.showRemaining && bundle.activeHorzTab == 'Classes'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-model-classes-sp id="edit"></ll-model-classes-sp>
  </div>

</div>

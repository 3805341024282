<div #loginMainDiv >
    
    <div *ngIf="bundle.timeWarning; then WARNING; else BLANK"></div>
    <div *ngIf="bundle.showLogIn; then LOGIN; else BLANK"></div>
    <div *ngIf="bundle.pwCode; then PWCODE; else BLANK"></div>
    <div *ngIf="bundle.showChngPW; then CHNGPW; else BLANK"></div>
    <!--<div *ngIf="bundle.showMsgPU; then MSGPU; else BLANK"></div>-->

    <ng-template #BLANK ></ng-template>

    <ng-template #WARNING>

      <div class = "bkgrd_warning"></div>

      <div class = "holder">
        <div class = "subHolder_warning">
          
          <div class = "container_warning" style = "width:400px; height:120px;  " >
            <div style = "padding:20px; " >

              <img height = 50 style = "width: auto; float: left; padding-right:20px;"  src="assets/icon_hermes.png">
              <div class = "txtBold" style = "width:250px; float: left;" >
                
               
                  {{bundle.warningMsg}}
  
  
              </div>

            </div>
            
          </div>

        </div>
        
      </div>
    </ng-template>

    <ng-template #LOGIN>

      <div class = "bkgrd_login"></div>

      <div class = "holder">
        <div class = "subHolder_login">

          <div class="container_login" [style.width.px]="bundle.loginW" [style.height.px]="bundle.loginH" >

            <div [style.width.px]="bundle.loginW - 60" style = "padding-left:30px; padding-top:20px">

              <div class = "txtBold" [style.width.px]="bundle.loginW - 60" style = "text-align: center;" >
                System Login    
              </div>

              <div style = "padding-top:10px" >
                <div class = "failureMsg" *ngIf = "bundle.timedOut" [style.width.px]="bundle.loginW - 60" >Your Session Timed Out</div>

                <div class = "lbl" >User Name / Email</div>

                <jqxInput  #userName class="userName" style = "padding-top:5px; "
                  [width]="bundle.loginW - 60" 
                  [height]="24"                            
                  >              

                </jqxInput>

                <div class = "lbl" >Password</div>

                <jqxPasswordInput  #password class="password" (keyup)="onKeyUp_pw($event)" tabindex="2"  style = "padding-top:5px;  "
                  [width]="bundle.loginW - 60" 
                  [height]="24"
                  >
                
                </jqxPasswordInput>

                <div style = "padding-top:10px; padding-left:250px">
                  <div class = "wordBtn"  (click)="submit()" [style.width.px]="38" >Enter</div>
                </div>                

                
                <div class = "failureMsg" *ngIf = "bundle.showCountMsg" [style.width.px]="bundle.loginW - 60" >{{bundle.countMsg}}</div>
                <div class = "failureMsg" *ngIf = "bundle.missingMsg" [style.width.px]="bundle.loginW - 60" >Both fields are required</div>

               <!-- <div *ngIf = "bundle.showCountMsg" style = "text-align:center; padding-top:10px; padding-left:60px" >
                  <div class = "wordBtn"  (click)="getChangePWCode()" [style.width.px]="200" >Click here to change your password</div>
                </div>-->

                <div style = "text-align:center; padding-top:10px; padding-left:60px" >
                  <div class = "wordBtn"  (click)="getChangePWCode()" [style.width.px]="200" >Click here to change your password</div>
                </div>
     
              </div>

              <div class = "mediantBrand" [style.width.px]="bundle.loginW - 60" [style.top.px]="bundle.loginH - 30" >
                {{bundle.brandTxt}}   
              </div>

              

            </div>
          </div>
        </div>
      </div>  

    </ng-template>

    <ng-template #PWCODE>

      <div class = "bkgrd_login"></div>

      <div class = "holder">
        <div class = "subHolder_login">

          <div class="container_login" [style.width.px]="bundle.loginW" [style.height.px]="275" >

            <div [style.width.px]="bundle.loginW - 60" style="padding-left:30px; padding-top:30px">

              <div class="txtBold" [style.width.px]="bundle.loginW - 60" style="text-align: center;">
                Password Change
              </div>

              <div class="txtNorm" [style.width.px]="bundle.loginW - 60" style="text-align: center; padding-top:15px">
                A code has been emailed to {{bundle.userName}}. Please enter it below within 10 minutes.
              </div>

              <div class="txtBold" [style.width.px]="bundle.loginW - 60" style="text-align: center; padding-top:10px">
                If an email does not arrive soon, look in your spam folder or have us send it again.
              </div>

              <div style="padding-top:10px; padding-left:30px;  ">
                <div class="txtBold" style="float:left; padding-right:10px">Code:</div>

                <jqxInput #PWCode class="changePWCode" style="float:left; "
                          [width]="bundle.loginW - 200"
                          [height]="24">

                </jqxInput>

              </div>

              <div style="padding-top:30px; padding-left:200px">
                <div class="wordBtn" (click)="confirmPWCode()" [style.width.px]="38">Enter</div>
              </div>

              <div class="failureMsg" *ngIf="bundle.showCountMsg" [style.width.px]="bundle.loginW - 60">{{bundle.countMsg}}</div>



              <div class="mediantBrand" [style.width.px]="bundle.loginW - 60" [style.top.px]="275 - 30" style="clear:left;">
                {{bundle.brandTxt}}
              </div>

            </div>
          </div>
        </div>
      </div>  

    </ng-template>

    <ng-template #CHNGPW>

      <div class = "bkgrd_login"></div>

      <div class = "holder">
        <div class = "subHolder_login">

          <div class="container_login" [style.width.px]="bundle.loginW" [style.height.px]="bundle.loginH" >

            <div [style.width.px]="bundle.loginW - 60" style = "padding-left:30px; padding-top:20px">

              <div class = "txtBold" [style.width.px]="bundle.loginW - 60" style = "text-align: center;" >
                {{bundle.chngPWHeader}} 
              </div>

              <div style = "padding-top:10px" >
                <div class = "failureMsg" *ngIf = "bundle.timedOut" [style.width.px]="bundle.loginW - 60" >Your Session Timed Out</div>

                <div class = "lbl" >New Password</div>

                <jqxPasswordInput  #newPassword class="password" (keyup)="onKeyUp_newPW($event)" style = "padding-top:5px; "
                  [width]="bundle.loginW - 60" 
                  [height]="24"                        
                  >              

                </jqxPasswordInput>

                

                <div class = "lbl" >Confirm New Password</div>

                <jqxPasswordInput  #confirmPassword class="password" (keyup)="onKeyUp_confirmPW($event)" style = "padding-top:5px;  "
                  [width]="bundle.loginW - 60" 
                  [height]="24"
                  >
                
                </jqxPasswordInput>

                <div style = "padding-top:10px; padding-left:250px">
                  <div class = "wordBtn"  (click)="changePassword()" [style.width.px]="38" >Enter</div>
                </div> 

                <div class = "PWStrengthMsg" [style.width.px]="bundle.loginW - 60" >{{bundle.PWStrengthMsg}}</div>
                
                <div class = "PWStrengthMsg" *ngIf = "bundle.showPWMatchMsg" [style.width.px]="bundle.loginW - 60" >{{bundle.PWMatchMsg}}</div>
                
                
              </div>

              <div class = "mediantBrand" [style.width.px]="bundle.loginW - 60" [style.top.px]="bundle.loginH - 30" >
                {{bundle.brandTxt}}   
              </div>

            </div>
          </div>
        </div>
      </div>  

    </ng-template>

</div>




<div #mainDiv_ll_company_switch>

  <div *ngIf="bundle.companyId != 0 && bundle.showRemaining"
         [style.top.px]="10"
         [style.left.px]="bundle.companyLabelX"
         [style.width.px]="bundle.remainingW - bundle.companyLabelX - 10"
         style="position: absolute">

     <div class="txtBold_12" style="float: left; padding-left: 0px; ">
       SysID:
     </div>

     <div class="txtNorm12" style="float: left; padding-left: 10px; ">
       {{bundle.companyId}}
     </div>

     <div class="txtBold_12" style="float: left; padding-left: 10px; ">
       Company Name:
     </div>

     <div class="txtNorm12" style="float: left; padding-left: 10px; ">
       {{bundle.companyName}}
     </div>

     <div class="txtBold_12" style="float: left; padding-left: 10px; ">
       Company Abrev.:
     </div>

     <div class="txtNorm12" style="float: left; padding-left: 10px; ">
       {{bundle.companyAbrev}}
     </div>
   </div>


   <!-- horzTabs_ll_company_switch  TABS ACROSS THE TOP-->
  <div [hidden]="!bundle.showHorzTabs"
       [style.top.px]="bundle.horzTabsY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute;z-index:100">

    <canvas #horzTabs_ll_company_switch></canvas>

  </div>



  <!-- ll-company-models-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Models'">
    <div [style.top.px]="bundle.remainingY"
         [style.left.px]="bundle.remainingX"
         [style.width.px]="bundle.remainingW"
         [style.height.px]="bundle.remainingH"
         style="position: absolute; z-index: 100">

      <ll-company-models-sp></ll-company-models-sp>
    </div>
  </div>


  <!-- ll-contacts-sp-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Users'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-contacts-sp id="from_ll_company_switch"></ll-contacts-sp>
  </div>

  <!-- ll-company-info-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Company'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-company-info></ll-company-info>
  </div>

  <!--Logins-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Logins'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-admin-generic-dg id="logins_from_ll_company_switch"></ll-admin-generic-dg>
  </div>

  <!--Emails-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Emails'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-admin-generic-dg id="emails_from_ll_company_switch"></ll-admin-generic-dg>
  </div>

  <!--Entry-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'Entry'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <ll-admin-generic-dg id="entry_from_ll_company_switch"></ll-admin-generic-dg>
  </div>

  <!--Admin Trail-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'adminTrail'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <!--<ll-admin-generic-dg id="adminTrail_from_ll_company_switch"></ll-admin-generic-dg>-->
  </div>

  <!--System Errors-->
  <div *ngIf="bundle.showRemaining && bundle.activeHorzTab == 'errors'"
       [style.top.px]="bundle.remainingY"
       [style.left.px]="bundle.remainingX"
       [style.width.px]="bundle.remainingW"
       [style.height.px]="bundle.remainingH"
       style="position: absolute; z-index: 100">

    <!--<ll-admin-generic-dg id="errors_from_ll_company_switch"></ll-admin-generic-dg>-->
  </div>

</div>


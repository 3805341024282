export function filter(arr, field01, search):any{
   
    let returnArr = [];

    for(let i=0; i< arr.length; i++){        

        let searchLC = search.toLowerCase();

        if(arr[i][field01] != null){

            let target01  =  arr[i][field01].toLowerCase();

            if (target01.includes(searchLC)){
                returnArr[returnArr.length] = arr[i];
            }
        }

       
    }

    return returnArr;
   
}

<div #mainDiv_quest_graph>

  <div *ngIf="bundle.showObj">

    <div style="float: left; padding-top: 10px;">


      <div class="txtNorm" style="float: left;">
        Variable One:
      </div>

      <div style="float: left; padding-left: 10px;">
        <jqxDropDownList #dropDown_leftElement
                         [theme]="'material-green'"
                         [width]="300"
                         [height]="25"
                         [source]="bundle.elementChoices"
                         [dropDownHorizontalAlignment]="'left'"
                         [dropDownVerticalAlignment]="'bottom'"
                         [dropDownWidth]="300"
                         [disabled]='false'
                         (onSelect)="selectLeftIndex($event)"
                         [selectedIndex]="bundle.leftElementId">

        </jqxDropDownList>
      </div>

      <div class="txtNorm" style="float: left; padding-left: 20px;">
        Variable Two:
      </div>

      <div style="float: left; padding-left: 10px;">
        <jqxDropDownList #dropDown_rightElement
                         [theme]="'material-green'"
                         [width]="300"
                         [height]="25"
                         [source]="bundle.elementChoices"
                         [dropDownHorizontalAlignment]="'left'"
                         [dropDownVerticalAlignment]="'bottom'"
                         [dropDownWidth]="300"
                         [disabled]='false'
                         (onSelect)="selectRightIndex($event)"
                         [selectedIndex]="bundle.rightElementId">

        </jqxDropDownList>
      </div>

    </div>





  </div>

  <div [style.top.px]="80"
       [style.left.px]="20"
       style="position: absolute;">
    <jqxChart #myChart_line
              [width]="bundle.objW - 40"
              [height]="bundle.objH - 100"
              [showLegend]="true"
              [enableAnimations]="true"
              [colorScheme]="'scheme04'"
              [enableCrosshairs]="true"
              [crosshairsColor]="'#111888'"
              [crosshairsLineWidth]="2"
              [showBorderLine]="false">
    </jqxChart>
  </div>

</div>

export function removeTextChars(text):string{  
    let allowed =["0","1", "2", "3", "4", "5", "6", "7", "8", "9", ".", ",", "-"]
    
    let result = "";

    for (var i = 0; i < text.length; i++) {
        for (var j = 0; j < allowed.length; j++) {
            if(text.charAt(i) == allowed[j]){
                result = result + text.charAt(i);
            }
        }
      }

    return result
   
}

export function removeAntiFolderChars(text):string{  
    let notAllowed =['/', ',',':',';',"'" ]
    
    let result = "";

    for (var i = 0; i < text.length; i++) {

        let add = true;

        for (var j = 0; j < notAllowed.length; j++) {
            
            if(text.charAt(i) == notAllowed[j]){
                add = false
            }
        }

        if(add == true){
            result = result + text.charAt(i);
        }
      }

    return result
   
}

export function removeNumberCommas(text): string{
    let allowed =["0","1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "-"]
    
    let result = "";

    for (var i = 0; i < text.length; i++) {
        for (var j = 0; j < allowed.length; j++) {
            if(text.charAt(i) == allowed[j]){
                result = result + text.charAt(i);
            }
        }
      }

    return result
   
}

export function allowOnlyNumbers(text):string{  
    let allowed =["0","1", "2", "3", "4", "5", "6", "7", "8", "9"]
    
    let result = "";

    for (var i = 0; i < text.length; i++) {
        for (var j = 0; j < allowed.length; j++) {
            if(text.charAt(i) == allowed[j]){
                result = result + text.charAt(i);
            }
        }
      }

    return result
   
}

export function removeCarriageReturn(text):string{  
    //return text.replace(/\s+/g, ' ').trim();
    //return text.replace(/[\n\r]+/g, '');
    return text.replace(/\r?\n|\r/, '');
}

export function addUSD(text):string{  
    return "$ "+text;
}

export function formatCurrency(inbound)
{
    //num = num.toString().replace(/\$|\,/g, '');

    inbound  = inbound.toString().replace(/\$|\,/g, '');

    let num = inbound
    let cents = ""

    let num_txt = String(inbound)

    ///console.log(num_txt.indexOf("."))

    if(num_txt.indexOf(".") > -1){

        let dec_arr = num_txt.split(".");
        
        num = dec_arr[0];
        cents = "."+dec_arr[1].substring(0, 2);

        //if(cents.length == 2){
            //cents = cents +"0"
        //}
    }

    if (isNaN(num))
    {
        num = "0";
    }

    let sign = (num == (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    //let cents:any = num % 100;
    num = Math.floor(num / 100).toString();

    //if (cents < 10)
    //{
        //cents = "0" + cents;
    //}

    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
    {
        num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
    }

    //return (((sign) ? '' : '-') + '$' + num + '.' + cents);

    if(cents == ''){
        return (((sign) ? '' : '-') + '$' + num);
    }else{
        return (((sign) ? '' : '-') + '$' + num + cents);  
    }
}

export function formatNumber(num)
{
    num = num.toString().replace(/\$|\,/g, '');

    if (isNaN(num))
    {
        num = "0";
    }

    let sign = (num == (num = Math.abs(num)));
    num = Math.floor(num * 100 + 0.50000000001);
    let cents:any = num % 100;
    num = Math.floor(num / 100).toString();

    if (cents < 10)
    {
        cents = "0" + cents;
    }
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
    {
        num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
    }

    ////return (((sign) ? '' : '-') + '$' + num + '.' + cents);
    return (((sign) ? '' : '-')  + num );
}

export function formatGraphNumber_old(num, DISPLAYFORMAT)
{
  
    //console.log(Number(num))

    /////console.log(DISPLAYFORMAT)

    if(DISPLAYFORMAT == 'c'){  ///currency
        num = num.toString().replace(/\$|\,/g, '');

        if (isNaN(num))
        {
            num = "0";
        }

        let sign = (num == (num = Math.abs(num)));        
        num = Math.floor(num * 100 + 0.50000000001);
        
        let cents:any = num % 100;
        num = Math.floor(num / 100).toString();

        if (cents < 10)
        {
            cents = "0" + cents;
        }
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
        {
            num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
        }

        ////return (((sign) ? '' : '-') + '$' + num + '.' + cents);

        ///console.log("num: "+num)

        return (((sign) ? '' : '-')  + num );

    }else{  
        num = num.toString().replace(/\$|\,/g, '');

        if (isNaN(num))
        {
            num = "0";
        }

        let sign = (num == (num = Math.abs(num)));        
        num = Math.floor(num * 100 + 0.50000000001);
        
        let cents:any = num % 100;
        num = Math.floor(num / 100).toString();

        if (cents < 10)
        {
            cents = "0" + cents;
        }
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
        {
            num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
        }

        return (((sign) ? '' : '-') + num + '.' + cents);

        ///console.log("num: "+num)

        //return (((sign) ? '' : '-')  + num );
    //}else{
        //return num 
    }
}

export function formatGraphNumber(num, DISPLAYFORMAT)
{
  
    //console.log(Number(num))

    /////console.log(DISPLAYFORMAT)

    if(DISPLAYFORMAT == 'c'){  ///currency
        num = num.toString().replace(/\$|\,/g, '');

        if (isNaN(num))
        {
            num = "0";
        }

        let sign = (num == (num = Math.abs(num)));        
        num = Math.floor(num * 100 + 0.50000000001);
        
        let cents:any = num % 100;
        num = Math.floor(num / 100).toString();

        if (cents < 10)
        {
            cents = "0" + cents;
        }

        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
        {
            num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
        }

        ////return (((sign) ? '' : '-') + '$' + num + '.' + cents);

        ///console.log("num: "+num)

        return (((sign) ? '' : '-')  + num + '.' + cents);

        ///return (num + '.' + cents);

    }else{  
        num = num.toString().replace(/\$|\,/g, '');

        if (isNaN(num))
        {
            num = "0";
        }

        let sign = (num == (num = Math.abs(num)));        
        num = Math.floor(num * 100 + 0.50000000001);
        
        let cents:any = num % 100;
        num = Math.floor(num / 100).toString();

        if (cents < 10)
        {
            cents = "0" + cents;
        }
        for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
        {
            num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
        }

        return (((sign) ? '' : '-') + num + '.' + cents);

        ///return (num + '.' + cents);
    }
}

export function stringToNumber(num, DISPLAYFORMAT, decimalPlaces) {
  
  num = num.toString().replace(/\$|\,/g, '');

  let cents = "";
  let num_txt = String(num)

  if (num_txt.indexOf(".") > -1) {

    let dec_arr = num_txt.split(".");

    num = dec_arr[0];
    cents = dec_arr[1].substring(0, decimalPlaces);

  } else {
    
    let dflt = "0000000";

    cents = dflt.substring(0, decimalPlaces);
  }


  //if (DISPLAYFORMAT == 'c') {  ///currency

    if (isNaN(num)) {
      num = "0";
    } else {
      num = numberWithCommas(num)
    }

  if (decimalPlaces > 0) {

    let len = cents.length
    let diff = decimalPlaces - len;

    if (diff == 1) {
      cents = cents + '0';
    } else if (diff == 2) {
      cents = cents + '00';
    } else if (diff == 3) {
      cents = cents + '000';
    } else if (diff == 4) {
      cents = cents + '0000';
    }

    return (num + "." + cents);

  } else {
    return (num);
  }
    

  //} else {
    
  //}
}

function numberWithCommas(x) {
  ///x = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
    x = x.replace(pattern, "$1,$2");
  return x;
}

export function formatDecimalPlaces(inbound, decimal_places) {

  inbound = inbound.toString().replace(/\$|\,/g, '');

  let num = inbound
  let cents = ""

  let num_txt = String(inbound)

  ////console.log(num_txt.indexOf("."))

  if (num_txt.indexOf(".") > -1) {

    let dec_arr = num_txt.split(".");

    num = dec_arr[0];
    cents = "." + dec_arr[1].substring(0, decimal_places);

  }

  if (isNaN(num)) {
    num = "0";
  }

  let sign = (num == (num = Math.abs(num)));
  num = Math.floor(num * 100 + 0.50000000001);
  num = Math.floor(num / 100).toString();

  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
    num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
  }

  if (cents == '') {
    return (((sign) ? '' : '-') + num);
  } else {
    return (((sign) ? '' : '-') + num + cents);
  }
}


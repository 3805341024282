<div #mainDiv_ll_model_metric_display>

  <div [hidden]="!bundle.showDisplay">

    <div *ngIf="bundle.graphArea_show"
         [style.top.px]="bundle.graphAreaY"
         [style.left.px]="bundle.graphAreaX"
         [style.width.px]="bundle.graphAreaW"
         [style.height.px]="bundle.graphAreaH"
         style="position: absolute;  outline: 2px dashed white;  ">

      <div [style.top.px]="0"
           [style.left.px]="0"
           [style.width.px]="bundle.graphW"
           [style.height.px]="bundle.graphH"
           style="position: absolute;">
        <ll-model-metric-graph></ll-model-metric-graph>
      </div>

      <div [style.left.px]="bundle.graphW - 120"
           [style.top.px]="bundle.graphH - 25"
           [style.width.px]="120"
           [style.height.px]="100"
           style="position: absolute; background-color:white">
      </div>
    </div>


    <div *ngIf="bundle.displayArea_show"
         [style.top.px]="bundle.displayAreaY"
         [style.left.px]="bundle.displayAreaX"
         style="position: absolute;  outline: 2px dashed white;  ">

      <jqxPanel [width]="bundle.displayAreaW"
                [height]="bundle.displayAreaH"
                [autoUpdate]="true"
                style="position: absolute; white-space: nowrap; outline: none;  border-bottom: 2px solid #ccc;">


        <!--Date lables--->
        <div *ngFor="let record of bundle.records;">

          <div *ngIf="record.COLYN == 'Y' "
               [style.left.px]="record.colX"
               [style.width.px]="100"
               style="position: absolute;">

            <div [style.top.px]="0"
                 [style.left.px]=""
                 [style.width.px]="100"
                 style="position: absolute;">

              <!--<img width = 100 height = 20 src="assets/icon_bracket.png"  >-->

              <div [style.left.px]="0"
                   [style.top.px]="10"
                   [style.width.px]="100"
                   style="position: absolute;
                            text-align: center;
                            font-size: 12px;
                            font-weight: bold;
                            font-family: Arial;
                            color:black; ">

                {{record.PERIODENDDATE_DATE}}
              </div>
            </div>


          </div>
        </div>

        <div *ngFor="let form of bundle.forms; index as i" style="white-space: nowrap;">

          <div [style.height.px]="10"></div>

          <div class="arLoanForm"
               [style.width.px]="bundle.metricLabelsW"
               [style.height.px]="20"
               style="border-bottom: 1px solid white; ">

            {{form.FORMNAME}}

          </div>

        <tr *ngFor="let element of bundle.elements | visitCategory:{FORMID: form.FORMID}" style="clear:left; white-space:nowrap;">


          <div [id]="element.ELEMENT"
               [style.left.px]="0"
               [style.width.px]="bundle.displayAreaW"
               [style.height.px]="20"
               style=" border-bottom: 1px solid white;  ">

            <div style="float: left;  ">

              <jqxTooltip [position]="'right'"
                          [name]="'movieTooltip'"
                          [autoHideDelay]="30000"
                          [content]="element.DESCRIPTION"
                          [opacity]="1.0"
                          [width]="400">

                <img class="infoBtn" src="assets/infoBtn.png" />

              </jqxTooltip>

            </div>

            <div class="arLoanLabel" style="float: left; white-space:nowrap; ">
              {{element.LABEL}}
            </div>


            <div *ngFor="let record of bundle.records | visitCategory:{BENCHMARKGROUPID: element.BENCHMARKGROUPID};">

              <div *ngIf="record.COLYN == 'Y'"
                   [style.left.px]="record.colX"
                   [style.width.px]="100"
                   style="position: absolute;">

                <div [style.left.px]="-20"
                     [style.top.px]="2"
                     [style.width.px]="100"
                     style="position: absolute;
                   text-align: right;
                   font-size: 12px;
                   font-family: Arial;
                   color:black; ">

                  {{record[bundle.activeELEMENT]}}

                </div>

              </div>
            </div>

          </div>
        </tr>


    </div>

      </jqxPanel>
    </div>


  
  </div>

  <!--BOTTOM-->
  <div [style.left.px]="bundle.displayAreaX"
       [style.top.px]="bundle.displayAreaY + bundle.displayAreaH + 10"
       style="position: absolute;">

    <div class="wordBtn_11" (click)="downloadCSVReport()">Download CSV Report</div>
  </div>

</div>

